import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;

  .container {
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    overflow-y: auto;
    .account {
      .inputContainer {
        .space {
          opacity: 0;
        }
      }
    }
    section {
      display: flex;
      flex-direction: column;
      margin-top: 7px;

      h1 {
        font-size: 24px;
        line-height: 36px;
        color: ${colors.darkOrange};
      }

      .inputContainer {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;

        fieldset {
          width: 30%;
          border: none;
          margin-top: 10px;
          position: relative;

          p {
            font-size: 14px;
            color: ${colors.mediumGray};

            input :focus {
              p {
                color: red;
              }
            }
          }

          input {
            border: none;
            border-bottom: 1px solid ${colors.mediumGray};
            color: ${colors.mediumGray};
            width: 100%;
            outline: none;
            margin-top: 8px;
            font-size: 14px;
            background-color: transparent;

            :focus {
              border-bottom: 1px solid ${colors.orange};
            }
          }

          .eye {
            color: ${colors.opacityGray};
            position: absolute;
            bottom: 6%;
            right: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .header {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 12px rgba(17, 14, 27, 0.13);
    padding: 24px;

    h1 {
      font-size: 32px;
      color: ${colors.darkOrange};
    }
  }

  .actions {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .error {
      .errors {
        color: red;
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;

      div {
        margin-left: 5px;
      }
    }
  }
`;
