import styled from 'styled-components';

export const HeaderContainer = styled.div`
  width: 100%;
  height: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .content {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    header {
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap-reverse;
      align-items: center;

      .buttonBack {
        width: 20%;
        display: flex;
        align-items: center;
        span {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          color: #fff;
          margin-left: 13px;
        }
      }

      img {
        width: 50%;
        justify-self: center;
      }
    }

    nav {
      width: 250px;
      display: flex;
      justify-content: space-around;

      div {
        display: flex;
        align-items: center;
        span {
          margin-left: 10px;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          color: #fff;
        }
      }
    }
  }
`;
