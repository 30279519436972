import styled from 'styled-components';

import { size, device } from '../../../constants/size';
import { colors } from '../../../constants/colors';

export const Wrapper = styled.div`
  .sectionOne {
    background: transparent
      linear-gradient(180deg, ${colors.orange} 0%, ${colors.pink} 100%) 0% 0%
      no-repeat padding-box;
    height: 516px;
    img {
      width: 100%;
      height: 105%;
      opacity: 0.16;
    }

    .title {
      position: absolute;
      top: 55%;
      left: ${size.mobile.padding};

      h1 {
        font-size: 32px;
        color: #fff;
      }
      p {
        font-size: 24px;
        color: #fff;
      }
    }
  }
  .border {
    background-color: ${colors.orange};
    width: 70%;
    height: 12px;
    margin-top: -6px;
    z-index: 2;
  }

  @media ${device.desktop} {
    .sectionOne {
      display: flex;
      align-items: center;
      padding-left: ${size.desktop.padding};

      img {
        width: auto;
        height: inherit;
      }

      .title {
        position: initial;
        margin-left: -3px;
      }
    }
  }
`;
