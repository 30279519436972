/* eslint-disable prefer-destructuring */
export function FormatUser(user) {
  const formattedUser = user;
  delete formattedUser.created_at;
  delete formattedUser.updated_at;
  delete formattedUser.resetPasswordExpires;
  delete formattedUser.plan.created_at;
  delete formattedUser.avatar.created_at;

  formattedUser.birth_date = formattedUser.birth_date.split('T')[0];
  formattedUser.subscription_aquisition = formattedUser.birth_date.split(
    'T',
  )[0];

  return formattedUser;
}
