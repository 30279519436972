import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import RouteWithLayout from './components/RouteWithLayout';
import User from './Layout/User';

import Home from './pages/Home';
import Planos from './pages/Planos';
import AbrirMEI from './pages/AbrirMEI';
import FAQ from './pages/FAQ';
import Login from './pages/Login';
import RecuperarSenha from './pages/RecuperarSenha';
import RegisterUser from './pages/RegisterUser';
import NovaSenha from './pages/NovaSenha';
import Profile from './pages/Profile';
import DadosMEI from './pages/DadosMEI';
import DashboardRelatorios from './pages/Relatorios';
import AlterarSenhaDashboard from './pages/AlterarSenhaDashboard';
import AlterarSenhaDashboardMobile from './pages/Mobile/AlterarSenhaDashboard';
import PlanosDashboard from './pages/PlanosDashboard';
import Pagamento from './pages/Pagamento';
import EmployeeRegistration from './pages/EmployeeRegistration';
import AdminLogin from './pages/AdminLogin';
import SearchClients from './pages/Gestao/SearchClients';
import SearchOverdue from './pages/Gestao/SearchOverdue';
import DetailClients from './pages/Gestao/DetailClients';
import DadosMeiADM from './pages/Gestao/ShowClientMei';
import NovoSertec from './pages/Gestao/NovoSertec';
import ManagementPlans from './pages/Gestao/ManagementPlans';
import LoginMobile from './pages/Mobile/Login';
import PagamentoMobile from './pages/Mobile/Pagamento';
import RegisterUserMobile from './pages/Mobile/RegisterUser';
import RecuperarSenhaMobile from './pages/Mobile/RecuperarSenha';
import NovaSenhaMobile from './pages/Mobile/NovaSenha';
import ProfileMobile from './pages/Mobile/Profile';
import RelatoriosMobile from './pages/Mobile/Relatorios';
import DadosMEIMobile from './pages/Mobile/DadosMEI';
import PlanosMobile from './pages/Mobile/Planos';
import EmployeeRegistrationMobile from './pages/Mobile/EmployeeRegistration';

export default function Routes() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/planos" component={Planos} />
        <Route exact path="/abrir" component={AbrirMEI} />
        <Route exact path="/faq" component={FAQ} />
        <Route
          exact
          path="/login"
          component={windowWidth >= 769 ? Login : LoginMobile}
        />
        <Route
          exact
          path="/cadastrar"
          component={windowWidth >= 769 ? RegisterUser : RegisterUserMobile}
        />
        <Route
          exact
          path="/resetPassword/:token"
          component={windowWidth >= 769 ? NovaSenha : NovaSenhaMobile}
        />
        <Route exact path="/cadastrar" component={RegisterUser} />
        <Route
          exact
          path="/recuperarSenha"
          component={windowWidth >= 769 ? RecuperarSenha : RecuperarSenhaMobile}
        />
        <Route exact path="/gestao/login" component={AdminLogin} />

        <PrivateRoute
          exact
          path="/gestao/novo-usuario-sertec"
          component={NovoSertec}
          isAdm
        />
        <PrivateRoute
          /* TODO: checar se o usuario esta logado, caso contrario encaminhar para login */
          exact
          path="/dashboard/profile"
          component={Profile}
          mobileComponent={ProfileMobile}
        />
        <PrivateRoute
          exact
          path="/dashboard/dados-mei"
          component={DadosMEI}
          mobileComponent={DadosMEIMobile}
        />

        <PrivateRoute
          exact
          path="/dashboard"
          // Deve ser declarado da seguinte forma componente : desktop e mobileComponente : mobile
          component={DashboardRelatorios}
          mobileComponent={RelatoriosMobile}
        />

        <PrivateRoute
          exact
          path="/dashboard/alterarSenha"
          component={AlterarSenhaDashboard}
          mobileComponent={AlterarSenhaDashboardMobile}
        />
        <PrivateRoute
          exact
          path="/dashboard/pagamento"
          component={Pagamento}
          mobileComponent={PagamentoMobile}
        />
        <PrivateRoute
          exact
          path="/dashboard/planos"
          component={PlanosDashboard}
          mobileComponent={PlanosMobile}
        />

        <PrivateRoute
          exact
          path="/dashboard/employeeRegistration"
          component={EmployeeRegistration}
          mobileComponent={EmployeeRegistrationMobile}
        />

        <PrivateRoute
          /* TODO: checar se o usuario esta logado, caso contrario encaminhar para login */
          exact
          path="/gestao/dashboard"
          component={SearchClients}
          isAdm
        />

        <PrivateRoute
          /* TODO: checar se o usuario esta logado, caso contrario encaminhar para login */
          exact
          path="/gestao/client"
          component={DetailClients}
          isAdm
        />
        <PrivateRoute
          /* TODO: checar se o usuario esta logado, caso contrario encaminhar para login */
          exact
          path="/gestao/client/mei"
          component={DadosMeiADM}
          isAdm
        />

        <PrivateRoute
          /* TODO: checar se o usuario esta logado, caso contrario encaminhar para login */
          exact
          path="/gestao/client/overdue"
          component={SearchOverdue}
          isAdm
        />

        {/* <PrivateRoute
          
          exact
          path="/gestao/plans"
          component={ManagementPlans}
          isAdm
        /> */}

        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
}
