import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: scroll;
`;

export const SectionTitle = styled.h3`
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  color: #ff6d24;
  text-align: center;
  margin-bottom: 16px;
`;

export const Input = styled.input`
  border: 1px solid #262626;
  box-sizing: border-box;
  border-radius: 8px;
  max-width: 250px;
  width: 100%;
  height: 36px;
  margin-bottom: 12px;
  padding-left: 5px;
  &:focus {
    border-color: #ff6d24;
  }
`;

export const Label = styled.label`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 5px;
`;
export const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 250px;
  width: 100%;
  height: 100%;
  margin-top: 20px;
  div {
    width: 100%;
  }
`;
export const Button = styled.button`
  width: 100%;
  height: 32px;
  color: #fff;
  background-color: #ff6d24;
  border-radius: 8px;
  border: 0;
  margin-bottom: 40px;
`;

export const CancelButton = styled.button`
  width: 47%;
  height: 32px;
  color: #ff6d24;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ff6d24;
  margin-bottom: 40px;
`;

export const LineDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ErrorBox = styled.span`
  color: red;
  margin-top: -50px;
  margin-bottom: 20px;
  font-size: 12px;
  /* margin-left: 5px; */
  max-width: 80%;
`;
