import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { size, device } from '../../constants/size';
import { ButtonContainer } from '../Button/styles';

export const Wrapper = styled.div`
  background: transparent
    linear-gradient(180deg, ${colors.pink} 0%, ${colors.orange} 120%) 0% 0%
    no-repeat padding-box;

  .container {
    width: 100%;
    padding: ${size.mobile.padding};
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
      display: none;
    }

    .description {
      h1 {
        color: #f5f5f5;
        font-size: 32px;
      }
      p {
        color: #ffffff;
        font-size: 18px;
        margin-top: ${size.mobile.margin};
      }
    }

    img {
      width: 100%;
    }
  }

  @media ${device.desktop} {
    .container {
      padding: 25px 0 25px ${size.desktop.padding};
      height: 100%;
      position: relative;
      display: grid;
      grid-template-areas: 'description imagem' ' botao imagem';

      .logo {
        display: block;
        opacity: 0.16;
        position: absolute;
        height: 104%;
        top: -22px;
        left: 32%;
      }

      .description {
        grid-area: description;
        align-self: flex-end;
      }

      img {
        grid-area: imagem;
        width: auto;
        height: 450px;
      }

      .ipad {
        z-index: 1;
      }

      .button {
        grid-area: botao;
        align-self: flex-start;
        margin-top: 16px;
      }
    }
  }
`;

export const ButtonDowload = styled(ButtonContainer)`
  width: 270px;
  height: 70px;
  background-color: transparent;
  border: 2px solid ${colors.white};

  span {
    color: ${colors.white};
    font-weight: bold;
    font-size: 1em;
  }

  &:hover {
    background-color: #ffffff;
    span {
      color: ${colors.orange};
    }
  }
  &:active {
    background-color: ${colors.orange};
    border-color: ${colors.orange};
    span {
      color: ${colors.white};
    }
  }
`;
