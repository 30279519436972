import styled from 'styled-components';

import { colors } from '../../../constants/colors';
import { device } from '../../../constants/size';

import woman1 from '../../../assets/jpg/woman1.jpg';

export const Section = styled.div`
  @media ${device.desktop} {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: ${colors.white};
    #section1 {
      display: flex;
      position: relative;
      width: 100%;
      height: 100%;
      background-image: url(${woman1});
      background-repeat: no-repeat;
      background-size: auto 70vw;
      background-position-x: 50%;

      /* 
      ----------------------------------------------------------------
      after - efeito de opacidade
       */
      &::after {
        position: absolute;
        content: '';
        background: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 100%;
        z-index: 6;
      }

      /* 
      ----------------------------------------------
      before - linha laranja
       */
      &::before {
        display: flex;
        align-self: flex-end;
        width: 55%;
        height: 10px;
        content: '';
        background: ${colors.orange};
        z-index: 8;
      }

      #bannerTextDesktop {
        display: flex;
        position: absolute;
        z-index: 7;
        bottom: 35px;
        left: 200px;
        width: 500px;
      }
      #text-container {
        display: flex;
        width: 25%;
        position: absolute;
        top: 35%;
        left: 10%;
        p {
          font-weight: 500;
          font-size: 35px;
          color: ${colors.white};
          z-index: 5000;
        }
      }
    }

    #section2 {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      background: ${colors.white};
      p {
        color: rgba(112, 112, 112, 1);
        padding: 25px 25px 60px 0px;
        width: 30%;
        margin-right: 35px;
      }
    }
  }

  @media ${device.mobile} {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 82vh;
    #section1 {
      display: flex;
      position: relative;
      width: 100%;
      height: 100%;
      background-image: url(${woman1});
      background-repeat: no-repeat;
      background-size: auto 140vw;
      background-position-x: 60%;
      background-position-y: inherit;
      &::after {
        position: absolute;
        content: '';
        background: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 100%;
        z-index: 6;
      }
      &::before {
        display: flex;
        align-self: flex-end;
        width: 70%;
        height: 10px;
        content: '';
        background: ${colors.orange};
        z-index: 8;
      }
      #bannerText {
        display: flex;
        position: absolute;
        width: 150px;
        z-index: 7;
        top: 230px;
        left: 40px;
      }
      #text-container {
        display: flex;
        width: 25%;
        position: absolute;
        top: 35%;
        left: 10%;
        z-index: 40;
        p {
          font-weight: 500;
          font-size: 25px;
          color: ${colors.white};
          z-index: 5000;
        }
      }
    }

    #section2 {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: ${colors.white};
      p {
        color: rgba(112, 112, 112, 1);
        padding: 15px 26px 25px;
        width: 100%;
      }
    }
  }
`;
