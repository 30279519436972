/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { AiOutlineLeft, AiOutlineUserAdd } from 'react-icons/ai';
import { BiLogIn } from 'react-icons/bi';
import { useHistory, useLocation } from 'react-router-dom';
import { HeaderContainer } from './styles';
import Logo from '../../../assets/svg/logoHeaderDesktop.svg';

const HeaderMobileLogin = () => {
  const history = useHistory();
  const location = useLocation();
  const pathName = location.pathname;

  return (
    <HeaderContainer>
      <div className="content">
        <header>
          <div className="buttonBack" onClick={() => history.goBack()}>
            <AiOutlineLeft color="#FFF" size={14} />
            <span>Voltar</span>
          </div>
          <img src={Logo} alt="Logo" />
          <div style={{ width: '20%' }} />
        </header>

        <nav>
          <div
            className="login"
            style={{
              borderBottom: pathName === '/login' ? '1px solid #fff' : 'none',
            }}
            onClick={() => history.push('/login')}
          >
            <BiLogIn color="#FFF" size={14} />
            <span
              style={{ fontWeight: pathName === '/login' ? 'bold' : 'normal' }}
            >
              Login
            </span>
          </div>
          <div
            className="register"
            style={{
              borderBottom:
                pathName === '/cadastrar' ? '1px solid #fff' : 'none',
            }}
            onClick={() => history.push('/cadastrar')}
          >
            <AiOutlineUserAdd color="#FFF" size={14} />
            <span
              style={{
                fontWeight: pathName === '/cadastrar' ? 'bold' : 'normal',
              }}
            >
              Criar Conta
            </span>
          </div>
        </nav>
      </div>
    </HeaderContainer>
  );
};

export default HeaderMobileLogin;
