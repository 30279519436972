import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Content = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
  header {
    width: 100%;
    height: 100px;
    min-height: 100px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    box-shadow: 0px 4px 12px rgba(17, 14, 27, 0.13);
    margin-bottom: 10px;
  }

  main {
    padding: 24px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 100px);

    .description {
      h3 {
        font-size: 24px;
        line-height: 36px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .action {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 15px;

      button {
        width: 140px;
        height: 44px;
        border: 1px solid #000;
        border-radius: 8px;
        padding: 10px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        outline: none;

        cursor: pointer;

        span {
          color: #000;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .plans {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
`;

export const Plan = styled.div`
  width: 330px;
  height: 240px;
  background-color: ${(props) => props.background};
  border-radius: 16px;
  display: grid;
  grid-template-areas: ' . ico' 'info .';
  margin-top: 10px;

  .infos {
    grid-area: info;
    padding: 24px;
    color: #fff;
    cursor: pointer;
    h3 {
      font-weight: bold;
      font-size: 32px;
      line-height: 48px;
    }
    p {
      font-size: 20px;
      line-height: 30px;
    }
  }

  .icone {
    grid-area: ico;
    padding: 19px;
    justify-self: flex-end;

    img {
      cursor: pointer;
    }
  }
`;
