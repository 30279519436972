import api from './api';

export const exportClientsInfo = () =>
  new Promise((resolve, reject) => {
    api
      .get('/meis/getinfos')
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
