import React from 'react';
import { useLocation } from 'react-router-dom';
import { Wrapper, Button, Container } from './styles';
import MenuAdm from '../../../components/MenuAdm';
import { cpfMask, zipCodeMask } from '../../../utils/mask';

// import Button from '../../components/Button';

const DadosMEI = () => {
  const {
    state: { client },
  } = useLocation();

  return (
    <Container>
      <MenuAdm />
      <Wrapper>
        <div className="header">
          <h1>Dados do MEI</h1>
        </div>
        <div className="container">
          <section>
            <h1>Informações da conta</h1>

            <div className="inputContainer">
              <fieldset>
                <p>Nome Empresarial</p>
                <input
                  type="text"
                  value={`${
                    client.mei.business_name ? client.mei.business_name : ''
                  }`}
                />
              </fieldset>

              <fieldset>
                <p>Nome do Empresário</p>
                <input
                  type="text"
                  value={`${
                    client.mei.businessman_name
                      ? client.mei.businessman_name
                      : ''
                  }`}
                />
              </fieldset>

              <fieldset>
                <p>Nome fantasia</p>
                <input
                  type="text"
                  value={`${
                    client.mei.fantasy_name ? client.mei.fantasy_name : ''
                  }`}
                />
              </fieldset>

              <fieldset>
                <p>CNPJ</p>
                <input
                  type="text"
                  value={`${client.mei.cnpj ? client.mei.cnpj : ''}`}
                />
              </fieldset>

              <fieldset>
                <p>Situação cadastral vigente</p>
                <input
                  type="text"
                  value={`${
                    client.mei.current_registration_situation
                      ? client.mei.current_registration_situation
                      : ''
                  }`}
                />
              </fieldset>

              <fieldset>
                <p>Data do cadastro vigente</p>
                <input
                  type="text"
                  value={`${
                    client.mei.current_registration_situation_start_date
                      ? client.mei.current_registration_situation_start_date
                      : ''
                  }`}
                />
              </fieldset>
            </div>
          </section>

          <section>
            <h1>Informações pessoais</h1>
            <div className="inputContainer">
              <fieldset>
                <p>Numero da identidade</p>
                <input
                  type="text"
                  value={`${client.mei.rg ? client.mei.rg : ''}`}
                />
              </fieldset>

              <fieldset>
                <p>Orgão emissor</p>
                <input
                  type="text"
                  value={`${
                    client.mei.emitting_organ ? client.mei.emitting_organ : ''
                  }`}
                />
              </fieldset>

              <fieldset>
                <p>UF Emissor</p>
                <input
                  type="text"
                  value={`${
                    client.mei.emitting_ufe ? client.mei.emitting_ufe : ''
                  }`}
                />
              </fieldset>

              <fieldset>
                <p>CPF</p>
                <input
                  type="text"
                  value={cpfMask(`${client.mei.cpf ? client.mei.cpf : ''} `)}
                />
              </fieldset>
            </div>
          </section>

          <section>
            <h1>Endereço comercial</h1>
            <div className="inputContainer">
              <fieldset>
                <p>CEP</p>
                <input
                  type="text"
                  value={zipCodeMask(
                    `${client.mei.zip_code ? client.mei.zip_code : ''}`,
                  )}
                />
              </fieldset>

              <fieldset>
                <p>Rua</p>
                <input
                  type="text"
                  value={`${
                    client.mei.public_place ? client.mei.public_place : ''
                  }`}
                />
              </fieldset>

              <fieldset>
                <p>Numero</p>
                <input
                  type="text"
                  value={`${client.mei.number ? client.mei.number : ''}`}
                />
              </fieldset>

              <fieldset>
                <p>Bairro</p>
                <input
                  type="text"
                  value={`${
                    client.mei.neighborhood ? client.mei.neighborhood : ''
                  }`}
                />
              </fieldset>

              <fieldset>
                <p>Municipio</p>
                <input
                  type="text"
                  value={`${client.mei.county ? client.mei.county : ''}`}
                />
              </fieldset>

              <fieldset>
                <p>UF</p>
                <input
                  type="text"
                  value={`${client.mei.uf ? client.mei.uf : ''}`}
                />
              </fieldset>
            </div>
          </section>
          <div className="download">
            <Button
              onClick={() => {
                window.open(`${client.mei.certificate_url}`);
              }}
            >
              <span>Baixar Certificado</span>
            </Button>
          </div>
        </div>
      </Wrapper>
    </Container>
  );
};

export default DadosMEI;
