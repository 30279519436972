import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { ButtonContainer } from '../../../components/Button/styles';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 354px;
  display: flex;
  #line {
    background: ${colors.orange};
    width: 22px;
    height: 100%;
    content: '';
    display: block;
  }
  #wrapper {
    background-color: #fafafa;
    box-shadow: 7px 0 4px -3px rgba(17, 14, 27, 0.13);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 3em;
    padding-top: 1.5em;

    .content {
      height: 80%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      #logoHeader {
        width: 80%;
        margin-bottom: 30px;
      }
    }

    #square {
      height: 120px;
      width: 120px;
      background: linear-gradient(to bottom, ${colors.orange}, ${colors.pink});
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5em;
      #logoSquare {
        opacity: 0.6;
      }
      span {
        font-size: 1.2em;
        color: ${colors.white};
      }
    }
    .userTerms {
      margin-bottom: 55px;
      text-align: center;
    }
    .actions {
      overflow-y: scroll;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.2em;

      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      li {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
        }
      }
      ::-webkit-scrollbar {
        display: none;
      }
      .criarMEI {
        position: relative;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        a {
          z-index: 10;
        }
        #pulse {
          content: '';
          position: absolute;
          border-radius: 50px;
          margin: auto;
          display: block;
          pointer-events: none;
          background: linear-gradient(
            to right,
            ${colors.pink},
            ${colors.orange}
          );
          animation: pulse ease-in-out infinite 1s forwards;
        }
        @keyframes pulse {
          0% {
            opacity: 1;
            height: 3em;
            width: 80%;
          }
          100% {
            opacity: 0;
            height: 100%;
            width: 90%;
          }
        }
      }
    }
  }
`;

export const ButtonSideBar = styled(ButtonContainer)`
  background-color: transparent;
  width: 80%;
  border: 2px solid ${colors.orange};
  border-radius: 8px;
  font-size: 1.2em;
  span {
    color: ${colors.orange};
  }
  &:hover {
    border: none;
    span {
      color: #fff;
    }
  }
  &:active {
    border: none;
    span {
      color: #fff;
    }
  }
`;
