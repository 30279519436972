import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { ButtonContainer } from '../Button/styles';

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  max-width: 354px;
  display: flex;
  #line {
    background: #ee7200;
    width: 22px;
    height: 100vh;
    content: '';
    display: block;
  }
  #wrapper {
    background-color: #fafafa;
    box-shadow: 7px 0 4px -3px rgba(17, 14, 27, 0.13);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.2em;
    padding-top: 1.5em;
    h1 {
      color: #00a79d;
      font-size: 64px;
      line-height: 64px;
      margin-bottom: 35px;
    }
    #logoHeader {
      width: 80%;
      margin-top: 0.5em;
      margin-bottom: 1.5em;
    }
    #square {
      height: 120px;
      width: 120px;
      background: linear-gradient(to bottom, ${colors.orange}, ${colors.pink});
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5em;
      #logoSquare {
        opacity: 0.6;
      }
      span {
        font-size: 1.2em;
        color: ${colors.white};
      }
    }
    #wrapper-buttons {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
    }
  }
  ul {
    overflow-y: scroll;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.2em;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    li {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }
    }
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const ButtonSideBar = styled(ButtonContainer)`
  background-color: transparent;
  width: 80%;
  border: 2px solid ${colors.orange};
  color: ${(props) => (!props.active ? colors.orange : `#fff`)};
  border-radius: 50px;
  font-size: 1em;
  background-color: ${(props) =>
    props.active ? colors.orange : `transparent`};
  margin-bottom: 3px;
  height: 50px;
  span {
    color: ${colors.orange};
  }
  &:hover {
    border: none;
    color: #fff;
    span {
      color: #fff;
    }
  }
  &:active {
    border: none;
    span {
      color: #fff;
    }
  }
`;

export const AvatarMenu = styled.div`
  display: flex;
  padding-bottom: 1em;
  width: 75%;
  height: auto;
  position: relative;
  justify-content: space-between;
  cursor: pointer;
  #profile-pic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    img {
      background-color: ${colors.orange};
      display: flex;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  #userInfoContainer {
    display: flex;

    align-self: center;
    flex-direction: column;
    .infos {
      color: ${colors.orange};
    }
    #nome {
      font-weight: bolder;
    }
    #tipoMEI {
      font-size: 14px;
      padding-top: 0.5em;
    }
  }
  #arrow {
    display: flex;
    margin-left: 5px;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  #options {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 3.5em;
    top: 13.5em;
    background-color: ${colors.white};
    border-radius: 5%;
    border: 1px solid #dcdcdc;
    width: 260px;
    height: auto;
    z-index: 1500;
    box-shadow: 0px 8px 5px #b0a7a1, -2px 0px 5px #b0a7a1, 2px 0px 5px #b0a7a1;
    #photo-name {
      display: flex;
      padding: 1em 1.3em;
      flex-direction: row;
      align-items: center;
      img {
        display: flex;
        background-color: ${colors.orange};
        width: 30px;
        height: 30px;
        border-radius: 50%;
        object-fit: cover;
      }
      span {
        padding-left: 0.7em;
      }
    }

    #list {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 1em;
      #options-button {
        display: flex;
        width: 100%;
        height: 1px;
        justify-content: center;
        margin-bottom: 0.5em;
        background-color: #000;
      }
      .text-options {
        cursor: pointer;
        padding: 0.7em 0 0.7em;
        &:hover {
          color: ${colors.orange};
        }
      }
    }
  }
`;
