/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { ToastContainer, toast } from 'react-toastify';
import api from '../../../services/api';
import 'react-toastify/dist/ReactToastify.css';

import MenuAdm from '../../../components/MenuAdm';
import { Container, MainContainer } from './styles';
import Button from '../../../components/Button';

import closeIcon from '../../../assets/svg/close-circle.svg';

const NovoSertec = () => {
  const history = useHistory();
  const [errors, setErrors] = useState(false);
  const [validate, setValidate] = useState(false);
  const [hidePass, setHidePass] = useState('password');
  const [hideRepeatPass, setHideRepeatPass] = useState('password');
  const user = JSON.parse(sessionStorage.getItem('users'));

  const handleClose = () => {
    history.push('/gestao/dashboard');
  };

  const [inputs, setInputs] = useState({
    nomeUsuario: '',
    email: '',
    password: '',
    repeat_email: '',
    repeat_password: '',
  });

  const schema = yup.object().shape({
    nomeUsuario: yup
      .string()
      .required('O campo Nome de usuário é obrigatório.'),
    email: yup
      .string()
      .email('Formato do email inválido')
      .required('O campo email é obrigatório'),
    password: yup
      .string()
      .required('O campo senha é obrigatório')
      .min(8, 'Senha deve ter no mínimo 8 caracteres'),
    repeat_email: yup
      .string()
      .oneOf([yup.ref('email'), null], 'Os emails devem ser iguais'),
    repeat_password: yup
      .string()
      .oneOf([yup.ref('password'), null], 'As senhas devem ser iguais'),
  });

  const handleSubmit = async () => {
    const securityuuid =
      process.env.REACT_APP_PRODUCTION !== 'TRUE'
        ? process.env.REACT_APP_QA_SECURITY_UUID
        : process.env.REACT_APP_PRODUCTION_SECURITY_UUID;

    if (validate) {
      const res = await api.post(`/admins?securityuuid=${securityuuid}`, {
        name: inputs.nomeUsuario,
        email: inputs.email,
        repeat_email: inputs.repeat_email,
        password: inputs.password,
        repeat_password: inputs.repeat_password,
      });
      console.log(res);
      if (res.status === 201) {
        toast('Novo usuário SERTEC criado');
        setInputs({
          nomeUsuario: '',
          email: '',
          password: '',
          repeat_email: '',
          repeat_password: '',
        });
      }
    }
  };

  const handleValidate = async () => {
    setErrors(false);
    try {
      await schema.validate(inputs);
      setValidate(true);
      handleSubmit();
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        console.log(error.errors);
        setErrors(error.errors[0]);
      }
      return false;
    }
    return true;
  };

  useEffect(() => {
    setValidate(!errors);
  }, [errors]);

  const handleShowPassword = () => {
    if (hidePass === 'password') {
      setHidePass('text');
    } else {
      setHidePass('password');
    }
  };

  const handleShowRepeatPassword = () => {
    if (hideRepeatPass === 'password') {
      setHideRepeatPass('text');
    } else {
      setHideRepeatPass('password');
    }
  };

  return (
    <Container>
      <ToastContainer />
      <MenuAdm page="RegisterAdm" />
      <MainContainer>
        <img src={closeIcon} alt="close-icon" onClick={handleClose} />
        <h1>Novo usuário SERTEC</h1>
        {errors ? <p id="error">{errors}</p> : ''}
        <ul>
          <li>
            <span>Nome do usuário</span>
            <fieldset>
              <input
                type="text"
                name="nomeUsuario"
                placeholder="Fulano de tal"
                value={inputs.nomeUsuario}
                onChange={(e) =>
                  setInputs({ ...inputs, [e.target.name]: e.target.value })
                }
              />
            </fieldset>
          </li>
          <li>
            <span>Email</span>
            <fieldset>
              <input
                type="text"
                name="email"
                value={inputs.email}
                placeholder="fulano@email.com"
                onChange={(e) =>
                  setInputs({ ...inputs, [e.target.name]: e.target.value })
                }
              />
            </fieldset>
          </li>
          <li>
            <span>Repetir Email</span>
            <fieldset>
              <input
                type="text"
                name="repeat_email"
                value={inputs.repeat_email}
                placeholder="fulano@gmail.com"
                onChange={(e) =>
                  setInputs({ ...inputs, [e.target.name]: e.target.value })
                }
              />
            </fieldset>
          </li>
          <li>
            <span>Senha</span>
            <fieldset>
              <input
                type={hidePass}
                name="password"
                value={inputs.password}
                placeholder="********"
                onChange={(e) =>
                  setInputs({ ...inputs, [e.target.name]: e.target.value })
                }
              />
              {hidePass === 'password' ? (
                <BsEyeSlashFill
                  onClick={handleShowPassword}
                  size="20"
                  className="eye"
                />
              ) : (
                <BsEyeFill
                  onClick={handleShowPassword}
                  size="20"
                  className="eye"
                />
              )}
            </fieldset>
          </li>
          <li>
            <span>Repetir Senha</span>
            <fieldset>
              <input
                type={hideRepeatPass}
                name="repeat_password"
                value={inputs.repeat_password}
                placeholder="********"
                onChange={(e) =>
                  setInputs({ ...inputs, [e.target.name]: e.target.value })
                }
              />
              {hideRepeatPass === 'password' ? (
                <BsEyeSlashFill
                  onClick={handleShowRepeatPassword}
                  size="20"
                  className="eye"
                />
              ) : (
                <BsEyeFill
                  onClick={handleShowRepeatPassword}
                  size="20"
                  className="eye"
                />
              )}
            </fieldset>
          </li>
        </ul>
        <Button
          text="Confirmar"
          radius="8px"
          width="64%"
          marginTop="0.5em"
          onClick={handleValidate}
        />
      </MainContainer>
    </Container>
  );
};

export default NovoSertec;
