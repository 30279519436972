/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';

import {
  ErrorBox,
  Input,
  Label,
  LineDiv,
  SectionTitle,
} from '../Profile/styles';
import { ListDocuments } from './styles';

export const ContractForm = ({ formik }) => {
  return (
    <>
      <SectionTitle>Contrato</SectionTitle>
      <div>
        <Label>Função</Label>
        <Input
          id="function"
          name="function"
          value={formik.values.function}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.function && formik.errors.function ? (
          <ErrorBox>{formik.errors.function}</ErrorBox>
        ) : null}
      </div>
      <div>
        <Label>Atividade</Label>
        <Input
          id="activity"
          name="activity"
          value={formik.values.activity}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.activity && formik.errors.activity ? (
          <ErrorBox>{formik.errors.activity}</ErrorBox>
        ) : null}
      </div>
      <LineDiv>
        <div style={{ width: '45%' }}>
          <Label>Data de admissão</Label>
          <Input
            type="date"
            id="company_admission_date"
            name="company_admission_date"
            value={formik.values.company_admission_date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.company_admission_date &&
          formik.errors.company_admission_date ? (
            <ErrorBox>{formik.errors.company_admission_date}</ErrorBox>
          ) : null}
        </div>
        <div style={{ width: '45%', marginTop: '16px' }}>
          <Label>Salário</Label>
          <Input
            type="number"
            step="0.01"
            id="income"
            name="income"
            value={formik.values.income}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.income && formik.errors.income ? (
            <ErrorBox>{formik.errors.income}</ErrorBox>
          ) : null}
        </div>
      </LineDiv>
      <LineDiv>
        <div style={{ width: '45%' }}>
          <Label>Insalubridade</Label>
          <Input
            type="number"
            step="0.01"
            id="insalubrity"
            name="insalubrity"
            value={formik.values.insalubrity}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.insalubrity && formik.errors.insalubrity ? (
            <ErrorBox>{formik.errors.insalubrity}</ErrorBox>
          ) : null}
        </div>
        <div style={{ width: '45%' }}>
          <Label>Vale transporte</Label>
          <Input
            type="number"
            step="0.01"
            id="ticket_transport"
            name="ticket_transport"
            value={formik.values.ticket_transport}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.ticket_transport && formik.errors.ticket_transport ? (
            <ErrorBox>{formik.errors.ticket_transport}</ErrorBox>
          ) : null}
        </div>
      </LineDiv>
      <LineDiv>
        <div style={{ width: '45%' }}>
          <Label>Quantidade de vales</Label>
          <Input
            type="number"
            id="ticket_quantity"
            name="ticket_quantity"
            value={formik.values.ticket_quantity}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.ticket_quantity && formik.errors.ticket_quantity ? (
            <ErrorBox>{formik.errors.ticket_quantity}</ErrorBox>
          ) : null}
        </div>
        <div style={{ width: '45%' }}>
          <Label>Data atestado médico</Label>
          <Input
            type="date"
            id="medical_test_date"
            name="medical_test_date"
            value={formik.values.medical_test_date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.medical_test_date &&
          formik.errors.medical_test_date ? (
            <ErrorBox>{formik.errors.medical_test_date}</ErrorBox>
          ) : null}
        </div>
      </LineDiv>
      <LineDiv>
        <div style={{ width: '45%' }}>
          <Label>CRM</Label>
          <Input
            id="crm"
            name="crm"
            value={formik.values.crm}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.crm && formik.errors.crm ? (
            <ErrorBox>{formik.errors.crm}</ErrorBox>
          ) : null}
        </div>
        <div style={{ width: '45%' }}>
          <Label>Médico</Label>
          <Input
            id="doctor_name"
            name="doctor_name"
            value={formik.values.doctor_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.doctor_name && formik.errors.doctor_name ? (
            <ErrorBox>{formik.errors.doctor_name}</ErrorBox>
          ) : null}
        </div>
      </LineDiv>
      <div>
        <Label>Contrato de experiência (dias)</Label>
        <Input
          type="number"
          id="experience_days_contract"
          name="experience_days_contract"
          value={formik.values.experience_days_contract}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.experience_days_contract &&
        formik.errors.experience_days_contract ? (
          <ErrorBox>{formik.errors.experience_days_contract}</ErrorBox>
        ) : null}
      </div>
      <SectionTitle>Documentos necessários</SectionTitle>
      <ListDocuments>
        <p>
          Enviar os documentos da lista abaixo para o email
          atendimento@numecontabil.com.br
        </p>
        <li>Atestado médico</li>
        <li>Carteira profissional</li>
        <li>CNIS (PREVIDÊNCIA SOCIAL) </li>
        <li>1 foto 3x4</li>
        <li>CPF</li>
        <li>CI/RG</li>
        <li>PIS - Caso não conste na carteira de trabalho</li>
        <li>Título de eleitor</li>
        <li>
          Comprovante de Endereço (conta da luz parte com CEP, não consumo)
        </li>
        <li>
          Certidão nascimento dos filhos menores de 14 anos, os menores de 5
          anos carteira de vacinação também.
        </li>
      </ListDocuments>
    </>
  );
};
