// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';

import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Wrapper, ButtonBack } from './styles';

import Button from '../../components/Button';
import Logo from '../../assets/svg/logoHeaderMob.svg';
import leftArrow from '../../assets/svg/left-arrow.svg';
import { LoginAdmRequest } from '../../store/ducks/auth/auth';

import { colors } from '../../constants/colors';

const AdminLogin = () => {
  const [showPassword, setShowPassword] = useState('password');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState('');
  const auth = useSelector((state) => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();

  function handleShowPassword() {
    if (showPassword === 'password') {
      setShowPassword('text');
    } else {
      setShowPassword('password');
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    // setErrors(false);
    dispatch(LoginAdmRequest(email, password));
  }
  useEffect(() => {
    if (auth.isLoggedAdm) {
      history.push('/gestao/dashboard');
    }
    if (auth.error) {
      if (auth.error === 'Email or password does not match') {
        setErrors('Combinação de e-mail e senha inválidos');
      } else {
        setErrors(auth.error);
      }
    }
  }, [auth]);

  return (
    <Wrapper>
      <div className="content">
        <nav>
          <ButtonBack
            image={leftArrow}
            backgroundColor={colors.white}
            border={colors.white}
            color={colors.pink}
            width="200px"
            height="50px"
            onClick={() => {
              history.push('/');
            }}
          >
            <img src={leftArrow} alt="icone" />
            <span>Voltar</span>
          </ButtonBack>
        </nav>
        <div className="container">
          <div className="containerLogin">
            <div className="logo">
              <img src={Logo} alt="logo Nume" />
              <h2>login da gestão</h2>
            </div>
            {errors ? <p className="errors">{errors}</p> : null}
            <div className="containerInputs">
              <div className="field-set">
                <p>E-mail</p>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>

              <div className="field-set" id="pass">
                <p>Senha</p>
                <input
                  type={showPassword}
                  name="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                {showPassword === 'password' ? (
                  <BsEyeSlashFill
                    onClick={handleShowPassword}
                    className="eye"
                  />
                ) : (
                  <BsEyeFill onClick={handleShowPassword} className="eye" />
                )}
              </div>
            </div>
            <div className="actions">
              <Button
                type="submit"
                onClick={handleSubmit}
                width="200px"
                text="Entrar"
              />
              <Link to="/nãotemparaadm">Esqueci minha senha</Link>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default AdminLogin;
