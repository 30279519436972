import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const MobileContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, ${colors.pink} 0%, ${colors.orange} 100%);
  display: flex;
  flex-direction: column;
  align-items: center;

  .container {
    width: 75%;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;

    .message {
      text-align: center;
    }
    .inputContainer {
      width: 100%;
    }
  }
`;
