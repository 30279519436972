import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { device, size } from '../../constants/size';

export const Container = styled.div`
  #one {
    padding: ${size.mobile.padding};
    background-color: ${colors.white};
  }
  #two {
    padding: ${size.mobile.padding};
    #first {
      h2 {
        color: ${colors.orange};
        align-self: flex-start;
      }
      p {
        padding-top: ${size.mobile.padding};
        color: ${colors.darkGray};
      }
      #vantagens {
        @media ${device.mobile} {
          display: none;
        }
        font-weight: 500;
      }
      #planos {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        li {
          width: 120px;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          background-color: ${colors.lightnessGray};
          display: flex;
          flex-direction: column;
          align-items: center;
          &:nth-child(3) {
            background-color: ${colors.white};
          }
          .title {
            color: ${colors.white};
            font-weight: bold;
            font-size: 1.5em;
            width: 100%;
            height: 2em;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
          }
          .subtitle {
            font-weight: bold;
            font-size: 1em;
            width: 100%;
            height: 2em;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          button {
            height: 3em;
            width: 90%;
            border-radius: 50px;
            border: none;
            color: ${colors.white};
            font-weight: bold;
          }
          .item {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            color: red;
            height: auto;
            width: 100%;

            p {
              @media ${device.desktop} {
                display: none;
              }
              font-size: 0.8em;
              width: 100%;
              height: 5em;
              padding-top: 0;
              display: flex;
              align-items: center;
            }
            img {
              justify-self: flex-end;
              object-fit: contain;
              height: 1em;
            }
          }
          &:nth-child(1) {
            .title {
              background-color: ${colors.blue};
            }
            .subtitle {
              color: ${colors.blue};
            }
            button {
              background-color: ${colors.blue};
            }
            span {
              font-size: 1em;
            }
          }
          &:nth-child(2) {
            .title {
              background-color: ${colors.orange};
            }
            .subtitle {
              color: ${colors.darkGray};
            }
            button {
              background-color: transparent;
              color: ${colors.darkGray};
              border: solid 2px ${colors.darkGray};
            }
          }
          &:nth-child(3) {
            display: flex;
            .title {
              background-color: ${colors.pink};
            }
            .subtitle {
              color: ${colors.pink};
            }
            button {
              background-color: transparent;
              color: ${colors.pink};
              border: solid 2px ${colors.pink};
            }
            img {
              height: 3em;
            }

            #item1 {
              font-weight: 500;
            }
            #item2 {
              font-weight: 500;
              color: ${colors.pink};
              font-size: 1.2em;
            }
            #item3 {
              font-size: 0.6em;
            }
          }
        }
        &:nth-child(1) {
          display: flex;
        }
      }
      .Four {
        height: 100%;
        width: 100%;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;
