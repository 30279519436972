import styled from 'styled-components';

import { device, size } from '../../../constants/size';
import { colors } from '../../../constants/colors';
import { ButtonContainer } from '../../../components/Button/styles';

export const Section = styled.div`
  display: flex;
  @media ${device.mobile} {
    flex-direction: column;
    width: 100%;
    height: auto;
    #section1 {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      height: 583px;
      background-color: #fef7f2;
      #computer {
        display: flex;
        align-self: center;
        width: 90%;
      }

      #afterComputer {
        height: 50%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 26px;
        .description {
          width: 80%;
          h3 {
            font-size: 28px;
            font-weight: 600;
            color: ${colors.orange};
          }
          div {
            max-width: 465px;
            display: grid;
            grid-template-areas: 'money price' '. month';
            .money {
              grid-area: money;
              font-size: 1.5rem;
              font-weight: 600;
              color: ${colors.orange};
              justify-self: flex-end;
              margin-right: 10px;
            }
            .price {
              grid-area: price;
              font-size: 4.5rem;
              font-weight: 600;
              color: ${colors.orange};
            }
            .month {
              grid-area: month;
              font-size: 1rem;
              color: ${colors.orange};
              justify-self: center;
              margin-top: -15px;
              margin-left: 50px;
            }
          }
        }
      }
    }
    #section2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: auto;
      padding: 10px;
      #section2Wrapper {
        .nossos-planos {
          margin-bottom: 10px;
          h1 {
            font-size: 32px;
            color: ${colors.orange};
          }
          p {
            font-size: 18px;
            color: ${colors.opacityGray};
            line-height: 32px;
          }
        }
      }
      .plansWrapper {
        display: flex;
        align-self: center;
        height: auto;
        width: 100%;
      }
      /* #title {
        width: 40%;
        margin-top: 1.5em;
        margin-left: ${size.mobile.margin};
      }
      #comecePeloPlano {
        width: 70%;
        margin-top: 1em;
        margin-left: ${size.mobile.margin};
      } */

      table {
        padding: 0 !important;
        width: 100% !important;
        height: auto;
        margin-right: 5px;

        thead {
          width: 100%;
          tr {
            display: flex;
            justify-content: space-evenly;
            width: 100%;
            gap: 0.5em;
            td {
              display: flex;
              width: 100%;
              justify-content: center;
              border: none;
              height: 3em;
              font-size: 1.2em;
              font-weight: bold;
              color: ${colors.white};

              &:nth-child(1) {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                background-color: ${colors.blue};
              }
              &:nth-child(2) {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                background-color: ${colors.orange};
              }
              &:nth-child(3) {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                background-color: ${colors.pink};
              }
            }
          }
        }
        tr {
          display: flex;
          justify-content: space-evenly;
          width: 100%;
          gap: 0.5em;
          td {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-direction: column;
            width: 100%;
            padding-top: 0.5em;
            padding-bottom: 0.5em;

            p {
              font-size: 0.8em;
              text-align: center;
            }
            h2 {
              text-align: center;
            }
            button {
              border: none;
              border-radius: 50px;
              font-size: 1.2em;
              height: 2em;
              width: 100%;
              max-width: 6em;
              &:nth-child(1) {
                background-color: #016c9d;
                color: white;
              }
            }
            &:nth-child(1) {
            }
            &:nth-child(2) {
            }
            &:nth-child(3) {
            }
            img {
              object-fit: contain;
              width: 25px;
            }
            .X {
              font-size: 25px;
              width: 25px;
              height: 25px;
              display: flex;
              justify-content: center;
              align-items: center;

              color: ${colors.pink};
            }
          }
        }
      }
      .advantages {
        display: none;
      }
      .premium {
        width: 45%;
        margin-top: -5px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fef7f2;
        .header {
          width: 100%;
          font-weight: bold;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          background-color: #df3071;
          height: 57.6px;
          font-size: 1.2em;
          margin-top: 6.8px;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          /* padding: 0.5em 0; */
        }
        p {
          padding-top: 0.5em;
          padding-bottom: 0.5em;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          width: 100%;
        }
      }
    }
  }

  @media ${device.desktop} {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    #section1 {
      display: flex;
      flex-direction: row;
      /* position: relative; */
      justify-content: space-around;
      width: 100%;
      height: 754px;
      background-color: #fef7f2;
      #computer {
        display: flex;
        align-self: center;
        width: 45%;
        margin-left: 10px;
      }
      #afterComputer {
        height: 100%;
        width: 50%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .description {
          width: 49%;
          h3 {
            font-size: 28px;
            font-weight: 600;
            color: ${colors.orange};
          }
          div {
            max-width: 465px;
            display: grid;
            grid-template-areas: 'money price' '. month';
            .money {
              grid-area: money;
              font-size: 3rem;
              font-weight: 600;
              color: ${colors.orange};
              justify-self: flex-end;
              margin-right: 10px;
            }
            .price {
              grid-area: price;
              font-size: 6rem;
              font-weight: 600;
              color: ${colors.orange};
            }
            .month {
              grid-area: month;
              font-size: 2rem;
              color: ${colors.orange};
              justify-self: flex-end;
              margin-top: -30px;
              margin-right: 15px;
            }
          }
        }
      }
    }
    #section2 {
      display: flex;
      width: 100%;
      padding: 4em;
      #section2Wrapper {
        max-width: 50%;
        padding-left: 4em;
        margin-right: 5%;
        /* #nossos-planos {
          display: grid;
          margin-right: 50px;
        }
        #title {
          height: 4em;
          max-width: 350px;
          width: 100%;
        }
        #comecePeloPlano {
          object-fit: contain;
          height: 8em;
          max-width: 460px;
          width: 100%;
        } */
        .nossos-planos {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          object-fit: contain;
          height: 14em;
          width: 100%;
          margin-bottom: 10px;
          h1 {
            font-size: 32px;
            color: ${colors.orange};
          }
          p {
            font-size: 18px;
            color: ${colors.opacityGray};
            line-height: 25px;
          }
        }
      }
      .plansWrapper {
        display: flex;
        align-self: flex-end;
        height: auto;
        width: auto;
      }
      table {
        width: 100%;
        tr {
          height: 67px;
          display: flex;
          align-items: center;
        }
      }
    }
    table {
      width: 50%;
      height: auto;
      /* padding-right: 4em; */
      thead {
        width: 100%;
        tr {
          display: flex;
          justify-content: space-evenly;
          width: 100%;
          gap: 0.5em;
          td {
            width: 7em;
            display: flex;
            justify-content: center;
            align-items: center;
            /* width: 100%; */
            border: none;
            height: 3em;
            font-size: 1.2em;
            font-weight: bold;
            color: ${colors.white};

            &:nth-child(1) {
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
              background-color: ${colors.blue};
            }
            &:nth-child(2) {
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
              background-color: ${colors.orange};
            }
            &:nth-child(3) {
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
              background-color: ${colors.pink};
            }
          }
        }
        .das {
          margin-top: 30px;
        }
      }
      tbody {
        width: 100%;
        tr {
          display: flex;
          justify-content: space-evenly;
          gap: 0.5em;
          td {
            width: 7em;
            padding-top: 0.5em;
            padding-bottom: 0.5em;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            p {
              font-size: 0.8em;
              display: none;
              text-align: center;
            }
            h2 {
              text-align: center;
            }
            button {
              border: none;
              border-radius: 50px;
              font-size: 1.2em;
              height: 2em;
              width: 6em;
              background-color: #016c9d;
              color: white;
              cursor: pointer;
            }
            img {
              object-fit: contain;
              width: 25px;
            }
            .X {
              display: block;
              font-size: 2em;
              color: ${colors.pink};
            }
          }
        }
      }
    }
    .premium {
      width: 12em;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #fef7f2;
      .header {
        width: 100%;
        font-weight: bold;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background-color: #df3071;
        height: 57.6px;
        font-size: 1.2em;
        margin: 6.8px 0;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5em 0;
      }
      p {
        /* padding-top: 0.5em; */
        /* padding-bottom: 0.5em; */
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        min-height: 67px;
      }
    }
  }
`;

export const ButtonFree = styled(ButtonContainer)`
  background-color: ${colors.blue};
  width: 90%;

  span {
    color: ${colors.white};
  }

  &:hover {
    background-color: #004b6d;
  }
  &:active {
    background-color: #0b8cc7;
  }
`;

export const ButtonBasico = styled(ButtonContainer)`
  background-color: transparent;
  border: 2px solid ${colors.opacityGray};
  width: 90%;
  span {
    color: ${colors.darkGray};
  }

  &:hover {
    background-color: ${colors.darkGray};
    border-color: ${colors.darkGray};
    span {
      color: ${colors.white};
    }
  }
  &:active {
    background-color: #9e9e9e;
    border-color: #9e9e9e;
    span {
      color: ${colors.white};
    }
  }
`;

export const ButtonPremium = styled(ButtonContainer)`
  background-color: transparent;
  border: 2px solid ${colors.pink};
  width: 74%;
  margin: 10px 0;

  @media ${device.mobile} {
    width: 95%;
  }
  span {
    color: ${colors.pink};
  }

  &:hover {
    background-color: ${colors.pink};
    border-color: ${colors.pink};
    span {
      color: ${colors.white};
    }
  }
  &:active {
    background-color: #ffffff;
    border-color: #ffffff;
    span {
      color: ${colors.pink};
    }
  }
`;
