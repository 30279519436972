/* eslint-disable no-param-reassign */
import axios from 'axios';

const BaseURL =
  process.env.REACT_APP_PRODUCTION !== 'TRUE'
    ? process.env.REACT_APP_QA_URL
    : process.env.REACT_APP_PRODUCTION_URL;

const api = axios.create({
  baseURL: BaseURL,
});

api.interceptors.request.use(
  async (config) => {
    const token = sessionStorage.getItem('token');

    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export const ChangePassword = (data) =>
  new Promise((resolve, reject) => {
    api
      .patch('/users/update', {
        password: data.newPassword,
        repeat_password: data.newPasswordConfirmation,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
export const apiCep = axios.create({
  baseURL: 'https://viacep.com.br/ws',
});

export const apiCities = axios.create({
  baseURL: 'https://servicodados.ibge.gov.br/api/v1/localidades/estados/',
});

export default api;
