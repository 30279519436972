import React, { useState } from 'react';
import * as yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Ipad from '../../assets/png/ipad.png';
import Logo from '../../assets/svg/logoNUME.svg';

import api from '../../services/api';
import { Wrapper, ButtonDowload } from './styles';
import Button from '../Button';
import closeIcon from '../../assets/svg/close-circle.svg';
import { ModalContent } from '../Modal/styles';

const DuvidasMEI = () => {
  const [modalState, setModalState] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [errors, setErrors] = useState('');
  const [validate, setValidate] = useState(false);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email('Formato de email invalido')
      .required('É necessário um email'),
  });
  const data = { email, name };

  function openModal() {
    setModalState(true);
  }

  function closeModal() {
    setModalState(false);
    setErrors('');
    setEmail('');
    setName('');
  }

  async function validateEmail() {
    setErrors(false);

    try {
      await schema.validate(data);
      setValidate(true);
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        error.errors.map((err) => setErrors(err));
        setValidate(false);
      }
      return false;
    }

    return true;
  }

  async function handleSubmitEmail(e) {
    e.preventDefault();

    if (validate) {
      await api
        .post('/meiguides', data)
        .then(() => {
          setEmail('');
          setName('');
          closeModal();
          toast('Email enviado com sucesso!!');
        })
        .catch((error) => {
          if (error.response.data) {
            setErrors(error.response.data.error);
          }
          return false;
        });
      return true;
    }
    setErrors('Preencha o email');
    return 0;
  }

  return (
    <Wrapper>
      <ToastContainer />
      <div className="container">
        <img className="logo" src={Logo} alt="logo Nume" />
        <div className="description">
          <h1>DÚVIDAS SOBRE O MEI?</h1>
          <p>
            Tudo que você precisa saber, respondido para você no nosso guia.
          </p>
        </div>
        <img className="ipad" src={Ipad} alt="ipad" />

        <div className="button">
          <ButtonDowload className="button" onClick={openModal}>
            <span>Baixar guia do MEI</span>
          </ButtonDowload>
        </div>
      </div>

      <ModalContent open={modalState} onClose={closeModal}>
        <div className="modalContainer">
          <div className="header">
            <p>
              Para receber a sua cartilha MEI, por favor digite seu email e seu
              nome
            </p>
            <button type="button" onClick={closeModal}>
              <img src={closeIcon} alt="Close" />
            </button>
          </div>

          {errors ? <p className="errors">{errors}</p> : null}

          <input
            type="email"
            placeholder="E-mail"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            onBlur={validateEmail}
          />
          <input
            type="text"
            placeholder="Nome"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <Button
            color="#FFF"
            border="#FFF"
            width="200px"
            height="60px"
            text="Salvar"
            className="button"
            onClick={handleSubmitEmail}
          />
        </div>
      </ModalContent>
    </Wrapper>
  );
};

export default DuvidasMEI;
