import api from './api';

export const RegisterUserApi = (data) =>
  new Promise((resolve, reject) => {
    api
      .post('/users', data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        let error;
        if (err.response) {
          if (err.response.data.error === 'User already registered') {
            error = 'Usuário ja cadastrado';
          } else {
            error = error.response.data.error;
          }
        }
        reject(error);
      });
  });
