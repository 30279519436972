import React from 'react';

import { ButtonContainer } from './styles';
import loadingLogo from '../../assets/svg/loading.svg';

const Button = ({
  text = 'botao',
  image = '',
  backgroundColor = '',
  color = '',
  border = '',
  width = '',
  height = '',
  fontSize = '',
  marginLeft = '',
  marginRight = '',
  marginTop = '',
  marginBottom = '',
  radius = '',
  hover = '',
  active = '',
  onClick = () => {},
  loading = '',
}) => {
  return (
    <ButtonContainer
      onClick={onClick}
      image={image}
      backgroundColor={backgroundColor}
      color={color}
      border={border}
      hover={hover}
      active={active}
      width={width}
      height={height}
      radius={radius}
      fontSize={fontSize}
      marginLeft={marginLeft}
      marginRight={marginRight}
      marginTop={marginTop}
      marginBottom={marginBottom}
      loading={loading}
    >
      <img src={!image ? loadingLogo : image} alt="" />
      <span>{text}</span>
    </ButtonContainer>
  );
};

export default Button;
