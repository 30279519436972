/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { IoIosArrowDown } from 'react-icons/io';
import { useSelector, useDispatch } from 'react-redux';
import { Logout } from '../../store/ducks/auth/auth';
import SideBar from '../components/SideBar';
import Button from '../../components/Button';
import { Container, MainContainer, AvatarMenu } from './styles';
import { colors } from '../../constants/colors';
import logoHeader from '../../assets/svg/logoHeaderMob.svg';
import userIcon from '../../assets/svg/userIcon.svg';

function Free({ children }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const pathName = location.pathname;
  const [showAvatarMenu, setShowAvatarMenu] = useState(false);

  const history = useHistory();

  const user = useSelector((state) => state.auth.user);

  const handleChangePassword = () => {
    history.push('/dashboard/alterarSenha');
    setShowAvatarMenu(false);
  };

  const handleProfile = () => {
    history.push('/dashboard/profile');
    setShowAvatarMenu(false);
  };

  const handleLogout = () => {
    dispatch(Logout());
    history.push('/login');
  };

  return (
    <Container>
      <SideBar>
        <div className="content">
          <img id="logoHeader" src={logoHeader} alt="logo da nume" />
          <MainContainer>
            <AvatarMenu
              id="avatar-menu"
              onClick={() => setShowAvatarMenu(!showAvatarMenu)}
            >
              <div id="profile-pic">
                <img src={userIcon} alt="avatar-profile-pic" />
              </div>
              <div id="userInfoContainer">
                <span id="nome" className="infos">
                  {`${user.first_name} ${user.last_name}`}
                </span>
                <span id="tipoMEI" className="infos">
                  {`MEI ${user ? user.plan.name : 'loading'}`}
                </span>
              </div>
              <IoIosArrowDown size="24" color={colors.orange} id="arrow" />
            </AvatarMenu>
            {showAvatarMenu ? (
              <div id="options">
                <div id="photo-name">
                  <img src={userIcon} alt="avatar-profile-pic" />
                  <span id="user-name">{`${user.first_name} ${user.last_name}`}</span>
                </div>
                <div id="list">
                  <div id="options-button">
                    <Button
                      text="Visualizar Planos"
                      width="100%"
                      radius="10px"
                      onClick={() => {
                        history.push({
                          pathname: '/dashboard/planos',
                          state: { idPlan: user.plan.id },
                        });
                        setShowAvatarMenu(false);
                      }}
                    />
                  </div>
                  <span className="text-options" onClick={handleProfile}>
                    Configurações de conta
                  </span>
                  <span className="text-options" onClick={handleChangePassword}>
                    Alterar Senha
                  </span>
                  <span className="text-options" onClick={handleLogout}>
                    Logout
                  </span>
                </div>
              </div>
            ) : (
              ''
            )}
          </MainContainer>
          <ul className="actions">
            {!user.mei && (
              <li className="criarMEI">
                <div id="pulse" />
                <a
                  target="_blank"
                  href="https://www.gov.br/empresas-e-negocios/pt-br/empreendedor/quero-ser-mei"
                >
                  <Button
                    backgroundColor={`linear-gradient(to right, ${colors.pink}, ${colors.orange})`}
                    border="none"
                    color="#FFF"
                    width="80%"
                    radius="50px"
                    text="Criar MEI"
                    fontSize="1.2em"
                  />
                </a>
              </li>
            )}
            <li>
              <Link to="/dashboard">
                <Button
                  backgroundColor={
                    pathName === '/dashboard'
                      ? `${colors.orange}`
                      : 'transparent'
                  }
                  border={colors.orange}
                  color={
                    pathName === '/dashboard' ? '#FFF' : `${colors.orange}`
                  }
                  width="80%"
                  radius="50px"
                  text="Relatório mensal financeiro"
                  fontSize="1.2em"
                />
              </Link>
            </li>
            {user.mei !== null && (
              <>
                <li>
                  <a
                    target="blank"
                    href="http://www8.receita.fazenda.gov.br/simplesnacional/aplicacoes/atspo/dasnsimei.app/default.aspx"
                  >
                    <Button
                      backgroundColor="transparent"
                      border={colors.orange}
                      color={colors.orange}
                      width="80%"
                      radius="50px"
                      text="Declaração anual do MEI"
                      fontSize="1.2em"
                    />
                  </a>
                </li>
                <li>
                  <a
                    target="blank"
                    href="http://www8.receita.fazenda.gov.br/SimplesNacional/Aplicacoes/ATSPO/pgmei.app/Identificacao"
                  >
                    <Button
                      backgroundColor="transparent"
                      border={colors.orange}
                      color={colors.orange}
                      width="80%"
                      radius="50px"
                      text="DAS"
                      fontSize="1.2em"
                    />
                  </a>
                </li>
              </>
            )}
            {user.mei !== null && (
              <>
                <li>
                  <a
                    target="blank"
                    href="https://servicos.receita.fazenda.gov.br/Servicos/certidao/CNDConjuntaInter/InformaNICertidao.asp?tipo=2"
                  >
                    <Button
                      backgroundColor="transparent"
                      border={colors.orange}
                      color={colors.orange}
                      width="80%"
                      radius="50px"
                      text="CND"
                      fontSize="1.2em"
                    />
                  </a>
                </li>
                <li>
                  <Link to="/dashboard/dados-mei">
                    <Button
                      backgroundColor={
                        pathName === '/dashboard/dados-mei'
                          ? `${colors.orange}`
                          : 'transparent'
                      }
                      border={colors.orange}
                      color={
                        pathName === '/dashboard/dados-mei'
                          ? '#FFF'
                          : `${colors.orange}`
                      }
                      width="80%"
                      radius="50px"
                      text="Dados MEI"
                      fontSize="1.2em"
                    />
                  </Link>
                </li>
              </>
            )}

            {user.plan.name === 'Premium' && user.mei !== null && (
              <li>
                <Link to="/dashboard/employeeRegistration">
                  <Button
                    backgroundColor={
                      pathName === '/dashboard/employeeRegistration'
                        ? `${colors.orange}`
                        : 'transparent'
                    }
                    border={colors.orange}
                    color={
                      pathName === '/dashboard/employeeRegistration'
                        ? '#FFF'
                        : `${colors.orange}`
                    }
                    width="80%"
                    radius="50px"
                    text="Cadastro de admissão"
                    fontSize="1.2em"
                  />
                </Link>
              </li>
            )}
          </ul>
        </div>
      </SideBar>
      {children}
    </Container>
  );
}

export default Free;
