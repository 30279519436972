import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const UserDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  margin-top: 40px;

  img {
    height: 64px;
    width: 64px;
    margin-bottom: 6px;
    background-color: ${colors.orange};
    border-radius: 50%;
  }
  p {
    margin-bottom: 4px;
    color: #ff6d24;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }

  strong {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }
  svg {
    position: absolute;
    left: 18px;
    top: 10px;
  }
`;

export const Button = styled.button`
  max-width: 116px;
  width: 100%;
  height: 32px;
  color: #fff;
  background-color: #ff6d24;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  border: 0;
  padding: 2px;

  margin-bottom: 40px;
`;

export const LineDiv = styled.div`
  height: 1px;
  width: 90%;
  background-color: #000;
  margin-bottom: 40px;
`;

export const Detail = styled.details``;
