import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import api from '../../services/api';

import { Wrapper, Button } from './styles';
import { colors } from '../../constants/colors';
import leftArrow from '../../assets/svg/left-arrow.svg';
import logoNume from '../../assets/svg/logoHeaderDesktop.svg';
import TudoCerto from '../../components/TudoCerto';
import { ButtonBack } from '../Login/styles';
import Loading from '../../components/Loading';

function RecuperarSenha() {
  const [errors, setErrors] = useState(false);
  const [validate, setValidate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [tudoCerto, setTudoCerto] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loadingButton, setLoadingButton] = useState(true);
  const history = useHistory();

  const schema = yup.object().shape({
    email: yup
      .string()
      .email('Formato de email invalido')
      .required('É necessário um email'),
  });

  const data = { email };

  async function validateEmail() {
    try {
      await schema.validate(data);
      setValidate(true);
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        error.errors.map((err) => setErrors(err));
        setValidate(false);
      }
      return false;
    }

    return true;
  }

  async function handleSubmitEmail(e) {
    e.preventDefault();

    if (validate) {
      setIsLoading(true);
      await api
        .post('/reset-user-password', data)
        .then(() => {
          setIsLoading(false);
          setTudoCerto(true);
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response.data) {
            setErrors(error.response.data.error);
          }
          return false;
        });
      return true;
    }
    return 0;
  }

  return (
    <Wrapper>
      <Loading open={isLoading} />
      <div className="content">
        {!tudoCerto ? (
          <>
            <nav>
              <img src={logoNume} alt="logo-nume" />
              <ButtonBack
                image={leftArrow}
                backgroundColor="#fff"
                color={colors.pink}
                border="#fff"
                width="200px"
                height="50px"
                text="Voltar"
                onClick={() => {
                  history.goBack();
                }}
              >
                <img src={leftArrow} alt="icone" />
                <span>Voltar</span>
              </ButtonBack>
            </nav>
            <div className="container-title">
              <h1> Esqueci minha senha</h1>
              <p>Não se preocupe, basta nos informar seu e-mail</p>
            </div>
            <div className="container">
              <div className="containerLogin">
                {/* <div className="logo">
              <img src={Logo} alt="logo Nume" />
            </div> */}
                {errors ? <p className="errors">{errors}</p> : null}
                <div className="containerInputs">
                  <div className="fieldset">
                    <p>Informe o email cadastrado</p>
                    <input
                      placeholder="Digite o endereço de e-mail"
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      onBlur={validateEmail}
                    />
                  </div>
                </div>
                <div className="actions">
                  <Button
                    loading={loadingButton}
                    type="submit"
                    onClick={handleSubmitEmail}
                    width="200px"
                    text="Recuperar Senha"
                    backgroundColor={colors.pink}
                  >
                    <span>Recuperar senha</span>
                  </Button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <img
              src={logoNume}
              alt="logo-nume"
              style={{ display: 'flex', alignSelf: 'flex-start' }}
            />
            <div className="tudo-certo">
              <TudoCerto />
            </div>
          </>
        )}
      </div>
    </Wrapper>
  );
}

export default RecuperarSenha;
