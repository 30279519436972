import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { toast, ToastContainer } from 'react-toastify';

import api from '../../services/api';
import { ButtonBack } from '../Login/styles';
import { Button } from '../RecuperarSenha/styles';
import { Wrapper } from './styles';
// import Button from '../../components/Button';
import { colors } from '../../constants/colors';
import leftArrow from '../../assets/svg/left-arrow.svg';
import logoNume from '../../assets/svg/logoHeaderDesktop.svg';

function NovaSenha() {
  const [password, setPassword] = useState('');
  const [passwordConfirmed, setPasswordConfirmed] = useState('');
  const [showPassword, setShowPassword] = useState('password');
  const [showPasswordConfirmed, setShowPasswordConfirmed] = useState(
    'password',
  );
  const history = useHistory();
  const [errors, setErrors] = useState('');
  const params = useParams();
  const { push } = useHistory();

  const schema = yup.object().shape({
    password: yup
      .string()
      .min(8, 'A senha deve ter no mínimo 8 caracteres')
      .required('É necessário uma senha'),
    passwordConfirmed: yup
      .string()
      .oneOf([yup.ref('password'), null], 'As senhas não coincidem '),
  });

  function handleShowPassword() {
    if (showPassword === 'password') {
      setShowPassword('text');
    } else {
      setShowPassword('password');
    }
  }

  function handleShowPasswordConfirmed() {
    if (showPasswordConfirmed === 'password') {
      setShowPasswordConfirmed('text');
    } else {
      setShowPasswordConfirmed('password');
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const data = { password, passwordConfirmed };
    setErrors(false);

    try {
      await schema.validate(data);
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        error.errors.map((err) => setErrors(err));
      }
      return false;
    }

    await api
      .put(`/reset-user-password/${params.token}`, { password })
      .then(() => {
        toast.success('Senha alterada com sucesso!!');
        setTimeout(() => {
          push('/login');
        }, 5000);
      })
      .catch((error) => {
        if (error.response.data) {
          toast.error(error.response.data.error);
        }
        return false;
      });

    return true;
  }

  return (
    <Wrapper>
      <ToastContainer />
      <div className="content">
        <nav>
          <img src={logoNume} alt="logo-nume" />
          <ButtonBack
            image={leftArrow}
            backgroundColor="#fff"
            color={colors.pink}
            border="#fff"
            width="200px"
            height="50px"
            onClick={() => {
              history.goBack();
            }}
          >
            <img src={leftArrow} alt="icone" />
            <span>Voltar</span>
          </ButtonBack>
        </nav>
        <div className="container-title">
          <h1> Redefinir senha</h1>
          <p>Não se preocupe, basta nos informar sua nova senha</p>
        </div>
        <div className="container">
          <div className="containerPassword">
            {errors ? <p className="errors">{errors}</p> : null}
            <div className="containerInputs">
              <fieldset>
                <p>Digite a Senha</p>
                <input
                  placeholder="Digite a senha"
                  type={showPassword}
                  name="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                {showPassword === 'password' ? (
                  <BsEyeFill onClick={handleShowPassword} className="eye" />
                ) : (
                  <BsEyeSlashFill
                    onClick={handleShowPassword}
                    className="eye"
                  />
                )}
              </fieldset>

              <fieldset>
                <p>Confirme a Senha</p>
                <input
                  placeholder="Confirme a senha"
                  type={showPasswordConfirmed}
                  name="passwordConfirmed"
                  value={passwordConfirmed}
                  onChange={(e) => {
                    setPasswordConfirmed(e.target.value);
                  }}
                />
                {showPasswordConfirmed === 'password' ? (
                  <BsEyeFill
                    onClick={handleShowPasswordConfirmed}
                    className="eye"
                  />
                ) : (
                  <BsEyeSlashFill
                    onClick={handleShowPasswordConfirmed}
                    className="eye"
                  />
                )}
              </fieldset>
            </div>
            <div className="actions">
              <Button
                type="submit"
                width="200px"
                backgroundColor={colors.pink}
                onClick={handleSubmit}
              >
                <span>Redefinir senha</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default NovaSenha;
