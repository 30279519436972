import React from 'react';

import { Container } from './styles';
import Form from '../../../components/FormDuvidas';
import Computer from '../../../assets/png/computer.png';

const SectionFour = ({ reference = 'formDuvidas' }) => {
  return (
    <Container>
      <div className="imageContainer">
        <img src={Computer} alt="computer" />
        <h1>
          Não seria simples de verdade se você não pudesse resolver com o
          celular.
        </h1>
      </div>
      <div className="formDiv">
        <div className="form" ref={reference}>
          <Form />
        </div>
      </div>
    </Container>
  );
};

export default SectionFour;
