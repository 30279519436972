/* eslint-disable no-unused-expressions */
/* eslint-disable no-use-before-define */
/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Helmet } from 'react-helmet';
import { toast, ToastContainer } from 'react-toastify';
import api, { apiCep } from '../../services/api';

import {
  cpfMask,
  removeMask,
  phoneMask,
  zipCodeMask,
  removeMaskPhone,
} from '../../utils/mask';

import {
  Wrapper,
  ButtonExit,
  ButtonNext,
  ButtonBackContainer,
  Loading,
} from './styles';
import { colors } from '../../constants/colors';
import leftArrow from '../../assets/svg/left-arrow.svg';
import logoNume from '../../assets/svg/logoHeaderDesktop.svg';
import { ButtonBack } from '../Login/styles';

function RegisterUser() {
  const [errors, setErrors] = useState(false);
  const [validate, setValidate] = useState(false);
  const history = useHistory();
  const [termsConfirmed, setTermsConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState('');
  const [receiveEmail, setReceiveEmail] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [step, setStep] = useState(0);

  const steps = [
    '1. informações pessoais',
    '2. informações da conta',
    '3. informações de contato',
  ];

  const [inputs, setInputs] = useState({
    email: '',
    password: '',
    repeat_password: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    birth_date: '',
    zip_code: '',
    street_name: '',
    address_number: '',
    additional_info: '',
    state: '',
    city: '',
    rg: '',
    cpf: '',
    plan_id: 1,
    newsletter_flag: false,
  });

  useEffect(() => {
    if (step === 2) {
      setValidate(true);
    }
  }, [step]);

  useEffect(() => {
    if (inputs.zip_code.length >= 8) {
      apiCep.get(`/${inputs.zip_code}/json`).then((res) => {
        setInputs({
          ...inputs,
          street_name: res.data.logradouro,
          state: res.data.uf,
          city: res.data.localidade,
        });
      });
    }
  }, [inputs.zip_code]);

  useEffect(() => {
    const dateNow = new Date();
    dateNow.setFullYear(dateNow.getFullYear() - 18);
    setCurrentDate(dateNow.toISOString().slice(0, 10));
  }, [inputs.birth_date]);

  // eslint-disable-next-line no-unused-vars
  const schemaStep1 = yup.object().shape({
    first_name: yup.string().required('Por favor, preencha o campo Nome'),
    last_name: yup.string().required('Por favor, preencha o campo Sobrenome'),
    birth_date: yup
      .string()
      .required('Por favor, informe a data de nascimento'),
    cpf: yup
      .string('CPF inválido')
      .min(11, 'O CPF deve ter 11 dígitos')
      .max(11, 'O CPF deve ter 11 dígitos')
      .matches(/\d+/g)
      .required('CPF é um campo obrigatório'),
    rg: yup
      .string()
      .min(10, 'O RG deve ter no minimo 11 dígitos')
      .max(11, 'O RG deve ter no máximo 11 dígitos')
      .required('RG é um campo obrigatório'),
  });

  const schemaStep2 = yup.object().shape({
    email: yup
      .string()
      .email('Formato do email inválido')
      .required('Por favor, preencha o campo e-mail'),
    password: yup
      .string()
      .min(8, 'A senha deve ter pelo menos 8 caracteres')
      .required('A senha é obrigatória'),
    repeat_password: yup
      .string()
      .oneOf([yup.ref('password'), null], 'As senhas devem ser iguais'),
  });

  const schemaStep3 = yup.object().shape({
    phone_number: yup
      .string()
      .min(11, 'Telefone deve ter no mínimo 11 dígitos')
      .max(11, 'Telefone deve ter no máximo 11 dígitos')
      .required('Por favor, preencha o campo telefone'),
    zip_code: yup
      .string()
      .min(8, 'O CEP deve conter 8 dígitos')
      .max(8, 'O CEP deve conter 8 dígitos')
      .required(),
    street_name: yup.string().required('Por favor, informe a Rua.'),
    address_number: yup
      .string()
      .max(50, 'A rua deve no máximo 50 caracteres')
      .required('Por favor, informe o Número do endereço.'),
    state: yup.string().required('Por favor, informe o Estado.'),
    city: yup.string().required('Por favor, informe a Cidade.'),
  });

  async function validateData() {
    switch (step) {
      case 0:
        if (inputs.birth_date > currentDate) {
          setErrors('É permitido o cadastro apenas para maiores de 18 anos');
          return false;
        }
        try {
          await schemaStep1.validate(inputs);
          setValidate(true);
          setErrors(false);
          handleNextStep();
        } catch (error) {
          if (error instanceof yup.ValidationError) {
            error.errors.map((err) => setErrors(err));
            setValidate(false);
          }
          return false;
        }
        break;
      case 1:
        try {
          await schemaStep2.validate(inputs);
          setValidate(true);
          setErrors(false);
          handleNextStep();
        } catch (error) {
          if (error instanceof yup.ValidationError) {
            error.errors.map((err) => setErrors(err));
            setValidate(false);
          }
          return false;
        }
        break;

      case 2:
        // setInputs({
        // ...inputs,
        // phone_number: removeMaskPhone(inputs.phone_number),
        // });
        // setInputs({ ...inputs, zip_code: removeMask(inputs.zip_code) });
        // console.log(inputs.phone_number);
        // setInputs({ ...inputs, zip_code: removeMask(inputs.zip_code) });
        // console.log(inputs.zip_code);
        try {
          await schemaStep3.validate(inputs);
          setErrors(false);
          termsConfirmed
            ? handleNextStep()
            : setErrors(
                'Você deve aceitar os termos de uso e políticas de privacidade',
              );
        } catch (error) {
          if (error instanceof yup.ValidationError) {
            error.errors.map((err) => setErrors(err));
            setValidate(false);
          }
          return false;
        }
        break;
    }

    return true;
  }
  // eslint-disable-next-line no-unused-vars
  async function handleSubmitEmail() {
    setLoading(true);
    if (validate) {
      await api
        .post('/users', inputs)
        .then(() => {
          toast.success(
            'Usuário cadastrado com sucesso. Confirme sua conta pelo email para ganhar acesso a plataforma!!',
          );
          setTimeout(() => {
            history.push('/login');
          }, 5000);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.data) {
            if (error.response.data.error === 'User already registered') {
              setErrors('Informações de caráter único já estão em uso');
            } else {
              setErrors(error.response.data.error);
            }
          }
          return false;
        });
      return true;
    }
    return 0;
  }

  // async function registerIncomplete() {
  //   const data = {
  //     cpf: inputs.cpf,
  //     first_name: inputs.first_name,
  //     last_name: inputs.last_name,
  //     email: inputs.email,
  //   };
  //   if (
  //     inputs.cpf !== '' &&
  //     inputs.first_name !== '' &&
  //     inputs.last_name !== '' &&
  //     inputs.email !== ''
  //   ) {
  //     api
  //       .post('/unfinished-registers', data)
  //       .then(() => {
  //         history.push('/login');
  //       })
  //       .catch((error) => {
  //         if (error.response.data) {
  //           setErrors(error.response.data.error);
  //           history.push('/login');
  //         }
  //         return false;
  //       });
  //     return true;
  //   }
  //   history.push('/login');
  //   return 0;
  // }

  async function handleNextStep() {
    switch (step) {
      case 0:
        setStep(1);
        setValidate(false);
        break;
      case 1:
        setStep(2);
        setValidate(false);
        break;
      case 2:
        handleSubmitEmail();
        break;
    }
  }

  function handleGoBackStep() {
    setTermsConfirmed(false);
    setStep(step - 1);
  }

  const handleEmail = () => {
    setReceiveEmail(!receiveEmail);
    setInputs({ ...inputs, newsletter_flag: !receiveEmail });
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Nume | Simplifique seu MEI</title>
      </Helmet>
      <ToastContainer />
      <div className="content">
        <nav>
          <img src={logoNume} alt="logo-nume" />
          <div className="containerButtons">
            <ButtonBack
              image={leftArrow}
              backgroundColor="#fff"
              color={colors.pink}
              border="#fff"
              width="200px"
              height="50px"
              onClick={() =>
                step !== 0 ? handleGoBackStep() : history.push('/login')
              }
            >
              <img src={leftArrow} alt="icone" />
              <span>Voltar</span>
            </ButtonBack>
            <ButtonExit
              backgroundColor="transparent"
              border="#fff"
              onClick={() => {
                history.push('/login');
              }}
            >
              <span>Sair</span>
            </ButtonExit>
          </div>
        </nav>
        <div className="container-title">
          <h1>Criar uma conta</h1>
          <p>{steps[step]}</p>
        </div>
        <div className="container">
          <div className="infoSteps">
            <p
              style={{
                opacity: step === 0 ? 1 : 0.5,
                borderBottom: step === 0 ? '2px solid #fff' : null,
              }}
            >
              {steps[0]}
            </p>
            <p
              style={{
                opacity: step === 1 ? 1 : 0.5,
                borderBottom: step === 1 ? '2px solid #fff' : null,
              }}
            >
              {steps[1]}
            </p>
            <p
              style={{
                opacity: step === 2 ? 1 : 0.5,
                borderBottom: step === 2 ? '2px solid #fff' : null,
              }}
            >
              {steps[2]}
            </p>
          </div>
          <div className="containerLogin">
            {loading && <Loading />}
            {/* <div className="logo">
              <img src={Logo} alt="logo Nume" />
            </div> */}
            {errors ? <p className="errors">{errors}</p> : null}
            {step === 0 && (
              <div
                className="containerInputs"
                style={{ justifyContent: 'space-evenly' }}
              >
                <fieldset>
                  <p>Nome</p>
                  <input
                    placeholder="Digite seu primeiro Nome"
                    type="text"
                    name="first_name"
                    value={inputs.first_name}
                    onChange={(e) => {
                      setInputs({ ...inputs, [e.target.name]: e.target.value });
                    }}
                    // onBlur={validateData}
                  />
                </fieldset>
                <fieldset>
                  <p>Sobrenome</p>
                  <input
                    placeholder="Digite seu Sobrenome"
                    type="text"
                    name="last_name"
                    value={inputs.last_name}
                    onChange={(e) => {
                      setInputs({ ...inputs, [e.target.name]: e.target.value });
                    }}
                    // onBlur={validateData}
                  />
                </fieldset>
                <fieldset>
                  <p>Data de nascimento</p>
                  <input
                    placeholder="Digite seu Sobrenome"
                    type="date"
                    max={currentDate}
                    name="birth_date"
                    value={inputs.birth_date}
                    onChange={(e) => {
                      setInputs({ ...inputs, [e.target.name]: e.target.value });
                    }}
                    // onBlur={validateData}
                  />
                </fieldset>
                <fieldset>
                  <p>CPF</p>
                  <input
                    placeholder="Digite seu CPF"
                    type="text"
                    name="cpf"
                    maxLength={14}
                    value={cpfMask(inputs.cpf)}
                    onChange={(e) => {
                      setInputs({
                        ...inputs,
                        [e.target.name]: removeMask(e.target.value),
                      });
                    }}
                    // onBlur={validateData}
                  />
                </fieldset>
                <fieldset>
                  <p>RG</p>
                  <input
                    placeholder="Digite seu RG"
                    type="text"
                    name="rg"
                    maxLength={11}
                    value={inputs.rg}
                    onChange={(e) => {
                      setInputs({ ...inputs, [e.target.name]: e.target.value });
                    }}
                    // onBlur={validateData}
                  />
                </fieldset>
              </div>
            )}

            {step === 1 && (
              <div
                className="containerInputs"
                style={{ justifyContent: 'space-evenly' }}
              >
                <fieldset>
                  <p>E-mail</p>
                  <input
                    placeholder="Digite seu e-mail"
                    type="email"
                    name="email"
                    value={inputs.email}
                    onChange={(e) => {
                      setInputs({ ...inputs, [e.target.name]: e.target.value });
                    }}
                    // onBlur={validateData}
                  />
                </fieldset>
                <fieldset>
                  <p>Crie uma senha</p>
                  <input
                    type="password"
                    name="password"
                    value={inputs.password}
                    onChange={(e) => {
                      setInputs({ ...inputs, [e.target.name]: e.target.value });
                    }}
                    // onBlur={validateData}
                  />
                </fieldset>
                <fieldset>
                  <p>Confirmar senha</p>
                  <input
                    type="password"
                    name="repeat_password"
                    value={inputs.repeat_password}
                    onChange={(e) => {
                      setInputs({ ...inputs, [e.target.name]: e.target.value });
                    }}
                    // onBlur={validateData}
                  />
                </fieldset>
              </div>
            )}

            {step === 2 && (
              <div className="containerInputs">
                <div
                  className="fieldset-third-step"
                  style={{ marginTop: '2.5em' }}
                >
                  <p>Telefone</p>
                  <input
                    className="large-input"
                    placeholder="Digite seu telefone"
                    maxLength="15"
                    type="text"
                    name="phone_number"
                    value={phoneMask(inputs.phone_number)}
                    onChange={(e) => {
                      setInputs({
                        ...inputs,
                        [e.target.name]: removeMaskPhone(e.target.value),
                      });
                    }}
                    // onBlur={validateData}
                  />
                </div>
                <div className="fieldset-third-step">
                  <p>CEP</p>
                  <input
                    className="large-input"
                    maxLength="9"
                    placeholder="00000-000"
                    type="text"
                    name="zip_code"
                    value={zipCodeMask(inputs.zip_code)}
                    onChange={(e) => {
                      setInputs({
                        ...inputs,
                        [e.target.name]: removeMask(e.target.value),
                      });
                    }}
                    // onBlur={validateData}
                  />
                </div>
                <div className="fieldset-third-step">
                  <p>Rua</p>
                  <input
                    className="large-input"
                    type="text"
                    name="street_name"
                    value={inputs.street_name}
                    onChange={(e) => {
                      setInputs({ ...inputs, [e.target.name]: e.target.value });
                    }}
                    // onBlur={validateData}
                  />
                </div>
                <div className="wrapper">
                  <div className="small-input-container">
                    <p>Número</p>
                    <input
                      type="text"
                      name="address_number"
                      value={inputs.address_number}
                      onChange={(e) => {
                        setInputs({
                          ...inputs,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      // onBlur={validateData}
                    />
                  </div>
                  <div className="medium-input-container">
                    <p>Complemento</p>
                    <input
                      type="text"
                      name="additional_info"
                      value={inputs.additional_info}
                      onChange={(e) => {
                        setInputs({
                          ...inputs,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      // onBlur={validateData}
                    />
                  </div>
                </div>
                <div className="wrapper">
                  <div className="small-input-container">
                    <p>Estado</p>
                    <input
                      type="text"
                      name="state"
                      value={inputs.state}
                      onChange={(e) => {
                        setInputs({
                          ...inputs,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      // onBlur={validateData}
                    />
                  </div>
                  <div className="medium-input-container">
                    <p>Cidade</p>
                    <input
                      type="text"
                      name="city"
                      value={inputs.city}
                      onChange={(e) => {
                        setInputs({
                          ...inputs,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      // onBlur={validateData}
                    />
                  </div>
                </div>
                <div id="termos">
                  <div className="input-wrapper">
                    <input
                      id="input-termos"
                      type="checkbox"
                      onChange={() => {
                        setTermsConfirmed(!termsConfirmed);
                      }}
                    />
                    <span id="text-termos">
                      Concordo com os{' '}
                      <a
                        href="https://numebucket.s3.amazonaws.com/Termo_uso_11112020.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {' '}
                        termos de uso
                      </a>{' '}
                      e{' '}
                      <a
                        href="https://numebucket.s3.amazonaws.com/Declara%C3%A7%C3%A3o+de+privacidade-NUME+(1).pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        políticas de privacidade
                      </a>
                    </span>
                  </div>

                  <div className="input-wrapper">
                    <input
                      type="checkbox"
                      onChange={() => {
                        handleEmail();
                      }}
                      id="input-termos"
                      // checked={receiveEmail}
                    />
                    <span id="text-termos">
                      Eu desejo receber ofertas por email
                    </span>
                  </div>
                </div>
              </div>
            )}

            <div className="actions">
              {step > 0 && (
                <ButtonBackContainer
                  image={leftArrow}
                  backgroundColor="#fff"
                  color={colors.pink}
                  border={colors.pink}
                  width="40%"
                  height="50px"
                  onClick={handleGoBackStep}
                >
                  <img src={leftArrow} alt="icone" />
                  <span>Voltar</span>
                </ButtonBackContainer>
              )}
              <ButtonNext
                type="submit"
                onClick={validateData}
                width="40%"
                backgroundColor={colors.pink}
              >
                <span>{step === 2 ? 'Finalizar' : 'Próximo'}</span>
              </ButtonNext>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default RegisterUser;
