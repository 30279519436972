import React from 'react';
import { useHistory } from 'react-router-dom';

import { Section, ButtonFree, ButtonBasico, ButtonPremium } from './styles';

import computer from '../../../assets/png/computer.png';
// import simplifique from '../../../assets/png/simplifiqueSeuMEI (orange).png';
import money from '../../../assets/png/money (orange).png';
import price from '../../../assets/png/price (orange).png';
import month from '../../../assets/png/month (orange).png';
import nossosPlanos from '../../../assets/png/nossosPlanos (orange).png';
import comecePeloPlano from '../../../assets/png/comecePeloPlano.png';
import check from '../../../assets/svg/check.svg';
import { colors } from '../../../constants/colors';

const SectionThree = ({ refProp = 'planos' }) => {
  const history = useHistory();
  return (
    <Section>
      <div id="section1">
        <img id="computer" src={computer} alt="computer" />
        <div id="afterComputer">
          {/* <img id="simplifique" src={simplifique} alt="simplifique seu MEI" /> */}
          {/* <h3 id="simplifique">Simplifique seu MEI agora a partir de:</h3>
          <img id="money" src={money} alt="money" />
          <img id="price" src={price} alt="price" />
          <img id="month" src={month} alt="month" /> */}

          <div className="description">
            <h3>Simplifique seu MEI agora a partir de:</h3>
            <div>
              <p className="money">R$</p>
              <p className="price">29,90</p>
              <p className="month">mês</p>
            </div>
          </div>
        </div>
      </div>

      <div ref={refProp} id="section2">
        <div id="section2Wrapper">
          <div className="nossos-planos">
            {/* <img id="title" src={nossosPlanos} alt="Nossos planos" />
            
            <img
              id="comecePeloPlano"
              src={comecePeloPlano}
              alt="comece pelo plano..."
            /> */}
            <h1>Nossos planos</h1>
            <p>
              Comece pelo nosso plano Free para conhecer o sistema, ou escolha
              entre o básico e Premium e simplifique seu MEI.
            </p>
          </div>
          <table className="advantages">
            <tr>
              <p>DAS MEI (guia)</p>
            </tr>
            <tr>
              <p>Gerenciador Financeiro com gráficos</p>
            </tr>
            <tr>
              <p>Suporte via Chat</p>
            </tr>
            <tr>
              <p>Declaração Anual do MEI</p>
            </tr>
            <tr>
              <p>CND</p>
            </tr>
            <tr>
              <p>Declaração de Faturamento assinada pelo contador</p>
            </tr>
            <tr>
              <p>Reemissão de guias</p>
            </tr>
          </table>
        </div>
        {/* <ul className="planos">
          <li id="free">Free</li>
          <li id="basico">Básico</li>
          <li id="premium">Premium</li>
        </ul> */}
        <div className="plansWrapper">
          <table>
            <thead>
              <tr>
                <td>Free</td>
                <td>Básico</td>
                {/* <td>Premium</td> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    color: colors.blue,
                    fontWeight: 'bold',
                    fontSize: '1.2em',
                  }}
                >
                  Grátis
                </td>
                <td
                  style={{
                    color: colors.darkGray,
                    fontWeight: '600',
                    fontSize: '1.2em',
                  }}
                >
                  R$ 29,90/<span style={{ fontSize: '0.6em' }}>mês</span>
                </td>
                {/* <td
                  style={{
                    color: colors.pink,
                    fontWeight: 'bold',
                    fontSize: '1.2em',
                    backgroundColor: '#FEF7F2',
                  }}
                >
                  R$ 69,90/<span style={{ fontSize: '0.6em' }}>mês</span>
                </td> */}
              </tr>
              <tr>
                <td>
                  <ButtonFree
                    onClick={() => {
                      history.push('/login');
                    }}
                  >
                    <span>Começar</span>
                  </ButtonFree>
                </td>
                <td>
                  <ButtonBasico
                    onClick={() => {
                      history.push('/login');
                    }}
                  >
                    <span>Contratar</span>
                  </ButtonBasico>
                </td>
                {/* <td style={{ backgroundColor: '#FEF7F2' }}>
                  <ButtonPremium
                    onClick={() => {
                      history.push('/login');
                    }}
                  >
                    <span>Contratar</span>
                  </ButtonPremium>
                </td> */}
              </tr>
              <tr className="das">
                <td>
                  <img src={check} alt="check" />
                </td>
                <td>
                  <p className="paragraph">DAS MEI (guia)</p>
                  <img src={check} alt="check" />
                </td>
                {/* <td style={{ backgroundColor: '#FEF7F2' }}>
                  <img src={check} alt="check" style={{ width: '50px' }} />
                </td> */}
              </tr>
              <tr>
                <td>
                  <img src={check} alt="check" />
                </td>
                <td>
                  <p className="paragraph">
                    Gerenciador financeiro com gráficos
                  </p>
                  <img src={check} alt="check" />
                </td>
                {/* <td style={{ backgroundColor: '#FEF7F2' }}>
                  <p style={{ fontWeight: 'bold', display: 'block' }}>
                    Melhor opção
                  </p>
                  <h2 style={{ color: colors.pink }}>
                    Todas as funções do sistema.
                  </h2>
                </td> */}
              </tr>
              <tr>
                <td>
                  <img src={check} alt="check" />
                </td>
                <td>
                  <p className="paragraph">Suporte via Chat</p>
                  <img src={check} alt="check" />
                </td>
                {/* <td style={{ backgroundColor: '#FEF7F2' }}>
                  <p style={{ display: 'block' }}>
                    Incluindo 1 funcionário e todos os serviços adicionais.
                  </p>
                </td> */}
              </tr>
              <tr>
                <td>
                  <img src={check} alt="check" />
                </td>
                <td>
                  <p className="paragraph">Declaração anual do MEI</p>
                  <img src={check} alt="check" />
                </td>
                {/* <td style={{ backgroundColor: '#FEF7F2' }} /> */}
              </tr>
              <tr>
                <td>
                  <img src={check} alt="check" />
                </td>
                <td>
                  <p className="paragraph">CND</p>
                  <img src={check} alt="check" />
                </td>
                {/* <td style={{ backgroundColor: '#FEF7F2' }} /> */}
              </tr>
              <tr>
                <td>
                  <p className="X">x</p>
                </td>
                <td>
                  <p className="paragraph">
                    Declaração de faturamento assinada pelo contador
                  </p>
                  <img src={check} alt="check" />
                </td>
                {/* <td style={{ backgroundColor: '#FEF7F2' }} /> */}
              </tr>
              <tr>
                <td>
                  <p className="X">x</p>
                </td>
                <td>
                  <p className="paragraph">Reemissão de guias</p>
                  <img src={check} alt="check" />
                </td>
                {/* <td style={{ backgroundColor: '#FEF7F2' }} /> */}
              </tr>
            </tbody>
          </table>
          <div className="premium">
            <div className="header">Premium</div>
            <p
              style={{
                color: colors.pink,
                fontWeight: 'bold',
                fontSize: '1.2em',
              }}
            >
              R$ 69,90/ <span style={{ fontSize: '0.6em' }}>mês</span>
            </p>
            <ButtonPremium
              onClick={() => {
                history.push('/login');
              }}
            >
              <span>Contratar</span>
            </ButtonPremium>
            <img src={check} alt="check" style={{ width: '50px' }} />
            <p
              style={{
                fontWeight: 'bold',
                display: 'block',
                fontSize: '0.8em',
              }}
            >
              Melhor opção
            </p>
            <h2 style={{ color: colors.pink }}>Todas as funções do sistema.</h2>
            <p style={{ display: 'block', fontSize: '0.8em' }}>
              Incluindo 1 funcionário e todos os serviços adicionais.
            </p>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default SectionThree;
