import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const Container = styled.div`
  display: flex;
`;

export const Content = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;

  header {
    width: 100%;
    height: 100px;
    min-height: 100px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    box-shadow: 0px 4px 12px rgba(17, 14, 27, 0.13);
    margin-bottom: 45px;
  }
  .title {
    padding-left: 24px;
    display: flex;
    margin-bottom: 24px;
    h2 {
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      &:last-child {
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }
  .data {
    padding-left: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    .info {
      display: flex;
      h3 {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        &:last-child {
          font-weight: 500;
        }
      }
    }
    button {
      max-width: 183px;
      height: 44px;
      border: 1px solid #000;
      border-radius: 8px;
      padding: 10px;
      background-color: #fff;
      cursor: pointer;
    }
  }
  .line {
    display: flex;
    margin-left: 24px;
    margin-top: 40px;
    height: 1px;
    width: 96%;
    background-color: #000;
  }
  #list {
    width: 100%;
    height: 90%;
    overflow-y: scroll;
    nav {
      padding-top: 24px;

      padding-left: 24px;
      padding-right: 24px;
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
      #tabs {
        display: flex;
        gap: 1em;
        span {
          cursor: pointer;
          width: 5em;
        }
        .selected {
          color: ${colors.orange};

          &:after {
            display: block;
            margin-top: 10px;
            width: 5em;
            content: '';
            height: 2px;
            background-color: ${colors.orange};
          }
        }
      }
      .actions {
        display: flex;
        align-items: center;
        max-width: 240px;
        width: 100%;
        height: 44px;
        border: 1px solid #000;
        background-color: #fff;
        border-radius: 8px;
        padding: 10px;
        h3 {
          margin-left: 5px;
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
        }
        img {
          width: 18px;
          height: 18px;
        }
        select {
          height: 40px;
          width: 100%;
          font-size: 16px;
          line-height: 24px;
          font-weight: normal;
          border: 0;
          background-color: #fff;
          -webkit-appearance: none;
          -moz-appearance: none;
          text-indent: 1px;
          text-overflow: '';
          &:focus {
            outline: 0;
          }
        }
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 1em;
      list-style: none;
      padding: 1em 1em 0 1em;
      li {
        position: relative;
        height: 2em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .span {
          font-weight: 600;
        }

        &::after {
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: black;
          content: '';
          display: block;
        }
        &::after {
          bottom: 0;
        }
        div {
          display: flex;
          gap: 2em;
        }
      }
    }
  }
`;
