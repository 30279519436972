/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { toast, ToastContainer } from 'react-toastify';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { RiCloseCircleLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import Button from '../../../components/Button';
import Header from '../../../components/Mobile/Header';
import Footer from '../../../components/Mobile/Footer';
import { colors } from '../../../constants/colors';
import exclamation from '../../../assets/svg/exclamationMark.svg';
import api from '../../../services/api';
import formatDate from '../../../utils/formatDate';
import ExpenditureList from '../../../utils/expenditure';
import { ContainerMobile, Modal } from './styles';
import { InputField } from '../RecuperarSenha/styles';
import Loading from '../../../components/Loading';

const RelatoriosMobile = () => {
  const user = useSelector((state) => state.auth.user);
  const subs = user.subscription_aquisition;
  const expenditure = ExpenditureList();
  const dateNow = new Date().toISOString().slice(0, 10);
  const [receitas, setReceitas] = useState(true);
  const [chartData, setChartData] = useState([
    {
      name: 'carregando',
      receita: 300,
      despesas: 100,
    },
  ]);
  const [periodYear, setPeriodYear] = useState('');
  const [period, setPeriod] = useState('lastfifteendays');
  const [reload, setReload] = useState(false);
  const [data, setData] = useState(false);
  const [dataYear, setDataYear] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPopupExpiredPlan, setShowPopupExpiredPlan] = useState(false);
  const [showPopupNoTransactions, setShowPopupNoTransactions] = useState(false);
  const [showTransactionForm, setShowTransactionForm] = useState(false);
  const [billingMonths, setBillingMonths] = useState([]);
  const months = [
    'null',
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  useEffect(async () => {
    setData(false);
    if (periodYear === '') {
      const response = await api.get(`/transactions/show?${period}=1`);
      setData(response.data);
      setDataYear(false);
    } else {
      setPeriod('');
      const response = await api.get(`/transactions/show?${period}=1`);
      setData(response.data);
      if (String(periodYear).length === 4) {
        const responseYear = await api.get(
          `/transactions/show?year=${String(periodYear)}`,
        );
        setDataYear(responseYear.data);
      }
    }
  }, [period, periodYear, reload]);

  useEffect(() => {
    if (dataYear === false) {
      const list = [];
      const listN = [];
      if (data.length > 0) {
        data.map((item) => {
          if (item.value > 0) {
            list.push(item.value);
          } else {
            listN.push(item.value);
          }
        });
        const value =
          list.length > 0 ? list.reduce((total, val) => total + val) : 0;
        const valueN =
          listN.length > 0 ? listN.reduce((total, val) => total + val) : 0;
        setChartData([
          {
            name: 'Orçamento',
            receita: value || 0,
            despesas: valueN ? valueN * -1 : 0,
          },
        ]);
      } else {
        setChartData([
          {
            name: 'Orçamento',
            receita: 0,
            despesas: 0,
          },
        ]);
      }
    } else {
      setChartData([
        {
          name: `Orçamento ${periodYear}`,
          receita: dataYear.Revenues,
          despesas: dataYear.Expenses * -1,
        },
      ]);
    }
  }, [data, dataYear]);

  useEffect(async () => {
    const show = sessionStorage.getItem('showPopUp');
    const noTransaction = await api.get(
      `/transactions/show?notransactions=${dateNow.slice(0, 4)}`,
    );
    if (dateNow.slice(9, 10) >= 20) {
      handleNoTransactions(noTransaction.data, show);
    }
  }, []);

  useEffect(() => {
    handleExpiredMonth(subs);
  }, [subs]);

  function openTransactionForm() {
    formik.setValues(initialValue);
    setShowTransactionForm(true);
  }

  function addTransaction() {
    if (!receitas) {
      formik.values.value *= -1;
    }

    api
      .post('/transactions', formik.values)
      .then(() => {
        handleCloseTransactionForm();
        setReload(!reload);
        toast.success(
          `${receitas ? 'Receita' : 'Despesa'} cadastrada com sucesso!`,
        );
      })
      .catch((error) => {
        console.log(error);
        toast.error(`Erro ao cadastrar ${receitas ? 'receita' : 'despesa'}`);
      });
  }

  async function DeleteTransaction() {
    setIsLoading(true);
    setDeleteModalState(false);

    api
      .delete(`/transactions/${deleteModalState.id}`)
      .then(() => {
        toast.success(
          `${receitas ? 'Receita' : 'Despesa'} deletada com sucesso`,
        );
        setIsLoading(false);
        setReload(!reload);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error);
      });
  }

  async function EditTransactions() {
    setIsLoading(true);

    const dataSubmit = {
      name: formik.values.name,
      date: formik.values.date.slice(0, 10),
      value:
        receitas === false ? (formik.values.value *= -1) : formik.values.value,
    };

    api
      .patch(`/transactions/${showTransactionForm.id}`, dataSubmit)
      .then(() => {
        setIsLoading(false);
        toast.success(
          `${receitas ? 'Receita' : 'Despesa'} editada com sucesso`,
        );
        handleCloseTransactionForm();
        setReload(!reload);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error);
      });
  }

  const initialValue = {
    name: '',
    date: dateNow,
    value: 0,
    user_id: user.id,
    installments: 1,
    payment_method_id: 1,
    is_paid: 0,
  };

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: yup.object().shape({
      name: yup
        .string()
        .max(100, 'A descrição deve ter no máximo 100 caracteres')
        .required('Descrição é um campo obrigatório'),
      value: yup
        .number()
        .min(1, 'O valor deve ser de no minimo R$ 1.00')
        .required('Valor é um campo obrigatório'),
      date: yup
        .string()
        .test('dataLimit', `Data maxima ${formatDate(dateNow)}`, (value) => {
          if (value > dateNow) {
            return false;
          }
          return true;
        })
        .required('Data é um campo obrigatório'),
    }),
    onSubmit: (values) => {
      if (showTransactionForm.id) {
        EditTransactions();
      } else {
        addTransaction();
      }
    },
  });

  const handleExpiredMonth = () => {
    const show = sessionStorage.getItem('showPopUpExpired');
    const monthAcquisition = parseInt(
      new Date(subs).toISOString().slice(5, 7),
      10,
    );
    const todayMonth = parseInt(new Date().toISOString().slice(5, 7), 10);
    if (
      (todayMonth - monthAcquisition === -1 ||
        todayMonth - monthAcquisition === 11) &&
      show === 'true' &&
      user.plan.name !== 'Free'
    ) {
      setShowPopupExpiredPlan(true);
    }
    return true;
  };

  const handleCloseExpiredPlan = () => {
    setShowPopupExpiredPlan(false);
    sessionStorage.setItem('showPopUpExpired', false);
  };

  function handleOpenEditModal(item) {
    formik.setErrors('');
    formik.values.name = item.name;
    formik.values.date = item.date.slice(0, 10);
    formik.values.value = receitas === false ? item.value * -1 : item.value;
    setShowTransactionForm(item);
  }

  function handleCloseTransactionForm() {
    setShowTransactionForm(false);
    formik.setErrors(false);
  }

  const handleNoTransactions = (noTransactions, show) => {
    const noTransactionMonths = [];
    if (show === 'true') {
      noTransactions.map((noTransactionMonth) => {
        if (noTransactionMonth === null) {
          setShowPopupNoTransactions(false);
        } else {
          if (months[noTransactionMonth])
            noTransactionMonths.push(months[noTransactionMonth]);
          setShowPopupNoTransactions(true);
        }
      });
      if (noTransactionMonths === undefined || noTransactionMonths === null) {
        setShowPopupNoTransactions(false);
      }
      return setBillingMonths(noTransactionMonths);
    }
    return false;
  };

  function handleClick(e) {
    const target = e.target.className;
    if (!target || target.baseVal !== 'dropdownButton') {
      setDropDown(false);
    }
  }

  function DropDownState(itemId) {
    if (dropDown === itemId) {
      setDropDown(false);
    } else {
      setDropDown(itemId);
    }
  }

  return (
    <ContainerMobile>
      <Loading open={isLoading} />
      <ToastContainer />
      <Header thisPage="Receitas e Despesas" />
      <div className="content">
        <div className="graphicContainer">
          <div className="actions">
            <div className="yearContainer">
              <span>Ano:</span>
              <input
                name="ano"
                id="ano"
                type="number"
                max="9999"
                step="1"
                value={periodYear}
                onChange={(e) => setPeriodYear(e.target.value)}
              />
            </div>
            <select
              onChange={(e) => {
                setPeriod(e.target.value);
                setPeriodYear('');
              }}
              value={period}
              name="period"
            >
              <option value="lastfifteendays">Últimos 15 dias</option>
              <option value="lastthirtydays">Últimos 30 dias</option>
              <option value="lastsixtydays"> Últimos 60 dias</option>
              <option value="lastninetydays">Últimos 90 dias</option>
              <option value="">Todos os registros</option>
            </select>
          </div>
          <div className="graphicContent">
            <BarChart
              responsive
              width={window.innerWidth - (window.innerWidth * 13) / 100}
              height={300}
              data={chartData}
            >
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip
                formatter={(value) =>
                  new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(value)
                }
              />
              <Bar type="monotone" dataKey="receita" fill={colors.pink} />
              <Bar
                type="monotone"
                dataKey="despesas"
                fill={colors.orangeActive}
              />
              <Legend
                iconType="circle"
                verticalAlign="bottom"
                align="center"
                wrapperStyle={{ bottom: -10 }}
              />
            </BarChart>
          </div>
        </div>
        <div className="transactionsList">
          <nav>
            <div className="options">
              <span
                className={receitas && 'selected'}
                onClick={() => setReceitas(true)}
              >
                Receitas
              </span>
              <span
                className={!receitas && 'selected'}
                onClick={() => setReceitas(false)}
              >
                Despesas
              </span>
            </div>

            <Button
              color="#FFF"
              width="110px"
              height=" 30px"
              radius="8px"
              fontSize="14px"
              text={receitas ? 'Nova Receita' : 'Nova Despesa'}
              backgroundColor="#FF6D24"
              active="#FF6600"
              hover="#D26A24"
              onClick={() => openTransactionForm()}
            />
          </nav>
          <ul className="transactionsList">
            {data &&
              data
                .filter((o) => (receitas ? o.value >= 0 : o.value <= 0))
                .sort((a, b) => {
                  if (a.date > b.date) {
                    return -1;
                  }
                  if (a.date < b.date) {
                    return 1;
                  }
                  return 0;
                })
                .map((item) => (
                  <li className="transaction">
                    <div>
                      <span>
                        {item.date.slice(0, 10).split('-').reverse().join('/')}
                      </span>
                      <span>{item.name}</span>
                    </div>
                    <div>
                      <span className="value">
                        {receitas
                          ? Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(item.value)
                          : Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(-1 * item.value)}
                      </span>
                      <div>
                        <BiDotsVerticalRounded
                          className="dropdownButton"
                          style={{ cursor: 'pointer' }}
                          onClick={() => DropDownState(item.id)}
                          size={21}
                        />
                        <div className="optionsContainer">
                          <ul
                            className={`options ${
                              dropDown === item.id ? 'dropOpen' : 'dropClose'
                            }`}
                          >
                            <li
                              onClick={() => {
                                handleOpenEditModal(item);
                              }}
                            >
                              Editar
                            </li>
                            <li onClick={() => setDeleteModalState(item)}>
                              Deletar
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
          </ul>
        </div>
      </div>
      <Footer />
      <Modal open={showPopupNoTransactions}>
        <div className="container">
          <h1 id="popup-title">Estamos chegando no fim do mês!</h1>
          <p>
            Não se esqueça de informar os dados relacionados ao faturamento
            mensal! Abaixo se encontram os meses que não possuem nenhum registro
            no sistema:
          </p>
          <ul>
            {billingMonths.map((month) => (
              <li>
                <img
                  id="exclamation"
                  src={exclamation}
                  alt="exclamation-mark"
                />
                <span id="month">{month}</span>
              </li>
            ))}
          </ul>
          <div id="despesas-button">
            <Button
              text="Ok"
              width="100%"
              radius="8px"
              onClick={() => {
                sessionStorage.setItem('showPopUp', false);
                setShowPopupNoTransactions(false);
              }}
            />
          </div>
        </div>
      </Modal>
      <Modal
        open={showTransactionForm}
        onClose={() => handleCloseTransactionForm()}
      >
        <div className="transactionContainer">
          <nav>
            <h3>
              {showTransactionForm.id ? 'Editar' : ' Nova'}{' '}
              {receitas ? 'receita' : ' despesa'}
            </h3>
            <a onClick={() => handleCloseTransactionForm()}>
              <RiCloseCircleLine size={25} color={colors.mediumGray} />
            </a>
          </nav>
          <div className="inputContainer">
            {receitas ? (
              <InputField color="#000">
                <p>Descrição</p>
                <input
                  type="text"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="errors">{formik.errors.name}</div>
                ) : null}
              </InputField>
            ) : (
              <InputField color="#000">
                <p>Descrição</p>
                <select
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                >
                  <option value="">Selecione despesa</option>
                  {expenditure.map((expense) => (
                    <option value={expense.name}>{expense.name}</option>
                  ))}
                </select>
                {formik.touched.name && formik.errors.name ? (
                  <div className="errors">{formik.errors.name}</div>
                ) : null}
              </InputField>
            )}
            <InputField color="#000">
              <p>Data</p>
              <input
                type="date"
                name="date"
                max="9999-12-31"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.date.split('T')[0]}
              />
              {formik.touched.date && formik.errors.date ? (
                <div className="errors">{formik.errors.date}</div>
              ) : null}
            </InputField>
            <InputField color="#000">
              <p>Valor</p>
              <input
                type="number"
                name="value"
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.values.value = parseFloat(formik.values.value);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.value}
              />
              {formik.touched.value && formik.errors.value ? (
                <div className="errors">{formik.errors.value}</div>
              ) : null}
            </InputField>
          </div>
          <Button
            color="#FFF"
            width="100%"
            height="40px"
            radius="8px"
            fontSize="1.2em"
            text="Confirmar"
            backgroundColor={colors.orange}
            active="#FF6600"
            hover="#D26A24"
            onClick={() => formik.handleSubmit()}
            disabled
          />
        </div>
      </Modal>
      <Modal open={showPopupExpiredPlan} onClose={handleCloseExpiredPlan}>
        <div className="container">
          <h1>O seu plano está expirando!</h1>
          <p>
            Sem ele, você deixa de ter acesso aos recursos do plano{' '}
            {user.plan.name} da Nume. Considere renová-lo para continuar
            aproveitando todas as facilidades de nossa contabilidade online.
          </p>

          <Button
            text="OK"
            width="100%"
            radius="8px"
            onClick={handleCloseExpiredPlan}
          />
        </div>
      </Modal>
      <Modal open={deleteModalState} onclose={() => setDeleteModalState(false)}>
        <div className="popConfirmContainer">
          <h3>Tem certeza que deseja excluir este registro?</h3>

          <p>Essa ação não poderá ser desfeita.</p>
          <div className="actions">
            <Button
              color={colors.orange}
              width="125px"
              height="35px"
              radius="8px"
              fontSize="1.2em"
              text="Cancelar"
              backgroundColor="transparent"
              border={colors.orange}
              active="#FF6600"
              hover="#D26A24"
              onClick={() => setDeleteModalState(false)}
            />
            <Button
              color="#FFF"
              width="125px"
              height="35px"
              marginBottom="5px"
              marginTop="5px"
              radius="8px"
              fontSize="1.2em"
              text="Deletar"
              backgroundColor={colors.orange}
              active="#FF6600"
              hover="#D26A24"
              onClick={DeleteTransaction}
            />
          </div>
        </div>
      </Modal>
    </ContainerMobile>
  );
};

export default RelatoriosMobile;
