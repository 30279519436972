import React from 'react';

import { Container } from './styles';
import rs from './svgs/3.svg';
import mes from './svgs/2.svg';
import preco from './svgs/1.svg';

function Price() {
  return (
    <Container>
      <img id="rs" src={rs} alt="R$" />
      <img id="preco" src={preco} alt="29,90" />
      <img id="mes" src={mes} alt="mes" />
    </Container>
  );
}

export default Price;
