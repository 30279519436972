import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Section } from './styles';

import bannerText from '../../../assets/png/homePageSectionOne-bannerText.png';
import bannerTextDesktop from '../../../assets/png/homePageSectionOne-bannerTextDesktop.png';
import Button from '../../../components/Button';
import { colors } from '../../../constants/colors';

// import woman1 from '../../../assets/jpg/woman1.jpg';

const SectionOne = ({ scroll = function () {} }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const history = useHistory();

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  return (
    <Section>
      <div id="section1">
        <div id="text-container">
          <p>
            Contabilidade <b>simplificada ao máximo</b> e sem medo.
          </p>
        </div>

        {/* {windowWidth <= 768 ? (
          <p>Contabilidade simplificada ao máximo e sem medo.</p>
        ) : (
          // <img id="bannerText" src={bannerText} alt="text-banner" />
          <img
            id="bannerTextDesktop"
            src={bannerTextDesktop}
            alt="text-banner"
          />
        )} */}
      </div>

      <div id="section2">
        <p>
          Reduza ao máximo os custos da sua contabilidade e mantenha sua empresa
          em dia com um serviço extremamente simples e um atendimento que sempre
          faz de tudo para te ajudar.
        </p>
        {/* {window.innerWidth < 768 ? (
          <> */}
        <Button
          text={windowWidth < 768 ? 'Conheça agora' : 'Quero ser Nume'}
          onClick={() => history.push('/cadastrar')}
          width={windowWidth < 768 ? '260px' : '230px'}
          height="50px"
          id="orangeButton"
          backgroundColor={colors.orange}
          fontSize="20px"
          marginBottom={windowWidth < 768 ? '20px' : '0px'}
          marginRight={windowWidth > 768 ? '40px' : '0px'}
        />
        {windowWidth < 768 ? (
          <Button
            text="Já sou cadastrado"
            backgroundColor={colors.white}
            color={colors.opacityGray}
            border={colors.orange}
            width="260px"
            height="50px"
            onClick={() => history.push('/login')}
          />
        ) : (
          <Button
            text="Quero saber mais"
            backgroundColor={colors.white}
            color={colors.opacityGray}
            border={colors.orange}
            width="230px"
            height="50px"
            onClick={scroll}
          />
        )}
        <div style={{ margin: '10px 0px' }} />
        {/* </>
        ) : (
          <>
            <Button
              text="Quero ser Nume"
              width={window.innerWidth < 768 ? '260px' : 'auto'}
              height="50px"
              id="orangeButton"
              backgroundColor={colors.orange}
              fontSize="20px"
            />
            <div style={{ margin: '10px 0px' }} />
            <Button
              text="Quero saber mais"
              backgroundColor={colors.white}
              color={colors.opacityGray}
              border={colors.orange}
              width={window.innerWidth < 768 ? '260px' : 'auto'}
              height="50px"
            />
            <div style={{ margin: '10px 0px' }} />
          </>
        )} */}
      </div>

      {/* <img src={woman1} alt="background" /> */}
    </Section>
  );
};

export default SectionOne;
