import styled from 'styled-components';

export const Container = styled.div`
  min-height: 84px;
  width: 100%;
  background-color: #ee7200;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
  color: #fff;
  padding-right: 16px;
  bottom: 0;
`;
