import React from 'react';
// CONCERTARRRRRR

import { useHistory } from 'react-router-dom';
import { Wrapper, Button } from './styles';
import { colors } from '../../constants/colors';
import logoNume from '../../assets/svg/logoNUME(pink).svg';
import userIcon from '../../assets/svg/user(pink).svg';

const TudoCerto = () => {
  const history = useHistory();
  return (
    <Wrapper>
      <div id="logo-container">
        <img
          style={{ color: 'transparent', height: '70%' }}
          src={logoNume}
          alt="logo-nume"
        />
      </div>

      <h1>Tudo certo!</h1>
      <p>
        Foram enviadas instruções para o seu e-mail, para recuperar sua senha.
        Verifique sua caixa de entrada e seus spams, e siga as instruções.
      </p>
      <Button
        width="200px"
        image={userIcon}
        backgroundColor={colors.white}
        color={colors.pink}
        onClick={() => history.push('/login')}
      >
        <img src={userIcon} alt="icone" />
        <span>Ir para o login</span>
      </Button>
    </Wrapper>
  );
};

export default TudoCerto;
