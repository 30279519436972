import styled from 'styled-components';

import { colors } from '../../constants/colors';
import { ButtonContainer } from '../Button/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  #logo-container {
    margin-top: 8em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: ${colors.white};
    img {
      color: ${colors.pink} !important;
    }
  }
  h1 {
    padding-top: 1em;
    color: ${colors.white};
    font-weight: 500;
  }
  p {
    padding-top: 1em;
    padding-bottom: 2em;
    width: 38%;
    color: ${colors.white};
    text-align: center;
  }
`;

export const Button = styled(ButtonContainer)`
  &:hover {
    background-color: transparent;
    border: 2px solid #fff;

    img {
      display: none;
    }
    span {
      color: #fff;
    }
  }
  &:active {
    background-color: #fff;
    border: none;
    img {
      display: block;
    }
    span {
      color: ${colors.pink};
    }
  }
`;
