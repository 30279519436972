/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import api from '../../services/api';

import { MainContainer } from './styles';

import Button from '../../components/Button';
import Loading from '../../components/Loading';

import closeIcon from '../../assets/svg/close-circle.svg';

const AlterarSenhaDashboard = () => {
  const history = useHistory();
  const [errors, setErrors] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validate, setValidate] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState('password');
  const [showNewPassword, setShowNewPassword] = useState('password');
  const [showConfirmPassword, setShowConfirmPassword] = useState('password');
  const user = useSelector((state) => state.auth.user);
  const token = sessionStorage.getItem('token');

  const [inputs, setInputs] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const handleClose = () => {
    history.push('/dashboard');
  };

  const schema = yup.object().shape({
    currentPassword: yup
      .string()
      .min(8, 'A senha deve ter pelo menos 8 caracteres')
      .required(),
    newPassword: yup
      .string()
      .min(8, 'A nova senha deve ter pelo menos 8 caracteres')
      .required(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], 'As senhas devem ser iguais'),
  });

  const handleValidate = async () => {
    setErrors(false);
    try {
      await schema.validate(inputs);
      setValidate(true);
      setErrors(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      if (error instanceof yup.ValidationError) {
        error.errors.map((err) => setErrors(err));
        setValidate(false);
      }
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (validate) {
      let res;
      await api
        .post('/sessions', {
          email: user.email,
          password: inputs.currentPassword,
        })
        .then((response) => {
          console.log(response.error);
          res = response;
        })
        .catch((err) => {
          res = {
            status: 400,
          };
          setIsLoading(false);
          setErrors('Senha atual inválida');
        });

      if (res.status === 201) {
        api
          .patch(
            '/users/update',
            {
              password: inputs.newPassword,
              repeat_password: inputs.confirmPassword,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .then(() => {
            setPasswordChanged(true);
            setIsLoading(false);
          })
          .then(() => document.location.reload())
          // eslint-disable-next-line no-console
          .catch((err) => {
            setIsLoading(false);
            setErrors('Erro ao salvar nova senha');
            console.log(err);
          });
      }
    }
  };

  function handleShowCurrentPassword() {
    if (showCurrentPassword === 'password') {
      setShowCurrentPassword('text');
    } else {
      setShowCurrentPassword('password');
    }
  }

  function handleShowConfirmPassword() {
    if (showConfirmPassword === 'password') {
      setShowConfirmPassword('text');
    } else {
      setShowConfirmPassword('password');
    }
  }

  function handleShowNewPassword() {
    if (showNewPassword === 'password') {
      setShowNewPassword('text');
    } else {
      setShowNewPassword('password');
    }
  }

  return (
    <MainContainer>
      <Loading open={isLoading} />
      <img src={closeIcon} alt="close-icon" onClick={handleClose} />
      <h1>Alterar senha</h1>
      {errors ? <p style={{ color: 'red' }}>{errors}</p> : null}
      <ul>
        <li>
          <span>Senha atual</span>
          <fieldset>
            <input
              type={showCurrentPassword}
              name="currentPassword"
              placeholder="Por favor, insira a sua senha atual"
              onChange={(e) =>
                setInputs({ ...inputs, [e.target.name]: e.target.value })
              }
              onBlur={handleValidate}
            />
            {showCurrentPassword === 'password' ? (
              <BsEyeFill onClick={handleShowCurrentPassword} className="eye" />
            ) : (
              <BsEyeSlashFill
                onClick={handleShowCurrentPassword}
                className="eye"
              />
            )}
          </fieldset>
        </li>
        <li>
          <span>Nova senha</span>
          <fieldset>
            <input
              type={showNewPassword}
              name="newPassword"
              placeholder="Insira sua nova senha"
              onChange={(e) =>
                setInputs({ ...inputs, [e.target.name]: e.target.value })
              }
              onBlur={handleValidate}
            />
            {showNewPassword === 'password' ? (
              <BsEyeFill onClick={handleShowNewPassword} className="eye" />
            ) : (
              <BsEyeSlashFill onClick={handleShowNewPassword} className="eye" />
            )}
          </fieldset>
        </li>
        <li>
          <span>Confirme sua nova senha</span>
          <fieldset>
            <input
              type={showConfirmPassword}
              name="confirmPassword"
              placeholder="Confirme sua nova senha"
              onChange={(e) =>
                setInputs({ ...inputs, [e.target.name]: e.target.value })
              }
              onBlur={handleValidate}
            />
            {showConfirmPassword === 'password' ? (
              <BsEyeFill onClick={handleShowConfirmPassword} className="eye" />
            ) : (
              <BsEyeSlashFill
                onClick={handleShowConfirmPassword}
                className="eye"
              />
            )}
          </fieldset>
        </li>
      </ul>
      <Button
        onClick={handleSubmit}
        text="Confirmar"
        radius="8px"
        width="64%"
        marginTop="0.5em"
      />
      {passwordChanged ? (
        <p style={{ marginTop: '2em', color: 'red', fontSize: '18px' }}>
          Senha alterada com sucesso!
        </p>
      ) : null}
    </MainContainer>
  );
};

export default AlterarSenhaDashboard;
