import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { size, device } from '../../constants/size';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};

  .content {
    padding: ${size.mobile.padding};
    width: 100%;

    .formContainer {
      h1 {
        font-size: 27px;
        color: #e9545a;
      }

      form {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .inputContainer {
          padding-top: ${size.mobile.padding};

          p {
            color: ${colors.mediumGray};
            font-size: ${size.mobile.fontSize};
            font-weight: 600;
            padding-bottom: 17px;
          }

          input,
          textarea {
            width: 100%;
            border: 0;
            background-color: transparent;
            border-bottom: 1px solid ${colors.lightGray};
            resize: none;
            color: ${colors.mediumGray};
            outline: none;
            font-size: 16px;
          }

          *:focus {
            border-color: ${colors.orange};
          }

          .errors {
            color: ${colors.pink};
            font-size: 14px;
          }
        }

        .action {
          padding-top: 16px;
        }
      }
    }
  }

  @media ${device.desktop} {
    .content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .formContainer {
        h1 {
          font-size: 48px;
        }

        form {
          .inputContainer {
            p {
              font-size: 24px;
            }
            input,
            textarea {
              font-size: 18px;
            }
          }
        }

        .action {
          padding-top: 0;
          margin-top: 70px;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
`;
