/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { AiOutlineSearch } from 'react-icons/ai';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Content, Container, Client } from './styles';
import AdmMenu from '../../../components/MenuAdm';

import eye from '../../../assets/svg/eye.svg';
import api from '../../../services/api';
import { cpfMask } from '../../../utils/mask';
import FormatDate from '../../../utils/formatDate';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const SearchOverdue = () => {
  const [clients, setClients] = useState([]);
  const [clientsOverdue, setClientsOverdue] = useState([]);
  const [clientsOverdueDisplayed, setClientsOverdueDisplayed] = useState([]);
  const [input, setInput] = useState([]);
  const history = useHistory();

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(true);
    api.get('/users').then((response) => {
      setClients(response.data);
    });
  }, []);

  useEffect(() => {
    api.get('/invoices/expired ').then((response) => {
      setClientsOverdue(response.data.items);
      setClientsOverdueDisplayed(response.data.items);
      handleClose();
    });
  }, []);

  const goToDetail = async (cpf) => {
    const user = await clients.find((client) => cpf === client.cpf);

    if (user) {
      history.push({
        pathname: '/gestao/client',
        state: { client: user },
      });
    } else {
      toast('Usuário não existe');
    }
  };

  useEffect(() => {
    setClientsOverdueDisplayed(
      clientsOverdue.filter((client) =>
        client.payer_name.toLowerCase().includes(input.toLowerCase()),
      ),
    );

    if (!input) {
      setClientsOverdueDisplayed(clientsOverdue);
    }
  }, [input]);

  return (
    <Container>
      <AdmMenu page="ViewInadinplences" />
      <ToastContainer />
      <Content>
        <Backdrop className={classes.backdrop} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <header>
          <h1>Inadimplências</h1>
        </header>
        <div className="input">
          <input
            type="text"
            placeholder="Pesquisar clientes"
            onChange={(e) => setInput(e.target.value)}
          />{' '}
          <button type="button">
            <AiOutlineSearch size={24} />
          </button>
        </div>
        <div className="line" />
        <div className="clients">
          {clientsOverdueDisplayed.map((OverdueClient) => (
            <Client>
              <div className="data">
                <h2>{FormatDate(OverdueClient.due_date)}</h2>
                <h2 className="name">{OverdueClient.payer_name}</h2>
                <h2>{cpfMask(OverdueClient.payer_cpf_cnpj)}</h2>
                <h2 className="value">{OverdueClient.total}</h2>
                <button
                  type="button"
                  onClick={() => goToDetail(OverdueClient.payer_cpf_cnpj)}
                >
                  <img src={eye} alt="eye" />
                </button>
              </div>
              <div className="line" />
            </Client>
          ))}
        </div>
      </Content>
    </Container>
  );
};
export default SearchOverdue;
