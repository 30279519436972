import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { size, device } from '../../../constants/size';

export const Wrapper = styled.div`
  width: 100%;

  .content {
    background-size: 0;
    img.background {
      width: 100%;
    }

    .list {
      margin-bottom: 36px;
      .title {
        h1 {
          color: ${colors.orange};
          padding: ${size.mobile.padding};
        }
      }
      .border {
        border-left: 12px solid ${colors.orange};
        ul {
          padding: ${size.mobile.padding};
          width: 100%;
          li {
            display: flex;
            align-items: baseline;
            margin-top: 15px;

            img {
              margin-right: 10px;
            }

            div {
              h2 {
                font-size: ${size.mobile.fontSize};
                color: ${colors.orange};
              }
              p {
                font-size: 18px;
                color: ${colors.darkGray};
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }

  @media ${device.desktop} {
    background: url(${(props) => props.back});
    min-height: 609px;
    background-size: cover;

    .content {
      img.background {
        display: none;
      }

      .list {
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #fff;
        margin-left: 60px;
        height: 100%;
        width: 45%;

        .title {
          width: 90%;
          margin: ${size.desktop.margin};
          h1 {
            font-size: 48px;
          }
        }

        .border {
          ul {
            li {
              div {
                margin-bottom: ${size.desktop.margin};
                h2 {
                  font-size: 24px;
                  color: ${colors.orange};
                }
                p {
                  font-size: 22px;
                  line-height: 27px;
                }
              }
            }
          }
        }
      }
    }
  }
`;
