/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Container, Content } from './styles';
import { cpfMask } from '../../../utils/mask';
import dateSvg from '../../../assets/svg/dateSVG.svg';
import AdmMenu from '../../../components/MenuAdm';
import formatDate from '../../../utils/formatDate';
import api from '../../../services/api';

const DetailClients = () => {
  const [inadinplence, setInadincplence] = useState(false);
  const [period, setPeriod] = useState('lastfifteendays=1');
  const [data, setData] = useState(false);
  const [receitas, setReceitas] = useState(true);
  const { push } = useHistory();
  const {
    state: { client },
  } = useLocation();

  useEffect(() => {
    api.get(`/invoices/expired?email=${client.email}`).then((response) => {
      setInadincplence(response.data.totalItems);
    });
  }, [client]);

  useEffect(() => {
    api
      .get(`/admins/gettransactions/${client.id}?${period}`)
      .then((response) => setData(response.data));
  }, [period]);

  const GoToUserMei = useCallback(() => {
    if (!client.mei) {
      toast('Usuário não possui MEI');
      return;
    }
    sessionStorage.setItem('users', JSON.stringify(client));
    push({ pathname: '/gestao/client/mei', state: { client } });
  }, []);

  return (
    <Container>
      <AdmMenu />
      <Content>
        <ToastContainer />
        <header>
          <h1>Clientes</h1>
        </header>

        <div className="title">
          <h2>Clientes {' > '}</h2>
          <h2>{` ${client.first_name} ${client.last_name}`}</h2>
        </div>
        <div className="data">
          <div className="info">
            <h3>Primero nome:</h3>
            <h3>{client.first_name}</h3>
          </div>
          <div className="info">
            <h3>Plano atual:</h3>
            <h3>{client.plan.name}</h3>
          </div>
          <div className="info">
            <h3>Data de nascimento:</h3>
            <h3>{formatDate(client.birth_date)}</h3>
          </div>
          <div className="info">
            <h3>Situação de inadimplência:</h3>
            <h3>
              {inadinplence === false
                ? 'Buscando...'
                : inadinplence === 0
                ? 'Regular'
                : 'Inadimplente'}
            </h3>
          </div>
          <div className="info">
            <h3>Cpf:</h3>
            <h3>{cpfMask(client.cpf)}</h3>
          </div>
          <div className="info">
            <h3>Email:</h3>
            <h3>{client.email}</h3>
          </div>
          <div className="info">
            <h3>RG:</h3>
            <h3>{client.rg}</h3>
          </div>
          <div className="info">
            <h3>Endereço:</h3>
            <h3>{`${client.street_name}, n°${client.address_number}`}</h3>
          </div>
          <button type="button" onClick={GoToUserMei}>
            Visualizar Dados MEI
          </button>
        </div>
        <div className="line" />

        <div id="list">
          <nav>
            <div id="tabs">
              <span
                className={receitas && 'selected'}
                onClick={() => setReceitas(true)}
              >
                Receitas
              </span>
              <span
                className={!receitas && 'selected'}
                onClick={() => setReceitas(false)}
              >
                Despesas
              </span>
            </div>
            <div className="actions">
              <img src={dateSvg} alt="date" />
              <h3>Período:</h3>
              <select onChange={(e) => setPeriod(e.target.value)} name="period">
                <option value="lastfifteendays=1"> Últimos 15 dias</option>
                <option value="lastthirtydays=1">Últimos 30 dias</option>
                <option value="lastsixtydays=1">Últimos 60 dias</option>
                <option value="lastninetydays=1">Últimos 90 dias</option>
                <option value="">Todos os registros</option>
              </select>
            </div>
          </nav>
          <div className="line" />
          <ul>
            {data &&
              data
                .filter((o) => (receitas ? o.value >= 0 : o.value <= 0))
                .sort((a, b) => {
                  if (a.date > b.date) {
                    return -1;
                  }
                  if (a.date < b.date) {
                    return 1;
                  }
                  return 0;
                })
                .map((item) => (
                  <li>
                    <div>
                      <span>
                        {item.date.slice(0, 10).split('-').reverse().join('/')}
                      </span>
                      <span>{item.name}</span>
                    </div>
                    <span className="span">
                      {receitas
                        ? Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(item.value)
                        : Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(-1 * item.value)}
                    </span>
                  </li>
                ))}
          </ul>
        </div>
      </Content>
    </Container>
  );
};

export default DetailClients;
