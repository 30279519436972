export const colors = {
  orange: '#f8863a',
  orangeHover: '#D26A24',
  orangeActive: '#FF6600',
  darkOrange: '#e64862',
  green: '#21eba8',
  pink: '#DF3071',
  white: '#FEF7F2',
  lightnessGray: '#00000029',
  opacityGray: '#707070',
  lightGray: '#C5C5C5',
  mediumGray: '#828282',
  darkGray: '#808080',
  black: '#000000',
  blue: '#016C9D',
};
