import styled from 'styled-components';
import { device } from '../../../constants/size';
import { colors } from '../../../constants/colors';

export const Container = styled.div`
  @media ${device.mobile} {
    padding-left: 0;
  }
  @media (max-width: 940px) {
    padding-left: 0;
  }
  max-height: 853.26px;
  width: 100%;
  height: 853.26px;
  background-color: #fef7f2;

  display: flex;
  justify-content: space-around;
  align-items: center;

  .imageContainer {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media ${device.mobile} {
      display: none;
    }
    img {
      display: block;
      width: 70%;
    }
    h1 {
      font-size: 24px;
      color: ${colors.darkGray};
      font-weight: 500;
      width: 70%;

      a {
        text-decoration: underline;
        color: ${colors.orange};
        font-weight: 500;
      }
    }
  }

  .formDiv {
    @media ${device.mobile} {
      width: 100%;
      justify-content: center;
    }
    .form {
      max-width: 550px;
      max-height: 853px;
    }
    max-height: 853px;
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    align-content: center;
  }
`;
