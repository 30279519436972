import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Wrapper, Button } from './styles';
import { cpfMask, zipCodeMask } from '../../utils/mask';

const DadosMEI = () => {
  const [erro, setError] = useState('');
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (!user.mei) {
      setError('Mei não cadastrado no banco de dados da Nume');
    } else {
      setError('');
    }
  }, []);

  return (
    <Wrapper>
      <div className="header">
        <h1>Dados do MEI</h1>
        {erro ? <p>{erro}</p> : null}
      </div>
      <div className="container">
        <section>
          <h1>Informações da conta</h1>

          <div className="inputContainer">
            <fieldset>
              <p>Nome Empresarial</p>
              <input
                type="text"
                value={`${user.mei ? user.mei.business_name : ''}`}
              />
            </fieldset>

            <fieldset>
              <p>Nome do Empresário</p>
              <input
                type="text"
                value={`${user.mei ? user.mei.businessman_name : ''}`}
              />
            </fieldset>

            <fieldset>
              <p>Nome fantasia</p>
              <input
                type="text"
                value={`${user.mei ? user.mei.fantasy_name : ''}`}
              />
            </fieldset>

            <fieldset>
              <p>CNPJ</p>
              <input type="text" value={`${user.mei ? user.mei.cnpj : ''}`} />
            </fieldset>

            <fieldset>
              <p>Situação cadastral vigente</p>
              <input
                type="text"
                value={`${
                  user.mei ? user.mei.current_registration_situation : ''
                }`}
              />
            </fieldset>

            <fieldset>
              <p>Data do cadastro vigente</p>
              <input
                type="text"
                value={`${
                  user.mei
                    ? user.mei.current_registration_situation_start_date
                    : ''
                }`}
              />
            </fieldset>
          </div>
        </section>

        <section>
          <h1>Informações pessoais</h1>
          <div className="inputContainer">
            <fieldset>
              <p>Numero da identidade</p>
              <input type="text" value={`${user.mei.rg ? user.mei.rg : ''}`} />
            </fieldset>

            <fieldset>
              <p>Órgão emissor</p>
              <input
                type="text"
                value={`${user.mei ? user.mei.emitting_organ : ''}`}
              />
            </fieldset>

            <fieldset>
              <p>UF Emissor</p>
              <input
                type="text"
                value={`${user.mei ? user.mei.emitting_ufe : ''}`}
              />
            </fieldset>

            <fieldset>
              <p>CPF</p>
              <input
                type="text"
                value={cpfMask(`${user.mei ? user.mei.cpf : ''} `)}
              />
            </fieldset>
          </div>
        </section>

        <section>
          <h1>Endereço comercial</h1>
          <div className="inputContainer">
            <fieldset>
              <p>CEP</p>
              <input
                type="text"
                value={zipCodeMask(`${user.mei ? user.mei.zip_code : ''}`)}
              />
            </fieldset>

            <fieldset>
              <p>Rua</p>
              <input
                type="text"
                value={`${user.mei ? user.mei.public_place : ''}`}
              />
            </fieldset>

            <fieldset>
              <p>Numero</p>
              <input type="text" value={`${user.mei ? user.mei.number : ''}`} />
            </fieldset>

            <fieldset>
              <p>Bairro</p>
              <input
                type="text"
                value={`${user.mei ? user.mei.neighborhood : ''}`}
              />
            </fieldset>

            <fieldset>
              <p>Município</p>
              <input type="text" value={`${user.mei ? user.mei.county : ''}`} />
            </fieldset>

            <fieldset>
              <p>UF</p>
              <input type="text" value={`${user.mei ? user.mei.uf : ''}`} />
            </fieldset>
          </div>
        </section>
        <div className="download">
          {user.mei && (
            <Button
              onClick={() => {
                window.open(`${user.mei.certificate_url}`);
              }}
            >
              <span>Baixar Certificado</span>
            </Button>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default DadosMEI;
