import styled from 'styled-components';
import { ButtonContainer } from '../../components/Button/styles';
import { colors } from '../../constants/colors';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent
    linear-gradient(180deg, ${colors.pink} 0%, ${colors.orange} 120%) 0% 0%
    no-repeat padding-box;
  #pass {
    position: relative;
  }

  .content {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    nav {
      width: 100%;
      height: 90px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .container {
      width: 90%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .containerLogin {
        width: 40%;
        height: 70%;
        background-color: #fff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        .logo {
          margin-top: 10px;
          display: flex;
          height: 20%;
          width: 100%;
          justify-content: center;
          align-items: center;
          img {
            width: 40%;
          }
        }

        .errors {
          color: red;
          font-size: 13px;
        }

        .containerInputs {
          height: 40%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          width: 100%;

          .field-set {
            border: none;
            width: 80%;
            input {
              outline: none;
              width: 100%;
              max-height: 35.09px;
              height: 100%;
              border: 1px solid #dcdcdc;
              border-radius: 4px;
              padding-left: 5px;

              :focus {
                border-color: ${colors.mediumGray};
              }
            }

            .eye {
              color: ${colors.opacityGray};
              position: absolute;
              bottom: -19%;
              right: 10px;
              cursor: pointer;
            }
          }
          .field-set {
            border: none;
            width: 80%;

            p {
              color: ${colors.orange};
              font-weight: 600;
              margin-bottom: 5px;
              font-size: 14px;
            }
          }
        }
        .actions {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          height: 30%;

          a {
            color: #00a779;
            text-decoration: underline;
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
`;

export const ButtonBack = styled(ButtonContainer)`
  &:hover {
    background-color: transparent;
    span {
      color: ${colors.white};
    }
    img {
      display: none;
    }
  }
  &:active {
    background-color: #ffffff;
    span {
      color: ${colors.pink};
    }
    img {
      display: block;
    }
  }
`;

export const ButtonRegister = styled(ButtonContainer)`
  &:hover {
    background-color: #ffffff;
    span {
      color: ${colors.pink};
    }
    img {
      display: none;
    }
  }
  &:active {
    background-color: transparent;
    span {
      color: #ffffff;
    }
    img {
      display: block;
    }
  }
`;
