import React from 'react';

import { useHistory } from 'react-router-dom';
import { Wrapper } from './styles';

import Button from '../../../components/Button';
// import Form from '../../../components/FormDuvidas';
// import Logo from '../../../components/Logo';

import { colors } from '../../../constants/colors';
// import bannerText from '../../../assets/svg/SectionThree-bannerText.svg';
import abrirMeiText from '../../../assets/svg/SectionThree-abraSeuMei.svg';
// import computerPNG from '../../../assets/jpg/SectionThree-computer.png';
// import facaTourText from '../../../assets/svg/SectionThree-facaTourText.svg';
// import numeApresentacao from '../../../assets/svg/SectionThree-numeApresentacao.svg';
import logo from '../../../assets/svg/logoNUME.svg';

function SectionThree() {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const history = useHistory();

  function toTop() {
    window.scrollTo(0, 0);
  }

  React.useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);
  return (
    <Wrapper>
      <div id="sectionOne">
        <p id="bannerText">
          Passe longe <b>da burocracia</b> chegue mais perto da Nume
        </p>
        <div id="logoMei">
          <img src={logo} alt="Logo NUME" />
          <p>MEI</p>
        </div>
      </div>
      <div id="sectionTwo">
        {window.innerWidth < 768 ? (
          <img
            id="abrirMeiText"
            src={abrirMeiText}
            alt="Abra seu MEI, comprove sua renda e contribua para o INSS em poucos cliques."
          />
        ) : (
          <p id="presentationText">
            A Nume é uma solução para empreendedores que buscam simplicidade na
            hora de organizar a contabilidade dos seus negócios. O atendimento é
            rápido e pode ser feito pelo celular, na hora que o cliente desejar.
          </p>
        )}
        <div id="wrapperButtons">
          <Button
            text="Abrir MEI"
            width={windowWidth < 768 ? '300px' : '300px'}
            onClick={() => {
              history.push('/abrir');
              toTop();
            }}
          />
          <Button
            text="Já tenho MEI"
            backgroundColor="transparent"
            color={colors.darkGray}
            border={colors.orange}
            width={windowWidth < 768 ? '300px' : '300px'}
            onClick={() => {
              history.push('/login');
            }}
          />
        </div>
      </div>
      {/* <div id="sectionThree">
        <div id="one">
          <img id="computerPng" src={computerPNG} alt="computador" />

          <img
            id="facaTourText"
            src={facaTourText}
            alt="Não seria simples de verdade se você não pudesse resolver com o celular. Faça o tour"
          />
        </div>
        <div id="two">
          <Form />
        </div>
      </div> */}
    </Wrapper>
  );
}

export default SectionThree;
