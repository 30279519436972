import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;

  #termos {
    color: ${colors.orange};
    margin-top: 3px;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  #wrapper {
    width: 100%;
  }
  #options {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 3.5em;
    top: 13.5em;
    background-color: ${colors.white};
    border-radius: 5%;
    width: 260px;
    height: auto;
    z-index: 1500;
    box-shadow: 0px 8px 5px #b0a7a1, -2px 0px 5px #b0a7a1, 2px 0px 5px #b0a7a1;
    #photo-name {
      display: flex;
      padding: 1em 1.3em;
      flex-direction: row;
      align-items: center;
      img {
        background-color: ${colors.orange};
        display: flex;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        object-fit: cover;
      }
      span {
        padding-left: 0.7em;
      }
    }

    #list {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 1em;
      #options-button {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-bottom: 0.5em;
      }
      .text-options {
        cursor: pointer;
        padding: 0.7em 0 0.7em;
      }
      a {
        margin: 0.2em 0;
        color: ${colors.orange};
      }
    }
  }
`;

export const AvatarMenu = styled.div`
  display: flex;
  padding: 1em;
  width: auto;
  height: auto;
  position: relative;
  cursor: pointer;
  #profile-pic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    img {
      background-color: ${colors.orange};
      display: flex;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  #userInfoContainer {
    display: flex;
    padding-left: 1em;
    align-self: center;
    flex-direction: column;
    .infos {
      color: ${colors.orange};
    }
    #nome {
      font-weight: bolder;
    }
    #tipoMEI {
      font-size: 14px;
      padding-top: 0.5em;
    }
  }
  #arrow {
    display: flex;
    margin-left: 5px;
  }
`;
