/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import {
  RiCloseCircleLine,
  RiCheckboxBlankFill,
  RiCheckboxFill,
} from 'react-icons/ri';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import * as yup from 'yup';

import { Container, Content, Plan } from './styles';
import { colors } from '../../../constants/colors';
import AdmMenu from '../../../components/MenuAdm';
import Edit from '../../../assets/svg/edit.svg';
import { ModalContent, ModalSelect } from '../../../components/Modal/styles';
import Button from '../../../components/Button';

import api from '../../../services/api';

const ManagementPlans = () => {
  const [plans, setPlans] = useState([]);
  const [planChange, setPlanChange] = useState({
    name: '',
    description: '',
    value_cents: '',
    advantages: '',
    suspended_at: '',
  });
  const [modalState, setModalState] = useState(false);
  const [select, setSelect] = useState(false);
  const [edit, setEdit] = useState(false);
  const [reload, setReload] = useState(false);
  const [errors, setErrors] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [advantageChange, setAdvantageChange] = useState([]);
  const [advantages, SetAdvantages] = useState([]);
  const schema = yup.object().shape({
    name: yup.string().required('Nome é um campo obrigatório'),
    description: yup
      .string()
      .max(255, 'A descrição deve ter no máximo 255 caracteres'),
    value_cents: yup.number().required('Valor é um campo obrigatório'),
    advantages: yup.string().required('Benefícios é um campo obrigatório'),
  });

  useEffect(() => {
    api
      .get('/plans/advantages')
      .then((response) => SetAdvantages(response.data));
  }, [reload]);

  useEffect(() => {
    api.get('/plans').then((response) => setPlans(response.data));
  }, [reload]);

  function closeModal() {
    setModalState(false);
    setErrors(false);
  }

  useEffect(() => {
    if (planChange && edit === true) {
      setPlanChange({
        ...planChange,
        value_cents: planChange.value_cents / 100,
      });
    }
  }, [modalState]);

  function openModal(planId) {
    if (planId) {
      const plano = plans.find((plan) => plan.id === planId);
      setPlanChange(plano);
      const ArrayTemp = [];
      if (plano) {
        plano.plan_advantages.map((element) =>
          ArrayTemp.push(element.advantages_id),
        );
      }
      setAdvantageChange(ArrayTemp);
    } else {
      setPlanChange(false);
      setAdvantageChange([]);
    }

    setModalState(true);
  }

  function openDetailModal(planId) {
    const plano = plans.find((plan) => plan.id === planId);
    setPlanChange(plano);
    setDetailModal(true);
  }

  function searchAdvantage(advantage, posAdvantage, tempAdvantages) {
    if (posAdvantage === -1) {
      // tempAdvantages.push(advantage);
      setAdvantageChange([...tempAdvantages, advantage]);
    } else {
      tempAdvantages.splice(posAdvantage, 1);
      setAdvantageChange([...tempAdvantages]);
    }
  }

  function openAdvantages(planId) {
    if (!advantageChange) {
      const plano = plans.find((plan) => plan.id === planId);
      const ArrayTemp = [];
      if (plano) {
        plano.plan_advantages.map((element) =>
          ArrayTemp.push(element.advantages_id),
        );
        setAdvantageChange(ArrayTemp);
      }
    }
    setSelect(true);
  }

  async function HandleSubmitPlan() {
    const dataSubmit = {
      name: planChange.name,
      value_cents: planChange.value_cents * 100,
      suspended_at: planChange.suspended_at,
      description: planChange.description,
      advantages: `[${String(advantageChange)}]`,
      visible: 1,
      identifier: planChange.name,
      interval: 1,
      interval_type: 'months',
      payable_with: 'all',
      billing_days: 5,
      max_cycles: 12,
    };

    try {
      await schema.validate(dataSubmit);

      if (!edit) {
        api
          .post('/plans', dataSubmit)
          .then(() => {
            setReload(!reload);
            toast('Plano criado com sucesso');
            setModalState(false);
          })
          .catch((err) => console.log(err));
      } else {
        api
          .patch(`/plans/${planChange.id}`, {
            name: planChange.name,
            value_cents: planChange.value_cents * 100,
            suspended_at: planChange.suspended_at,
            description: planChange.description,
            advantages: `[${String(advantageChange)}]`,
          })
          .then(() => {
            setReload(!reload);
            toast('Plano editado com sucesso');
            setModalState(false);
          })
          .catch((err) => console.log(err));
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        error.errors.map((err) => setErrors(err));
      }
      return false;
    }
  }

  return (
    <Container>
      <ToastContainer />
      <AdmMenu page="Plans" />
      <Content>
        <header>
          <h1>Planos</h1>
        </header>

        <main>
          <div className="description">
            <h3>Planos ativos</h3>
            <p>
              Os planos atualmente disponíveis encontram-se abaixo. Você pode
              visualiza-los, edita-los ou até mesmo excluí-los. Caso queira
              adicionar um novo plano, basta clicar no botão{' '}
              <strong>Novo plano +</strong> e preencher os campos!
            </p>
          </div>
          <div className="action">
            <button
              type="button"
              onClick={() => {
                openModal(false);
                setEdit(false);
              }}
            >
              <span>Novo plano</span>
              <MdAdd size={23} />
            </button>
          </div>

          <div className="plans">
            {plans.map((plan) => (
              <Plan
                background={
                  plan.id === 1
                    ? colors.blue
                    : plan.id === 11
                    ? colors.orange
                    : colors.pink
                }
              >
                <div
                  className="icone"
                  onClick={() => {
                    openModal(plan.id);
                    setEdit(true);
                  }}
                >
                  <img src={Edit} alt="icone" />
                </div>
                <div className="infos" onClick={() => openDetailModal(plan.id)}>
                  <h2>MEI {plan.name}</h2>
                  <p>
                    {plan.value_cents === 0
                      ? 'Grátis'
                      : Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(plan.value_cents / 100)}
                  </p>
                </div>
              </Plan>
            ))}
          </div>
        </main>
      </Content>
      <ModalContent open={modalState} onClose={closeModal}>
        <div className="modalPlans">
          <header>
            <h1>
              {edit === false ? 'Novo plano' : `Editar: MEI ${planChange.name}`}
            </h1>
            <RiCloseCircleLine
              className="close"
              size={21}
              onClick={() => closeModal()}
            />
          </header>
          {errors ? <p className="errors">{errors}</p> : ''}
          <div className="inputs">
            <fieldset className="inputLarge">
              <p>Nome do plano</p>
              <input
                type="text"
                value={planChange.name ? planChange.name : ''}
                name="name"
                onChange={(e) => {
                  setPlanChange({
                    ...planChange,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </fieldset>
            <fieldset className="inputLarge">
              <p>Valor</p>
              <input
                type="number"
                name="value_cents"
                value={planChange.value_cents ? planChange.value_cents : ''}
                onChange={(e) => {
                  setPlanChange({
                    ...planChange,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </fieldset>
            <fieldset className="inputShort">
              <p>Data de suspensão do plano</p>
              <input
                type="date"
                name="suspended_at"
                value={planChange.suspended_at ? planChange.suspended_at : ''}
                onChange={(e) => {
                  setPlanChange({
                    ...planChange,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </fieldset>
            <fieldset className="inputShort">
              <p>Benefícios</p>
              <div
                className="advantages"
                onClick={() => openAdvantages(edit ? planChange.id : false)}
              >
                <p>
                  {advantageChange ? advantageChange.length : ''} Benefícios
                </p>
              </div>
            </fieldset>
            <fieldset className="inputLarge">
              <p>Mensagem promocional</p>
              <input
                type="text"
                name="description"
                value={planChange.description ? planChange.description : ''}
                onChange={(e) => {
                  setPlanChange({
                    ...planChange,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </fieldset>
          </div>
          <Button
            className="button"
            color="#FFF"
            width="100%"
            height="40px"
            radius="8px"
            fontSize="1.2em"
            text="Confirmar"
            backgroundColor={colors.orange}
            active="#FF6600"
            hover="#D26A24"
            onClick={() => HandleSubmitPlan()}
          />
        </div>
      </ModalContent>
      <ModalSelect open={select} onClose={() => setSelect(false)}>
        <div className="container">
          <header>
            <h1>Selecione os benefícios</h1>
            <RiCloseCircleLine
              className="close"
              size={18}
              onClick={() => setSelect(false)}
            />
          </header>
          <ul>
            {advantages.map((advantage) => (
              <li key={advantage.id}>
                {advantageChange.includes(advantage.id) ? (
                  <RiCheckboxFill
                    className="checkbox"
                    size={24}
                    color="#EE7200"
                    onClick={() => {
                      let posAdvantage = advantageChange.indexOf(advantage.id);
                      let tempAdvantages = advantageChange;
                      setAdvantageChange(false);
                      searchAdvantage(
                        advantage.id,
                        posAdvantage,
                        tempAdvantages,
                      );
                    }}
                  />
                ) : (
                  <RiCheckboxBlankFill
                    className="checkbox"
                    size={24}
                    color="rgba(238, 114, 0, 0.5)"
                    onClick={() => {
                      let posAdvantage = advantageChange.indexOf(advantage.id);
                      let tempAdvantages = advantageChange;
                      setAdvantageChange(false);
                      searchAdvantage(
                        advantage.id,
                        posAdvantage,
                        tempAdvantages,
                      );
                    }}
                  />
                )}
                <p>{advantage.advantage}</p>
              </li>
            ))}
          </ul>
        </div>
      </ModalSelect>

      <ModalSelect open={detailModal} onClose={() => setDetailModal(false)}>
        <div className="detailContainer">
          <RiCloseCircleLine
            className="close"
            size={21}
            onClick={() => setDetailModal(false)}
          />
          <header>
            <h1
              style={{
                color:
                  planChange.id === 1
                    ? colors.blue
                    : planChange.id === 11
                    ? colors.orange
                    : colors.pink,
              }}
            >
              MEI {planChange.name}{' '}
            </h1>

            <p>
              {planChange.value_cents === 0
                ? 'Grátis'
                : Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(planChange.value_cents / 100)}{' '}
              / mês
            </p>

            <span>{planChange.description}</span>
          </header>
          <div className="advantages">
            <ul>
              {advantages.map((advantage) => (
                <li key={advantage.id}>
                  {planChange.plan_advantages ? (
                    planChange.plan_advantages.find(
                      (element) => element.advantages_id === advantage.id,
                    ) ? (
                      <AiFillCheckCircle
                        className="ico"
                        color={colors.green}
                        size={21}
                      />
                    ) : (
                      <AiFillCloseCircle
                        className="ico"
                        color={colors.darkOrange}
                        size={21}
                      />
                    )
                  ) : null}
                  <p>{advantage.advantage}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </ModalSelect>
    </Container>
  );
};

export default ManagementPlans;
