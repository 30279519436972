import styled from 'styled-components';

import { size, device } from '../../../constants/size';
import { colors } from '../../../constants/colors';

export const Wrapper = styled.div`
  .sectionTwo {
    width: 100%;
    ul {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      li {
        width: 100%;
        margin: 30px 0;
        color: ${colors.darkGray};
        padding: ${size.mobile.padding};
        .quest {
          display: flex;
          align-items: center;
          justify-content: space-between;
          h3 {
            font-size: 24px;
            font-weight: normal;
            width: 90%;
          }

          .arrowRotate {
            transition: transform 1.5s;
            transform: rotate(360deg);
          }
        }
        .response {
          display: none;
          font-size: 16px;
          line-height: 26px;
          margin: ${size.mobile.margin} 0;
        }
      }

      li.arrowUp {
        width: 90%;
        border: 3px solid ${colors.orange};
        border-radius: 20px;
        padding: 24px;
        transition: border 0.5s linear;
        .quest {
          color: ${colors.orange};

          h3 {
            font-weight: bold;
          }

          .arrow {
            transition: transform 0.5s linear;
            transform: rotate(180deg);
          }
        }

        .response {
          display: block;
          transition: display 0.5s linear;
        }
      }
    }
  }
  @media ${device.desktop} {
    .sectionOne {
      display: flex;
      align-items: center;
      padding-left: ${size.desktop.padding};

      img {
        width: auto;
        height: inherit;
      }

      .title {
        position: initial;
        margin-left: -3px;
      }
    }

    .sectionTwo {
      display: flex;
      justify-content: center;
      ul {
        width: 65%;

        li.arrowUp {
          width: 100%;
        }
      }
    }
  }
`;
