import styled from 'styled-components';

import { colors } from '../../constants/colors';

export const ButtonContainer = styled.div`
  display: ${(props) => (props.image ? 'grid' : 'flex')};
  justify-content: ${(props) => (props.image ? '' : 'center')};
  align-items: center;
  text-align: center;
  grid-template-areas: 'img text .';
  width: ${(props) => (props.width ? props.width : 'min(200px, 70%)')};
  height: ${(props) => (props.height ? props.height : '3em')};
  border-radius: ${(props) => (props.radius ? props.radius : '50px')};
  border: ${(props) => (props.border ? `solid 2px ${props.border}` : 'none')};
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : colors.orange};
  cursor: pointer;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0px')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '0px')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0px')};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '0px'};
  span {
    color: ${(props) => (props.color ? props.color : colors.white)};
    font-size: ${(props) => (props.fontSize ? props.fontSize : '1em')};
    grid-area: text;
    justify-self: center;
  }
  img {
    grid-area: img;
    width: 20px;
    display: ${(props) => (props.image ? 'block' : 'none')};
    justify-self: ${(props) => (props.image ? 'flex-end' : 'none')};
    &:hover {
      color: ${(props) => (props.color ? props.colorHover : colors.white)};
    }
    animation: loading 1s infinite;
  }
  @keyframes {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  &:hover {
    background: ${(props) => (props.hover ? props.hover : '#D26A24')};
    span {
      color: #fff;
    }
  }
  &:active {
    background: ${(props) => (props.active ? props.active : '#FF6600')};
  }
`;
