/* eslint-disable react/prop-types */
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { Container, UserDiv, Button, LineDiv } from './styles';
import AvatarProfile from '../../../assets/jpg/avatarProfile.jpg';
import userIcon from '../../../assets/svg/userIcon.svg';
import SelectNavigator from '../SelectNavigator';
import SettingsNavigator from '../SettingsNavigator';

function Header({ thisPage: thispage }) {
  const user = useSelector((state) => state.auth.user);
  const { push } = useHistory();
  return (
    <Container>
      <UserDiv>
        <SettingsNavigator className="Settings" />

        <img src={userIcon} alt="Avatar profile" />
        <strong>{`${user.first_name} ${user.last_name}`}</strong>
        <p>Plano{` ${user.plan.name}`}</p>
        <Button type="button" onClick={() => push('/dashboard/planos')}>
          Visualizar planos
        </Button>
      </UserDiv>

      <LineDiv />
      <SelectNavigator page={thispage} />
    </Container>
  );
}

export default Header;
