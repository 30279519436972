/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import { Container } from './styles';
import Button from '../../../components/Button';
import Price from '../../../components/Price';

import logo from '../../../assets/svg/logoNUME.svg';
import item1 from '../../../assets/svg/SectionOne-item1.svg';
import item2 from '../../../assets/svg/SectionOne-item2.svg';
import item3 from '../../../assets/svg/SectionOne-item3.svg';

import man from '../../../assets/png/pageTwoSectionOne.png';

function SectionOne() {
  const [startForm, setStartForm] = useState(false);

  const history = useHistory();

  return (
    <Container>
      <section id="one">
        <img id="background1" src={man} alt="man" />

        {startForm ? (
          <div id="abrirMei">
            <p style={{ fontSize: '2em', fontWeight: 'bold' }}>
              Abra um CNPJ MEI grátis agora mesmo!
            </p>
            <p>
              Abrir um MEI é muito fácil e você pode fazer isso nesse momento.
              Abra seu cadastro rapidinho que nosso atendimento já estará à
              disposição para ajudar.
            </p>
          </div>
        ) : (
          <div id="wrapper">
            <div id="logo">
              <img src={logo} alt="Logo NUME" />
              <p>MEI</p>
            </div>
            <div id="textWrapperOne">
              <p>Seu MEI pode</p>
              <p>ser bem mais fácil.</p>
            </div>
          </div>
        )}
      </section>
      {startForm ? (
        <section id="form">
          <h3>Comece aqui</h3>

          <input
            type="text"
            placeholder="Digite seu nome completo"
            id="name"
            name="name"
          />

          <input type="text" placeholder="000000000000" id="cnpj" name="cnpj" />

          <input
            type="text"
            placeholder="Digite seu e-mail"
            id="email"
            name="email"
          />
          <Button width="192px" text="Começar agora" />
        </section>
      ) : (
        <section id="two">
          <div id="textWrapperTwo">
            <p>Simplifique seu MEI agora</p>
            <p>por a partir de:</p>
          </div>
          <div id="price-container">
            <h4>R$</h4>
            <h3>29,90</h3>
            <span>mês</span>
          </div>
          {/* <Price /> */}
          <Button
            onClick={() => history.push('/cadastrar')}
            height="3em"
            width="192px"
            text="Simplificar MEI"
          />
        </section>
      )}

      <section id="three">
        <div id="title">
          <img src={logo} alt="logo" />
          <div id="text">
            <p>Por que ser MEI</p>
            <p>com a Nume?</p>
          </div>
        </div>
        <ul>
          <li>
            <div className="icon">
              <img src={item1} alt="item" />
            </div>
            <div className="itemWrapper">
              <div className="itemTitle">1. Atendimento Amigo</div>
              <div className="itemText">
                Qualquer dúvidas sobre como preencher as informações podem ser
                resolvidas online na hora.
              </div>
            </div>
          </li>
          <li>
            <div className="icon">
              <img src={item2} alt="item" />
            </div>
            <div className="itemWrapper">
              <div className="itemTitle">2. CNPJ pronto na hora</div>
              <div className="itemText">
                O número do seu CNPJ sai na hora e você recebe todos os
                documentos por e-mail. É grátis!
              </div>
            </div>
          </li>
          <li>
            <div className="icon">
              <img src={item3} alt="item" />
            </div>
            <div className="itemWrapper">
              <div className="itemTitle">3. Simplificando ao máximo</div>
              <div className="itemText">
                Somo especialistas em simplificar a burocracia. Você nunca viu
                um processo fácil assim.
              </div>
            </div>
          </li>
        </ul>
      </section>
    </Container>
  );
}

export default SectionOne;
