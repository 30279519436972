import React from 'react';

import { Wrapper } from './styles';

import logo from '../../../assets/svg/logoNUME.svg';

const SectionOne = () => {
  return (
    <Wrapper>
      <div className="sectionOne">
        <img src={logo} alt="logo Nume" />
        <div className="title">
          <h1>FAQ</h1>
          <p>Saiba mais sobre a Nume</p>
        </div>
      </div>
      <div className="border" />
    </Wrapper>
  );
};

export default SectionOne;
