import styled from 'styled-components';
import { Dialog } from '@material-ui/core';
import { colors } from '../../constants/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em;

    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
    }

    .actions {
      display: flex;
      align-items: center;

      select {
        margin-left: 5px;
        height: 30px;
        outline-style: none;
      }

      .yearContainer {
        margin-right: 7px;
        width: 97px;
        height: 30px;
        border: 1px solid #262626;
        box-sizing: border-box;
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 5px;
        position: relative;

        font-weight: normal;
        font-size: 14px;
        line-height: 20px;

        input {
          position: absolute;
          outline-style: none;
          width: 90%;
          height: 100%;
          padding-left: 33px;
          background: transparent;
          border: none !important;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          /* display: none; <- Crashes Chrome on hover */
          -webkit-appearance: none;
          margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }

        input[type='number'] {
          -moz-appearance: textfield;
        }
      }
    }
  }
  #popup-effect {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    opacity: 1;
    background-color: rgba(1, 1, 1, 0.5);
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .popup-expired-plan {
      z-index: 4000 !important;
      min-height: 20% !important;
    }
    #popup {
      display: flex;
      flex-direction: column;
      width: 20%;
      min-height: 30%;
      background-color: ${colors.white};
      z-index: 3000;
      border-radius: 10px;
      padding: 1em;
      position: relative;
      opacity: unset;
      #close-icon {
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        cursor: pointer;
      }
      h1 {
        color: ${colors.orange};
        font-weight: 500;
        font-size: 24px;
        padding-top: 0.5em;
      }
      p {
        font-size: 14px;
        opacity: 0.6;
        padding-top: 1em;
        line-height: 20px;
      }
      ul {
        display: flex;
        flex-direction: column;
        li {
          display: flex;
          align-items: center;
          padding: 0.5em 0;
          span {
            padding-left: 1em;
          }
        }
      }
      #despesas-button {
        display: flex;
        justify-content: center;
        margin-top: 1em;
      }
    }
  }
  #chart {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #list {
    width: 100%;
    height: 50%;
    overflow-y: scroll;
    nav {
      padding: 1em 1em 0 1em;
      display: flex;
      justify-content: space-between;
      #tabs {
        display: flex;
        gap: 1em;
        span {
          cursor: pointer;
          width: 5em;
        }
        .selected {
          color: ${colors.orange};
          font-weight: 600;
        }
      }
    }
    ul.transactionsList {
      display: flex;
      flex-direction: column;
      gap: 1em;
      list-style: none;
      padding: 1em 1em 0 1em;
      li.transaction {
        position: relative;
        height: 2em;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &::after {
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: black;
          content: '';
          display: block;
        }
        &::after {
          bottom: 0;
        }
        div {
          display: flex;
          gap: 2em;
        }
        .optionsContainer {
          right: 20px;
          position: absolute;
          display: inline-block;
          .options {
            display: none;
            position: absolute;
            top: -10;
            right: 0px;
            list-style: none;
            width: 100px;
            /* height: 120px; */
            background: #ffffff;
            box-shadow: 0px 18px 36px rgba(17, 14, 27, 0.1),
              0px 5.42647px 10.8529px rgba(17, 14, 27, 0.065102),
              0px 2.25388px 4.50776px rgba(17, 14, 27, 0.0499628),
              0px 0.815184px 1.63037px rgba(17, 14, 27, 0.0348159);
            border-radius: 8px;
            flex-direction: column;
            justify-content: space-around;
            align-items: flex-start;
            z-index: 1;
            &.dropOpen {
              display: flex;
            }
            & li {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding-left: 15px;
              cursor: pointer;
              width: 100%;
              height: 30px;
              &:hover {
                background-color: ${colors.orangeHover};
                color: #ffffff;
              }
            }
          }
        }

        .value {
          font-weight: 400;
        }
      }
    }
  }
`;

export const Modal = styled(Dialog)`
  & .MuiPaper-root {
    background-color: #fff;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
  }

  .popConfirmContainer {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: 330px;
    height: 150px;
    h3 {
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      color: ${colors.darkOrange};
    }
    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      margin: 5px 0px;
    }
    .actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .transactionContainer {
    padding: 18px;
    width: 400px;
    height: 315px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    nav {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        color: ${colors.darkOrange};
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

    .inputContainer {
      margin: 10px 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;

      fieldset {
        width: 45%;
        border: none;
        margin-top: 10px;
        position: relative;

        p {
          font-size: 14px;
          color: ${colors.mediumGray};
        }

        input,
        select {
          height: 18px;
          border: none;
          border-bottom: 1px solid ${colors.mediumGray};
          background-color: #fff;
          color: ${colors.mediumGray};
          width: 100%;
          outline: none;
          margin-top: 8px;
          font-size: 14px;

          :focus {
            border-bottom: 1px solid ${colors.orange};
          }
        }

        .errors {
          color: red;
          font-size: 12px;
          max-width: 80%;
        }
      }
      .inputLarge {
        width: 100%;
      }
    }
  }
`;
