import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { AiFillCheckCircle } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import { UpdateUserRequest } from '../../store/ducks/auth/auth';

import { Wrapper } from './styles';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import { colors } from '../../constants/colors';
import { ModalContent } from '../../components/Modal/styles';
import api from '../../services/api';
import { FormatUser } from '../../utils/FormatUser';

const PlanosDashboard = () => {
  const [modalState, setModalState] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [planChange, setPlanChange] = useState({});
  const [myAdvantages, setMyAdvantages] = useState('');
  const [advantages, setAdvantages] = useState([]);
  const [plans, setPlans] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const myPlan = useSelector((state) => state.auth.plan);
  const { push } = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    api
      .get('/plans')
      .then((response) => setPlans(response.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const tempArray = [];
    myPlan.advantages.map((advantage) => {
      tempArray.push(advantage.advantage);
      return true;
    });
    setMyAdvantages(tempArray.join(', '));
  }, [myPlan]);

  useEffect(() => {
    api
      .get('/plans/advantages')
      .then((response) => {
        setAdvantages(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  function verifyAdvantage(advantageId, plan) {
    const existAdvantage = plan.advantages.find(
      (advantage) => advantage.id === advantageId,
    );
    if (existAdvantage !== undefined) {
      return true;
    }
    return false;
  }

  function closeModal() {
    setModalState(false);
    setTimeout(() => {
      setSuccess(false);
    }, 1000);
  }

  function openModal(plan) {
    setPlanChange(plan);
    setModalState(true);
  }

  const changePlan = async () => {
    setIsLoading(true);
    api
      .patch('/subscriptions/change-plan', { plan_id: planChange.id })
      .then(() => {
        setSuccess(true);
        api.get('/users/me').then(async ({ data }) => {
          const userFormat = await FormatUser(data);
          dispatch(UpdateUserRequest(userFormat));
          setIsLoading(false);
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error.response);
        setIsLoading(false);
      });
  };

  return (
    <Wrapper>
      <Loading open={isLoading} />
      <div className="container">
        <div className="header">
          <h1>Planos</h1>
        </div>
        <div className="content">
          <div className="planDescription">
            <h1>
              Atualmente o seu plano é o{' '}
              <span
                style={{
                  color:
                    // eslint-disable-next-line no-nested-ternary
                    user.plan.name === 'Free'
                      ? colors.blue
                      : user.plan.name === 'Básico'
                      ? colors.orange
                      : colors.pink,
                }}
              >
                MEI {user.plan.name}
              </span>
            </h1>

            <div className="textDescription">
              <p>
                Para que você possa conhecer melhor a plataforma,
                disponibilizamos algumas funções para auxiliar nessa jornada!
                Você tem à sua disposição o <strong>{myAdvantages}</strong> .
              </p>
              <p>
                Essas funções podem ser acessadas através do
                <strong> menu lateral esquerdo</strong>.
              </p>
            </div>
          </div>

          <div className="planContainer">
            <h1>
              Escolha um plano que atenda <span>suas</span> necessidades!
            </h1>
            <div className="planList">
              {plans.map((plan) => (
                <div
                  className={`planCard ${
                    plan.name === 'Free'
                      ? 'free'
                      : plan.name === 'Premium'
                      ? 'premium'
                      : 'basic'
                  }`}
                >
                  <h3>MEI {plan.name}</h3>
                  <p>
                    {plan.value_cents === 0
                      ? 'Grátis'
                      : Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(plan.value_cents / 100)}{' '}
                    / mês
                  </p>
                  <div className="message">
                    <p>{plan.description}</p>
                  </div>
                  <ul>
                    {advantages.map((advantage) => (
                      <li>
                        <AiFillCheckCircle
                          className="ico"
                          color={
                            verifyAdvantage(advantage.id, plan)
                              ? colors.green
                              : colors.darkOrange
                          }
                        />
                        <span>{advantage.advantage}</span>
                      </li>
                    ))}
                  </ul>
                  {plan.id === 1 ? (
                    <Button
                      color="#FFF"
                      width="100%"
                      height="42px"
                      radius="8px"
                      fontSize="14px"
                      text={
                        user.plan_id === 1
                          ? 'Você já possui esse plano'
                          : 'Selecionar Plano'
                      }
                      backgroundColor={colors.darkGray}
                      active={colors.darkGray}
                      hover={colors.darkGray}
                    />
                  ) : (
                    <Button
                      color="#FFF"
                      width="100%"
                      height="42px"
                      radius="8px"
                      fontSize="14px"
                      text={
                        user.plan_id === plan.id
                          ? 'Você já possui esse plano'
                          : 'Selecionar Plano'
                      }
                      backgroundColor={
                        user.plan_id === plan.id ||
                        user.next_plan_id === plan.id
                          ? colors.darkGray
                          : colors.orange
                      }
                      active={
                        user.plan_id === plan.id ||
                        user.next_plan_id === plan.id
                          ? colors.darkGray
                          : colors.orangeActive
                      }
                      hover={
                        user.plan_id === plan.id ||
                        user.next_plan_id === plan.id
                          ? colors.darkGray
                          : colors.orangeHover
                      }
                      onClick={() => {
                        // eslint-disable-next-line no-unused-expressions
                        user.plan_id === plan.id ||
                        user.next_plan_id === plan.id
                          ? null
                          : user.id_iugu_subscription
                          ? openModal(plan)
                          : push({
                              pathname: '/dashboard/pagamento',
                              state: { plan },
                            });
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <ModalContent open={modalState} onClose={closeModal}>
        <div className="modalPayment">
          <div className="title">
            <h3>Confirmação de alteração</h3>
          </div>
          <div className="message">
            <p>
              {success === false
                ? `Estou ciente de que estou prestes a trocar o plano 
              ${user.plan.name} pelo plano  MEI ${planChange.name}. Com isso estou ciente
              que o valor do meu plano mudará também.`
                : user.last_payment_method === 'bank_slip'
                ? 'Mudança feita com sucesso, agora você já deve ter recebido a fatura em seu email e poderá desfrutar das novas funcionalidades que a NUME oferece para o seu novo plano.'
                : 'Mudança feita com sucesso, o valor da sua parcela já foi alterado e poderá desfrutar das novas funcionalidades que a NUME oferece para o seu novo plano.'}
            </p>
          </div>

          {success === false ? (
            <div className="actions">
              <Button
                id="buttonSubmit"
                backgroundColor="transparent"
                color={colors.orange}
                border={colors.orange}
                radius="8px"
                hover="#D26A24"
                active="#FF6600"
                width="170px"
                height="50px"
                text="cancelar"
                onClick={closeModal}
              />
              <Button
                id="buttonSubmit"
                backgroundColor={colors.orange}
                radius="8px"
                hover="#D26A24"
                active="#FF6600"
                width="170px"
                height="50px"
                text="Ok"
                onClick={changePlan}
              />
            </div>
          ) : (
            <div className="actions">
              <Button
                id="buttonSubmit"
                backgroundColor={colors.orange}
                radius="8px"
                hover="#D26A24"
                active="#FF6600"
                width="170px"
                height="50px"
                text="Ok"
                onClick={closeModal}
              />
            </div>
          )}
        </div>
      </ModalContent>
    </Wrapper>
  );
};

export default PlanosDashboard;
