import React from 'react';

import { Container, DivLogo } from './styles';
import Form from '../../../components/FormDuvidas';
import Logo from '../../../assets/svg/LogoMeiSection4Page2.svg';
import Addon from '../../../assets/svg/addonLogoPage2Section4.svg';
import AddonRight from '../../../assets/svg/addonLogoPage2Section4Right.svg';

const SectionFour = () => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);
  return (
    <Container>
      {windowWidth > 940 && (
        <DivLogo windowWidth={windowWidth}>
          <div className="FirstAddon">
            <img src={Addon} alt="addom" />
          </div>
          <div className="square">
            <img src={Logo} alt="logo" />
            <h2>MEI</h2>
          </div>
          <img className="SecondAddon" src={AddonRight} alt="addom" />
        </DivLogo>
      )}
      <div className="formDiv">
        <div className="form">
          <Form />
        </div>
      </div>
    </Container>
  );
};

export default SectionFour;
