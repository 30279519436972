/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { Container, FormDiv, ErrorBox, Content, Button } from './styles';
import Header from '../../../components/Mobile/Header';
import Schema from '../../../utils/ChangePasswordSchema';
import api, { ChangePassword } from '../../../services/api';
import { UpdateUserRequest } from '../../../store/ducks/auth/auth';
import Loading from '../../../components/Loading';
import Footer from '../../../components/Mobile/Footer';
import { FormatUser } from '../../../utils/FormatUser';

function AlterarSenhaDashboard() {
  const [isLoading, setIsLoading] = React.useState(false);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  async function UpdateUserOnStore() {
    api.get('/users/me').then(async ({ data }) => {
      const userFormat = await FormatUser(data);
      dispatch(UpdateUserRequest(userFormat));
    });
  }

  async function submit(data) {
    try {
      setIsLoading(true);
      const res = await api.post('/sessions', {
        email: user.email,
        password: data.actualPassword,
      });
      if (res.status === 201) {
        // api
        //   .patch('/users/update', {
        //     password: data.newPassword,
        //     repeat_password: data.newPasswordConfirmation,
        //   })
        ChangePassword(data)
          .then(async () => {
            await UpdateUserOnStore();
            toast('senha alterada com sucesso');
            // eslint-disable-next-line no-use-before-define
            ClearForm();
            setIsLoading(false);
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
            setIsLoading(false);
          });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      setIsLoading(false);

      toast('Senha atual incorreta');
    }
  }
  const formik = useFormik({
    initialValues: {
      actualPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
    },
    validationSchema: Schema,
    onSubmit: submit,
  });

  async function ClearForm() {
    formik.values.actualPassword = '';
    formik.values.newPassword = '';
    formik.values.newPasswordConfirmation = '';
  }

  return (
    <>
      <Container>
        <div>
          <Loading open={isLoading} />
          <Header thisPage="Alterar Senha" />
          <ToastContainer />
          <Content>
            <FormDiv>
              <label htmlFor="name">Senha atual</label>
              <input
                placeholder="Por favor, insira a sua senha atual"
                type="password"
                name="actualPassword"
                onChange={formik.handleChange}
                value={formik.values.actualPassword}
              />
              {formik.touched.actualPassword && formik.errors.actualPassword ? (
                <ErrorBox>{formik.errors.actualPassword}</ErrorBox>
              ) : null}
              <label htmlFor="creadit_card">Nova senha</label>
              <input
                placeholder="Insira sua nova senha "
                type="password"
                name="newPassword"
                onChange={formik.handleChange}
                value={formik.values.newPassword}
              />
              {formik.touched.newPassword && formik.errors.newPassword ? (
                <ErrorBox>{formik.errors.newPassword}</ErrorBox>
              ) : null}

              <label htmlFor="creadit_card">Confirme sua nova senha</label>
              <input
                placeholder="Confirme sua nova senha"
                type="password"
                name="newPasswordConfirmation"
                onChange={formik.handleChange}
                value={formik.values.newPasswordConfirmation}
              />
              {formik.touched.newPasswordConfirmation &&
              formik.errors.newPasswordConfirmation ? (
                <ErrorBox>{formik.errors.newPasswordConfirmation}</ErrorBox>
              ) : null}
              <Button type="button" onClick={formik.handleSubmit}>
                Confirmar
              </Button>
            </FormDiv>
          </Content>
        </div>
        <Footer />
      </Container>
    </>
  );
}

export default AlterarSenhaDashboard;
