import styled from 'styled-components';

export const Container = styled.div`
  /* height: calc(100vh - 84px); */
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 250px;
  width: 100%;
  height: 100%;
  margin-top: 10px;

  label {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 5px;
  }

  input {
    border: 1px solid #262626;
    box-sizing: border-box;
    border-radius: 8px;
    max-width: 250px;
    width: 100%;
    height: 36px;
    margin-bottom: 12px;
    padding-left: 5px;
  }
  div {
    display: flex;
    justify-content: space-between;
    div {
      flex-direction: column;
      justify-content: flex-start;
      input {
        width: 130px;
        margin-bottom: 16px;
      }
    }
  }
`;

export const ErrorBox = styled.span`
  color: red;
  margin-top: -12px;
  margin-bottom: 10px;
  font-size: 12px;
  margin-left: 5px;
  max-width: 80%;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Button = styled.button`
  width: 100%;
  height: 32px;
  color: #fff;
  background-color: #ff6d24;
  border-radius: 8px;
  border: 0;
  margin-bottom: 40px;
`;
