import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';

import { colors } from '../../constants/colors';

export const ModalContent = styled(Dialog)`
  & .MuiPaper-root {
    width: 448px;
    height: fit-content;
    background-color: #fff;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    padding: 18px;
  }

  .modalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    height: 40vh;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      p {
        font-size: 20px;
        color: ${colors.mediumGray};
      }
      button {
        background-color: #fff;
        border-radius: 50%;
        border: none;
        outline-style: none;
      }
    }

    p {
      font-size: 20px;
      color: ${colors.mediumGray};
    }

    input {
      outline: none;
      width: 100%;
      height: 40px;
      border: 1px solid #dcdcdc;
      border-radius: 4px;
      padding-left: 5px;

      :focus {
        border-color: ${colors.mediumGray};
      }
    }

    .errors {
      color: red;
      font-size: 13px;
    }
  }
  .modalTransaction {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    height: 40vh;

    nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      h3 {
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        color: ${colors.darkOrange};
      }

      a {
        cursor: pointer;
      }
    }
    .errors {
      width: 100%;
      font-size: 16px;
      color: red;
    }

    .inputContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-wrap: wrap;

      fieldset {
        width: 45%;
        border: none;
        margin-top: 10px;
        position: relative;

        p {
          font-size: 14px;
          color: ${colors.mediumGray};

          input :focus {
            p {
              color: red;
            }
          }
        }

        input,
        select {
          border: none;
          border-bottom: 1px solid ${colors.mediumGray};
          background-color: #fff;
          color: ${colors.mediumGray};
          width: 100%;
          outline: none;
          margin-top: 8px;
          font-size: 14px;

          :focus {
            border-bottom: 1px solid ${colors.orange};
          }
        }
      }
      .inputLarge {
        width: 100%;
      }
    }
  }
  .modalPayment {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    height: 30vh;
    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: ${colors.darkOrange};
    }
    .message {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: ${colors.opacityGray};
    }
    .actions {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
    }
  }

  .modalPlans {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;

    height: 45vh;
    header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: ${colors.orange};
      }

      .close {
        cursor: pointer;
      }
    }
    .errors {
      color: red;
      font-size: 13px;
    }

    .inputs {
      height: 70%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-wrap: wrap;

      fieldset {
        border: none;
        margin-top: 5px;

        p {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
        }
        input {
          outline: none;
          height: 36px;
          border: 1px solid #000000;
          box-sizing: border-box;
          border-radius: 8px;
          width: 100%;
          padding: 5px;
        }
      }

      .advantages {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        outline: none;
        outline: none;
        height: 36px;
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 8px;
        width: 100%;
        padding: 5px;
        cursor: pointer;
      }

      .inputLarge {
        width: 100%;
      }
      .inputShort {
        width: 45%;
      }
      /* .select {
        display: flex;
        flex-direction: column;

        button {
          width: 100%;
          height: 36px;
          border: 1px solid #000;
          border-radius: 8px;
          padding: 10px;
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: space-between;
          outline: none;

          cursor: pointer;

          color: #000;
          font-size: 16px;
          line-height: 24px;
        }
      } */
    }
  }
`;

export const ModalSelect = styled(Dialog)`
  & .MuiPaper-root {
    width: 320px;
    height: fit-content;
    background-color: #fff;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    padding: 18px;
  }
  .container {
    height: 30vh;
    header {
      height: 20%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
      }

      .close {
        cursor: pointer;
      }
    }

    ul {
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      li {
        display: flex;
        align-items: center;
      }
      .checkbox {
        margin-right: 5px;
        width: 20%;
        cursor: pointer;
      }
      p {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        width: 60%;
      }
    }
  }

  .detailContainer {
    position: relative;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    header {
      height: 30%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      h1 {
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
      }
      p {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.7);
      }
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
      }
    }
    .advantages {
      height: 60%;
      ul {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        li {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .ico {
            width: 20%;
          }
          p {
            width: 80%;
          }
        }
      }
    }

    .close {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
    }
  }

  .childrenContainer {
    display: flex;
    flex-direction: column;
    height: 30vh;
    fieldset {
      height: 57px;
      width: 100%;
      border: none;
      margin-top: 10px;
      position: relative;

      p {
        font-size: 14px;
        color: ${colors.mediumGray};
      }

      input,
      select {
        border: none;
        border-bottom: 1px solid ${colors.mediumGray};
        color: ${colors.mediumGray};
        width: 100%;
        outline: none;
        height: 20px;
        margin-top: 8px;
        font-size: 14px;
        background-color: transparent;

        :focus {
          border-bottom: 1px solid ${colors.orange};
        }
      }
    }
    .errors {
      font-size: 0.8rem;
      color: red;
      margin-top: 1px;
    }
    .actions {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
    }
  }
`;
