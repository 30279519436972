import styled from 'styled-components';
import { device } from '../../../constants/size';

export const Container = styled.div`
  @media ${device.mobile} {
    padding-left: 0;
  }
  @media (max-width: 940px) {
    padding-left: 0;
  }
  max-height: 853.26px;
  width: 100%;
  height: 853.26px;
  background-color: #fef7f2;
  padding-left: 100px;

  display: flex;
  justify-content: space-around;
  align-items: center;

  .formDiv {
    @media ${device.mobile} {
      width: 100%;
      justify-content: center;
    }
    .form {
      max-width: 550px;
      max-height: 853px;
    }
    max-height: 853px;
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    align-content: center;
    /* padding-right: 25px; */
  }
`;

export const DivLogo = styled.div`
  position: relative;
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;

  .FirstAddon {
    position: absolute;
    z-index: 1;
    top: 180px;
    left: 76px;
  }

  .SecondAddon {
    position: absolute;
    z-index: 1;
    bottom: 202px;
    right: 90px;
  }

  .square {
    position: absolute;
    top: 25%;
    max-width: 397px;
    max-height: 397px;
    width: 100%;
    height: 100%;
    opacity: 1;
    z-index: 30;

    border-radius: 31px;
    background: linear-gradient(147deg, #f8863a 0%, #df3071 100%);
    h2 {
      color: #fff;
      font-size: 38px;
      font-weight: 600;
      text-align: left;
    }

    img {
      max-width: 104.35px;
      max-height: 172.93px;
      height: 100%;
      width: 100%;
      margin-bottom: 23.34px;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
