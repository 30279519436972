import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { colors } from '../../../constants/colors';
import { ButtonContainer } from '../../../components/Button/styles';

export const MobileContainer = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, ${colors.pink} 0%, ${colors.orange} 100%);
  display: flex;
  flex-direction: column;
  align-items: center;

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    height: calc(75vh - 10px);
    margin: 5px;
    main {
      width: 50%;
      height: 100%;
      .inputsContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        fieldset {
          height: 57px;
          width: 100%;
          border: none;
          margin-top: 10px;
          margin-bottom: 1.4em;
          p {
            font-size: 14px;
            color: #ffffff;
          }
          input {
            border: 1px solid #ffffff;
            box-sizing: border-box;
            border-radius: 8px;
            color: #ffffff;
            width: 100%;
            outline: none;
            height: 36px;
            margin-top: 8px;
            padding: 5px;
            font-size: 14px;
            background: transparent;
            :focus {
              border: 1px solid ${colors.orange};
            }
          }
        }
        .shortInput {
          width: 30%;
        }
        .mediumInput {
          width: 60%;
        }
        .inputGroup {
          display: flex;
          justify-content: space-between;
        }
      }
      .terms {
        color: #fff;

        div {
          margin-bottom: 5px;
          input {
            margin-right: 5px;
          }
          a {
            color: ${colors.pink};
          }
        }
      }
      .errors {
        font-size: 0.8rem;
        color: white;
        margin-top: 1px;
      }
    }
  }
`;

export const ButtonRegister = styled(ButtonContainer)`
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 8px;
  color: ${colors.pink};
  border: '#fff';
  width: 100%;
  height: 32px;

  &:hover {
    span {
      color: ${colors.pink};
    }
  }
`;

export const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 5,
    borderRadius: 5,
    marginBottom: '5px',
  },
  colorPrimary: {
    backgroundColor: 'transparent',
    border: ' 1px solid rgba(255,255,255,0.888)',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: 'rgba(255,255,255,0.888)',
  },
}))(LinearProgress);
