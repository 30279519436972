import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const MobileContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, ${colors.pink} 0%, ${colors.orange} 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  main {
    width: 250px;
    height: 70vh;
    margin-top: 10px;
    .inputsContainer {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      fieldset {
        height: 57px;
        width: 100%;
        border: none;
        position: relative;
        margin-top: 10px;
        margin-bottom: 1.4em;
        p {
          font-size: 14px;
          color: #ffffff;
        }
        input {
          border: 1px solid #ffffff;
          box-sizing: border-box;
          border-radius: 8px;
          color: #ffffff;
          width: 100%;
          outline: none;
          height: 36px;
          margin-top: 8px;
          padding: 5px;
          font-size: 14px;
          background: transparent;
          :focus {
            border: 1px solid ${colors.orange};
          }
        }
      }
      .errors {
        font-size: 0.8rem;
        color: white;
        margin-top: 1px;
      }
      .eye {
        color: ${colors.pink};
        position: absolute;
        bottom: 10%;
        right: 10px;
        cursor: pointer;
      }

      a {
        color: #ffffff;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        text-decoration-line: underline;
        margin-top: 15px;
      }
    }
  }
`;
