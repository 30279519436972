import * as yup from 'yup';

const schema = yup.object().shape({
  actualPassword: yup
    .string()
    .min(8, 'A senha deve ter pelo menos 8 caracteres')
    .required('Este campo é obrigatório'),
  newPassword: yup
    .string()
    .min(8, 'A nova senha deve ter pelo menos 8 caracteres')
    .required('Este campo é obrigatório'),
  newPasswordConfirmation: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'As senhas devem ser iguais')
    .required('Este campo é obrigatório'),
});

export default schema;
