import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  color: ${colors.mediumGray};

  .container {
    width: 100%;
    height: 100%;
    .header {
      width: 100%;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px;
      box-shadow: 0px 4px 12px rgba(17, 14, 27, 0.13);

      h1 {
        font-size: 32px;
        line-height: 36px;
        color: ${colors.darkOrange};
      }
    }
  }

  .content {
    height: calc(100% - 100px);
    padding: 0 24px;
    overflow-y: scroll;

    .planDescription {
      height: 23%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      h1 {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
      }
      .textDescription {
        p {
          font-size: 16px;
          line-height: 24px;
        }
      }

      &::after {
        display: block;
        content: '';
        position: absolute;
        left: 0;
        right: 50%;
        bottom: -1%;
        width: 100%;
        height: 1px;
        background-color: ${colors.darkGray};
        z-index: 1;
      }
    }

    .planContainer {
      width: 100%;
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      h1 {
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 48px;

        span {
          color: #8434bb;
        }
      }

      .planList {
        height: 86%;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;

        .planCard {
          margin-top: 10px;
          min-width: 30%;
          height: 568px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          padding: 24px;
          box-shadow: 0px 18px 36px rgba(17, 14, 27, 0.1),
            0px 5.42647px 10.8529px rgba(17, 14, 27, 0.065102),
            0px 2.25388px 4.50776px rgba(17, 14, 27, 0.0499628),
            0px 0.815184px 1.63037px rgba(17, 14, 27, 0.0348159);
          border-radius: 16px;

          display: flex;
          flex-direction: column;
          align-items: center;

          h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 30px;
            line-height: 38px;
          }
          p {
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
          }
          .message {
            p {
              font-size: 14px;
            }
          }

          ul {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 60%;
            li {
              display: flex;

              p {
                margin-left: 17px;
                font-size: 14px;
              }

              .ico {
                min-width: 21px;
              }
            }
          }
        }
        .free {
          h3 {
            color: ${colors.blue};
          }
        }

        .basic {
          h3 {
            color: ${colors.orange};
          }
        }

        .premium {
          h3 {
            color: ${colors.pink};
          }
        }
      }
    }
  }
`;
