/* eslint-disable no-redeclare */
/* eslint-disable no-plusplus */
/* eslint-disable vars-on-top */
/* eslint-disable radix */
/* eslint-disable no-var */
/* eslint-disable block-scoped-var */
export function valiteCPF(cpf) {
  if (typeof cpf !== 'string') return false;
  const cpfTest = cpf.replace(/[\s.-]*/gim, '');
  if (
    !cpfTest ||
    cpfTest.length !== 11 ||
    cpfTest === '00000000000' ||
    cpfTest === '11111111111' ||
    cpfTest === '22222222222' ||
    cpfTest === '33333333333' ||
    cpfTest === '44444444444' ||
    cpfTest === '55555555555' ||
    cpfTest === '66666666666' ||
    cpfTest === '77777777777' ||
    cpfTest === '88888888888' ||
    cpfTest === '99999999999'
  ) {
    return false;
  }
  let soma = 0;
  let resto;
  for (var i = 1; i <= 9; i++)
    soma += parseInt(cpfTest.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpfTest.substring(9, 10))) return false;
  soma = 0;
  for (var i = 1; i <= 10; i++)
    soma += parseInt(cpfTest.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpfTest.substring(10, 11))) return false;
  return true;
}
