/* eslint-disable one-var */
/* eslint-disable object-shorthand */
/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineSearch } from 'react-icons/ai';
import { RiCheckboxBlankFill, RiCheckboxFill } from 'react-icons/ri';

import { Content, Container, Client } from './styles';
import AdmMenu from '../../../components/MenuAdm';

import eye from '../../../assets/svg/eye.svg';
import api from '../../../services/api';
import { cpfMask } from '../../../utils/mask';

import {exportClientsInfo} from '../../../services/management'

const SearchClients = () => {
  const [clients, setClients] = useState([]);
  const [clientsDisplayed, setClientsDisplayed] = useState([]);
  const [input, setInput] = useState([]);
  const [emailFilter, setEmailFilter] = useState(false);
  const [urlDownloadTxt, setUrlDownloadTxt] = useState('')

  useEffect(() => {
    api.get('/users').then((response) => {
      setClients(response.data);
      setClientsDisplayed(response.data);
    });
  }, []);

  useEffect(() => {
    const clientsSearch = clients.filter((client) =>
      `${client.first_name} ${client.last_name}`
        .toLowerCase()
        .includes(input.toLowerCase()),
    );

    if (emailFilter === true) {
      setClientsDisplayed(
        clientsSearch.filter((client) => client.newsletter_flag === 1),
      );
    } else {
      setClientsDisplayed(clientsSearch);
    }
  }, [input]);

  useEffect(() => {
    setInput('');
    if (emailFilter === true) {
      setClientsDisplayed(
        clients.filter((client) => client.newsletter_flag === 1),
      );
    } else {
      setClientsDisplayed(clients);
    }
  }, [emailFilter]);

  function handleDownloadTxt(){
    
     exportClientsInfo()
      .then(res=>{
        const element = document.createElement('a');
        element.setAttribute('href', `data:text/plain;charset=utf-8,${  encodeURIComponent(JSON.stringify(res.data))}`);
        element.setAttribute('download', 'dados_clientes.txt');
      
        element.style.display = 'none';
        document.body.appendChild(element);
      
        element.click();
      
        document.body.removeChild(element);  
      })
      .catch(err=>console.log(err))
  }

  return (
    <Container>
      <AdmMenu page="SearchClients" />
      <Content>
        <header>
          <h1>Clientes</h1>
        </header>
        <div className="actions">
          <div className="input">
            <input
              type="text"
              placeholder="Pesquisar clientes"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />{' '}
            <button type="button">
              <AiOutlineSearch size={24} />
            </button>
          </div>
          <button id="export-button" type="button" onClick={handleDownloadTxt}>
            Exportar clientes
          </button>
          <div className="filter">
            {emailFilter === false ? (
              <RiCheckboxBlankFill
                className="checkbox"
                size={24}
                color="rgba(238, 114, 0, 0.5)"
                onClick={() => {
                  setEmailFilter(!emailFilter);
                }}
              />
            ) : (
              <RiCheckboxFill
                className="checkbox"
                size={24}
                color="#EE7200"
                onClick={() => {
                  setEmailFilter(!emailFilter);
                }}
              />
            )}
            <p>Somente clientes que recebem email</p>
          </div>
        </div>
        <div className="line" />
        <div className="clients">
          {clientsDisplayed.map((client) => (
            <Client>
              <div className="data">
                <h2>{`${client.first_name} ${client.last_name}`}</h2>
                <div className="cpf">
                  <h2>{cpfMask(client.cpf)}</h2>
                </div>
                <Link
                  to={{
                    pathname: '/gestao/client',
                    state: { client },
                  }}
                >
                  <img src={eye} alt="eye" />
                </Link>
              </div>
              <div className="line" />
            </Client>
          ))}
        </div>
      </Content>
    </Container>
  );
};
export default SearchClients;
