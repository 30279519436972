import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { ButtonRegister, ButtonBack } from '../Login/styles';
import { ButtonContainer } from '../../components/Button/styles';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent
    linear-gradient(180deg, ${colors.pink} 0%, ${colors.orange} 120%) 0% 0%
    no-repeat padding-box;

  .content {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    nav {
      width: 100%;
      height: 90px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1em;
      .containerButtons {
        width: 30%;
        display: flex;
        justify-content: flex-end;
        &:first-child {
          margin-right: 10px;
        }
      }
    }
    .container-title {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      padding-top: 1em;
      h1 {
        color: ${colors.white};
        padding-bottom: 0.5em;
        font-weight: 500;
      }
      p {
        color: ${colors.white};
      }
    }
    .container {
      width: 90%;
      height: 680px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .infoSteps {
        display: flex;
        color: white;
        font-size: 0.8em;
        margin-bottom: 15px;
        justify-content: space-between;
        width: 30%;
        p {
          opacity: 0.5;
        }
      }

      .containerLogin {
        width: 40%;
        height: 100%;
        background-color: #fff;
        margin-bottom: 2em;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

        .logo {
          margin-top: 10px;
          display: flex;
          height: 20%;
          width: 100%;
          justify-content: center;
          align-items: center;
          img {
            width: 40%;
          }
        }

        .errors {
          padding-top: 0.3em;
          margin-bottom: -1.45em;
          color: red;
          font-size: 13px;
        }

        .containerInputs {
          height: 90%;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;

          .fieldset-third-step {
            display: flex;
            flex-direction: column;
            width: 80%;
            height: 10%;
            margin-bottom: 1em;

            input {
              border: 1px solid #dcdcdc;
              border-radius: 4px;
              border-width: 1px;
              height: 55%;
              padding-left: 0.5em;
              padding-right: 0.5em;
            }
            p {
              color: ${colors.darkGray};
              font-weight: 600;
              margin-bottom: 5px;
              font-size: 14px;
            }
          }

          .wrapper {
            display: flex;
            width: 80%;
            height: 10%;
            margin-bottom: 1em;
            p {
              color: ${colors.darkGray};
              font-weight: 600;
              margin-bottom: 5px;
              font-size: 14px;
            }
            .small-input-container {
              display: flex;
              flex-direction: column;
              width: 30%;
              height: 100%;
              margin-bottom: 1em;

              input {
                border: 1px solid #dcdcdc;
                border-radius: 4px;
                border-width: 1px;
                height: 55%;
                width: 100%;
                padding-left: 0.5em;
                padding-right: 0.5em;
              }
            }
            .medium-input-container {
              display: flex;
              flex-direction: column;
              width: 100%;
              height: 100%;
              margin-bottom: 1em;
              margin-left: 1em;
              input {
                border: 1px solid #dcdcdc;
                border-radius: 4px;
                border-width: 1px;
                height: 55%;
                width: 100%;
                padding-left: 0.5em;
                padding-right: 0.5em;
              }
            }
          }

          #termos {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 80%;
            .input-wrapper {
              display: flex;
              align-items: center;
              width: 100%;
              margin-bottom: 1em;
              #input-termos {
                display: flex;
                width: 20px;
                height: 20px;
                margin-right: 0.5em;
              }
              #text-termos {
                width: 100%;
                font-size: 15px;
                a {
                  font-weight: 600;
                  color: ${colors.orange};
                }
              }
            }
          }

          fieldset {
            border: none;
            width: 80%;
            height: 35px;

            p {
              color: ${colors.darkGray};
              font-weight: 600;
              margin-bottom: 5px;
              font-size: 14px;
            }

            input {
              outline: none;
              width: 100%;
              height: 35px;
              border: 1px solid #dcdcdc;
              border-radius: 4px;
              padding-left: 5px;

              :focus {
                border-color: ${colors.mediumGray};
              }
            }

            .eye {
              color: ${colors.opacityGray};
              position: absolute;
              bottom: -19%;
              right: 10px;
              cursor: pointer;
            }
          }
          .password {
            position: relative;
          }
        }

        .actions {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          height: auto;
          margin-bottom: 5px;
          a {
            color: #00a779;
            text-decoration: underline;
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
`;

export const ButtonExit = styled(ButtonRegister)`
  margin-left: 5px;
`;

export const ButtonNext = styled(ButtonContainer)`
  &:hover {
    background-color: transparent;
    border: 2px solid ${colors.pink};
    span {
      color: ${colors.pink};
    }
  }
  &:active {
    background-color: ${colors.pink};
    span {
      color: ${colors.white};
    }
  }
`;

export const ButtonBackContainer = styled(ButtonBack)`
  &:hover {
    background-color: ${colors.pink};
  }
  &:active {
    background-color: #fff;
    img {
      margin-right: 0;
      display: block;
    }
    span {
      color: ${colors.pink};
    }
  }
`;

export const Loading = styled.div`
  width: 100%;
  position: relative;
  height: 2vh;
  background-color: blue;
  overflow: hidden;
  content: '';
  margin-top: 5px;
  display: block;
  &:after {
    animation: load 1s ease-in-out infinite alternate;
    position: absolute;
    background-image: linear-gradient(
      to right,
      ${colors.orange},
      ${colors.pink},
      ${colors.orange}
    );
    width: 150%;
    height: 100%;
    content: '';
  }

  @keyframes load {
    0% {
      left: 0%;
    }
    100% {
      left: -50%;
    }
  }
`;
