/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { RiErrorWarningFill } from 'react-icons/ri';
import { toast, ToastContainer } from 'react-toastify';

import { useLocation } from 'react-router-dom';
import ValidationSchema from '../../../utils/PaymentSchema';
import { numberMask, validationDateMask } from '../../../utils/mask';
import api from '../../../services/api';
import { UpdateUserRequest } from '../../../store/ducks/auth/auth';
import Loading from '../../../components/Loading';
import Footer from '../../../components/Mobile/Footer';

import {
  Container,
  PaymentDiv,
  RadioDiv,
  FormDiv,
  Button,
  BankSlipDiv,
  ErrorBox,
  PlanInfo,
  PlanTitle,
  PlanContainer,
} from './styles';
import Header from '../../../components/Mobile/Header';
import { FormatUser } from '../../../utils/FormatUser';

function Pagamento() {
  const [paymentType, setPaymentType] = useState(false);
  const [errors, setErrors] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);

  const { state } = useLocation();
  const { plan } = state;

  const advantages = [];
  // eslint-disable-next-line array-callback-return
  plan.advantages.map((advantage) => {
    advantages.push(advantage.advantage);
  });

  const planTitle = `MEI ${plan.name}`;
  const planInfo = `Finalize sua assinatura do plano ${
    plan.name
  } e usufrua de recursos como ${advantages.join(', ')} e muito mais!`;
  const dispatch = useDispatch();

  async function UpdateUserOnStore() {
    api.get('/users/me').then(async ({ data }) => {
      const userFormat = await FormatUser(data);
      dispatch(UpdateUserRequest(userFormat));
    });
  }

  async function HandleSubscription(data, token) {
    if (token) {
      try {
        await api.post('/iugu/payment-method', {
          description: `Cartão de ${data.first} ${data.last}`,
          token,
          set_as_default: true,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error.response);
        setIsLoading(false);
      }
    }

    await api
      .post('/subscriptions', {
        plan_id: plan.id,
        is_creditcard: !!token,
      })
      .then(async () => {
        toast('Compra realizada com sucesso!');
        // eslint-disable-next-line no-use-before-define
        ClearForm();
        await UpdateUserOnStore();
        setIsLoading(false);
      })
      .catch(async (error) => {
        await api
          .delete('/iugu/user-create')
          .then()
          // eslint-disable-next-line no-console
          .catch((err) => console.log(err.response));

        toast('Erro no pagamento da assinatura');
        // eslint-disable-next-line no-console
        console.log(error.response);
        setIsLoading(false);
      });
  }

  async function HandleIuguUser() {
    if (!user.id_iugu) {
      await api
        .post('/iugu/user-create')
        .then((response) => {
          const userTemp = user;
          sessionStorage.setItem(
            'users',
            JSON.stringify({
              ...userTemp,
              id_iugu: response.data.id,
            }),
          );
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error.response);
          setIsLoading(false);
        });
    }
  }

  async function HandleCreditCard(dataa) {
    const nameArray = dataa.name.split(' ');
    const first = nameArray[0];
    nameArray.shift();
    const last = nameArray.join(' ');
    const [month, year] = dataa.due_date.split('/');
    // eslint-disable-next-line no-undef
    const cc = await Iugu.CreditCard(
      dataa.credit_card,
      month,
      year,
      first,
      last,
      Number(dataa.cvv),
    );

    // eslint-disable-next-line no-undef
    Iugu.setAccountID(process.env.REACT_APP_ACCOUNT_ID);

    // eslint-disable-next-line no-undef
    Iugu.setTestMode(process.env.REACT_APP_PRODUCTION !== 'TRUE');

    // eslint-disable-next-line no-undef
    await Iugu.createPaymentToken(cc, async (response) => {
      if (response.errors) {
        // console.log(response.errors);
        setIsLoading(false);
        if (response.errors.number) {
          setErrors('Número do cartão inválido');
        } else if (response.errors.frist_name) {
          setErrors('Nome do cartão inválido');
        } else if (response.errors.last_name) {
          setErrors('Sobrenome do cartão inválido');
        } else if (response.errors.expiration) {
          setErrors('Data de validade do cartão inválida');
        } else if (response.errors.verification_value) {
          setErrors('CVV do cartão inválido');
        } else {
          setErrors('Cartão inválido');
        }
      } else {
        await HandleSubscription(dataa, response.id);
        // toast.success('deu Bom');
      }
    });
  }

  async function Submit(data) {
    setErrors('');
    setIsLoading(true);
    await HandleIuguUser();
    if (!paymentType) {
      await HandleCreditCard(data, setErrors);
    } else {
      await HandleSubscription();
    }
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      credit_card: '',
      due_date: '',
      cvv: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ValidationSchema,
    onSubmit: async (data) => {
      await Submit(data);
    },
  });

  async function ClearForm() {
    formik.values.name = '';
    formik.values.due_date = '';
    formik.values.cvv = '';
    formik.values.credit_card = '';
  }

  return (
    <>
      <Container>
        <Loading open={isLoading} />
        <ToastContainer />
        <Header thisPage="Planos" />

        <PlanContainer>
          <PlanTitle>{planTitle}</PlanTitle>
          <PlanInfo style={{ marginBottom: 15 }}>
            {' '}
            Estamos a um passo de inovar sua experiência!
          </PlanInfo>

          <PlanInfo>{planInfo}</PlanInfo>
        </PlanContainer>

        <PaymentDiv>
          <h3>Pagamento</h3>
          <RadioDiv>
            <input
              type="radio"
              name="credit-card"
              checked={!paymentType}
              onClick={() => setPaymentType(false)}
            />
            <label htmlFor="credit-card">Cartão de crédito</label>

            <input
              type="radio"
              name="bank_split"
              checked={paymentType}
              onClick={() => setPaymentType(true)}
            />
            <label htmlFor="credit-card">Boleto bancário</label>
          </RadioDiv>
          <FormDiv>
            {paymentType ? (
              <BankSlipDiv>
                <RiErrorWarningFill size={30} color="#EE7200" />
                <p>
                  Uma cópia do boleto será enviada por e-mail e você poderá
                  imprimi-lo na página seguinte.
                </p>
              </BankSlipDiv>
            ) : (
              <>
                {errors && (
                  <ErrorBox style={{ marginTop: '-2px' }}>{errors}</ErrorBox>
                )}
                <label htmlFor="name">Nome impresso no cartão</label>
                <input
                  type="text"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name ? (
                  <ErrorBox>{formik.errors.name}</ErrorBox>
                ) : null}
                <label htmlFor="creadit_card">Número do cartão</label>
                <input
                  type="text"
                  name="credit_card"
                  onChange={(e) => {
                    e.target.value = numberMask(e.target.value);
                    formik.handleChange(e);
                  }}
                  value={formik.values.credit_card}
                  maxLength={16}
                />
                {formik.touched.credit_card && formik.errors.credit_card ? (
                  <ErrorBox>{formik.errors.credit_card}</ErrorBox>
                ) : null}
                <div>
                  <div>
                    <label htmlFor="due_date">Validade</label>
                    <input
                      type="text"
                      name="due_date"
                      onChange={(e) => {
                        e.target.value = validationDateMask(e.target.value);
                        formik.handleChange(e);
                      }}
                      value={formik.values.due_date}
                      maxLength={5}
                    />
                    {formik.touched.due_date && formik.errors.due_date ? (
                      <ErrorBox>{formik.errors.due_date}</ErrorBox>
                    ) : null}
                  </div>
                  <div style={{ width: '100px' }}>
                    <label htmlFor="cvv">Cvv</label>
                    <input
                      type="text"
                      name="cvv"
                      onChange={(e) => {
                        e.target.value = numberMask(e.target.value);
                        formik.handleChange(e);
                      }}
                      value={formik.values.cvv}
                      maxLength={4}
                      minLength={3}
                      style={{ width: '100px' }}
                    />
                    {formik.touched.cvv && formik.errors.cvv ? (
                      <ErrorBox>{formik.errors.cvv}</ErrorBox>
                    ) : null}
                  </div>
                </div>
              </>
            )}
            <Button
              type="button"
              onClick={!paymentType ? formik.handleSubmit : Submit}
            >
              Finalizar compra
            </Button>
          </FormDiv>
        </PaymentDiv>
      </Container>
      <Footer />
    </>
  );
}

export default Pagamento;
