import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: scroll;
`;

export const PaymentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 250px;
  width: 100%;
  max-height: 386px;
  height: 100%;
  margin-top: 40px;

  h3 {
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    color: #ff6d24;
    text-align: center;
  }
`;

export const RadioDiv = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;
  padding-top: 5px;
  label {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: #000000;
    margin-right: 8px;
    margin-left: 8px;
  }
  input {
    border: 0px;
    width: 20px;
    height: 2em;
  }
`;

export const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 250px;
  width: 100%;
  height: 100%;
  margin-top: 10px;

  label {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 5px;
  }

  input {
    border: 1px solid #262626;
    box-sizing: border-box;
    border-radius: 8px;
    max-width: 250px;
    width: 100%;
    height: 36px;
    margin-bottom: 12px;
    padding-left: 5px;
  }
  div {
    display: flex;
    justify-content: space-between;
    div {
      flex-direction: column;
      justify-content: flex-start;
      input {
        width: 130px;
        margin-bottom: 16px;
      }
    }
  }
`;

export const Button = styled.button`
  width: 100%;
  height: 32px;
  color: #fff;
  background-color: #ff6d24;
  border-radius: 8px;
  border: 0;
  margin-bottom: 40px;
`;

export const BankSlipDiv = styled.div`
  max-width: 250px;
  height: 120px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  margin-bottom: 16px;

  box-shadow: 0px 4px 12px rgba(17, 14, 27, 0.13);
  border-radius: 8px;
  p {
    text-align: center;
    color: #262626;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
  }
  svg {
    margin-bottom: 5px;
  }
`;
export const ErrorBox = styled.span`
  color: red;
  margin-top: -12px;
  margin-bottom: 10px;
  font-size: 12px;
  margin-left: 5px;
  max-width: 80%;
`;

export const PlanContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 19px;
`;

export const PlanTitle = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  margin-bottom: 15px;
`;

export const PlanInfo = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.02em;
`;
