/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { Wrapper } from './styles';
import Button from '../../components/Button';
import api, { apiCep } from '../../services/api';
import { ModalContent } from '../../components/Modal/styles';
import { colors } from '../../constants/colors';
import { UpdateUserRequest, Logout } from '../../store/ducks/auth/auth';
import {
  cpfMask,
  removeMask,
  phoneMask,
  zipCodeMask,
  removeMaskPhone,
} from '../../utils/mask';
import 'react-toastify/dist/ReactToastify.css';
import { FormatUser } from '../../utils/FormatUser';

const Profile = () => {
  const history = useHistory();
  const [errors, setErrors] = useState('');
  const [modalState, setModalState] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);
  const [inputs, setInputs] = useState({
    email: '',
    password: '',
    repeat_password: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    birth_date: '',
    zip_code: '',
    street_name: '',
    address_number: '',
    additional_info: '',
    state: '',
    city: '',
    cpf: '',
    plan_id: 1,
  });

  const [planType, setPlanType] = useState('');
  const dispatch = useDispatch();
  const users = useSelector((state) => state.auth.user);

  useEffect(() => {
    setInputs(users);
    setPlanType(users.plan.name);
  }, [users]);

  useEffect(() => {
    if (inputs.zip_code.length >= 8) {
      apiCep.get(`/${inputs.zip_code}/json`).then((res) => {
        setInputs({
          ...inputs,
          street_name:
            res.data.logradouro !== ''
              ? res.data.logradouro
              : inputs.street_name,
          state: res.data.uf,
          city: res.data.localidade,
        });
      });
    }
  }, [inputs.zip_code]);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email('Formato inválido de email')
      .max(255)
      .required('Email é um campo obrigatório'),
    first_name: yup.string().max(255).required('Nome é um campo obrigatório'),
    last_name: yup
      .string()
      .max(255)
      .required('Sobrenome é um campo obrigatório'),
    cpf: yup
      .string()
      .min(11, 'O CPF deve ter 11 dígitos')
      .max(11, 'O CPF deve ter 11 dígitos')
      .matches(/\d+/g)
      .required('Cpf é um campo obrigatório'),
    phone_number: yup
      .string()
      .min(11, 'O telefone deve ter no minimo 11 dígitos')
      .max(12, 'O telefone deve ter no máximo 12 dígitos')
      .required('Telefone é um campo obrigatório'),
    birth_date: yup
      .string()
      .required('Data de Nascimento é um campo obrigatório'),
    zip_code: yup
      .string()
      .min(8, 'O CEP deve ter 8 dígitos')
      .max(8, 'O CEP deve ter 8 dígitos')
      .matches(/\d+/g)
      .required('Cep é um campo obrigatório'),
    street_name: yup
      .string()
      .max(50, 'A rua deve no máximo 50 caracteres')
      .required('Rua é um campo obrigatório'),
    address_number: yup.string().required('Número é um campo obrigatório'),
    state: yup
      .string()
      .min(2, 'O estado deve ter 2 dígitos')
      .max(2, 'O estado deve ter 2 dígitos')
      .required('UF é um campo obrigatório'),
    city: yup
      .string('string')
      .min(4, 'min')
      .max(26, 'max')
      .required('Cidade é um campo obrigatório'),
    additional_info: yup
      .string()
      .test(
        'name',
        'preenchido',
        (value) => !value || (value.length <= 24 && value.length >= 4),
      ),
  });

  function closeModal() {
    setModalState(false);
    setTimeout(() => {
      setSuccess(false);
    }, 1000);
  }

  const DeleteUser = () => {
    api
      .delete('/users/delete')
      .then(() => {
        setSuccessDelete(true);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error.response);
      });
  };

  const cancelPlan = () => {
    api
      .delete('/subscriptions')
      .then(() => {
        setSuccess(true);
        api.get('/users/me').then(async ({ data }) => {
          const userFormat = await FormatUser(data);
          dispatch(UpdateUserRequest(data));
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error.response);
      });
  };

  async function handleEditUser(e) {
    e.preventDefault();

    delete inputs.employee;
    const data = {
      email: inputs.email,
      first_name: inputs.first_name,
      last_name: inputs.last_name,
      birth_date: new Date(inputs.birth_date).toISOString().slice(0, 10),
      cpf: inputs.cpf,
      zip_code: inputs.zip_code,
      state: inputs.state,
      city: inputs.city,
      street_name: inputs.street_name,
      address_number: inputs.address_number,
      additional_info: inputs.additional_info,
      phone_number: inputs.phone_number,
      plan: inputs.plan,
    };
    data.avatar_id === null ? delete data.avatar_id : data.avatar_id;
    setErrors(false);

    try {
      await schema.validate(data);

      dispatch(UpdateUserRequest(data));
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        error.errors.map((err) => setErrors(err));
      }
      return false;
    }

    // await api
    //   .patch('/users/update', data)
    //   .then((response) => {
    //     sessionStorage.setItem('users', JSON.stringify(response.data));
    //     setErrors('Alterações feitas com sucesso!');
    //     document.location.reload();
    //   })
    //   .catch((error) => {
    //     if (error.response.data) {
    //       setErrors(error.response.data.error.message);
    //     }
    //     return false;
    //   });

    return true;
  }

  return (
    <Wrapper>
      <ToastContainer />
      <div className="header">
        <h1>Perfil</h1>
      </div>
      <div className="container">
        <section className="account">
          <h1>Informações sobre a conta</h1>
          <div className="inputContainer">
            <fieldset>
              <p>Email</p>
              <input
                type="email"
                placeholder="Email"
                value={inputs.email}
                name="email"
                onChange={(e) => {
                  setInputs({ ...inputs, [e.target.name]: e.target.value });
                }}
              />
            </fieldset>

            <fieldset>
              <p>Plano Atual</p>
              <input type="text" value={planType} name="plan_id" />
            </fieldset>
            <fieldset className="space">
              <p>Plano Atual</p>
              <input type="text" className="space" disabled />
            </fieldset>
          </div>
        </section>

        <section>
          <h1>Informações pessoais</h1>
          <div className="inputContainer">
            <fieldset>
              <p>Nome</p>
              <input
                type="text"
                placeholder="Nome"
                value={inputs.first_name}
                name="first_name"
                onChange={(e) => {
                  setInputs({ ...inputs, [e.target.name]: e.target.value });
                }}
              />
            </fieldset>

            <fieldset>
              <p>Sobrenome</p>
              <input
                type="text"
                placeholder="Sobrenome"
                value={inputs.last_name}
                name="last_name"
                onChange={(e) => {
                  setInputs({ ...inputs, [e.target.name]: e.target.value });
                }}
              />
            </fieldset>

            <fieldset>
              <p>Data de nascimento</p>
              <input
                type="date"
                value={inputs.birth_date.split('T')[0]}
                name="birth_date"
                onChange={(e) => {
                  setInputs({ ...inputs, [e.target.name]: e.target.value });
                }}
              />
            </fieldset>

            <fieldset>
              <p>CPF</p>
              <input
                type="text"
                placeholder="CPF"
                name="cpf"
                value={cpfMask(inputs.cpf)}
              />
            </fieldset>
          </div>
        </section>

        <section>
          <h1>Informações de contato</h1>
          <div className="inputContainer">
            <fieldset>
              <p>Estado</p>
              <input
                type="text"
                placeholder="UF"
                value={inputs.state}
                name="state"
                onChange={(e) => {
                  setInputs({ ...inputs, [e.target.name]: e.target.value });
                }}
              />
            </fieldset>

            <fieldset>
              <p>Cidade</p>
              <input
                type="text"
                placeholder="Cidade"
                value={inputs.city}
                name="city"
                onChange={(e) => {
                  setInputs({ ...inputs, [e.target.name]: e.target.value });
                }}
              />
            </fieldset>

            <fieldset>
              <p>CEP</p>
              <input
                type="text"
                placeholder="CEP"
                name="zip_code"
                value={zipCodeMask(inputs.zip_code)}
                onChange={(e) => {
                  setInputs({
                    ...inputs,
                    [e.target.name]: removeMask(e.target.value),
                  });
                }}
              />
            </fieldset>

            <fieldset>
              <p>Rua</p>
              <input
                type="text"
                placeholder="Rua"
                value={inputs.street_name}
                name="street_name"
                onChange={(e) => {
                  setInputs({ ...inputs, [e.target.name]: e.target.value });
                }}
              />
            </fieldset>

            <fieldset>
              <p>Número</p>
              <input
                type="number"
                placeholder="Numero"
                value={String(inputs.address_number)}
                name="address_number"
                onChange={(e) => {
                  setInputs({ ...inputs, [e.target.name]: e.target.value });
                }}
              />
            </fieldset>

            <fieldset>
              <p>Complemento</p>
              <input
                type="text"
                placeholder="Complemento"
                value={inputs.additional_info}
                name="additional_info"
                onChange={(e) => {
                  setInputs({ ...inputs, [e.target.name]: e.target.value });
                }}
              />
            </fieldset>

            <fieldset>
              <p>Telefone</p>
              <input
                type="tel"
                placeholder="Telefone"
                name="phone_number"
                value={phoneMask(inputs.phone_number)}
                onChange={(e) => {
                  setInputs({
                    ...inputs,
                    [e.target.name]: removeMaskPhone(e.target.value),
                  });
                }}
              />
            </fieldset>
          </div>
        </section>
        <div className="actions">
          <div className="error">
            {errors ? <p className="errors">{errors}</p> : null}
          </div>
          <div className="buttons">
            <Button
              width="206px"
              backgroundColor="transparent"
              color={colors.orange}
              border={colors.orange}
              text="Excluir conta NUME"
              onClick={() => {
                users.id_iugu_subscription
                  ? toast(
                      'Você deve cancelar o seu plano atual para excluir a conta',
                    )
                  : setModalDelete(true);
              }}
            />
            {users.id_iugu_subscription && (
              <Button
                width="206px"
                backgroundColor="transparent"
                color={colors.orange}
                border={colors.orange}
                text="Cancelar plano"
                onClick={() => setModalState(true)}
              />
            )}
            <Button width="206px" text="Salvar" onClick={handleEditUser} />
          </div>
        </div>
      </div>
      <ModalContent open={modalState} onClose={closeModal}>
        <div className="modalPayment">
          <div className="title">
            <h3>Confirmação de cancelamento</h3>
          </div>
          <div className="message">
            <p>
              {success === false
                ? `Estou ciente de que estou prestes a cancelar o plano ${
                    planType !== 'Free'
                      ? planType
                      : 'que foi adquirido via boleto e esta aguardando pagamento'
                  }.`
                : 'Cancelamento feito com sucesso, agora você esta no plano Free.'}
            </p>
          </div>

          {success === false ? (
            <div className="actions">
              <Button
                id="buttonSubmit"
                backgroundColor="transparent"
                color={colors.orange}
                border={colors.orange}
                radius="8px"
                hover="#D26A24"
                active="#FF6600"
                width="170px"
                height="50px"
                text="cancelar"
                onClick={closeModal}
              />
              <Button
                id="buttonSubmit"
                backgroundColor={colors.orange}
                radius="8px"
                hover="#D26A24"
                active="#FF6600"
                width="170px"
                height="50px"
                text="Ok"
                onClick={cancelPlan}
              />
            </div>
          ) : (
            <div className="actions">
              <Button
                id="buttonSubmit"
                backgroundColor={colors.orange}
                radius="8px"
                hover="#D26A24"
                active="#FF6600"
                width="170px"
                height="50px"
                text="Ok"
                onClick={closeModal}
              />
            </div>
          )}
        </div>
      </ModalContent>
      <ModalContent open={modalDelete} onClose={() => setModalDelete(false)}>
        <div className="modalPayment">
          <div className="title">
            <h3>
              {successDelete === false
                ? 'Você deseja excluir sua conta?'
                : 'Conta excluída'}
            </h3>
          </div>
          <div className="message">
            <p>
              {successDelete === false
                ? `Há algo que possamos fazer para não perder um cliente tão
              importante quanto você? Se sim entre em contato conosco. Tem
              certeza que deseja nos deixar?`
                : 'Conta excluída com sucesso, esperamos que volte a trabalhar conosco!! '}
            </p>
          </div>

          <div className="actions">
            {successDelete === false ? (
              <>
                <Button
                  id="buttonSubmit"
                  backgroundColor="transparent"
                  color={colors.orange}
                  border={colors.orange}
                  radius="8px"
                  hover="#D26A24"
                  active="#FF6600"
                  width="170px"
                  height="50px"
                  text="cancelar"
                  onClick={() => setModalDelete(false)}
                />
                <Button
                  id="buttonSubmit"
                  backgroundColor={colors.orange}
                  radius="8px"
                  hover="#D26A24"
                  active="#FF6600"
                  width="170px"
                  height="50px"
                  text="Sim"
                  onClick={() => DeleteUser()}
                />
              </>
            ) : (
              <Button
                id="buttonSubmit"
                backgroundColor={colors.orange}
                radius="8px"
                hover="#D26A24"
                active="#FF6600"
                width="170px"
                height="50px"
                text="Sim"
                onClick={() => {
                  dispatch(Logout());
                  history.push('/');
                }}
              />
            )}
          </div>
        </div>
      </ModalContent>
    </Wrapper>
  );
};

export default Profile;
