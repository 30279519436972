import styled from 'styled-components';
import { ButtonContainer } from '../../../components/Button/styles';
import { colors } from '../../../constants/colors';

export const MobileContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, ${colors.pink} 0%, ${colors.orange} 100%);
  display: flex;
  flex-direction: column;
  align-items: center;

  .container {
    width: 80%;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;

    .message {
      text-align: center;
    }
  }

  .successContainer {
    /* padding: 24px; */
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    img {
      width: 50%;
    }

    div {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;
      text-align: center;

      img {
        height: 80px;
      }
      strong {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        margin: 10px 0;
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
      }
    }
  }
`;

export const InputField = styled.fieldset`
  height: 57px;
  position: relative;
  width: 100%;
  border: none;
  margin-top: 10px;
  margin-bottom: 1.4em;
  color: ${(props) => (props.color ? props.color : '#fff')};
  p {
    font-size: 14px;
  }
  input,
  select {
    border: 1px solid ${(props) => (props.color ? props.color : '#fff')};
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    outline: none;
    height: 36px;
    color: ${(props) => (props.color ? props.color : '#fff')};
    margin-top: 8px;
    padding: 5px;
    font-size: 14px;
    background: transparent;
    :focus {
      border: 1px solid ${colors.orange};
    }
  }

  .errors {
    font-size: 0.8rem;
    margin-top: 1px;
  }
  .eye {
    color: ${colors.pink};
    position: absolute;
    bottom: 10%;
    right: 10px;
    cursor: pointer;
  }
`;

export const ButtonWhite = styled(ButtonContainer)`
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 8px;
  color: ${colors.pink};
  border: '#fff';
  width: 100%;
  height: 32px;
  span {
    color: ${colors.pink};
  }
  &:hover {
    background-color: #fff;
    span {
      color: ${colors.pink};
    }
  }
`;
