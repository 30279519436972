import React from 'react';
import { Wrapper } from './styles';
import Check from '../../../assets/svg/checkbox.svg';
import VideoHome from '../../../assets/video/NumeFullHD.mp4';

const SectionTwo = () => {
  return (
    <Wrapper>
      <div className="content">
        <div className="sectionOne">
          <div className="title">
            <h1>Organize suas obrigações fiscais de um modo rápido e fácil.</h1>
          </div>
          <div className="video">
            <video controls src={VideoHome}>
              <track default kind="captions" srcLang="pt-br" />
            </video>
          </div>
        </div>
        <div className="sectionTwo">
          <div className="description">
            <h1>Descomplicando a vida de quem é MEI</h1>
            <p>
              Se você fez um MEI para descomplicar a sua vida e esbarrou na
              burocracia, ou se você nem tem empresa, mas tem de gerar recibos
              de salário e calcular férias, FGTS e 13º de alguém que você
              emprega, a NUME veio agilizar essa burocracia e garantir que o
              simples seja realmente simples.
            </p>
          </div>

          <div className="border">
            <ul>
              <li>
                <img src={Check} alt="check" />
                <div>
                  <h2>Despesas</h2>
                  <p>Fazem toda a diferença na sua gestão.Nós te ajudamos.</p>
                </div>
              </li>
              <li>
                <img src={Check} alt="check" />
                <div>
                  <h2>Receitas</h2>
                  <p>Simples, claro e desmistificado. A gente organiza tudo.</p>
                </div>
              </li>
              <li>
                <img src={Check} alt="check" />
                <div>
                  <h2>Orientação para Alvará </h2>
                  <p>Sem complicações mesmo, a gente te orienta como fazer.</p>
                </div>
              </li>
              <li>
                <img src={Check} alt="check" />
                <div>
                  <h2>Declaração Anual </h2>
                  <p>Vai uma ajuda na declaração anual de faturamento?</p>
                </div>
              </li>
              <li>
                <img src={Check} alt="check" />
                <div>
                  <h2>DAS</h2>
                  <p>
                    Mais organização e disciplina para quem precisa de um
                    empurrão.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SectionTwo;
