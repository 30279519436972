import React from 'react';
import { Link } from 'react-router-dom';

import { IoLogoFacebook, IoLogoInstagram } from 'react-icons/io';
import { Wrapper } from './styles';

import Logo from '../../assets/svg/logoFooter.svg';
import AtendimentoIcon from '../../assets/svg/atendimentoIcon.svg';

const Footer = () => {
  function toTop() {
    window.scrollTo(0, 0);
  }

  return (
    <Wrapper>
      <div className="container">
        <Link to="/" onClick={toTop}>
          <img className="logo" src={Logo} alt="logo nume" />
        </Link>
        <ul>
          <li>
            <Link to="/abrir" onClick={toTop}>
              Abrir MEI
            </Link>
          </li>
          <li>
            <Link to="/planos" onClick={toTop}>
              Já Tenho MEI
            </Link>
          </li>
          {/* <li>
            <Link to="/" onClick={toTop}>
              Blog
            </Link>
          </li> */}
          <li>
            <Link to="/faq" onClick={toTop}>
              FAQ
            </Link>
          </li>
          <li>
            <a
              href="https://numebucket.s3.amazonaws.com/Termo_uso_11112020.pdf"
              target="__blank"
              rel="noreferer"
            >
              Termos de uso
            </a>
          </li>
        </ul>

        <div className="atendimento">
          <img src={AtendimentoIcon} alt="icone atendimento" />
          <div className="horaContainer">
            <p>
              Horário de atendimento:
              <br />
              Seg. à sex. 08:00 as 18:00h
            </p>
          </div>
        </div>

        <div className="redesSociais">
          <a
            href="https://www.facebook.com/pg/Nume-Contabilidade-Online-111366994337942"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoLogoFacebook color="#808080" size="40px" />
          </a>
          <a
            href=" https://www.instagram.com/numecontabil/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoLogoInstagram color="#808080" size="40px" />
          </a>
        </div>
      </div>
    </Wrapper>
  );
};

export default Footer;
