import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { size, device } from '../../../constants/size';
import womanJPG from '../../../assets/jpg/woman2.jpg';

export const Wrapper = styled.div`
  height: auto;
  #sectionOne {
    @media ${device.mobile} {
      height: 380px;
      background-size: 100%;
      background-position-x: 50%;
    }
    @media ${device.desktop} {
      height: 90vh;
      background-size: 100%;
      background-position: 0% 30%;
    }
    position: relative;
    width: 100%;
    background-image: url(${womanJPG});
    background-repeat: no-repeat;
    filter: grayscale(20%);
    /* &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 1;
    } */
    #bannerText {
      color: ${colors.white};
      font-size: 45px;
      @media ${device.mobile} {
        width: 30%;
        top: 20%;
        font-size: 20px;
        left: 15%;
      }
      @media ${device.desktop} {
        width: 21%;
        top: 30%;
        left: 20%;
      }
      z-index: 2;
      position: absolute;
      left: ${size.mobile.padding};
    }
    #logoMei {
      @media ${device.mobile} {
        top: 250px;
      }
      @media ${device.desktop} {
        top: calc(100% - 100px);
      }
      z-index: 2;
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 160px;
      height: 160px;
      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column;
      background: transparent linear-gradient(147deg, #f8863a 0%, #df3071 100%)
        0% 0% no-repeat padding-box;
      border-radius: 31px;
      p {
        padding-top: 10px;
        color: ${colors.white};
        font-weight: bold;
        font-size: 1.2em;
        text-align: center;
      }
      img {
        object-fit: contain;
        height: 50%;
      }
    }
  }
  #sectionTwo {
    flex-direction: column;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 50vh;
    padding: ${size.mobile.padding};

    #abrirMeiText {
      margin-top: ${size.mobile.margin};
      width: 100%;
    }
    #presentationText {
      width: 38%;
      opacity: 50%;
      @media (max-width: 1460px) {
        margin-top: 2em;
        padding-top: 2em;
      }
    }
    @media ${device.desktop} {
      #wrapperButtons {
        display: flex;
        width: 70%;
        height: 7em;
        align-items: center;
        justify-content: space-evenly;
      }
    }
    @media (max-width: 768px) {
      #wrapperButtons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 7em;
      }
    }
  }
  #sectionThree {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    @media ${device.desktop} {
      min-height: 100vh;
      flex-direction: row;
      background-color: ${colors.white};
      padding: ${size.desktop.padding};
    }
    @media ${device.mobile} {
    }
    #one {
      display: flex;
      align-items: center;
      justify-content: center;

      @media ${device.desktop} {
        width: 60%;
        height: 100%;
        flex-wrap: wrap;
      }
      @media ${device.mobile} {
        flex-direction: column;
        #computerPng {
          height: 50%;
          padding: ${size.mobile.padding};
        }
      }
      #computerPng {
        width: 85%;
      }
      #facaTourText {
        object-fit: contain;
        box-sizing: border-box;
        width: 80%;
      }
    }
    #two {
      width: 100%;
      @media ${device.desktop} {
        width: 40%;
        height: 100%;
      }
    }
  }
`;
