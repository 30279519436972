/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SideMenu from '../../Layout/User';

const PrivateRoute = ({
  component: Component,
  mobileComponent: MobileComponent,
  isAdm = false,
  ...rest
}) => {
  const AuthState = useSelector((state) => state.auth);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  return (
    <Route
      {...rest}
      render={() => {
        return isAdm ? (
          AuthState.isLoggedAdm ? (
            <>
              <Component />
            </>
          ) : (
            <Redirect to={{ pathname: '/' }} />
          )
        ) : AuthState.isLogged ? (
          <>
            {MobileComponent ? (
              windowWidth <= 769 ? (
                <MobileComponent />
              ) : (
                <SideMenu>
                  <Component />
                </SideMenu>
              )
            ) : (
              <SideMenu>
                <Component />
              </SideMenu>
            )}
          </>
        ) : (
          <Redirect to={{ pathname: '/' }} />
        );
      }}
    />
  );
};

export default PrivateRoute;
