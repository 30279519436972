import { Dialog } from '@material-ui/core';
import styled from 'styled-components';
import { colors } from '../../../constants/colors';

export const ContainerMobile = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .content {
    padding: 0 16px;
    header {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px solid #262626;
      margin-bottom: 15px;
      text-align: center;

      h3 {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 15px;

        strong {
          color: ${colors.orange};
        }
      }
      p {
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 15px;
      }
    }

    .plansContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 30px;
      }
    }
  }
`;

export const Plan = styled.div`
  color: ${(props) => (props.color ? props.color : colors.orange)};
  width: 100%;
  max-width: 400px;
  height: ${(props) => (props.active === true ? '580px' : '140px')};
  min-height: ${(props) => (props.active === true ? '580px' : '140px')};
  box-shadow: 0px 18px 36px rgba(17, 14, 27, 0.1),
    0px 5.42647px 10.8529px rgba(17, 14, 27, 0.065102),
    0px 2.25388px 4.50776px rgba(17, 14, 27, 0.0499628),
    0px 0.815184px 1.63037px rgba(17, 14, 27, 0.0348159);
  border-radius: 16px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.3s;

  .header {
    margin-top: 20px;
    h1 {
      font-weight: 600;
      font-size: 24px;
      line-height: 38px;
    }
    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      margin: 0 !important;
    }
    .arrow {
      transition: transform 0.5s linear;
      transform: rotate(180deg);
    }
    .back {
      transition: transform 0.5s linear;
      transform: rotate(360deg);
    }
  }

  .content {
    width: 70%;
    height: 70%;
    .description {
      margin: 10px 0;
      p {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #262626;
        margin: 0 !important;
      }
    }

    ul {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      li {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #262626;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .ico {
          width: 20%;
          height: 21px;
        }
        span {
          width: 80%;
          text-align: left;
        }
      }
    }
  }
  .open {
    display: flex;
    flex-direction: column;
    height: 75%;
  }
  .close {
    display: none;
  }
`;

export const Modal = styled(Dialog)`
  & .MuiPaper-root {
    background-color: #fff;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    padding: 18px;
  }

  .containerModal {
    max-width: 325px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      text-align: center;
      font-size: 22px;
    }
    p {
      text-align: center;
      font-size: 14px;
      margin: 10px 0;
    }
  }
`;
