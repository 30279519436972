// import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { valiteCPF } from './validateCPF';

// const users = useSelector((state) => state.auth.user);

const maxDate = new Date();

const schema = Yup.object({
  name_employee: Yup.string()
    .max(255, 'Este campo deve ter no máximo 255 caracteres')
    .required('Este campo é obrigatório'),
  sex: Yup.string().required('Este campo é obrigatório'),
  father_name: Yup.string()
    .max(255, 'Este campo deve ter no máximo 255 caracteres')
    .required('Este campo é obrigatório'),
  mother_name: Yup.string()
    .max(255, 'Este campo deve ter no máximo 255 caracteres')
    .required('Este campo é obrigatório'),
  birth_date: Yup.date()
    .max(
      new Date(maxDate),
      `Deve ser anterior a ${maxDate.toLocaleDateString()}`,
    )
    .required('Este campo é obrigatório'),
  birth_city: Yup.string()
    .min(4, 'Este campo deve ter no minimo 4 caracteres')
    .max(26, 'Este campo deve ter no máximo 26 caracteres')
    .required('Este campo é obrigatório'),
  birth_uf: Yup.string()
    .min(2, 'Este campo deve ter 2 caracteres')
    .max(2, 'Este campo deve ter 2 caracteres')
    .required('Este campo é obrigatório'),
  civil_state: Yup.string().required('Este campo é obrigatório'),
  civil_condition: Yup.string(),
  ctps_number: Yup.string()
    .min(7, 'Este campo deve ter 7 caracteres')
    .max(7, 'Este campo deve ter 7 caracteres')
    // .test('lengthTest', 'Este campo deve ter 7 caracteres', (value) => {
    //   return value.length === 7;
    // })
    .required('Este campo é obrigatório'),
  ctps_serie: Yup.string()
    .min(4, 'Este campo deve ter 4 caracteres')
    .max(4, 'Este campo deve ter 4 caracteres')
    // .test('lengthTest', 'Este campo deve ter 4 caracteres', (value) => {
    //   return value.length === 4;
    // })
    .required('Este campo é obrigatório'),
  ctps_emission_uf: Yup.string()
    .min(2, 'Este campo deve ter 2 caracteres')
    .max(2, 'Este campo deve ter 2 caracteres')
    .required('Este campo é obrigatório'),
  ctps_emission_date: Yup.date()
    .max(
      new Date(maxDate),
      `Deve ser anterior a ${maxDate.toLocaleDateString()}`,
    )
    .required('Este campo é obrigatório'),
  instruction_rank: Yup.string().required('Este campo é obrigatório'),
  pis_number: Yup.string()
    .min(11, 'Este campo deve ter 11 caracteres')
    .max(11, 'Este campo deve ter 11 caracteres')
    // .test('lengthTest', 'Este campo deve ter 11 caracteres', (value) => {
    //   formik.values.pis_number = numberMask(formik.values.pis_number);
    //   return formik.values.pis_number.length === 11;
    // })
    .required('Este campo é obrigatório'),
  ci_number: Yup.string()
    .min(9, 'Este campo deve ter no minimo 9 caracteres')
    .max(11, 'Este campo deve ter no máximo 11 caracteres')
    // .test(
    //   'lengthTest',
    //   'Este campo deve ter entre 9 e 11 caracteres',
    //   (value) => {
    //     formik.values.ci_number = numberMask(formik.values.ci_number);
    //     return (
    //       formik.values.ci_number.length >= 9 &&
    //       formik.values.ci_number.length <= 11
    //     );
    //   },
    // )
    .matches(/\d+/g)
    .required('Este campo é obrigatório'),
  ci_emission_date: Yup.date()
    .max(
      new Date(maxDate),
      `Deve ser anterior a ${maxDate.toLocaleDateString()}`,
    )
    .required('Este campo é obrigatório'),
  ci_institution_emission: Yup.string().required('Este campo é obrigatório'),
  ci_uf_emission: Yup.string()
    .min(2, 'Este campo deve ter 2 caracteres')
    .max(2, 'Este campo deve ter 2 caracteres')
    .required('Este campo é obrigatório'),
  cpf: Yup.string()
    .min(11, 'Este campo deve ter 11 caracteres')
    .max(11, 'Este campo deve ter 11 caracteres')
    .test('ValiteTest', 'Este cpf é invalido', (value) => valiteCPF(value))
    // .test('lengthTest', 'Este campo deve ter 11 caracteres', (value) => {
    //   formik.values.cpf = numberMask(formik.values.cpf);
    //   return formik.values.cpf.length === 11;
    // })
    .matches(/\d+/g)
    .required('Este campo é obrigatório'),
  eleitoral_number: Yup.string()
    .min(12, 'Este campo deve ter 12 caracteres')
    .max(12, 'Este campo deve ter 12 caracteres')
    // .test('lengthTest', 'Este campo deve ter 12 caracteres', (value) => {
    //   formik.values.eleitoral_number = numberMask(
    //     formik.values.eleitoral_number,
    //   );
    //   return formik.values.eleitoral_number.length === 12;
    // })
    .matches(/\d+/g)
    .required('Este campo é obrigatório'),
  eleitoral_number_emission_date: Yup.date()
    .max(
      new Date(maxDate),
      `Deve ser anterior a ${maxDate.toLocaleDateString()}`,
    )
    .required('Este campo é obrigatório'),
  eleitoral_number_zone: Yup.string().required('Este campo é obrigatório'),
  eleitoral_number_session: Yup.string().required('Este campo é obrigatório'),
  zip_code: Yup.string()
    .min(8, 'Este campo deve ter 8 caracteres')
    .max(8, 'Este campo deve ter 8 caracteres')
    // .test('lengthTest', 'Este campo deve ter 8 caracteres', (value) => {
    //   formik.values.zip_code = numberMask(formik.values.zip_code);
    //   return formik.values.zip_code.length === 8;
    // })
    .matches(/\d+/g)
    .required('Este campo é obrigatório'),
  neighborhood: Yup.string().required('Este campo é obrigatório'),
  street_name: Yup.string().required('Este campo é obrigatório'),
  street_number: Yup.string().required('Este campo é obrigatório'),
  function: Yup.string().required('Este campo é obrigatório'),
  activity: Yup.string().required('Este campo é obrigatório'),
  company_admission_date: Yup.date()
    .max(
      new Date(maxDate),
      `Deve ser anterior a ${maxDate.toLocaleDateString()}`,
    )
    .required('Este campo é obrigatório'),
  income: Yup.string().required('Este campo é obrigatório'),
  insalubrity: Yup.string().required('Este campo é obrigatório'),
  ticket_transport: Yup.string().required('Este campo é obrigatório'),
  ticket_quantity: Yup.string().required('Este campo é obrigatório'),
  medical_test_date: Yup.date()
    .max(
      new Date(maxDate),
      `Deve ser anterior a ${maxDate.toLocaleDateString()}`,
    )
    .required('Este campo é obrigatório'),
  crm: Yup.string().required('Este campo é obrigatório'),
  doctor_name: Yup.string().required('Este campo é obrigatório'),
  experience_days_contract: Yup.string().required('Este campo é obrigatório'),
});

const childSchema = Yup.object().shape({
  name: Yup.string()
    .max(255, 'Este campo deve ter no máximo 255 caracteres')
    .required('Este campo é obrigatório'),
  cpf: Yup.string()
    .matches(/\d*/g, 'Só são permitindo números')
    .min(11, 'Este campo deve ter 11 caracteres')
    .max(11, 'Este campo deve ter 11 caracteres')
    .nullable()
    .test(
      'valiteCpf',
      'CPF invalido',
      (value) => value === '' || value === undefined || valiteCPF(value),
    ),
  birth_date: Yup.date()
    .typeError('Este campo é obrigatório')
    .max(
      new Date(maxDate),
      `Deve ser anterior a ${maxDate.toLocaleDateString()}`,
    )
    .required('Este campo é obrigatório'),
});

export function initial(users) {
  const initialValues = {
    name_employee: users.employee ? users.employee.name_employee : '',
    sex: users.employee ? users.employee.sex : '',
    father_name: users.employee ? users.employee.father_name : '',
    mother_name: users.employee ? users.employee.mother_name : '',
    birth_date: users.employee ? users.employee.birth_date.split('T')[0] : '',
    birth_city: users.employee ? users.employee.birth_city : '',
    birth_uf: users.employee ? users.employee.birth_uf : '',
    civil_state: users.employee ? users.employee.civil_state : '',
    civil_condition: users.employee ? users.employee.civil_condition : '',
    ctps_number: users.employee ? users.employee.ctps_number : '',
    ctps_serie: users.employee ? users.employee.ctps_serie : '',
    ctps_emission_uf: users.employee ? users.employee.ctps_emission_uf : '',
    ctps_emission_date: users.employee
      ? users.employee.ctps_emission_date.split('T')[0]
      : '',
    instruction_rank: users.employee ? users.employee.instruction_rank : '',
    pis_number: users.employee ? users.employee.pis_number : '',
    ci_number: users.employee ? users.employee.ci_number : '',
    ci_emission_date: users.employee
      ? users.employee.ci_emission_date.split('T')[0]
      : '',
    ci_institution_emission: users.employee
      ? users.employee.ci_institution_emission
      : '',
    ci_uf_emission: users.employee ? users.employee.ci_uf_emission : '',
    cpf: users.employee ? users.employee.cpf : '',
    eleitoral_number: users.employee ? users.employee.eleitoral_number : '',
    eleitoral_number_emission_date: users.employee
      ? users.employee.eleitoral_number_emission_date.split('T')[0]
      : '',
    eleitoral_number_zone: users.employee
      ? users.employee.eleitoral_number_zone
      : '',
    eleitoral_number_session: users.employee
      ? users.employee.eleitoral_number_session
      : '',
    zip_code: users.employee ? users.employee.zip_code : '',
    neighborhood: users.employee ? users.employee.neighborhood : '',
    street_name: users.employee ? users.employee.street_name : '',
    street_number: users.employee ? users.employee.street_number : '',
    function: users.employee ? users.employee.function : '',
    activity: users.employee ? users.employee.activity : '',
    company_admission_date: users.employee
      ? users.employee.company_admission_date.split('T')[0]
      : '',
    income: users.employee ? users.employee.income : '',
    insalubrity: users.employee ? users.employee.insalubrity : '',
    ticket_transport: users.employee ? users.employee.ticket_transport : '',
    ticket_quantity: users.employee ? users.employee.ticket_quantity : '',
    medical_test_date: users.employee
      ? users.employee.medical_test_date.split('T')[0]
      : '',
    crm: users.employee ? users.employee.crm : '',
    doctor_name: users.employee ? users.employee.doctor_name : '',
    experience_days_contract: users.employee
      ? users.employee.experience_days_contract
      : '',
  };

  return initialValues;
}

export { schema, childSchema };
