import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { size, device } from '../../../constants/size';

export const Wrapper = styled.div`
  width: 100%;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;

    .sectionOne {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      background-color: #e54663;
      padding: ${size.mobile.padding};

      .title {
        display: flex;
        justify-content: flex-start;
        width: 86%;

        h1 {
          font-size: ${size.mobile.fontSize};
          color: #fff;
        }
      }
      .video {
        margin-top: 36px;
        width: 100%;

        video {
          width: 100%;
          height: 40vh;
          outline: none;
        }
      }
    }

    .sectionTwo {
      margin: 20px 0;
      .description {
        padding: ${size.mobile.padding};
        h1 {
          color: ${colors.orange};
        }
        p {
          color: #707070;
          font-size: ${size.mobile.fontSize};
          line-height: 32px;
        }
      }

      .border {
        border-left: 12px solid ${colors.orange};
        ul {
          padding: ${size.mobile.padding};
          width: 100%;
          li {
            display: flex;
            align-items: baseline;
            margin-top: 15px;

            img {
              margin-right: 10px;
            }

            div {
              h2 {
                font-size: ${size.mobile.fontSize};
                color: ${colors.orange};
              }
              p {
                font-size: 18px;
                color: ${colors.darkGray};
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }

  @media ${device.desktop} {
    .content {
      .sectionOne {
        padding: ${size.desktop.padding};
        .title {
          width: 50%;
          h1 {
            font-size: 36px;
          }
        }
        .video {
          video {
            height: 70vh;
          }
        }
      }

      .sectionTwo {
        padding: ${size.desktop.padding};
        display: flex;
        justify-content: space-between;

        .description {
          border-left: 12px solid ${colors.orange};
          width: 50%;
          h1 {
            font-size: 48px;
            word-break: break-word;
          }
        }

        .border {
          border: none;
          width: 50%;
        }
      }
    }
  }
`;
