import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import { colors } from '../../../constants/colors';

export const ContainerMobile = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .content {
    width: 100%;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .graphicContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .actions {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #262626;

        .yearContainer {
          margin-right: 7px;
          width: 97px;
          height: 30px;
          border: 1px solid #262626;
          box-sizing: border-box;
          border-radius: 8px;
          display: flex;
          align-items: center;
          padding: 5px;
          position: relative;

          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          p {
          }

          input {
            position: absolute;
            outline: none;
            width: 100%;
            height: 100%;
            padding-left: 33px;
            background: transparent;
            border: none;
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
          }

          input[type='number'] {
            -moz-appearance: textfield;
          }
        }
        select {
          margin-left: 7px;
          width: 97px;
          height: 30px;
          border: 1px solid #262626;
          box-sizing: border-box;
          border-radius: 8px;
          background: transparent;
          outline: none;
        }
        margin-bottom: 30px;
      }
      .graphicContent {
        box-shadow: 0px 18px 36px rgba(17, 14, 27, 0.1),
          0px 5.42647px 10.8529px rgba(17, 14, 27, 0.065102),
          0px 2.25388px 4.50776px rgba(17, 14, 27, 0.0499628),
          0px 0.815184px 1.63037px rgba(17, 14, 27, 0.0348159);
        border-radius: 16px;
        width: 100%;
        height: 350px;
        padding-top: 20px;
        margin-bottom: 10px;
      }
    }

    .transactionsList {
      width: 100%;
      nav {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .options {
          span {
            font-weight: normal;
            font-size: 15px;
            line-height: 22px;
            color: #262626;
            &:first-child {
              margin-right: 5px;
            }
          }
          .selected {
            color: #ff6d24;
            font-weight: 600;
          }
        }
      }
      ul.transactionsList {
        display: flex;
        flex-direction: column;
        gap: 1em;
        list-style: none;
        padding: 1em 0 0 0;
        li.transaction {
          position: relative;
          font-size: 14px;
          height: 4em;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &::after {
            margin-top: 15px;
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: black;
            content: '';
            display: block;
          }
          &::after {
            bottom: 0;
          }
          div {
            display: flex;
            align-items: center;
            gap: 2em;
          }
          .optionsContainer {
            right: 20px;
            position: absolute;
            display: inline-block;
            .options {
              display: none;
              position: absolute;
              top: -10;
              right: 0px;
              list-style: none;
              width: 100px;
              background: #ffffff;
              box-shadow: 0px 18px 36px rgba(17, 14, 27, 0.1),
                0px 5.42647px 10.8529px rgba(17, 14, 27, 0.065102),
                0px 2.25388px 4.50776px rgba(17, 14, 27, 0.0499628),
                0px 0.815184px 1.63037px rgba(17, 14, 27, 0.0348159);
              border-radius: 8px;
              flex-direction: column;
              justify-content: space-around;
              align-items: flex-start;
              z-index: 1;
              &.dropOpen {
                display: flex;
              }
              & li {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-left: 15px;
                cursor: pointer;
                width: 100%;
                height: 30px;
                &:hover {
                  background-color: ${colors.orangeHover};
                  color: #ffffff;
                }
              }
            }
          }
        }
      }

      margin-bottom: 30px;
    }
  }
`;

export const Modal = styled(Dialog)`
  & .MuiPaper-root {
    background-color: #fff;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    padding: 18px;
  }

  .popConfirmContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    h3 {
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      color: ${colors.darkOrange};
    }
    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      margin: 5px 0px;
    }
    .actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .container {
    text-align: center;
    h1 {
      font-size: 22px;
    }
    p {
      font-size: 14px;
      margin: 10px 0;
    }
    li {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      img {
        height: 20px;
        margin-right: 5px;
      }
      span {
        font-size: 14px;
      }
    }
  }

  .transactionContainer {
    width: 100%;
    display: flex;
    flex-direction: column;

    nav {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .inputContainer {
      margin: 10px 0;
    }
  }
`;
