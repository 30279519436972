import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Container, Mobile, Desktop } from './styles';

import Button from '../Button';

import logoMobile from '../../assets/svg/logoHeaderMob.svg';
import menuMobile from '../../assets/svg/menu.svg';

import logoDesktop from '../../assets/svg/logoHeaderDesktop.svg';

function Header() {
  const [toogle, setToogle] = useState(false);
  const history = useHistory();

  return (
    <Container>
      <Mobile>
        <div className={`menu ${toogle && 'expand'}`}>
          <div id="head">
            <Link to="/">
              <img src={logoMobile} alt="logo Nume" />
            </Link>
            {/* eslint-disable-next-line */}
            <div id="closeButton" onClick={()=>setToogle(false)} />
          </div>
          <div id="list">
            <ul>
              <li>
                <Link to="/abrir">Abrir MEI</Link>
              </li>
              <li>
                <Link to="/planos">Já tenho MEI</Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: '/planos',
                    state: { id: 'planos' },
                  }}
                >
                  Planos
                </Link>
              </li>
              {/* <li>
                <Link to="/">Blog Nume</Link>
              </li> */}
              <li>
                <Link to="/faq">FAQ</Link>
              </li>
              <li>
                <Button
                  onClick={() => history.push('/login')}
                  width={window.innerWidth <= 768 ? '186px' : '15%'}
                  className="button"
                  text="Entrar"
                />
              </li>
            </ul>
          </div>
        </div>
        <div id="imageWrapper">
          <Link to="/">
            <img src={logoMobile} alt="logo Nume" />
          </Link>
        </div>
        {/* eslint-disable-next-line */}
        <div
          onClick={() => setToogle(true)}
          id="buttonWrapper"
        >
          <img src={menuMobile} alt="expandir menu" />
        </div>
      </Mobile>
      <Desktop>
        <div id="imageWrapper">
          <Link to="/">
            <img src={logoDesktop} alt="logo Nume" />
          </Link>
        </div>
        <ul>
          <li>
            <Link to="/abrir">Abrir MEI</Link>
          </li>
          <li>
            <Link to="/planos">Ja Tenho MEI</Link>
          </li>
          <li>
            <Link
              to={{
                pathname: '/planos',
                state: { id: 'planos' },
              }}
            >
              Planos
            </Link>
          </li>
          {/* <li>
            <Link to="/">Blog Nume</Link>
          </li> */}
          <li>
            <Link to="/faq">FAQ</Link>
          </li>
        </ul>
        <Button
          onClick={() => history.push('/login')}
          width={window.innerWidth <= 768 ? '186px' : '15%'}
          className="button"
          text="Entrar"
        />
      </Desktop>
    </Container>
  );
}

export default Header;
