import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage/session';
import createSagaMiddleware from 'redux-saga';
import reducers from './ducks/rootReducer';
import rootSaga from './ducks/rootSagas';

const persistConfig = {
  key: 'root',
  storage,
  transforms: [
    encryptTransform({
      secretKey: 'my-super-secret-key',
      onError: () => {
        sessionStorage.clear();
      },
    }),
  ],
};

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const pReducer = persistReducer(persistConfig, reducers);

const store = createStore(pReducer, applyMiddleware(...middlewares));
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
