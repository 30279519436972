import styled from 'styled-components';

import { colors } from '../../constants/colors';

import { size, device } from '../../constants/size';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  .container {
    width: 100%;
    padding: 0 ${size.mobile.padding};
    display: grid;
    grid-template-areas: 'ul .' 'logo redes';
    justify-content: space-between;
    margin: ${size.mobile.margin} 0;
  }

  a {
    grid-area: logo;
  }

  ul {
    grid-area: ul;
    list-style: none;
    margin-bottom: 5px;

    li {
      color: ${colors.darkGray};
      cursor: pointer;
      font-size: ${size.mobile.fontSize};
      text-align: center;
      width: fit-content;
    }
  }

  .atendimento {
    display: none;
  }

  .redesSociais {
    grid-area: redes;
    display: flex;
    flex-wrap: nowrap;
  }

  @media ${device.desktop} {
    height: 150px;
    .container {
      padding: 0 ${size.desktop.padding};
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    ul {
      width: 45%;
      display: flex;
      justify-content: space-between;
      margin: 0;
    }

    .redesSociais {
      display: flex;
      flex-wrap: nowrap;
    }
  }

  @media (min-width: 965px) {
    .atendimento {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      p {
        color: ${colors.darkGray};
        font-size: 18px;
        line-height: 22px;
        margin-left: 5px;
      }
    }
  }
`;
