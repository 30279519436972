import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { ToastContainer, toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router-dom';
import { MobileContainer } from './styles';
import HeaderMobileLogin from '../../../components/Mobile/HeaderLogin';
import { ButtonWhite, InputField } from '../RecuperarSenha/styles';
import api from '../../../services/api';

const NovaSenhaMobile = () => {
  const params = useParams();
  const { push } = useHistory();
  const [showPassword, setShowPassword] = useState(true);

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmed: '',
    },
    validationSchema: yup.object().shape({
      password: yup
        .string()
        .min(8, 'A senha deve ter no mínimo 8 caracteres')
        .required('É necessário uma senha'),
      passwordConfirmed: yup
        .string()
        .oneOf([yup.ref('password'), null], 'As senhas não coincidem '),
    }),
    onSubmit: async (values) => {
      await api
        .put(`/reset-user-password/${params.token}`, {
          password: values.password,
        })
        .then(() => {
          toast.success('Senha alterada com sucesso!!');
          setTimeout(() => {
            push('/login');
          }, 5000);
        })
        .catch((error) => {
          if (error.response.data) {
            toast.error('Token para redefinir senha inválido');
          }
          return false;
        });
    },
  });

  return (
    <MobileContainer>
      <ToastContainer />
      <HeaderMobileLogin />
      <div className="container">
        <div className="message">
          <strong>Redefinir senha</strong>
          <p>Não se preocupe, basta nos informar sua nova senha</p>
        </div>
        <div className="inputContainer">
          <InputField>
            <p>Senha</p>
            <input
              type={showPassword ? 'password' : 'text'}
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="errors">{formik.errors.password}</div>
            ) : null}
            {showPassword ? (
              <BsEyeFill
                onClick={() => setShowPassword(!showPassword)}
                className="eye"
              />
            ) : (
              <BsEyeSlashFill
                onClick={() => setShowPassword(!showPassword)}
                className="eye"
              />
            )}
          </InputField>

          <InputField>
            <p>Confirme a senha</p>
            <input
              type="password"
              name="passwordConfirmed"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.passwordConfirmed}
            />
            {formik.touched.passwordConfirmed &&
            formik.errors.passwordConfirmed ? (
              <div className="errors">{formik.errors.passwordConfirmed}</div>
            ) : null}
          </InputField>

          <ButtonWhite onClick={formik.handleSubmit}>
            <span>Redefinir senha</span>
          </ButtonWhite>
        </div>
      </div>
    </MobileContainer>
  );
};

export default NovaSenhaMobile;
