import React from 'react';

import { Container } from './styles';

function Footer() {
  return (
    <Container>
      <a
        href="https://numebucket.s3.amazonaws.com/Termo_uso_11112020.pdf"
        target="__blank"
        rel="noreferer"
      >
        Termos de uso
      </a>

      <a
        href="https://numebucket.s3.amazonaws.com/Declara%C3%A7%C3%A3o+de+privacidade-NUME+(1).pdf"
        target="__blank"
        rel="noreferer"
      >
        Políticas de privacidade
      </a>
    </Container>
  );
}

export default Footer;
