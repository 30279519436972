import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { Wrapper } from './styles';

const SectionTwo = () => {
  const [Arrow, setArrow] = useState(0);

  function arrowPosition(number) {
    if (Arrow === number) {
      setArrow(0);
    } else {
      switch (number) {
        case 1:
          setArrow(1);
          break;

        case 2:
          setArrow(2);
          break;

        case 3:
          setArrow(3);
          break;

        case 4:
          setArrow(4);
          break;

        case 5:
          setArrow(5);
          break;

        case 6:
          setArrow(6);
          break;

        case 7:
          setArrow(7);
          break;

        default:
          break;
      }
    }
  }

  return (
    <Wrapper>
      <div className="sectionTwo">
        <ul>
          <li className={Arrow === 1 ? 'arrowUp' : ''}>
            <div className="quest">
              <h3>O Que é a NUME?</h3>
              <IoIosArrowDown
                className="arrow"
                onClick={() => arrowPosition(1)}
                size={45}
              />
            </div>
            <div className="response">
              A NUME é uma contabilidade online, feita para ser simplificada ao
              máximo e ajudar a sua empresa a reduzir os custos. Tudo isso
              enquanto mantém a eficiência e te proporciona um atendimento
              amigável.
            </div>
          </li>

          <li className={Arrow === 2 ? 'arrowUp' : ''}>
            <div className="quest">
              <h3>Porque a NUME promete tanta facilidade?</h3>
              <IoIosArrowDown
                className="arrow"
                onClick={() => arrowPosition(2)}
                size={45}
              />
            </div>
            <div className="response">
              Porque é possível usar a mobilidade em seu favor para resolver
              questões de contabilidade, por meio do app da Nume. Relatórios e
              insights ao alcance da mão, com o mínimo de burocracia.
            </div>
          </li>
          <li className={Arrow === 3 ? 'arrowUp' : ''}>
            <div className="quest">
              <h3>A NUME também atende MEI?</h3>
              <IoIosArrowDown
                className="arrow"
                onClick={() => arrowPosition(3)}
                size={45}
              />
            </div>
            <div className="response">
              Com certeza! Com a Nume, você pode abrir MEI, comprovar renda e
              contribuir para o INSS em poucos cliques.
            </div>
          </li>
          <li className={Arrow === 4 ? 'arrowUp' : ''}>
            <div className="quest">
              <h3>
                Geralmente, MEI é so uma pessoa... Mesmo assim, precisa de
                contabilidade?
              </h3>
              <IoIosArrowDown
                className="arrow"
                onClick={() => arrowPosition(4)}
                size={45}
              />
            </div>
            <div className="response">
              Não acumular demandas em excesso e contar com uma assistência
              especializada é importante para que você possa focar no sucesso do
              seu empreendimento. A Nume está preparada para te orientar na
              abertura de empresa, na organização dos pagamentos, realização das
              declarações e tirar todas as suas dúvidas sobre a categoria (ou
              para mudar, se o seu objetivo é fazer com que a empresa cresça
              cada vez mais).
            </div>
          </li>
          <li className={Arrow === 5 ? 'arrowUp' : ''}>
            <div className="quest">
              <h3>A NUME atende apenas empresas da categoria MEI?</h3>
              <IoIosArrowDown
                className="arrow"
                onClick={() => arrowPosition(5)}
                size={45}
              />
            </div>
            <div className="response">
              Não, também há soluções para empregadores rurais e domésticos.
            </div>
          </li>
          <li className={Arrow === 6 ? 'arrowUp' : ''}>
            <div className="quest">
              <h3>
                Quais são as vantagens para empregadores rurais e domésticos?
              </h3>
              <IoIosArrowDown
                className="arrow"
                onClick={() => arrowPosition(6)}
                size={45}
              />
            </div>
            <div className="response">
              Quem é empregador rural, lida com a possibilidade de regularizar a
              situação do empregado em algum momento. Não precisa de um contador
              apenas para isso, é só usar o app e, em caso de dúvidas, solicitar
              o auxílio da equipe da Nume!
            </div>
          </li>
          <li className={Arrow === 7 ? 'arrowUp' : ''}>
            <div className="quest">
              <h3>É possível regularizar as questões principais?</h3>
              <IoIosArrowDown
                className="arrow"
                onClick={() => arrowPosition(7)}
                size={45}
              />
            </div>
            <div className="response">
              Sim, perfeitamente possível. Você pode gerar recibos, registrar no
              eSocial, resolver questões de FGTS, INSS, afastamentos, entre
              outras. A melhor é parte é a possibilidade de fazer tudo isso e
              muito mais sem sair de casa ou do escritório… com menos
              burocracias (bem menos, acredite).
            </div>
          </li>
        </ul>
      </div>
    </Wrapper>
  );
};

export default SectionTwo;
