/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import * as yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { LoginRequest } from '../../store/ducks/auth/auth';
import { Wrapper, ButtonBack, ButtonRegister } from './styles';
import Button from '../../components/Button';
import Logo from '../../assets/svg/logoHeaderMob.svg';
import userIcon from '../../assets/svg/user.svg';
import leftArrow from '../../assets/svg/left-arrow.svg';
import Loading from '../../components/Loading';

import { colors } from '../../constants/colors';

const Login = () => {
  const [showPassword, setShowPassword] = useState('password');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState('');

  const history = useHistory();
  const dispatch = useDispatch();
  const schema = yup.object().shape({
    email: yup
      .string()
      .email('Formato de email invalido')
      .required('É necessário um email'),
    password: yup
      .string()
      .min(8, 'A senha deve ter no mínimo 8 caracteres')
      .required('É necessário uma senha'),
  });
  const isLogged = useSelector((state) => state.auth.isLogged);
  const Errors = useSelector((state) => state.auth.error);

  useEffect(() => {
    if (isLogged) {
      setIsLoading(false);
      history.push('/dashboard');
    }
    if (Errors) {
      setIsLoading(false);
      if (
        Errors === 'Invalid email/password combination' ||
        Errors === 'Invalid email/password combination'
      ) {
        setErrors('Combinação de e-mail e senha inválidos');
      } else if (Errors === 'Please confirm your account by email') {
        setErrors(
          'Por favor , acesse seu email e confirme a criação de conta na Nume',
        );
      } else {
        setErrors(Errors);
      }
    }
  }, [isLogged, Errors]);

  function handleShowPassword() {
    if (showPassword === 'password') {
      setShowPassword('text');
    } else {
      setShowPassword('password');
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const data = { email, password };

    try {
      await schema.validate(data);
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        error.errors.map((err) => setErrors(err));
      }
      return false;
    }

    setIsLoading(true);

    dispatch(LoginRequest(data.email, data.password));
    sessionStorage.setItem('showPopUpExpired', true);
    sessionStorage.setItem('showPopUp', true);

    return true;
  }

  return (
    <Wrapper>
      <Loading open={isLoading} />
      <Helmet>
        <title>Nume | Simplifique seu MEI</title>
      </Helmet>
      <div className="content">
        <nav>
          <ButtonBack
            image={leftArrow}
            backgroundColor={colors.white}
            border={colors.white}
            color={colors.pink}
            width="200px"
            height="50px"
            onClick={() => {
              history.push('/');
            }}
          >
            <img src={leftArrow} alt="icone" />
            <span>Voltar</span>
          </ButtonBack>

          <ButtonRegister
            image={userIcon}
            backgroundColor="transparent"
            border="#fff"
            width="200px"
            height="50px"
            onClick={() => history.push('/cadastrar')}
          >
            <img src={userIcon} alt="icone" />
            <span>Criar conta</span>
          </ButtonRegister>
        </nav>
        <div className="container">
          <div className="containerLogin">
            <div className="logo">
              <img src={Logo} alt="logo Nume" />
            </div>
            {errors ? <p className="errors">{errors}</p> : null}
            <div className="containerInputs">
              <div className="field-set">
                <p>E-mail</p>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>

              <div className="field-set" id="pass">
                <p>Senha</p>
                <input
                  type={showPassword}
                  name="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                {showPassword === 'password' ? (
                  <BsEyeSlashFill
                    onClick={handleShowPassword}
                    className="eye"
                  />
                ) : (
                  <BsEyeFill onClick={handleShowPassword} className="eye" />
                )}
              </div>
            </div>
            <div className="actions">
              <Button
                type="submit"
                onClick={handleSubmit}
                width="200px"
                text="Entrar"
              />
              <Link to="/recuperarSenha">Esqueci minha senha</Link>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Login;
