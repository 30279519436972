/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AiOutlineCaretRight } from 'react-icons/ai';
import { Container } from './styles';
import { colors } from '../../../constants/colors';

function SelectNavigator({ page }) {
  const [isShowedOptions, setIsShowedOptions] = React.useState(false);
  const { push } = useHistory();
  const user = useSelector((state) => state.auth.user);

  return (
    <Container>
      <div onClick={() => setIsShowedOptions(!isShowedOptions)}>
        <strong>{page}</strong>

        <AiOutlineCaretRight
          size={20}
          color="#000"
          style={
            isShowedOptions
              ? { transform: 'rotate(270deg)' }
              : { transform: 'rotate(90deg)' }
          }
        />
      </div>
      {isShowedOptions && (
        <ul>
          {!user.mei && (
            <li
              style={{
                color: `${colors.pink}`,
              }}
            >
              <a
                target="_blank"
                href="https://www.gov.br/empresas-e-negocios/pt-br/empreendedor/quero-ser-mei"
              >
                Criar MEI
              </a>
            </li>
          )}
          <li
            style={{
              color: page === 'Receitas e Despesas' ? '#EE7200' : '#000',
            }}
            onClick={() => push('/dashboard')}
          >
            Receitas e Despesas
          </li>
          <li
            style={{
              color: page === 'Planos' ? '#EE7200' : '#000',
            }}
            onClick={() => push('/dashboard/planos')}
          >
            Planos
          </li>
          {user.mei && (
            <li>
              <a
                href="http://www8.receita.fazenda.gov.br/simplesnacional/aplicacoes/atspo/dasnsimei.app/default.aspx"
                target="_blank"
                rel="noreferrer"
              >
                Declaração anual do MEI
              </a>
            </li>
          )}

          {user.mei && (
            <li>
              <a
                href="http://www8.receita.fazenda.gov.br/SimplesNacional/Aplicacoes/ATSPO/pgmei.app/Identificacao"
                target="_blank"
                rel="noreferrer"
              >
                DAS
              </a>
            </li>
          )}
          {user.mei && (
            <li>
              <a
                href="https://servicos.receita.fazenda.gov.br/Servicos/certidao/CNDConjuntaInter/InformaNICertidao.asp?tipo=2"
                target="_blank"
                rel="noreferrer"
              >
                CND
              </a>
            </li>
          )}
          {user.mei && (
            <li
              style={{
                color: page === 'Dados Mei' ? '#EE7200' : '#000',
              }}
              onClick={() => push('/dashboard/dados-mei')}
            >
              Dados Mei
            </li>
          )}
          {user.mei && user.plan.name === 'Premium' && (
            <li
              style={{
                color: page === 'Cadastro de admissão' ? '#EE7200' : '#000',
              }}
              onClick={() => push('/dashboard/employeeRegistration')}
            >
              Cadastro de admissão
            </li>
          )}
        </ul>
      )}
    </Container>
  );
}

export default SelectNavigator;
