import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const Content = styled.div`
  width: 100%;
  height: 100vh;
  header {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 12px rgba(17, 14, 27, 0.13);
    padding: 24px;

    h1 {
      font-size: 32px;
      color: ${colors.darkOrange};
    }
  }

  .container {
    height: calc(100% - 100px);
    overflow-y: scroll;
    padding: 24px;

    section {
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: ${colors.mediumGray};
      }
      margin-top: 30px;
      h1 {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: ${colors.darkOrange};
      }
      .inputContainer {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;

        fieldset {
          height: 57px;
          width: 30%;
          border: none;
          margin-top: 10px;
          position: relative;

          p {
            font-size: 14px;
            color: ${colors.mediumGray};
          }

          input,
          select {
            border: none;
            border-bottom: 1px solid ${colors.mediumGray};
            color: ${colors.mediumGray};
            width: 100%;
            outline: none;
            height: 20px;
            margin-top: 8px;
            font-size: 14px;
            background-color: transparent;

            :focus {
              border-bottom: 1px solid ${colors.orange};
            }
          }
        }
        .errors {
          font-size: 0.8rem;
          color: red;
          margin-top: 1px;
        }
        .shortInput {
          width: 12.5%;
        }
        .mediumInput {
          width: 15%;
        }
      }
      .employeeInputs {
        fieldset {
          margin-bottom: 1.3em;
        }
      }
      .start {
        justify-content: flex-start;
        fieldset {
          margin-right: 20px;
        }
      }

      ul {
        padding-left: 24px;

        p {
          margin: 10px 0;
          margin-left: -24px;
        }
        li {
          list-style: disc;
          color: ${colors.mediumGray};
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
        }
      }

      .addNewChildrenContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .actions {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
`;
