export const size = {
  mobile: {
    sreenSize: '768px',
    padding: '26px',
    margin: '16px',
    fontSize: '20px',
  },
  desktop: {
    screenSize: '769px',
    padding: '128px',
    margin: '36px',
    fontSize: '20px',
  },
};

export const device = {
  mobile: `(max-width: ${size.mobile.sreenSize})`,
  desktop: `(min-width: ${size.desktop.screenSize})`,
};
