/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import HeaderMobileLogin from '../../../components/Mobile/HeaderLogin';
import {
  MobileContainer,
  BorderLinearProgress,
  ButtonRegister,
} from './styles';
import Button from '../../../components/Button';
import {
  numberMask,
  cpfMask,
  zipCodeMask,
  phoneMask,
} from '../../../utils/mask';
import { apiCep } from '../../../services/api';
import { RegisterUserApi } from '../../../services/registerUser';
import { colors } from '../../../constants/colors';
import 'react-toastify/dist/ReactToastify.css';

const RegisterUserMobile = () => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [currentDate, setCurrentDate] = useState('');
  const [termsConfirmed, setTermsConfirmed] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      repeat_password: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      birth_date: '',
      zip_code: '',
      street_name: '',
      address_number: '',
      additional_info: '',
      state: '',
      city: '',
      rg: '',
      cpf: '',
      plan_id: 1,
      newsletter_flag: false,
    },
    validationSchema: yup.object().shape({
      first_name: yup.string().required('Este campo é obrigatório'),
      last_name: yup.string().required('Este campo é obrigatório'),
      birth_date: yup
        .string()
        .test(
          'dataLimit',
          'É permitido o cadastro apenas para maiores de 18 anos',
          (value) => {
            if (value > currentDate) {
              return false;
            }
            return true;
          },
        )
        .required('Este campo é obrigatório'),
      cpf: yup
        .string('CPF inválido')
        .min(11, 'Este campo deve ter 11 dígitos')
        .max(11, 'Este campo deve ter 11 dígitos')
        .matches(/\d+/g)
        .required('Este campo é obrigatório'),
      rg: yup
        .string()
        .min(10, 'Este campo deve ter no minimo 10 dígitos')
        .max(11, 'Este campo deve ter no máximo 11 dígitos')
        .required('Este campo é obrigatório'),
      email: yup
        .string()
        .email('Formato do email inválido')
        .required('Este campo é obrigatório'),
      password: yup
        .string()
        .min(8, 'Este campo deve ter no minimo 8 caracteres')
        .required('Este campo é obrigatório'),
      repeat_password: yup
        .string()
        .oneOf([yup.ref('password'), null], 'As senhas devem ser iguais'),
      phone_number: yup
        .string()
        .min(11, 'Este campo deve ter 11 dígitos')
        .max(11, 'Este campo deve ter 11 dígitos')
        .required('Este campo é obrigatório'),
      zip_code: yup
        .string()
        .min(8, 'Este campo conter 8 dígitos')
        .max(8, 'Este campo conter 8 dígitos')
        .required('Este campo é obrigatório'),
      street_name: yup.string().required('Este campo é obrigatório'),
      address_number: yup
        .string()
        .max(50, 'Este campo deve ter no máximo 50 caracteres')
        .required('Este campo é obrigatório'),
      state: yup.string().required('Este campo é obrigatório'),
      city: yup.string().required('Este campo é obrigatório'),
    }),
    onSubmit: (values) => {
      if (termsConfirmed === true) {
        RegisterUserApi(values)
          .then(() => {
            toast('Cadastro bem sucedido! Confirme a conta pelo seu email!');

            setTimeout(() => {
              history.push('/login');
            }, 5000);
          })
          .catch((err) => toast.error(err));
      } else {
        toast('Você deve aceitar os termos de uso e políticas de privacidade');
      }
    },
  });

  useEffect(() => {
    const dateNow = new Date();
    dateNow.setFullYear(dateNow.getFullYear() - 18);
    setCurrentDate(dateNow.toISOString().slice(0, 10));
  }, [formik.values.birth_date]);

  useEffect(() => {
    if (formik.values.zip_code) {
      if (formik.values.zip_code.length >= 8) {
        apiCep.get(`/${formik.values.zip_code}/json`).then(({ data }) => {
          formik.values.street_name = data.logradouro;
          formik.values.state = data.uf;
          formik.values.city = data.localidade;
        });
      }
    }
  }, [formik.values.zip_code]);

  function GoBack() {
    if (page !== 1) {
      setPage(page - 1);
      setTermsConfirmed(false);
    }
  }

  return (
    <MobileContainer>
      <Helmet>
        <title>Nume | Simplifique seu MEI</title>
      </Helmet>
      <HeaderMobileLogin />
      <ToastContainer />
      <div className="container">
        <main>
          <div className="inputsContainer">
            {page === 1 && (
              <>
                <fieldset>
                  <p>Nome</p>
                  <input
                    type="text"
                    name="first_name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.first_name}
                  />
                  {formik.touched.first_name && formik.errors.first_name ? (
                    <div className="errors">{formik.errors.first_name}</div>
                  ) : null}
                </fieldset>
                <fieldset>
                  <p>Sobrenome</p>
                  <input
                    type="text"
                    name="last_name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.last_name}
                  />
                  {formik.touched.last_name && formik.errors.last_name ? (
                    <div className="errors">{formik.errors.last_name}</div>
                  ) : null}
                </fieldset>
                <fieldset c>
                  <p>Data de nascimento</p>
                  <input
                    type="date"
                    name="birth_date"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.birth_date}
                  />
                  {formik.touched.birth_date && formik.errors.birth_date ? (
                    <div className="errors">{formik.errors.birth_date}</div>
                  ) : null}
                </fieldset>
                <fieldset className="largeInput">
                  <p>CPF</p>
                  <input
                    type="text"
                    name="cpf"
                    maxLength="14"
                    onChange={(e) => {
                      e.target.value = numberMask(e.target.value);
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={cpfMask(formik.values.cpf)}
                  />
                  {formik.touched.cpf && formik.errors.cpf ? (
                    <div className="errors">{formik.errors.cpf}</div>
                  ) : null}
                </fieldset>
                <fieldset className="largeInput">
                  <p>RG</p>
                  <input
                    type="text"
                    name="rg"
                    maxLength="11"
                    onChange={(e) => {
                      e.target.value = numberMask(e.target.value);
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.rg}
                  />
                  {formik.touched.rg && formik.errors.rg ? (
                    <div className="errors">{formik.errors.rg}</div>
                  ) : null}
                </fieldset>
              </>
            )}
            {page === 2 && (
              <>
                <fieldset>
                  <p>Email</p>
                  <input
                    type="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="errors">{formik.errors.email}</div>
                  ) : null}
                </fieldset>
                <fieldset>
                  <p>Senha</p>
                  <input
                    type="password"
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="errors">{formik.errors.password}</div>
                  ) : null}
                </fieldset>
                <fieldset>
                  <p>Confirmar senha</p>
                  <input
                    type="password"
                    name="repeat_password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.repeat_password}
                  />
                  {formik.touched.repeat_password &&
                  formik.errors.repeat_password ? (
                    <div className="errors">
                      {formik.errors.repeat_password}
                    </div>
                  ) : null}
                </fieldset>
              </>
            )}

            {page === 3 && (
              <>
                <fieldset>
                  <p>Telefone</p>
                  <input
                    type="text"
                    maxLength="15"
                    name="phone_number"
                    onChange={(e) => {
                      e.target.value = numberMask(e.target.value);
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={phoneMask(formik.values.phone_number)}
                  />
                  {formik.touched.phone_number && formik.errors.phone_number ? (
                    <div className="errors">{formik.errors.phone_number}</div>
                  ) : null}
                </fieldset>
                <fieldset>
                  <p>CEP</p>
                  <input
                    type="text"
                    name="zip_code"
                    maxLength="9"
                    onChange={(e) => {
                      e.target.value = numberMask(e.target.value);
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={zipCodeMask(formik.values.zip_code)}
                  />
                  {formik.touched.zip_code && formik.errors.zip_code ? (
                    <div className="errors">{formik.errors.zip_code}</div>
                  ) : null}
                </fieldset>
                <fieldset>
                  <p>Rua</p>
                  <input
                    type="text"
                    name="street_name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.street_name}
                  />
                  {formik.touched.street_name && formik.errors.street_name ? (
                    <div className="errors">{formik.errors.street_name}</div>
                  ) : null}
                </fieldset>
                <div className="inputGroup">
                  <fieldset className="shortInput">
                    <p>Número</p>
                    <input
                      type="text"
                      name="address_number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.address_number}
                    />
                    {formik.touched.address_number &&
                    formik.errors.address_number ? (
                      <div className="errors">
                        {formik.errors.address_number}
                      </div>
                    ) : null}
                  </fieldset>
                  <fieldset className="mediumInput">
                    <p>Complemento</p>
                    <input
                      type="text"
                      name="additional_info"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.additional_info}
                    />
                    {formik.touched.additional_info &&
                    formik.errors.additional_info ? (
                      <div className="errors">
                        {formik.errors.additional_info}
                      </div>
                    ) : null}
                  </fieldset>
                </div>
                <div className="inputGroup">
                  <fieldset className="shortInput">
                    <p>Estado</p>
                    <input
                      type="text"
                      name="state"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.state}
                    />
                    {formik.touched.state && formik.errors.state ? (
                      <div className="errors">{formik.errors.state}</div>
                    ) : null}
                  </fieldset>
                  <fieldset className="mediumInput">
                    <p>Cidade</p>
                    <input
                      type="text"
                      name="city"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.city}
                    />
                    {formik.touched.city && formik.errors.city ? (
                      <div className="errors">{formik.errors.city}</div>
                    ) : null}
                  </fieldset>
                </div>
                <div className="terms">
                  <div className="input-wrapper">
                    <input
                      id="input-termos"
                      type="checkbox"
                      onChange={() => {
                        setTermsConfirmed(!termsConfirmed);
                      }}
                    />
                    <span id="text-termos">
                      Concordo com os{' '}
                      <a
                        href="https://numebucket.s3.amazonaws.com/Termo_uso_11112020.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {' '}
                        termos de uso
                      </a>{' '}
                      e{' '}
                      <a
                        href="https://numebucket.s3.amazonaws.com/Declara%C3%A7%C3%A3o+de+privacidade-NUME+(1).pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        políticas de privacidade
                      </a>
                    </span>
                  </div>

                  <div className="input-wrapper">
                    <input
                      type="checkbox"
                      name="newsletter_flag"
                      onChange={(e) => {
                        e.target.value = !e.target.value;
                        formik.handleChange(e);
                      }}
                      id="input-termos"
                      checked={formik.values.newsletter_flag}
                    />
                    <span id="text-termos">
                      Eu desejo receber ofertas por email
                    </span>
                  </div>
                </div>
              </>
            )}

            {page !== 1 && (
              <Button
                marginTop="10px"
                backgroundColor="transparent"
                radius="8px"
                color="#fff"
                border="#fff"
                hover="transparent"
                active="transparent"
                width="100%"
                height="32px"
                onClick={GoBack}
                text="voltar"
              />
            )}

            <ButtonRegister
              marginTop="10px"
              marginBottom="10px"
              backgroundColor="#fff"
              radius="8px"
              color={colors.pink}
              border="#fff"
              hover="#fff"
              active="#fff"
              width="100%"
              height="32px"
              onClick={() => {
                page !== 3 ? setPage(page + 1) : formik.handleSubmit();
                formik.isValid === false && page === 3
                  ? toast('Algum campo não foi preenchido corretamente')
                  : null;
              }}
            >
              <span>{page === 3 ? 'Finalizar' : 'Próximo'}</span>
            </ButtonRegister>
          </div>
          <BorderLinearProgress
            variant="determinate"
            value={page * 33.333333}
          />
        </main>
      </div>
    </MobileContainer>
  );
};

export default RegisterUserMobile;
