import { colors } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  width: 100%;
  overflow: scroll;
  justify-content: space-between;
  height: 100vh;
  display: flex;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

export const Select = styled.select`
  border: 1px solid #262626;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  max-width: 250px;
  width: 100%;
  height: 36px;
  margin-bottom: 12px;
  padding-left: 5px;
  outline: none;
  &:focus {
    border-color: #ff6d24;
  }
`;

export const NavBar = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  button {
    outline: none;
    border: none;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #ff6d24;
  }
  .selected {
    font-weight: 600;
    &::after {
      content: '';
      margin-top: 8px;
      width: 100%;
      height: 2px;
      background-color: #ff6d24;
    }
  }
`;

export const ChildCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 18px 36px rgb(17 14 27 / 10%),
    0px 5.42647px 10.8529px rgb(17 14 27 / 7%),
    0px 2.25388px 4.50776px rgb(17 14 27 / 5%),
    0px 0.815184px 1.63037px rgb(17 14 27 / 3%);
  border-radius: 16px;
  padding: 15px;
  margin-bottom: 15px;
`;

export const ListDocuments = styled.ul`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: #000000;

  p {
    margin: 10px 0;
  }
  li {
    list-style: disc;
  }
`;
