import React, { useEffect, useState } from 'react';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { MobileContainer } from './styles';
import HeaderMobileLogin from '../../../components/Mobile/HeaderLogin';
import Button from '../../../components/Button';
import { colors } from '../../../constants/colors';
import { LoginRequest } from '../../../store/ducks/auth/auth';
import Loading from '../../../components/Loading';

const LoginMobile = () => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const isLogged = useSelector((state) => state.auth.isLogged);
  const Errors = useSelector((state) => state.auth.error);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email('Formato de email invalido')
        .required('É necessário um email'),
      password: yup
        .string()
        .min(8, 'A senha deve ter no mínimo 8 caracteres')
        .required('É necessário uma senha'),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      dispatch(LoginRequest(values.email, values.password));
      sessionStorage.setItem('showPopUpExpired', true);
      sessionStorage.setItem('showPopUp', true);

      return true;
    },
  });

  useEffect(() => {
    if (isLogged) {
      setIsLoading(false);
      setErrors('');
      history.push('/dashboard');
    }
    if (Errors) {
      setIsLoading(false);
      if (
        Errors === 'Invalid email/password combination' ||
        Errors === 'Invalid email/password combination'
      ) {
        setErrors('Combinação de e-mail e senha inválidos');
      } else if (Errors === 'Please confirm your account by email') {
        setErrors(
          'Por favor , acesse seu email e confirme a criação de conta na Nume',
        );
      } else {
        setErrors(Errors);
      }
    }
  }, [isLogged, Errors]);

  return (
    <MobileContainer>
      <Loading open={isLoading} />
      <Helmet>
        <title>Nume | Simplifique seu MEI</title>
      </Helmet>
      <HeaderMobileLogin />
      <main>
        <div className="inputsContainer">
          <fieldset className="largeInput">
            <p>Email</p>
            <input
              type="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {(formik.touched.email && formik.errors.email) || errors ? (
              <div className="errors">{errors || formik.errors.email}</div>
            ) : null}
          </fieldset>
          <fieldset className="largeInput">
            <p>Senha</p>
            <input
              type={showPassword ? 'text' : 'password'}
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="errors">{formik.errors.password}</div>
            ) : null}
            {showPassword ? (
              <BsEyeFill
                onClick={() => setShowPassword(!showPassword)}
                className="eye"
              />
            ) : (
              <BsEyeSlashFill
                onClick={() => setShowPassword(!showPassword)}
                className="eye"
              />
            )}
          </fieldset>

          <Button
            marginTop="10px"
            backgroundColor="#fff"
            radius="8px"
            color={colors.pink}
            border="#fff"
            hover="transparent"
            active="transparent"
            width="100%"
            height="32px"
            onClick={formik.handleSubmit}
            text="Entrar"
          />

          <Link to="/recuperarSenha">Esqueci minha senha</Link>
        </div>
      </main>
    </MobileContainer>
  );
};

export default LoginMobile;
