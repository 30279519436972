import * as yup from 'yup';
import { valiteCPF } from './validateCPF';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Formato inválido de email')
    .max(255)
    .required('Email é um campo obrigatório'),
  first_name: yup.string().max(255).required('Nome é um campo obrigatório'),
  last_name: yup.string().max(255).required('Sobrenome é um campo obrigatório'),
  cpf: yup
    .string()
    .min(11, 'O CPF deve ter 11 dígitos')
    .max(11, 'O CPF deve ter 11 dígitos')
    .matches(/\d+/g)
    .required('Cpf é um campo obrigatório')
    .test((value) => valiteCPF(value)),
  phone_number: yup
    .string()
    .min(11, 'O telefone deve ter no minimo 11 dígitos')
    .max(15, 'O telefone deve ter no máximo 12 dígitos')
    .required('Telefone é um campo obrigatório'),
  birth_date: yup
    .string()
    .required('Data de Nascimento é um campo obrigatório'),
  zip_code: yup
    .string()
    .min(8, 'O CEP deve ter 8 dígitos')
    .max(8, 'O CEP deve ter 8 dígitos')
    .matches(/\d+/g)
    .required('Cep é um campo obrigatório'),
  street_name: yup
    .string()
    .max(50, 'A rua deve no máximo 50 caracteres')
    .required('Rua é um campo obrigatório'),
  address_number: yup.string().required('Número é um campo obrigatório'),
  state: yup
    .string()
    .min(2, 'O estado deve ter 2 dígitos')
    .max(2, 'O estado deve ter 2 dígitos')
    .required('UF é um campo obrigatório'),
  city: yup
    .string('string')
    .min(4, 'min')
    .max(26, 'max')
    .required('Cidade é um campo obrigatório'),
  additional_info: yup
    .string()
    .test(
      'name',
      'preenchido',
      (value) => !value || (value.length <= 24 && value.length >= 4),
    ),
});

export default schema;
