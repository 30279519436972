/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {
  cpfMask,
  numberMask,
  removeMask,
  zipCodeMask,
} from '../../../utils/mask';
import {
  ErrorBox,
  Input,
  Label,
  LineDiv,
  SectionTitle,
} from '../Profile/styles';
import { Select } from './styles';

export const EmployeeForm = ({ users, formik, cities, brazilianUf }) => {
  return (
    <>
      <SectionTitle>Dados da Empresa</SectionTitle>
      <div>
        <Label>Empresa</Label>
        <Input
          id="fantasy_name"
          name="fantasy_name"
          value={users.mei.fantasy_name ? users.mei.fantasy_name : null}
        />
        <Label>CNPJ</Label>
        <Input id="cnpj" name="cnpj" value={users.mei.cnpj} />
      </div>

      <SectionTitle>Dados do Empregado</SectionTitle>
      <div>
        <Label>Nome completo</Label>
        <Input
          id="name_employee"
          name="name_employee"
          value={formik.values.name_employee}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.name_employee && formik.errors.name_employee ? (
          <ErrorBox>{formik.errors.name_employee}</ErrorBox>
        ) : null}
      </div>
      <div>
        <Label>Nome do pai</Label>
        <Input
          id="father_name"
          name="father_name"
          value={formik.values.father_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.father_name && formik.errors.father_name ? (
          <ErrorBox>{formik.errors.father_name}</ErrorBox>
        ) : null}
      </div>
      <div>
        <Label>Nome da mãe</Label>
        <Input
          id="mother_name"
          name="mother_name"
          value={formik.values.mother_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.mother_name && formik.errors.mother_name ? (
          <ErrorBox>{formik.errors.mother_name}</ErrorBox>
        ) : null}
      </div>
      <div>
        <Label>Local de Nascimento</Label>
        <Select
          id="birth_city"
          name="birth_city"
          value={formik.values.birth_city}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
          {formik.values.birth_uf === '' && (
            <option value="">Selecione um UF antes</option>
          )}
          {cities.map((city) => (
            <option value={city.nome}>{city.nome}</option>
          ))}
        </Select>
        {formik.touched.birth_city && formik.errors.birth_city ? (
          <ErrorBox>{formik.errors.birth_city}</ErrorBox>
        ) : null}
      </div>
      <LineDiv>
        <div style={{ width: '45%' }}>
          <Label>Data de Nascimento</Label>
          <Input
            type="date"
            id="birth_date"
            name="birth_date"
            value={formik.values.birth_date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.birth_date && formik.errors.birth_date ? (
            <ErrorBox>{formik.errors.birth_date}</ErrorBox>
          ) : null}
        </div>
        <div style={{ width: '45%', marginTop: '16px' }}>
          <Label>UF</Label>

          <Select
            id="birth_uf"
            name="birth_uf"
            value={formik.values.birth_uf}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <option value="">Selecione o UF</option>
            {brazilianUf.map((uf) => (
              <option value={uf.uf}>{uf.uf}</option>
            ))}
          </Select>
          {formik.touched.birth_uf && formik.errors.birth_uf ? (
            <ErrorBox>{formik.errors.birth_uf}</ErrorBox>
          ) : null}
        </div>
      </LineDiv>
      <LineDiv>
        <div style={{ width: '45%' }}>
          <Label>Gênero</Label>
          <Select
            id="sex"
            name="sex"
            value={formik.values.sex}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <option value="">Selecione o gênero</option>
            <option value="masculino">Masculino</option>
            <option value="feminino">Feminino</option>
            <option value="nao-quero-informar">Não quero informar</option>
          </Select>
          {formik.touched.sex && formik.errors.sex ? (
            <ErrorBox>{formik.errors.sex}</ErrorBox>
          ) : null}
        </div>
        <div style={{ width: '45%' }}>
          <Label>Estado Civil</Label>
          <Select
            id="civil_state"
            name="civil_state"
            value={formik.values.civil_state}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <option value="">Selecione o seu estado civil</option>
            <option value="solteiro">solteiro</option>
            <option value="casado">casado</option>
            <option value="divorciado">divorciado</option>
            <option value="uniao-estavel">União Estável</option>
            <option value="viuvo">Viúvo</option>
          </Select>
          {formik.touched.civil_state && formik.errors.civil_state ? (
            <ErrorBox>{formik.errors.civil_state}</ErrorBox>
          ) : null}
        </div>
      </LineDiv>
      {formik.values.civil_state === 'casado' ? (
        <div>
          <Label>Regime Partilhas</Label>
          <Select
            name="civil_condition"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.civil_condition}
          >
            <option value="">Selecione o regime</option>
            <option value="parcial">Regime de comunhão parcial</option>
            <option value="universal">Regime de comunhão universal</option>
            <option value="separacao">Regime de separação de bens</option>
            <option value="participacao">
              Regime de participação funal nos aquestos
            </option>
          </Select>
          {formik.touched.civil_condition && formik.errors.civil_condition ? (
            <ErrorBox>{formik.errors.civil_condition}</ErrorBox>
          ) : null}
        </div>
      ) : null}
      <div>
        <Label>Endereço Residencial</Label>
        <Input
          id="street_name"
          name="street_name"
          value={formik.values.street_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.street_name && formik.errors.street_name ? (
          <ErrorBox>{formik.errors.street_name}</ErrorBox>
        ) : null}
      </div>
      <LineDiv>
        <div style={{ width: '45%' }}>
          <Label>Número</Label>
          <Input
            id="street_number"
            name="street_number"
            value={numberMask(formik.values.street_number)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.street_number && formik.errors.street_number ? (
            <ErrorBox>{formik.errors.street_number}</ErrorBox>
          ) : null}
        </div>
        <div style={{ width: '45%' }}>
          <Label>Bairro</Label>
          <Input
            id="neighborhood"
            name="neighborhood"
            value={formik.values.neighborhood}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.neighborhood && formik.errors.neighborhood ? (
            <ErrorBox>{formik.errors.neighborhood}</ErrorBox>
          ) : null}
        </div>
      </LineDiv>
      <div>
        <Label>CEP</Label>
        <Input
          maxLength="9"
          id="zip_code"
          name="zip_code"
          value={zipCodeMask(formik.values.zip_code)}
          onChange={(e) => {
            e.target.value = removeMask(e.target.value);
            formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
        />
        {formik.touched.zip_code && formik.errors.zip_code ? (
          <ErrorBox>{formik.errors.zip_code}</ErrorBox>
        ) : null}
      </div>
      <LineDiv>
        <div style={{ width: '45%' }}>
          <Label>CTPS</Label>
          <Input
            maxLength="7"
            id="ctps_number"
            name="ctps_number"
            value={numberMask(formik.values.ctps_number)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.ctps_number && formik.errors.ctps_number ? (
            <ErrorBox>{formik.errors.ctps_number}</ErrorBox>
          ) : null}
        </div>
        <div style={{ width: '45%' }}>
          <Label>Série</Label>
          <Input
            maxLength="4"
            id="ctps_serie"
            name="ctps_serie"
            value={numberMask(formik.values.ctps_serie)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.ctps_serie && formik.errors.ctps_serie ? (
            <ErrorBox>{formik.errors.ctps_serie}</ErrorBox>
          ) : null}
        </div>
      </LineDiv>
      <LineDiv>
        <div style={{ width: '45%' }}>
          <Label>UF</Label>
          <Select
            id="ctps_emission_uf"
            name="ctps_emission_uf"
            value={formik.values.ctps_emission_uf}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <option value="">Selecione o UF</option>
            {brazilianUf.map((uf) => (
              <option value={uf.uf}>{uf.uf}</option>
            ))}
          </Select>
          {formik.touched.ctps_emission_uf && formik.errors.ctps_emission_uf ? (
            <ErrorBox>{formik.errors.ctps_emission_uf}</ErrorBox>
          ) : null}
        </div>
        <div style={{ width: '45%' }}>
          <Label>Data de Emissão</Label>
          <Input
            type="date"
            id="ctps_emission_date"
            name="ctps_emission_date"
            value={formik.values.ctps_emission_date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.ctps_emission_date &&
          formik.errors.ctps_emission_date ? (
            <ErrorBox>{formik.errors.ctps_emission_date}</ErrorBox>
          ) : null}
        </div>
      </LineDiv>
      <div>
        <Label>Grau de Instrução</Label>
        <Select
          id="instruction_rank"
          name="instruction_rank"
          value={formik.values.instruction_rank}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
          <option value="">Selecione o grau de instrução</option>
          <option value="analfabeto">Analfabeto</option>
          <option value="1-grau-incompleto">1º grau incompleto</option>
          <option value="1-grau-completo">1º grau completo</option>
          <option value="2-grau-incompleto">2º grau incompleto</option>
          <option value="2-grau-completo">2º grau completo</option>
          <option value="superior-incompleto">Superior Incompleto</option>
          <option value="superior-completo">Superior Completo</option>
          <option value="pos-graduacao-especializacao">
            Pós-Graduação/Especialização
          </option>
          <option value="mestrado">Mestrado</option>
          <option value="doutorado">Doutorado</option>
          <option value="pos-dotourado">Pós-Doutorado</option>
        </Select>
        {formik.touched.instruction_rank && formik.errors.instruction_rank ? (
          <ErrorBox>{formik.errors.instruction_rank}</ErrorBox>
        ) : null}
      </div>
      <div>
        <Label>PIS</Label>
        <Input
          maxLength="11"
          id="pis_number"
          name="pis_number"
          value={numberMask(formik.values.pis_number)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.pis_number && formik.errors.pis_number ? (
          <ErrorBox>{formik.errors.pis_number}</ErrorBox>
        ) : null}
      </div>
      <LineDiv>
        <div style={{ width: '45%' }}>
          <Label>CI</Label>
          <Input
            maxLength="11"
            id="ci_number"
            name="ci_number"
            value={numberMask(formik.values.ci_number)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.ci_number && formik.errors.ci_number ? (
            <ErrorBox>{formik.errors.ci_number}</ErrorBox>
          ) : null}
        </div>
        <div style={{ width: '45%' }}>
          <Label>Data de Emissão</Label>
          <Input
            type="date"
            id="ci_emission_date"
            name="ci_emission_date"
            value={formik.values.ci_emission_date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.ci_emission_date && formik.errors.ci_emission_date ? (
            <ErrorBox>{formik.errors.ci_emission_date}</ErrorBox>
          ) : null}
        </div>
      </LineDiv>
      <LineDiv>
        <div style={{ width: '45%' }}>
          <Label>Órgão Expedidor</Label>
          <Input
            id="ci_institution_emission"
            name="ci_institution_emission"
            value={formik.values.ci_institution_emission}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.ci_institution_emission &&
          formik.errors.ci_institution_emission ? (
            <ErrorBox>{formik.errors.ci_institution_emission}</ErrorBox>
          ) : null}
        </div>
        <div style={{ width: '45%' }}>
          <Label>UF</Label>
          <Select
            id="ci_uf_emission"
            name="ci_uf_emission"
            value={formik.values.ci_uf_emission}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <option value="">Selecione o UF</option>
            {brazilianUf.map((uf) => (
              <option value={uf.uf}>{uf.uf}</option>
            ))}
          </Select>
          {formik.touched.ci_uf_emission && formik.errors.ci_uf_emission ? (
            <ErrorBox>{formik.errors.ci_uf_emission}</ErrorBox>
          ) : null}
        </div>
      </LineDiv>
      <div>
        <Label>CPF</Label>
        <Input
          maxLength="14"
          id="cpf"
          name="cpf"
          value={cpfMask(formik.values.cpf)}
          onChange={(e) => {
            e.target.value = removeMask(e.target.value);
            formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
        />
        {formik.touched.cpf && formik.errors.cpf ? (
          <ErrorBox>{formik.errors.cpf}</ErrorBox>
        ) : null}
      </div>
      <LineDiv>
        <div style={{ width: '45%' }}>
          <Label>Titulo de eleitor</Label>
          <Input
            maxLength="12"
            id="eleitoral_number"
            name="eleitoral_number"
            value={numberMask(formik.values.eleitoral_number)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.eleitoral_number && formik.errors.eleitoral_number ? (
            <ErrorBox>{formik.errors.eleitoral_number}</ErrorBox>
          ) : null}
        </div>
        <div style={{ width: '45%' }}>
          <Label>Data de Emissão</Label>
          <Input
            type="date"
            id="eleitoral_number_emission_date"
            name="eleitoral_number_emission_date"
            value={formik.values.eleitoral_number_emission_date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.eleitoral_number_emission_date &&
          formik.errors.eleitoral_number_emission_date ? (
            <ErrorBox>{formik.errors.eleitoral_number_emission_date}</ErrorBox>
          ) : null}
        </div>
      </LineDiv>
      <LineDiv>
        <div style={{ width: '45%' }}>
          <Label>Zona</Label>
          <Input
            id="eleitoral_number_zone"
            name="eleitoral_number_zone"
            value={numberMask(formik.values.eleitoral_number_zone)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.eleitoral_number_zone &&
          formik.errors.eleitoral_number_zone ? (
            <ErrorBox>{formik.errors.eleitoral_number_zone}</ErrorBox>
          ) : null}
        </div>
        <div style={{ width: '45%' }}>
          <Label>Seção</Label>
          <Input
            id="eleitoral_number_session"
            name="eleitoral_number_session"
            value={numberMask(formik.values.eleitoral_number_session)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.eleitoral_number_session &&
          formik.errors.eleitoral_number_session ? (
            <ErrorBox>{formik.errors.eleitoral_number_session}</ErrorBox>
          ) : null}
        </div>
      </LineDiv>
    </>
  );
};
