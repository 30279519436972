import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { size, device } from '../../../constants/size';
import woman from '../../../assets/png/woman.png';

export const Container = styled.div`
  height: auto;
  position: relative;
  #div-form{
    position:relative;
    width: 50%;
  }
  #form {
    @media (max-width:1261px){
     right: 1%;
     max-width: 416.2px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    z-index: 5;
    max-width: 536.2px;
    max-height: 664.2px;
    
    width: 100%;
    height: 100%;

    top: 18%;
    right: 10%;
    position: absolute;
    background-color: ${colors.white};
    border-radius: 28px;
    h3{
      align-self:flex-start;
      padding-left:${size.mobile.padding};
      font-weight:bold;
      font-size:32px;
      color:${colors.pink}
    }
    @media ${device.mobile}{
      position: relative;
      left:50%;
      height:400px;
      width:300px;
      transform:translate(-50%, 0%);
      &::before{
        position: absolute;
        display:block;
        width:300px;
        height:50px;
        border-radius:8px;
        top:-20px;
        background-color:${colors.white};
        content:'';
      }
      &::after{
        position: absolute;
        display:block;
        width:300px;
        height:50px;
        border-radius:8px;
        bottom:-20px;
        background-color:${colors.white};
        z-index:5;
        content:'';
      }
    }
    
    input {
      width: 90%;
      background-color:transparent;
      border:none;
      border-bottom:2px solid ${colors.darkGray};
      font-size:22px;
      font-weight: 300;
    }
  }
  #one {
   
  position:relative;
    @media ${device.mobile} {
      height: 50vh;
      background-position:center;
      /* background-size: 100% 430px; */
      background-size: cover;

    }
    background-image:  url(${woman}) ;
    background-position:90% 7%;
    height: 889px;
    overflow:hidden;
    position: relative;
    
    
    &::before {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 1;
    }
    @media (min-width: 850px) {
      &::after {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 10px;
      width: 50%;
      background-color: ${colors.orange};
      z-index: 15000;
      }
    }
    
    #abrirMei {
      
      @media ${device.mobile} {
        bottom: 10%;
        left: 10%;
        flex-direction: column;
        align-items: flex-start;
        max-width:216.4px;
        width:100%;
      }
      flex-direction: column;
      z-index: 2;
      position: absolute;
      bottom: 20%;
      left: 15%;
      display: flex;
      align-items: flex-start;
      max-width:480.3;
      width:30%;
      z-index:5;
     
      #span{
        @media (max-width:1261px){
          font-size:1em;
          line-height:22px; 
    }
        margin-top:10px;
        line-height:39px; 
        font-weight: lighter;
        font-size: 32px;
        text-align: left;
        color:#fff;
      }
      p{
          padding-top: 10px;
          font-size:48px;
          color: ${colors.white};
        /* @media (max-width:1261px){
          font-size:2em;
          } */
        }
       
        @media (max-width:768px) {
        #span{
          font-size:1em;
          line-height:22px; 

        }
       
        p{
          font-size:1.2em;
        }
      }
      }
    }
    #wrapper {
      @media ${device.mobile} {
        bottom: 15%;
        left: 10%;
        flex-direction: column;
        align-items: flex-start;
      }
      z-index: 2;
      position: absolute;
      bottom: 20%;
      left: 15%;
      display: flex;
      align-items: center;
      #logo {
        @media ${device.mobile} {
          width: 100px;
          height: 100px;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 160px;
        height: 160px;
        background: transparent
          linear-gradient(147deg, ${colors.orange} 0%, ${colors.pink} 100%) 0%
          0% no-repeat padding-box;
        border-radius: 31px;
        img {
          @media ${device.mobile} {
            height: 40%;
          }
          object-fit: contain;
          height: 50%;
        }
        p {
          @media ${device.mobile} {
            font-size: 0.9em;
          }
          padding-top: 10px;
          font-size: 1.1em;
          color: ${colors.white};
          font-weight: bold;
        }
      }
      #textWrapperOne {
        @media ${device.mobile} {
          padding-left: 0;
          padding-top: ${size.mobile.padding};
          font-size: 1.2em;
        }
        padding-left: ${size.mobile.padding};
        font-size: 1.8em;
        color: ${colors.white};
        font-weight: bold;
      }
    }
  }
  #two {
    @media ${device.mobile} {
      flex-direction: column;
      padding-left: ${size.mobile.padding};
      padding-right: ${size.mobile.padding};
      height: 40%;
    }
    padding-left: ${size.desktop.padding};
    padding-right: ${size.desktop.padding};
    height: 30%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    #form {
      z-index: 2;
      position: absolute;
      display: block;
      background-color: ${colors.orange};
      width: 300px;
      height: 400px;
      border-radius: 8px;
    }
    #textWrapperTwo {
      @media ${device.mobile} {
        font-size: 0.75rem;
        align-self: flex-start;
        p {
          display: inline-block;
          font-weight: bold;
        }
      }
      color: ${colors.darkGray};
      font-size: 1.2em;
    }
    
  }
  #three {
    height: 80vh;
    display: flex;
    position: relative;
    background: transparent
      linear-gradient(0deg, ${colors.orange} 0%, ${colors.pink} 100%) 0% 0%
      no-repeat padding-box;
    #title {
      position: relative;
      width: 40%;
      @media ${device.mobile} {
        width: 100%;
      }
      img {
        @media ${device.mobile} {
          height: 50%;
        }
        position: absolute;
        opacity: 0.2;
        height: 80%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        object-fit: contain;
      }
      #text {
        @media ${device.mobile} {
          top: 15%;
          left: 30%;
        }
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        p {
          @media ${device.mobile} {
            font-size: 1.2em;
          }
          font-size: 1.7em;
          color: ${colors.white};
          font-weight: bold;
        }
      }
    }
    ul {
      @media ${device.mobile} {
        position: absolute;
        top: 25%;
        left: 0%;
        width: 100%;
        height: 66%;

        gap: 1.5em;
      }
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      li {
        width: 80%;

        display: flex;
        .icon {
          @media ${device.mobile} {
            width: 120px;
          }
          width: 80px;

          img {
            object-fit: contain;
            width: 100%;
          }
        }
        .itemWrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-left: ${size.mobile.padding};
          .itemTitle {
            font-size: 1em;
            color: ${colors.white};
            font-weight: bold;
          }
          .itemText {
            font-size: 0.8em;
            color: ${colors.white};
          }
        }
      }
    }
  }
`;
