import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 8px;
  top: 5px;
  align-items: center;
  z-index: 24;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      transform: rotate(180deg);
      transition: all 0.1s linear;
    }
  }
  ul {
    width: 260px;
    border-radius: 8px;
    margin-top: 5px;
    padding: 12px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* width: 140%; */
    position: absolute;
    z-index: 10;
    top: 30px;
    left: 20px;
    background-color: #fff;
    box-shadow: 0px 18px 36px rgba(17, 14, 27, 0.1),
      0px 5.42647px 10.8529px rgba(17, 14, 27, 0.065102),
      0px 2.25388px 4.50776px rgba(17, 14, 27, 0.0499628),
      0px 0.815184px 1.63037px rgba(17, 14, 27, 0.0348159);
    li {
      margin: 12px 0px;
      img {
        background-color: #ff6d24;
        height: 24px;
        width: 24px;
        margin: 0;
        margin-right: 8px;
      }
      display: flex;
      align-items: center;
    }
  }
`;
