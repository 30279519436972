import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
  header {
    width: 100%;
    height: 100px;
    min-height: 100px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    box-shadow: 0px 4px 12px rgba(17, 14, 27, 0.13);
    margin-bottom: 45px;
  }
  .input {
    display: flex;
    align-items: center;
    min-height: 40px;
    max-width: 260px;
    width: 100%;
    border: 1px solid #ee7200;
    border-radius: 8px;
    margin-left: 24px;
    button {
      border: 0;
      background: #fff;
      cursor: pointer;
    }

    input {
      width: 90%;
      height: 30px;

      border: 0;
      padding-left: 20px;
      background-color: #fff;
      color: #ee7200;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: #ee7200;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
    }
    svg {
      color: #ee7200;
    }
    &:hover {
      background-color: #ee7200;
      color: #fff;
      input {
        background-color: #ee7200;
        color: #fff;
        &::placeholder {
          background-color: #ee7200;
          color: #fff;
        }
      }
      button {
        background-color: #ee7200;
        svg {
          color: #fff;
          background-color: #ee7200;
        }
      }
    }
  }
  .line {
    width: 96%;
    min-height: 1px;
    background-color: #000;
    margin-top: 40px;
    margin-left: 24px;
    margin-right: 24px;
  }
  .clients {
    margin-bottom: 40px;
  }
`;
export const Container = styled.div`
  display: flex;
`;

export const Client = styled.div`
  h2 {
    font-size: 20px;
    margin-top: 16px;

    font-weight: 400;
    line-height: 28px;
    padding-left: 30px;
    width: 20%;
    display: flex;
    justify-self: flex-start;
  }
  .value {
    font-weight: 600;
    color: red;
  }
  .line {
    width: 96%;
    min-height: 1px;
    background-color: #000;
    margin-top: 16px;
    margin-left: 24px;
    margin-right: 24px;
  }
  .data {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 45px;
    button {
      padding-top: 15px;
      border: none;
      background-color: white;
      cursor: pointer;
      outline: none;
    }
    .name {
      width: 30%;
      word-break: break-word;
    }
    /* .cpf {
      position: absolute;
      left: 45%;
      display: flex;
      justify-content: center;
    } */
  }
`;
