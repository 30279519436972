/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FiSettings } from 'react-icons/fi';
import AvatarProfile from '../../../assets/jpg/avatarProfile.jpg';
import userIcon from '../../../assets/svg/userIcon.svg';
import { Logout } from '../../../store/ducks/auth/auth';

import { Container } from './styles';

function SettingsNAvigator() {
  const [isShowedOptions, setIsShowedOptions] = React.useState(false);
  const { push } = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  return (
    <Container>
      <FiSettings
        size={20}
        color="#000"
        onClick={() => setIsShowedOptions(!isShowedOptions)}
      />
      {isShowedOptions && (
        <ul>
          <li onClick={() => push('/dashboard')}>
            <img src={userIcon} alt="Avatar profile" />
            {`${user.first_name} ${user.last_name}`}
          </li>
          <li onClick={() => push('/dashboard/profile')}>
            Configurações de conta
          </li>

          <li onClick={() => push('/dashboard/alterarSenha')}>Alterar senha</li>

          <li
            onClick={() => {
              dispatch(Logout());
            }}
          >
            Logout
          </li>
        </ul>
      )}
    </Container>
  );
}

export default SettingsNAvigator;
