import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import { Content } from './styles';
import Button from '../../components/Button';
import { colors } from '../../constants/colors';
import BrazilianStates from '../../utils/brazilianStates';
import {
  UpdateEmployeeRequest,
  UpdateUserOnReducer,
} from '../../store/ducks/auth/auth';

import { ModalSelect } from '../../components/Modal/styles';
import api, { apiCep, apiCities } from '../../services/api';
import { cpfMask, numberMask, removeMask, zipCodeMask } from '../../utils/mask';
import { valiteCPF } from '../../utils/validateCPF';
import Loading from '../../components/Loading';

const EmployeeRegister = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalState, setModalState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [brazilianUf, setBrazilianUf] = useState([]);
  const [indexChild, setIndexChild] = useState(0);
  const [action, setAction] = useState('');
  const [childrens, setChildrens] = useState([]);
  const [child, setChild] = useState({ name: '', cpf: '', birth_date: '' });
  const [errorChildren, setErrorChildren] = useState([]);
  const [cities, setCities] = useState([]);
  const users = useSelector((state) => state.auth.user);
  const maxDate = new Date();
  const childrensSchema = Yup.object().shape({
    name: Yup.string()
      .max(255, 'Este campo deve ter no máximo 255 caracteres')
      .required('Este campo é obrigatório'),
    cpf: Yup.string()
      .matches(/\d*/g, 'Só são permitindo números')
      .test({
        name: 'lengthTest',
        message: 'Este campo deve ter 11 caracteres',
        test: (value) => value === '' || value.length === 11,
      })
      .nullable()
      .test(
        'valiteCpf',
        'CPF invalido',
        (value) => value === '' || valiteCPF(value),
      ),
    birth_date: Yup.date()
      .typeError('Este campo é obrigatório')
      .max(
        new Date(maxDate),
        `Deve ser anterior a ${maxDate.toLocaleDateString()}`,
      ),
  });

  function closeModal() {
    setModalState(false);
    setIndexChild(false);
    setChild({ name: '', cpf: '', birth_date: '' });
    setErrorChildren([]);
  }

  useEffect(() => {
    if (users.plan.name !== 'Premium') {
      history.push('/dashboard');
    }
  }, []);

  async function submitChildren(employee) {
    try {
      await api.post('/users/employee/children', childrens).then((response) => {
        const resEmployee = employee;
        resEmployee.children = response.data;
        setChildrens(response.data);
        dispatch(UpdateUserOnReducer({ employee: resEmployee }));
        toast('Cadastro das crianças realizado com sucesso!');
      });
    } catch (error) {
      console.log(error);
    }
  }

  const formik = useFormik({
    initialValues: {
      name_employee: users.employee ? users.employee.name_employee : '',
      sex: users.employee ? users.employee.sex : '',
      father_name: users.employee ? users.employee.father_name : '',
      mother_name: users.employee ? users.employee.mother_name : '',
      birth_date: users.employee ? users.employee.birth_date.split('T')[0] : '',
      birth_city: users.employee ? users.employee.birth_city : '',
      birth_uf: users.employee ? users.employee.birth_uf : '',
      civil_state: users.employee ? users.employee.civil_state : '',
      civil_condition: users.employee ? users.employee.civil_condition : '',
      ctps_number: users.employee ? users.employee.ctps_number : '',
      ctps_serie: users.employee ? users.employee.ctps_serie : '',
      ctps_emission_uf: users.employee ? users.employee.ctps_emission_uf : '',
      ctps_emission_date: users.employee
        ? users.employee.ctps_emission_date.split('T')[0]
        : '',
      instruction_rank: users.employee ? users.employee.instruction_rank : '',
      pis_number: users.employee ? users.employee.pis_number : '',
      ci_number: users.employee ? users.employee.ci_number : '',
      ci_emission_date: users.employee
        ? users.employee.ci_emission_date.split('T')[0]
        : '',
      ci_institution_emission: users.employee
        ? users.employee.ci_institution_emission
        : '',
      ci_uf_emission: users.employee ? users.employee.ci_uf_emission : '',
      cpf: users.employee ? users.employee.cpf : '',
      eleitoral_number: users.employee ? users.employee.eleitoral_number : '',
      eleitoral_number_emission_date: users.employee
        ? users.employee.eleitoral_number_emission_date.split('T')[0]
        : '',
      eleitoral_number_zone: users.employee
        ? users.employee.eleitoral_number_zone
        : '',
      eleitoral_number_session: users.employee
        ? users.employee.eleitoral_number_session
        : '',
      zip_code: users.employee ? users.employee.zip_code : '',
      neighborhood: users.employee ? users.employee.neighborhood : '',
      street_name: users.employee ? users.employee.street_name : '',
      street_number: users.employee ? users.employee.street_number : '',
      function: users.employee ? users.employee.function : '',
      activity: users.employee ? users.employee.activity : '',
      company_admission_date: users.employee
        ? users.employee.company_admission_date.split('T')[0]
        : '',
      income: users.employee ? users.employee.income : '',
      insalubrity: users.employee ? users.employee.insalubrity : '',
      ticket_transport: users.employee ? users.employee.ticket_transport : '',
      ticket_quantity: users.employee ? users.employee.ticket_quantity : '',
      medical_test_date: users.employee
        ? users.employee.medical_test_date.split('T')[0]
        : '',
      crm: users.employee ? users.employee.crm : '',
      doctor_name: users.employee ? users.employee.doctor_name : '',
      experience_days_contract: users.employee
        ? users.employee.experience_days_contract
        : '',
    },
    validationSchema: Yup.object({
      name_employee: Yup.string()
        .max(255, 'Este campo deve ter no máximo 255 caracteres')
        .required('Este campo é obrigatório'),
      sex: Yup.string().required('Este campo é obrigatório'),
      father_name: Yup.string()
        .max(255, 'Este campo deve ter no máximo 255 caracteres')
        .required('Este campo é obrigatório'),
      mother_name: Yup.string()
        .max(255, 'Este campo deve ter no máximo 255 caracteres')
        .required('Este campo é obrigatório'),
      birth_date: Yup.date()
        .max(
          new Date(maxDate),
          `Deve ser anterior a ${maxDate.toLocaleDateString()}`,
        )
        .required('Este campo é obrigatório'),
      birth_city: Yup.string()
        .min(4, 'Este campo deve ter no minimo 4 caracteres')
        .max(26, 'Este campo deve ter no máximo 26 caracteres')
        .required('Este campo é obrigatório'),
      birth_uf: Yup.string()
        .min(2, 'Este campo deve ter 2 caracteres')
        .max(2, 'Este campo deve ter 2 caracteres')
        .required('Este campo é obrigatório'),
      civil_state: Yup.string().required('Este campo é obrigatório'),
      civil_condition: Yup.string(),
      ctps_number: Yup.string()
        .test('lengthTest', 'Este campo deve ter 7 caracteres', (value) => {
          formik.values.ctps_number = numberMask(formik.values.ctps_number);
          return formik.values.ctps_number.length === 7;
        })
        .required('Este campo é obrigatório'),
      ctps_serie: Yup.string()
        .test('lengthTest', 'Este campo deve ter 4 caracteres', (value) => {
          formik.values.ctps_serie = numberMask(formik.values.ctps_serie);
          return formik.values.ctps_serie.length === 4;
        })
        .required('Este campo é obrigatório'),
      ctps_emission_uf: Yup.string()
        .min(2, 'Este campo deve ter 2 caracteres')
        .max(2, 'Este campo deve ter 2 caracteres')
        .required('Este campo é obrigatório'),
      ctps_emission_date: Yup.date()
        .max(
          new Date(maxDate),
          `Deve ser anterior a ${maxDate.toLocaleDateString()}`,
        )
        .required('Este campo é obrigatório'),
      instruction_rank: Yup.string().required('Este campo é obrigatório'),
      pis_number: Yup.string()
        .test('lengthTest', 'Este campo deve ter 11 caracteres', (value) => {
          formik.values.pis_number = numberMask(formik.values.pis_number);
          return formik.values.pis_number.length === 11;
        })
        .required('Este campo é obrigatório'),
      ci_number: Yup.string()
        .test(
          'lengthTest',
          'Este campo deve ter entre 9 e 11 caracteres',
          (value) => {
            formik.values.ci_number = numberMask(formik.values.ci_number);
            return (
              formik.values.ci_number.length >= 9 &&
              formik.values.ci_number.length <= 11
            );
          },
        )
        .matches(/\d+/g)
        .required('Este campo é obrigatório'),
      ci_emission_date: Yup.date()
        .max(
          new Date(maxDate),
          `Deve ser anterior a ${maxDate.toLocaleDateString()}`,
        )
        .required('Este campo é obrigatório'),
      ci_institution_emission: Yup.string().required(
        'Este campo é obrigatório',
      ),
      ci_uf_emission: Yup.string()
        .min(2, 'Este campo deve ter 2 caracteres')
        .max(2, 'Este campo deve ter 2 caracteres')
        .required('Este campo é obrigatório'),
      cpf: Yup.string()
        .test('lengthTest', 'Este campo deve ter 11 caracteres', (value) => {
          formik.values.cpf = numberMask(formik.values.cpf);
          return formik.values.cpf.length === 11;
        })
        .matches(/\d+/g)
        .required('Este campo é obrigatório'),
      eleitoral_number: Yup.string()
        .test('lengthTest', 'Este campo deve ter 12 caracteres', (value) => {
          formik.values.eleitoral_number = numberMask(
            formik.values.eleitoral_number,
          );
          return formik.values.eleitoral_number.length === 12;
        })
        .matches(/\d+/g)
        .required('Este campo é obrigatório'),
      eleitoral_number_emission_date: Yup.date()
        .max(
          new Date(maxDate),
          `Deve ser anterior a ${maxDate.toLocaleDateString()}`,
        )
        .required('Este campo é obrigatório'),
      eleitoral_number_zone: Yup.string().required('Este campo é obrigatório'),
      eleitoral_number_session: Yup.string().required(
        'Este campo é obrigatório',
      ),
      zip_code: Yup.string()
        .test('lengthTest', 'Este campo deve ter 8 caracteres', (value) => {
          formik.values.zip_code = numberMask(formik.values.zip_code);
          return formik.values.zip_code.length === 8;
        })
        .matches(/\d+/g)
        .required('Este campo é obrigatório'),
      neighborhood: Yup.string().required('Este campo é obrigatório'),
      street_name: Yup.string().required('Este campo é obrigatório'),
      street_number: Yup.string().required('Este campo é obrigatório'),
      function: Yup.string().required('Este campo é obrigatório'),
      activity: Yup.string().required('Este campo é obrigatório'),
      company_admission_date: Yup.date()
        .max(
          new Date(maxDate),
          `Deve ser anterior a ${maxDate.toLocaleDateString()}`,
        )
        .required('Este campo é obrigatório'),
      income: Yup.string().required('Este campo é obrigatório'),
      insalubrity: Yup.string().required('Este campo é obrigatório'),
      ticket_transport: Yup.string().required('Este campo é obrigatório'),
      ticket_quantity: Yup.string().required('Este campo é obrigatório'),
      medical_test_date: Yup.date()
        .max(
          new Date(maxDate),
          `Deve ser anterior a ${maxDate.toLocaleDateString()}`,
        )
        .required('Este campo é obrigatório'),
      crm: Yup.string().required('Este campo é obrigatório'),
      doctor_name: Yup.string().required('Este campo é obrigatório'),
      experience_days_contract: Yup.string().required(
        'Este campo é obrigatório',
      ),
    }),
    onSubmit: async (values) => {
      const data = {
        ...values,
        income: String(formik.values.income),
        insalubrity: String(formik.values.insalubrity),
        ticket_transport: String(formik.values.ticket_transport),
        ticket_quantity: String(formik.values.ticket_quantity),
        experience_days_contract: String(
          formik.values.experience_days_contract,
        ),
      };

      if (!users.employee) {
        setLoading(true);
        try {
          await api.post('/users/employee', data).then(async (response) => {
            if (childrens) {
              await submitChildren(response.data);
            } else {
              dispatch(UpdateUserOnReducer({ employee: response.data }));
            }
            setLoading(false);
            toast('Cadastro do funcionário realizado com sucesso!');
          });
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      } else {
        dispatch(UpdateEmployeeRequest({ ...data, children: childrens }));
        toast('Cadastro editado com sucesso!');
      }
      setLoading(false);
    },
  });

  function deleteEmployee() {
    setLoading(true);
    api
      .delete('/users/employee/delete')
      .then(async () => {
        const user = users;
        delete user.employee;
        dispatch(UpdateUserOnReducer(user));
        setLoading(false);
        toast('Funcionário removido com sucesso');
        setTimeout(() => {
          setLoading(false);
          history.push('/dashboard');
        }, 5000);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  function getError(name) {
    const error = errorChildren.find((err) => err.path === `${name}`);
    return error ? error.message : null;
  }

  useEffect(() => {
    if (users.employee && users.employee.children) {
      setChildrens(users.employee.children);
    }
  }, [users]);

  useEffect(() => {
    setBrazilianUf(BrazilianStates());
  }, []);

  useEffect(() => {
    if (formik.values.zip_code) {
      if (formik.values.zip_code.length >= 8) {
        apiCep.get(`/${formik.values.zip_code}/json`).then(({ data }) => {
          formik.values.street_name = data.logradouro;
          formik.values.neighborhood = data.bairro;
        });
      }
    }
  }, [formik.values.zip_code]);

  useEffect(() => {
    if (formik.values.birth_uf) {
      apiCities
        .get(`/${formik.values.birth_uf}/municipios`)
        .then(({ data }) => {
          setCities(data);
          formik.values.birth_city = data[0].nome;
        });
    } else if (formik.values.birth_uf === '') {
      setCities([]);
    }
  }, [formik.values.birth_uf]);

  function UniqueCPF() {
    const childTest = childrens.find((children) => children.cpf === child.cpf);

    if (childTest === undefined || childTest.cpf === '' || !childTest.cpf) {
      return true;
    }

    if (childTest.cpf === childrens[indexChild].cpf) {
      return true;
    }
    if (childTest) {
      setErrorChildren([
        {
          path: 'cpf',
          message: 'Este CPF já esta em uso por outra criança',
        },
      ]);
      return false;
    }
  }

  async function AddChildren() {
    setLoading(true);
    setChild({
      ...child,
      cpf: removeMask(child.cpf),
      birth_date: child.birth_date.split('T')[0],
    });
    try {
      await childrensSchema.validate(child, { abortEarly: false });
      setErrorChildren([]);

      if (child.cpf === '') {
        delete child.cpf;
      } else if (UniqueCPF() === false) {
        setLoading(false);
        return false;
      }

      if (users.employee) {
        setModalState(false);
        await api
          .post('/users/employee/children', [child])
          .then((response) => {
            const resEmployee = users.employee;
            const childrensTemp = childrens;
            childrensTemp.push(response.data[0]);
            setChildrens([...childrensTemp]);
            resEmployee.children = childrensTemp;
            dispatch(UpdateUserOnReducer({ employee: resEmployee }));
            toast('Criança adicionada com sucesso');
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      } else {
        const childrensTemp = childrens;
        childrensTemp.push(child);
        setChildrens([...childrensTemp]);
        toast('Criança adicionada com sucesso');
      }

      setChild({ name: '', cpf: '', birth_date: '' });
      setErrorChildren([]);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        setErrorChildren(error.inner);
        setLoading(false);
      }
    }
    setLoading(false);
  }

  function RemoveChildren(index, id) {
    setLoading(true);
    if (id) {
      api
        .delete(`/users/employee/children/delete/${id}`)
        .then(() => {
          const user = users;
          user.employee.children.splice(index, 1);
          dispatch(UpdateUserOnReducer(user));
          toast('Criança removida com sucesso');
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else {
      const childrensTemp = childrens;
      childrensTemp.splice(index, 1);
      setChildrens([...childrensTemp]);
      toast('Criança removida com sucesso');
    }
    setLoading(false);
  }

  async function EditChildren() {
    if (!child.cpf || child.cpf === undefined) {
      setChild({ ...child, cpf: '' });
    }
    setChild({ ...child, birth_date: child.birth_date.split('T')[0] });

    setLoading(true);

    try {
      await childrensSchema.validate(
        { ...child, cpf: !child.cpf ? '' : child.cpf },
        { abortEarly: false },
      );
      setErrorChildren([]);

      if (child.cpf === '') {
        delete child.cpf;
      } else if (UniqueCPF() === false) {
        setLoading(false);
        return false;
      }

      delete child.created_at;
      delete child.updated_at;

      if (child.id) {
        api
          .patch(`/users/employee/children/update/${child.id}`, {
            ...child,
            birth_date: child.birth_date.split('T')[0],
          })
          .then(() => {
            const childrensTemp = childrens;
            childrensTemp[indexChild] = child;
            setChildrens(childrensTemp);
            const resEmployee = users.employee;
            resEmployee.children = childrens;
            dispatch(UpdateUserOnReducer({ employee: resEmployee }));
            toast('Criança editada com sucesso');
            closeModal();
          })
          .catch((error) => console.log(error));
      } else {
        const childrensTemp = childrens;
        childrensTemp[indexChild] = child;
        setChildrens(childrensTemp);
        toast('Criança editada com sucesso');
        closeModal();
      }
      setChild({ name: '', cpf: '', birth_date: '' });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        setErrorChildren(error.inner);
        setLoading(false);
      }
    }
    setLoading(false);
  }

  return (
    <Content>
      <Loading open={loading} />
      <ToastContainer />
      <header>
        <h1>Cadastro de admissão</h1>
      </header>
      <div className="container">
        <form id="Form">
          <section>
            <h1>Dados da empresa</h1>
            <div className="inputContainer start ">
              <fieldset className="largeInput">
                <p>Empresa</p>
                <input type="text" value={users.mei.fantasy_name} />
              </fieldset>
              <fieldset className="largeInput">
                <p>CNPJ</p>
                <input type="text" value={users.mei.cnpj} />
              </fieldset>
            </div>
          </section>
          <section>
            <h1>Dados do empregado</h1>
            <div className="inputContainer employeeInputs">
              <fieldset className="largeInput">
                <p>Nome</p>
                <input
                  type="text"
                  name="name_employee"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name_employee}
                />
                {formik.touched.name_employee && formik.errors.name_employee ? (
                  <div className="errors">{formik.errors.name_employee}</div>
                ) : null}
              </fieldset>
              <fieldset className="largeInput">
                <p>Nome do pai</p>
                <input
                  type="text"
                  name="father_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.father_name}
                />
                {formik.touched.father_name && formik.errors.father_name ? (
                  <div className="errors">{formik.errors.father_name}</div>
                ) : null}
              </fieldset>
              <fieldset className="largeInput">
                <p>Nome da mãe</p>
                <input
                  type="text"
                  name="mother_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mother_name}
                />
                {formik.touched.mother_name && formik.errors.mother_name ? (
                  <div className="errors">{formik.errors.mother_name}</div>
                ) : null}
              </fieldset>
              <fieldset className="largeInput">
                <p>Local de nascimento</p>
                <select
                  name="birth_city"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.birth_city}
                >
                  {formik.values.birth_uf === '' && (
                    <option value="">Selecione um UF antes</option>
                  )}
                  {cities.map((city) => (
                    <option value={city.nome}>{city.nome}</option>
                  ))}
                </select>
                {formik.touched.birth_uf && formik.errors.birth_city ? (
                  <div className="errors">{formik.errors.birth_city}</div>
                ) : null}
              </fieldset>
              <fieldset className="shortInput">
                <p>Data de nascimento</p>
                <input
                  type="date"
                  name="birth_date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.birth_date}
                />
                {formik.touched.birth_date && formik.errors.birth_date ? (
                  <div className="errors">{formik.errors.birth_date}</div>
                ) : null}
              </fieldset>
              <fieldset className="shortInput">
                <p>UF</p>
                <select
                  name="birth_uf"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.birth_uf}
                >
                  <option value="">Selecione o UF</option>
                  {brazilianUf.map((uf) => (
                    <option value={uf.uf}>{uf.uf}</option>
                  ))}
                </select>
                {formik.touched.birth_uf && formik.errors.birth_uf ? (
                  <div className="errors">{formik.errors.birth_uf}</div>
                ) : null}
              </fieldset>
              <fieldset className="shortInput">
                <p>Gênero</p>
                <select
                  name="sex"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.sex}
                >
                  <option value="">Selecione o gênero</option>
                  <option value="masculino">Masculino</option>
                  <option value="feminino">Feminino</option>
                  <option value="nao-quero-informar">Não quero informar</option>
                </select>
                {formik.touched.sex && formik.errors.sex ? (
                  <div className="errors">{formik.errors.sex}</div>
                ) : null}
              </fieldset>
              <fieldset className="shortInput">
                <p>Estado Civil</p>
                <select
                  name="civil_state"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.civil_state}
                >
                  <option value="">Selecione o seu estado civil</option>
                  <option value="solteiro">solteiro</option>
                  <option value="casado">casado</option>
                  <option value="divorciado">divorciado</option>
                  <option value="uniao-estavel">União Estável</option>
                  <option value="viuvo">Viúvo</option>
                </select>
                {formik.touched.civil_state && formik.errors.civil_state ? (
                  <div className="errors">{formik.errors.civil_state}</div>
                ) : null}
              </fieldset>
              <fieldset className="largeInput">
                <p>Endereço residencial</p>
                <input
                  type="text"
                  name="street_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.street_name}
                />
                {formik.touched.street_name && formik.errors.street_name ? (
                  <div className="errors">{formik.errors.street_name}</div>
                ) : null}
              </fieldset>
              <fieldset className="shortInput">
                <p>Numero</p>
                <input
                  type="text"
                  name="street_number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={numberMask(formik.values.street_number)}
                />
                {formik.touched.street_number && formik.errors.street_number ? (
                  <div className="errors">{formik.errors.street_number}</div>
                ) : null}
              </fieldset>
              <fieldset className="shortInput">
                <p>Bairro</p>
                <input
                  type="text"
                  name="neighborhood"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.neighborhood}
                />
                {formik.touched.neighborhood && formik.errors.neighborhood ? (
                  <div className="errors">{formik.errors.neighborhood}</div>
                ) : null}
              </fieldset>
              <fieldset
                className={
                  formik.values.civil_state === 'casado'
                    ? 'shortInput'
                    : 'largeInput'
                }
              >
                <p>CEP</p>
                <input
                  maxLength="9"
                  type="text"
                  name="zip_code"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={zipCodeMask(formik.values.zip_code)}
                />
                {formik.touched.zip_code && formik.errors.zip_code ? (
                  <div className="errors">{formik.errors.zip_code}</div>
                ) : null}
              </fieldset>
              {formik.values.civil_state === 'casado' && (
                <fieldset className="shortInput">
                  <p>Regime de partilhas</p>
                  <select
                    name="civil_condition"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.civil_condition}
                  >
                    <option value="">Selecione o regime</option>
                    <option value="parcial">Regime de comunhão parcial</option>
                    <option value="universal">
                      Regime de comunhão universal
                    </option>
                    <option value="separacao">
                      Regime de separação de bens
                    </option>
                    <option value="participacao">
                      Regime de participação funal nos aquestos
                    </option>
                  </select>
                  {formik.touched.civil_condition &&
                  formik.errors.civil_condition ? (
                    <div className="errors">
                      {formik.errors.civil_condition}
                    </div>
                  ) : null}
                </fieldset>
              )}
              <fieldset className="largeInput">
                <p>CTPS</p>
                <input
                  maxLength="7"
                  type="text"
                  name="ctps_number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={numberMask(formik.values.ctps_number)}
                />
                {formik.touched.ctps_number && formik.errors.ctps_number ? (
                  <div className="errors">{formik.errors.ctps_number}</div>
                ) : null}
              </fieldset>
              <fieldset className="shortInput">
                <p>Serie</p>
                <input
                  maxLength="4"
                  type="text"
                  name="ctps_serie"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={numberMask(formik.values.ctps_serie)}
                />
                {formik.touched.ctps_serie && formik.errors.ctps_serie ? (
                  <div className="errors">{formik.errors.ctps_serie}</div>
                ) : null}
              </fieldset>
              <fieldset className="shortInput">
                <p>UF</p>
                <select
                  name="ctps_emission_uf"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ctps_emission_uf}
                >
                  <option value="">Selecione o UF</option>
                  {brazilianUf.map((uf) => (
                    <option value={uf.uf}>{uf.uf}</option>
                  ))}
                </select>
                {formik.touched.ctps_emission_uf &&
                formik.errors.ctps_emission_uf ? (
                  <div className="errors">{formik.errors.ctps_emission_uf}</div>
                ) : null}
              </fieldset>
              <fieldset className="shortInput">
                <p>Data de emissão</p>
                <input
                  type="date"
                  name="ctps_emission_date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ctps_emission_date}
                />
                {formik.touched.ctps_emission_date &&
                formik.errors.ctps_emission_date ? (
                  <div className="errors">
                    {formik.errors.ctps_emission_date}
                  </div>
                ) : null}
              </fieldset>
              <fieldset className="shortInput">
                <p>Grau de instrução</p>
                <select
                  name="instruction_rank"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.instruction_rank}
                >
                  <option value="">Selecione o grau de instrução</option>
                  <option value="analfabeto">Analfabeto</option>
                  <option value="1-grau-incompleto">1º grau incompleto</option>
                  <option value="1-grau-completo">1º grau completo</option>
                  <option value="2-grau-incompleto">2º grau incompleto</option>
                  <option value="2-grau-completo">2º grau completo</option>
                  <option value="superior-incompleto">
                    Superior Incompleto
                  </option>
                  <option value="superior-completo">Superior Completo</option>
                  <option value="pos-graduacao-especializacao">
                    Pós-Graduação/Especialização
                  </option>
                  <option value="mestrado">Mestrado</option>
                  <option value="doutorado">Doutorado</option>
                  <option value="pos-dotourado">Pós-Doutorado</option>
                </select>
                {formik.touched.instruction_rank &&
                formik.errors.instruction_rank ? (
                  <div className="errors">{formik.errors.instruction_rank}</div>
                ) : null}
              </fieldset>
              <fieldset className="largeInput">
                <p>PIS</p>
                <input
                  maxLength="11"
                  type="text"
                  name="pis_number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={numberMask(formik.values.pis_number)}
                />
                {formik.touched.pis_number && formik.errors.pis_number ? (
                  <div className="errors">{formik.errors.pis_number}</div>
                ) : null}
              </fieldset>
              <fieldset className="shortInput">
                <p>CI</p>
                <input
                  maxLength="11"
                  type="text"
                  name="ci_number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={numberMask(formik.values.ci_number)}
                />
                {formik.touched.ci_number && formik.errors.ci_number ? (
                  <div className="errors">{formik.errors.ci_number}</div>
                ) : null}
              </fieldset>
              <fieldset className="shortInput">
                <p>Data de emissão</p>
                <input
                  type="date"
                  name="ci_emission_date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ci_emission_date}
                />
                {formik.touched.ci_emission_date &&
                formik.errors.ci_emission_date ? (
                  <div className="errors">{formik.errors.ci_emission_date}</div>
                ) : null}
              </fieldset>
              <fieldset className="shortInput">
                <p>Órgão expedidor</p>
                <input
                  type="text"
                  name="ci_institution_emission"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ci_institution_emission}
                />
                {formik.touched.ci_institution_emission &&
                formik.errors.ci_institution_emission ? (
                  <div className="errors">
                    {formik.errors.ci_institution_emission}
                  </div>
                ) : null}
              </fieldset>
              <fieldset className="shortInput">
                <p>UF</p>
                <select
                  name="ci_uf_emission"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ci_uf_emission}
                >
                  <option value="">Selecione o UF</option>
                  {brazilianUf.map((uf) => (
                    <option value={uf.uf}>{uf.uf}</option>
                  ))}
                </select>
                {formik.touched.ci_uf_emission &&
                formik.errors.ci_uf_emission ? (
                  <div className="errors">{formik.errors.ci_uf_emission}</div>
                ) : null}
              </fieldset>
              <fieldset className="largeInput">
                <p>CPF</p>
                <input
                  maxLength="14"
                  type="text"
                  name="cpf"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={cpfMask(formik.values.cpf)}
                />
                {formik.touched.cpf && formik.errors.cpf ? (
                  <div className="errors">{formik.errors.cpf}</div>
                ) : null}
              </fieldset>
              <fieldset className="shortInput">
                <p>Titulo de eleitor</p>
                <input
                  maxLength="12"
                  type="text"
                  name="eleitoral_number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={numberMask(formik.values.eleitoral_number)}
                />
                {formik.touched.eleitoral_number &&
                formik.errors.eleitoral_number ? (
                  <div className="errors">{formik.errors.eleitoral_number}</div>
                ) : null}
              </fieldset>
              <fieldset className="shortInput">
                <p>Data de emissão</p>
                <input
                  type="date"
                  name="eleitoral_number_emission_date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.eleitoral_number_emission_date}
                />
                {formik.touched.eleitoral_number_emission_date &&
                formik.errors.eleitoral_number_emission_date ? (
                  <div className="errors">
                    {formik.errors.eleitoral_number_emission_date}
                  </div>
                ) : null}
              </fieldset>
              <fieldset className="shortInput">
                <p>Zona</p>
                <input
                  type="text"
                  name="eleitoral_number_zone"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={numberMask(formik.values.eleitoral_number_zone)}
                />
                {formik.touched.eleitoral_number_zone &&
                formik.errors.eleitoral_number_zone ? (
                  <div className="errors">
                    {formik.errors.eleitoral_number_zone}
                  </div>
                ) : null}
              </fieldset>
              <fieldset className="shortInput">
                <p>Seção</p>
                <input
                  type="text"
                  name="eleitoral_number_session"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={numberMask(formik.values.eleitoral_number_session)}
                />
                {formik.touched.eleitoral_number_session &&
                formik.errors.eleitoral_number_session ? (
                  <div className="errors">
                    {formik.errors.eleitoral_number_session}
                  </div>
                ) : null}
              </fieldset>
            </div>
          </section>
          <section>
            <h1>Informações do contrato</h1>
            <div className="inputContainer employeeInputs">
              <fieldset className="largeInput">
                <p>Função</p>
                <input
                  type="text"
                  name="function"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.function}
                />
                {formik.touched.function && formik.errors.function ? (
                  <div className="errors">{formik.errors.function}</div>
                ) : null}
              </fieldset>
              <fieldset className="largeInput">
                <p>Atividade</p>
                <input
                  type="text"
                  name="activity"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.activity}
                />
                {formik.touched.activity && formik.errors.activity ? (
                  <div className="errors">{formik.errors.activity}</div>
                ) : null}
              </fieldset>
              <fieldset className="mediumInput">
                <p>Data de admissão</p>
                <input
                  type="date"
                  name="company_admission_date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.company_admission_date}
                />
                {formik.touched.company_admission_date &&
                formik.errors.company_admission_date ? (
                  <div className="errors">
                    {formik.errors.company_admission_date}
                  </div>
                ) : null}
              </fieldset>
              <fieldset className="mediumInput">
                <p>Salário</p>
                <input
                  type="number"
                  step="0.01"
                  name="income"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.income}
                />
                {formik.touched.income && formik.errors.income ? (
                  <div className="errors">{formik.errors.income}</div>
                ) : null}
              </fieldset>
              <fieldset className="shortInput">
                <p>Insalubridade</p>
                <input
                  type="number"
                  step="0.01"
                  name="insalubrity"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.insalubrity}
                />
                {formik.touched.insalubrity && formik.errors.insalubrity ? (
                  <div className="errors">{formik.errors.insalubrity}</div>
                ) : null}
              </fieldset>
              <fieldset className="shortInput">
                <p>Vale transporte</p>
                <input
                  type="number"
                  step="0.01"
                  name="ticket_transport"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ticket_transport}
                />
                {formik.touched.ticket_transport &&
                formik.errors.ticket_transport ? (
                  <div className="errors">{formik.errors.ticket_transport}</div>
                ) : null}
              </fieldset>
              <fieldset className="shortInput">
                <p>Quantidade de vales por dia</p>
                <input
                  type="number"
                  name="ticket_quantity"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ticket_quantity}
                />
                {formik.touched.ticket_quantity &&
                formik.errors.ticket_quantity ? (
                  <div className="errors">{formik.errors.ticket_quantity}</div>
                ) : null}
              </fieldset>
              <fieldset className="shortInput">
                <p>Data atestado médico</p>
                <input
                  type="date"
                  name="medical_test_date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.medical_test_date}
                />
                {formik.touched.medical_test_date &&
                formik.errors.medical_test_date ? (
                  <div className="errors">
                    {formik.errors.medical_test_date}
                  </div>
                ) : null}
              </fieldset>
              <fieldset className="shortInput">
                <p>CRM</p>
                <input
                  type="text"
                  name="crm"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={numberMask(formik.values.crm)}
                />
                {formik.touched.crm && formik.errors.crm ? (
                  <div className="errors">{formik.errors.crm}</div>
                ) : null}
              </fieldset>
              <fieldset className="shortInput">
                <p>Médico</p>
                <input
                  type="text"
                  name="doctor_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.doctor_name}
                />
                {formik.touched.doctor_name && formik.errors.doctor_name ? (
                  <div className="errors">{formik.errors.doctor_name}</div>
                ) : null}
              </fieldset>
              <fieldset className="largeInput">
                <p>Contrato de experiência (dias)</p>
                <input
                  type="number"
                  name="experience_days_contract"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.experience_days_contract}
                />
                {formik.touched.experience_days_contract &&
                formik.errors.experience_days_contract ? (
                  <div className="errors">
                    {formik.errors.experience_days_contract}
                  </div>
                ) : null}
              </fieldset>
            </div>
          </section>
          <section>
            <h1>Filhos(as) menores de 14 anos</h1>

            {childrens &&
              childrens.map((item, index) => (
                <div className="inputContainer">
                  <fieldset className="largeInput">
                    <p>Nome</p>
                    <input type="text" name="name" value={item.name} />
                    {errorChildren ? (
                      <div className="errors">{getError(index, 'name')}</div>
                    ) : null}
                  </fieldset>
                  <fieldset className="largeInput">
                    <p>CPF</p>
                    <input
                      type="text"
                      maxLength="14"
                      name="cpf"
                      value={item.cpf ? cpfMask(item.cpf) : ''}
                    />
                    {errorChildren ? (
                      <div className="errors">{getError(index, 'cpf')}</div>
                    ) : null}
                  </fieldset>
                  <fieldset className="mediumInput">
                    <p>Data de nascimento</p>
                    <input
                      type="date"
                      name="birth_date"
                      value={item.birth_date.split('T')[0]}
                    />
                    {errorChildren ? (
                      <div className="errors">
                        {getError(index, 'birth_date')}
                      </div>
                    ) : null}
                  </fieldset>

                  <Button
                    backgroundColor="transparent"
                    color={colors.orange}
                    border={colors.orange}
                    radius="8px"
                    hover="#D26A24"
                    active="#FF6600"
                    width="100px"
                    height="50px"
                    onClick={() => RemoveChildren(index, item.id)}
                    text="Excluir"
                  />

                  <Button
                    backgroundColor={colors.orange}
                    radius="8px"
                    hover="#D26A24"
                    active="#FF6600"
                    width="100px"
                    height="50px"
                    onClick={() => {
                      setAction('edit');
                      setModalState(true);
                      setChild(childrens[index]);
                      setIndexChild(index);
                    }}
                    text="Editar"
                  />
                </div>
              ))}

            <div className="addNewChildrenContainer">
              <Button
                marginRight="30px"
                marginTop="10px"
                marginLeft="10px"
                backgroundColor={colors.orange}
                radius="8px"
                hover="#D26A24"
                active="#FF6600"
                width="170px"
                height="50px"
                onClick={() => {
                  setAction('register');
                  setModalState(true);
                }}
                text="Adicionar"
              />
            </div>
          </section>
          <section>
            <h1>Documentos necessários</h1>

            <ul>
              <p>
                Enviar os documentos da lista abaixo para o email
                atendimento@numecontabil.com.br
              </p>
              <li>Atestado médico</li>
              <li>Carteira profissional</li>
              <li>CNIS (PREVIDÊNCIA SOCIAL) </li>
              <li>1 foto 3x4</li>
              <li>CPF</li>
              <li>CI/RG</li>
              <li>PIS - Caso não conste na carteira de trabalho</li>
              <li>Título de eleitor</li>
              <li>
                Comprovante de Endereço (conta da luz parte com CEP, não
                consumo)
              </li>
              <li>
                Certidão nascimento dos filhos menores de 14 anos, os menores de
                5 anos carteira de vacinação também.
              </li>
            </ul>
          </section>

          <div className="actions">
            <Button
              backgroundColor="transparent"
              color={colors.orange}
              border={colors.orange}
              radius="8px"
              hover="#D26A24"
              active="#FF6600"
              width="170px"
              height="50px"
              text="Remover funcionário"
              onClick={deleteEmployee}
            />
            <Button
              marginRight="30px"
              marginLeft="10px"
              backgroundColor={colors.orange}
              radius="8px"
              hover="#D26A24"
              active="#FF6600"
              width="170px"
              height="50px"
              onClick={formik.handleSubmit}
              text={users.employee ? 'Editar' : 'Cadastrar'}
            />
          </div>
        </form>
      </div>

      <ModalSelect open={modalState} close={closeModal}>
        <div className="childrenContainer">
          <fieldset>
            <p>Nome</p>
            <input
              type="text"
              name="name"
              value={child.name}
              onChange={(e) => {
                setChild({ ...child, [e.target.name]: e.target.value });
              }}
            />
            {errorChildren ? (
              <div className="errors">{getError('name')}</div>
            ) : null}
          </fieldset>
          <fieldset>
            <p>CPF</p>
            <input
              type="text"
              maxLength="14"
              name="cpf"
              value={child.cpf ? cpfMask(child.cpf) : ''}
              onChange={(e) => {
                setChild({
                  ...child,
                  [e.target.name]: removeMask(e.target.value),
                });
              }}
            />
            {errorChildren ? (
              <div className="errors">{getError('cpf')}</div>
            ) : null}
          </fieldset>
          <fieldset>
            <p>Data de nascimento</p>
            <input
              type="date"
              name="birth_date"
              value={child.birth_date.split('T')[0]}
              onChange={(e) =>
                setChild({ ...child, [e.target.name]: e.target.value })
              }
            />
            {errorChildren ? (
              <div className="errors">{getError('birth_date')}</div>
            ) : null}
          </fieldset>

          <div className="actions">
            <Button
              backgroundColor="transparent"
              color={colors.orange}
              border={colors.orange}
              radius="8px"
              hover="#D26A24"
              active="#FF6600"
              width="40%"
              height="40px"
              text="Cancelar"
              onClick={closeModal}
            />

            <Button
              backgroundColor={colors.orange}
              radius="8px"
              hover="#D26A24"
              active="#FF6600"
              width="40%"
              height="40px"
              onClick={() =>
                action === 'edit' ? EditChildren() : AddChildren()
              }
              text={action === 'edit' ? 'Editar' : 'Cadastrar'}
            />
          </div>
        </div>
      </ModalSelect>
    </Content>
  );
};

export default EmployeeRegister;
