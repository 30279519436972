import styled from 'styled-components';
import { colors } from '../../constants/colors';
import { size, device } from '../../constants/size';

export const Container = styled.div`
  position: relative;
`;

export const Mobile = styled.div`
  @media ${device.mobile} {
    z-index: 50;
    display: flex;
  }
  @media ${device.desktop} {
    display: none;
  }
  padding-left: ${size.mobile.padding};
  padding-right: ${size.mobile.padding};
  background-color: ${colors.white};
  height: 4em;
  display: flex;
  justify-content: space-between;
  #imageWrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        object-fit: contain;
        width: 100%;
      }
    }
  }
  #buttonWrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      object-fit: contain;
      width: 100%;
    }
  }
  .menu {
    z-index: 5000;
    position: absolute;
    display: block;
    background-color: ${colors.white};
    width: 100%;
    height: 50vh;
    left: 0;
    top: -50vh;
    display: block;
    transition: ease-in-out 0.5s;
    #head {
      height: 4em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        object-fit: contain;
        height: 50%;
        padding-left: ${size.mobile.padding};
      }
      #closeButton {
        position: relative;
        content: '';
        display: block;
        background-color: transparent;
        width: 4em;
        height: 4em;
        &::before {
          position: absolute;
          width: 4px;
          height: 2em;
          background-color: ${colors.orange};
          display: block;
          content: '';
          left: 50%;
          top: 50%;
          border-radius: 50px;
          transform: translate(-50%, -50%) rotate(45deg);
        }
        &::after {
          position: absolute;
          width: 4px;
          height: 2em;
          background-color: ${colors.orange};
          display: block;
          content: '';
          left: 50%;
          top: 50%;
          border-radius: 50px;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
    #list {
      height: calc(90% - 4em);
      ul {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        gap: 1em;
        list-style: none;
        li {
          color: ${colors.darkGray};
          font-weight: bold;
          a {
            color: inherit;
            text-decoration: none;
          }
        }
      }
    }
  }
  .expand {
    transition: ease-in-out 0.5s;
    top: 0vh;
  }
`;

export const Desktop = styled.div`
  @media ${device.mobile} {
    display: none;
  }
  @media ${device.desktop} {
    display: flex;
  }
  width: 100%;
  z-index: 7;
  position: absolute;
  padding-left: ${size.desktop.padding};
  padding-right: ${size.desktop.padding};
  background-color: transparent;
  height: 8em;
  flex-direction: row;
  align-items: center;
  #imageWrapper {
    height: 100%;
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  ul {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    width: 80%;
    li {
      font-size: ${size.desktop.fontSize};
      cursor: pointer;
      color: ${colors.white};
      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }
`;
