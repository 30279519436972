import React, { useRef, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header';
import SectionTwo from './SectionTwo';
import SectionThree from './SectionThree';
import Footer from '../../components/Footer';
import SectionOne from './SectionOne';
import FormDeDuvidas from './SectionFour';

function Home() {
  const refForm = useRef(null);
  const { search } = useLocation();

  useEffect(() => {
    const welcome = new URLSearchParams(search).get('welcome');
    if (welcome === 'true') {
      toast('Olá! Sua conta está confirmada.');
    }
  }, []);

  function scrollToQuestions() {
    if (refForm.current) {
      refForm.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }

  return (
    <>
      <Helmet>
        <title>Nume | Simplifique seu MEI</title>
      </Helmet>
      <ToastContainer />
      <Header />
      <SectionOne scroll={scrollToQuestions} />
      <SectionTwo />
      <SectionThree />
      <FormDeDuvidas reference={refForm} />
      <Footer />
    </>
  );
}

export default Home;
