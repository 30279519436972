import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { size, device } from '../../../constants/size';

export const Container = styled.div`
  height: auto;
  position: relative;
  #form {
    h3{
      align-self:flex-start;
      padding-left:${size.mobile.padding};
      font-weight:bold;
      font-size:1.5em;
      color:${colors.pink};
    }
    @media ${device.mobile}{
      position: relative;
      left:50%;
      height:400px;
      transform:translate(-50%, 0%);
      &::before{
        position: absolute;
        display:block;
        width:300px;
        height:50px;
        border-radius:8px;
        top:-20px;
        background-color:${colors.white};
        content:'';
      }
      &::after{
        position: absolute;
        display:block;
        width:300px;
        height:50px;
        border-radius:8px;
        bottom:-20px;
        background-color:${colors.white};
        z-index:5;
        content:'';
      }
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    z-index: 5;
    width: 300px;
    height: 400px;
    top: 12%;
    right: 20%;
    position: absolute;
    background-color: ${colors.white};
    border-radius: 8px;
    input {
      width: 90%;
      background-color:transparent;
      border:none;
      border-bottom:1px solid ${colors.darkGray};
    }
  }
  #one {
    display: flex;
    width: 100%;
    @media ${device.mobile} {
      height: 32vh;
    }
    height: 70vh;
    overflow: hidden;
    position: relative;
    #background1{
      position:absolute;
      width:120%;
      object-fit:contain;
      height:auto;
      top:-30%;
      left:-10%;
      @media ${device.mobile} {
        width:100%;
        left:0%;
        top:0%;
      }
    }
    #background2{
      position:absolute;
      height:140%;
      object-fit:contain;
      top:-10%;
      left:0%;
      @media ${device.mobile} {
        height:120%;
        top:-5%;
        left:-25%;
      }
     
    }
    &::before {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 1;
    }
    &::after {
      display: block;
      position: absolute;
      align-self: flex-end;
      bottom: 0;
      left: 0;
      content: '';
      width: 55%;
      height: 10px;
      background: ${colors.orange};
      z-index: 300;
    }
    #abrirMei {
      @media ${device.mobile} {
        top: 10%;
        left: 10%;
        flex-direction: column;
        align-items: flex-start;
        width:80%;
      }
      flex-direction: column;
      z-index: 2;
      position: absolute;
      bottom: 20%;
      left: 15%;
      display: flex;
      align-items: flex-start;
      width:30%;
      z-index:5;
      p{
        @media ${device.mobile} {
            font-size: 0.9em;
          }
          padding-top: 10px;
          font-size: 1.1em;
          color: ${colors.white};
          font-weight: bold;
        }
      }
    }
    #wrapper {
      @media ${device.mobile} {
        bottom: 15%;
        left: 10%;
        flex-direction: column;
        align-items: flex-start;
      }
      z-index: 2;
      position: absolute;
      bottom: 20%;
      left: 15%;
      display: flex;
      align-items: center;
      #logo {
        @media ${device.mobile} {
          width: 100px;
          height: 100px;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 160px;
        height: 160px;
        background: transparent
          linear-gradient(147deg, ${colors.orange} 0%, ${colors.pink} 100%) 0%
          0% no-repeat padding-box;
        border-radius: 31px;
        img {
          @media ${device.mobile} {
            height: 40%;
          }
          object-fit: contain;
          height: 50%;
        }
        p {
          @media ${device.mobile} {
            font-size: 0.9em;
          }
          padding-top: 10px;
          font-size: 1.1em;
          color: ${colors.white};
          font-weight: bold;
        }
      }
      #textWrapperOne {
        @media ${device.mobile} {
          padding-left: 0;
          padding-top: ${size.mobile.padding};
          font-size: 1.2em;
        }
        padding-left: ${size.mobile.padding};
        font-size: 1.8em;
        color: ${colors.white};
        font-weight: bold;
      }
    }
  }
  #two {
    @media ${device.mobile} {
      flex-direction: column;
      padding-left: ${size.mobile.padding};
      padding-right: ${size.mobile.padding};
      height: 40%;
    }
    padding-left: ${size.desktop.padding};
    padding-right: ${size.desktop.padding};
    height: 30%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    #form {
      z-index: 2;
      position: absolute;
      display: block;
      background-color: ${colors.orange};
      width: 300px;
      height: 400px;
      border-radius: 8px;
    }
    #price-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 35%;
      @media (max-width: 768px) {
        margin-top: 1em;
        margin-bottom: .5em;
      }
      h3 {
        font-size: 45px;
        color: ${colors.orange};
        padding-left: .2em;
        padding-right: .1em;
      }
      h4 {
        font-size: 25px;
        color: ${colors.orange};
        padding-top: 0.3em;
      }
      span {
        font-size: 16px;
        padding-top: 1.5em;
        color: ${colors.orange};
      }
    }
    #textWrapperTwo {
      @media ${device.mobile} {
        font-size: 0.75rem;
        align-self: flex-start;
        p {
          display: inline-block;
          font-weight: bold;
        }
      }
      color: ${colors.darkGray};
      font-size: 1.2em;
    }
    
  }
  #three {
    height: 80vh;
    display: flex;
    position: relative;
    background: transparent
      linear-gradient(0deg, ${colors.orange} 0%, ${colors.pink} 100%) 0% 0%
      no-repeat padding-box;
    #title {
      position: relative;
      width: 40%;
      @media ${device.mobile} {
        width: 100%;
      }
      img {
        @media ${device.mobile} {
          height: 50%;
        }
        position: absolute;
        opacity: 0.2;
        height: 80%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        object-fit: contain;
      }
      #text {
        @media ${device.mobile} {
          top: 15%;
          left: 30%;
        }
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        p {
          @media ${device.mobile} {
            font-size: 1.2em;
          }
          font-size: 1.7em;
          color: ${colors.white};
          font-weight: bold;
        }
      }
    }
    ul {
      @media ${device.mobile} {
        position: absolute;
        top: 25%;
        left: 0%;
        width: 100%;
        height: 66%;
        gap: 1.5em;
      }
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      li {
        width: 80%;
        display: flex;
        .icon {
          @media ${device.mobile} {
            width: 120px;
          }
          width: 80px;

          img {
            object-fit: contain;
            width: 100%;
          }
        }
        .itemWrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-left: ${size.mobile.padding};
          .itemTitle {
            font-size: 1em;
            color: ${colors.white};
            font-weight: bold;
          }
          .itemText {
            font-size: 0.8em;
            color: ${colors.white};
          }
        }
      }
    }
  }
  // eslint-disable-next-line
`;
