import styled from 'styled-components';

import { colors } from '../../constants/colors';

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  position: relative;
  img {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 2em;
    top: 1em;
    cursor: pointer;
  }
  h1 {
    font-weight: 500;
    color: ${colors.orange};
    padding-top: 1em;
  }
  ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 80%;
    height: 30%;
    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.3em;
      width: 100%;
      height: 100%;
      align-self: center;
      fieldset {
        position: relative;
        width: 80%;
        height: 50%;
        border: none;
        .eye {
          position: absolute;
          right: 1em;
          top: 0.5em;
          cursor: pointer;
        }
        input {
          width: 100%;
          height: 100%;
          font-size: 15px;
          border-radius: 8px;
          padding-left: 0.5em;
        }
      }

      span {
        width: 80%;
      }
    }
  }
`;
