/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import {
  Button,
  CancelButton,
  ErrorBox,
  FormDiv,
  Input,
  Label,
} from '../Profile/styles';
import Header from '../../../components/Mobile/Header';
import Footer from '../../../components/Mobile/Footer';
import { childSchema, initial, schema } from '../../../utils/employeeConstants';
import { cpfMask, removeMask } from '../../../utils/mask';
import { Modal } from '../Relatorios/styles';
import BrazilianStates from '../../../utils/brazilianStates';
import { NavBar, Container } from './styles';
import api, { apiCep, apiCities } from '../../../services/api';
import Loading from '../../../components/Loading';
import {
  UpdateEmployeeRequest,
  UpdateUserOnReducer,
} from '../../../store/ducks/auth/auth';
import { EmployeeForm } from './EmployeeForm';
import { ContractForm } from './ContractForm';
import { ChildrenForm } from './ChildrenForm';

const EmployeeRegistrationMobile = () => {
  const users = useSelector((state) => state.auth.user);
  const initialValue = initial(users);
  const brazilianUf = BrazilianStates();
  const [isLoading, setIsLoading] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [idChildEdit, setIdChildEdit] = useState(false);
  const [indexChild, setIndexChild] = useState(false);
  const [page, setPage] = useState(0);
  const [children, setChildren] = useState([]);
  const [cities, setCities] = useState([]);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: schema,
    // eslint-disable-next-line no-use-before-define
    onSubmit: (values) => handleSubmitEmployee(values),
  });

  const childFormik = useFormik({
    initialValues: {
      name: '',
      cpf: '',
      birth_date: '',
    },
    validationSchema: childSchema,
    onSubmit: (values) => {
      addChild(values);
    },
  });

  useEffect(() => {
    if (users.plan.name !== 'Premium') {
      push('/dashboard');
    }
  }, []);

  async function handleSubmitEmployee(values) {
    const data = {
      ...values,
      income: String(formik.values.income),
      insalubrity: String(formik.values.insalubrity),
      ticket_transport: String(formik.values.ticket_transport),
      ticket_quantity: String(formik.values.ticket_quantity),
      experience_days_contract: String(formik.values.experience_days_contract),
    };

    if (!users.employee) {
      setIsLoading(true);
      try {
        await api.post('/users/employee', data).then(async (response) => {
          dispatch(UpdateUserOnReducer({ employee: response.data }));

          setIsLoading(false);
          toast.success('Cadastro do funcionário realizado com sucesso!');
        });
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        toast.error('Erro ao cadastrar funcionário');
      }
    } else {
      dispatch(UpdateEmployeeRequest({ ...data, children }));
      toast.success('Cadastro editado com sucesso!');
      setIsLoading(false);
    }
  }

  function resetForm() {
    childFormik.values.name = '';
    childFormik.values.cpf = '';
    childFormik.values.birth_date = '';
  }

  function UniqueCPF() {
    const childTest = children.find(
      (element) => element.cpf === childFormik.values.cpf,
    );

    if (childTest === undefined || childTest.cpf === '' || !childTest.cpf) {
      return true;
    }
    if (
      childTest.cpf &&
      indexChild &&
      childTest.cpf === children[indexChild].cpf
    ) {
      return true;
    }
    if (childTest) {
      toast.error('Este CPF já esta em uso por outra criança');
      return false;
    }
  }

  function openModal(method, child, index) {
    if (method === 'edit') {
      childFormik.values.name = child.name;
      childFormik.values.cpf = child.cpf;
      childFormik.values.birth_date = child.birth_date;
      setIndexChild(index);
      setIdChildEdit(child.id);
    }
    setModalState(true);
  }

  function closeModal() {
    setIdChildEdit(false);
    setIndexChild(false);
    resetForm();
    childFormik.setErrors('');
    setModalState(false);
  }

  function deleteEmployee() {
    setIsLoading(true);
    api
      .delete('/users/employee/delete')
      .then(async () => {
        const user = users;
        delete user.employee;
        dispatch(UpdateUserOnReducer(user));
        setIsLoading(false);
        toast('Funcionário removido com sucesso');
        setTimeout(() => {
          setIsLoading(false);
          push('/dashboard');
        }, 5000);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  function removeCPF(values) {
    if (values.cpf === '') {
      // eslint-disable-next-line no-param-reassign
      delete values.cpf;
      return values;
    }
    return values;
  }

  async function addChild(values) {
    setIsLoading(true);

    const data = removeCPF(values);
    await api
      .post('/users/employee/children', [
        { ...data, birth_date: data.birth_date.split('T')[0] },
      ])
      .then((response) => {
        const resEmployee = users.employee;
        const childrenTemp = children;
        childrenTemp.push(response.data[0]);
        setChildren([...childrenTemp]);
        resEmployee.children = childrenTemp;
        dispatch(UpdateUserOnReducer({ employee: resEmployee }));
        setIsLoading(false);
        closeModal();
        toast('Criança adicionada com sucesso');
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  async function editChild() {
    setIsLoading(true);
    api
      .patch(`/users/employee/children/update/${idChildEdit}`, {
        name: childFormik.values.name,
        cpf: childFormik.values.cpf,
        birth_date: childFormik.values.birth_date.split('T')[0],
      })
      .then((response) => {
        const childrenTemp = children;
        childrenTemp[indexChild].name = childFormik.values.name;
        childrenTemp[indexChild].cpf = childFormik.values.cpf;
        childrenTemp[indexChild].birth_date = childFormik.values.birth_date;
        setChildren(childrenTemp);
        const resEmployee = users.employee;
        resEmployee.children = childrenTemp;
        dispatch(UpdateUserOnReducer({ employee: resEmployee }));
        setIsLoading(false);
        toast('Criança editada com sucesso');
        closeModal();
      })
      .catch((error) => console.log(error));
  }

  function deleteChild(id, index) {
    setIsLoading(true);

    api
      .delete(`/users/employee/children/delete/${id}`)
      .then(() => {
        const user = users;
        user.employee.children.splice(index, 1);
        dispatch(UpdateUserOnReducer(user));
        setIsLoading(false);
        toast('Criança removida com sucesso');
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (users.employee && users.employee.children) {
      setChildren(users.employee.children);
    }
  }, [users, children]);

  useEffect(() => {
    if (formik.values.birth_uf) {
      apiCities
        .get(`/${formik.values.birth_uf}/municipios`)
        .then(({ data }) => {
          setCities(data);
          formik.values.birth_city = data[0].nome;
        });
    } else if (formik.values.birth_uf === '') {
      setCities([]);
    }
  }, [formik.values.birth_uf]);

  useEffect(() => {
    if (formik.values.zip_code) {
      if (formik.values.zip_code.length >= 8) {
        apiCep.get(`/${formik.values.zip_code}/json`).then(({ data }) => {
          formik.values.street_name = data.logradouro;
          formik.values.neighborhood = data.bairro;
        });
      }
    }
  }, [formik.values.zip_code]);

  return (
    <Container>
      <div className="content">
        <Loading open={isLoading} />
        <ToastContainer />
        <Header thisPage="Cadastro de admissão" />
        <NavBar>
          <button
            type="button"
            className={page === 0 ? 'selected' : null}
            onClick={() => setPage(0)}
          >
            Empregado
          </button>
          <button
            type="button"
            className={page === 1 ? 'selected' : null}
            onClick={() => setPage(1)}
          >
            Contrato
          </button>
          <button
            type="button"
            className={page === 2 ? 'selected' : null}
            style={{ color: users.employee ? ' #ff6d24' : '#828282' }}
            onClick={() => {
              // eslint-disable-next-line no-unused-expressions
              users.employee
                ? setPage(2)
                : toast.error(
                    'Você deve cadastrar um funcionário antes de atribuir filhos a ele',
                  );
            }}
          >
            Crianças
          </button>
        </NavBar>
        <FormDiv>
          {page === 0 && (
            <EmployeeForm
              users={users}
              formik={formik}
              cities={cities}
              brazilianUf={brazilianUf}
            />
          )}

          {page === 1 && <ContractForm formik={formik} />}

          {page === 2 && (
            <ChildrenForm
              childrens={children}
              openModal={openModal}
              deleteChild={deleteChild}
            />
          )}

          {page !== 2 && (
            <Button
              style={{ margin: '15px 0' }}
              type="button"
              onClick={() => {
                page === 0 ? setPage(1) : formik.handleSubmit();
                formik.isValid === false && page === 1
                  ? toast('Algum campo não foi preenchido corretamente')
                  : null;
              }}
            >
              {page === 0
                ? 'Proxima'
                : users.employee
                ? 'Editar cadastro'
                : 'Cadastrar'}
            </Button>
          )}
          {users.employee && page !== 2 && (
            <CancelButton
              style={{ margin: '15px 0', width: '100%' }}
              type="button"
              onClick={deleteEmployee}
            >
              Remover funcionário
            </CancelButton>
          )}
        </FormDiv>
      </div>
      <Modal open={modalState} onClose={closeModal}>
        <FormDiv>
          <div>
            <Label>Nome</Label>
            <Input
              id="name"
              name="name"
              value={childFormik.values.name}
              onChange={childFormik.handleChange}
              onBlur={childFormik.handleBlur}
            />
            {childFormik.touched.name && childFormik.errors.name ? (
              <ErrorBox>{childFormik.errors.name}</ErrorBox>
            ) : null}
          </div>
          <div>
            <Label>CPF</Label>
            <Input
              id="cpf"
              name="cpf"
              maxLength="14"
              value={
                childFormik.values.cpf
                  ? cpfMask(childFormik.values.cpf)
                  : childFormik.values.cpf
              }
              onChange={(e) => {
                e.target.value = removeMask(e.target.value);
                childFormik.handleChange(e);
              }}
              onBlur={childFormik.handleBlur}
            />
            {childFormik.touched.cpf && childFormik.errors.cpf ? (
              <ErrorBox>{childFormik.errors.cpf}</ErrorBox>
            ) : null}
          </div>
          <div>
            <Label>Data de nascimento</Label>
            <Input
              type="date"
              id="birth_date"
              name="birth_date"
              value={
                childFormik.values.birth_date
                  ? childFormik.values.birth_date.split('T')[0]
                  : childFormik.values.birth_date
              }
              onChange={childFormik.handleChange}
              onBlur={childFormik.handleBlur}
            />
            {childFormik.touched.birth_date && childFormik.errors.birth_date ? (
              <ErrorBox>{childFormik.errors.birth_date}</ErrorBox>
            ) : null}
          </div>

          <Button
            style={{ margin: '15px 0' }}
            type="button"
            onClick={async () =>
              (await UniqueCPF()) === true
                ? idChildEdit === false
                  ? childFormik.handleSubmit()
                  : editChild(idChildEdit)
                : null
            }
          >
            {idChildEdit === false ? 'Adicionar' : 'Editar'}
          </Button>

          <CancelButton
            style={{ margin: '15px 0', width: '100%' }}
            type="button"
            onClick={() => closeModal()}
          >
            Cancelar
          </CancelButton>
        </FormDiv>
      </Modal>
      <Footer />
    </Container>
  );
};

export default EmployeeRegistrationMobile;
