/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { RiErrorWarningFill } from 'react-icons/ri';
import { ToastContainer, toast } from 'react-toastify';

import { useSelector, useDispatch } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { MainContainer } from './styles';
import { ModalContent } from '../../components/Modal/styles';
import Button from '../../components/Button';
import { colors } from '../../constants/colors';
import { numberMask, validationDateMask } from '../../utils/mask';
import api from '../../services/api';
import { UpdateUserRequest } from '../../store/ducks/auth/auth';
import 'react-toastify/dist/ReactToastify.css';
import { FormatUser } from '../../utils/FormatUser';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function Pagamento() {
  const [paymentType, setPaymentType] = useState('credit');
  const [errors, setErrors] = useState('');
  const [modalState, setModalState] = useState(false);
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [cvv, setCvv] = useState('');
  const [expiration, setExpiration] = useState('');
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);

  const { state } = useLocation();
  const { plan } = state;

  const advantages = [];
  // eslint-disable-next-line array-callback-return
  plan.advantages.map((advantage) => {
    advantages.push(advantage.advantage);
  });

  const dispatch = useDispatch();

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  async function UpdateUserOnStore() {
    api.get('/users/me').then(async ({ data }) => {
      const userFormat = await FormatUser(data);
      dispatch(UpdateUserRequest(data));
    });
  }

  async function closeModal() {
    setModalState(false);
    await UpdateUserOnStore();
    history.goBack();
  }

  const handleIuguUser = useCallback(async () => {
    if (!user.id_iugu) {
      await api
        .post('/iugu/user-create')
        .then((response) => {
          const userTemp = user;
          sessionStorage.setItem(
            'users',
            JSON.stringify({
              ...userTemp,
              id_iugu: response.data.id,
            }),
          );
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error.response);
        });
    }
  }, [user]);

  const handleSubscription = useCallback(async (token) => {
    setOpen(true);
    await handleIuguUser();

    if (token) {
      try {
        await api.post('/iugu/payment-method', {
          description: `Cartão ${name}`,
          token,
          set_as_default: true,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error.response);
      }
    }

    await api
      .post('/subscriptions', {
        plan_id: plan.id,
        is_creditcard: !!token,
      })
      .then(() => {
        handleClose();
        setName('');
        setNumber('');
        setExpiration('');
        setCvv('');
        setModalState(true);
      })
      .catch(async (error) => {
        await api
          .delete('/iugu/user-create')
          .then()
          .catch((err) => console.log(err.response));

        setOpen(false);
        toast('Erro no pagamento da assinatura');
        // eslint-disable-next-line no-console
        console.log(error.response);
      });

    UpdateUserOnStore();
  }, []);

  const handleToken = useCallback(async () => {
    const nameArray = name.split(' ');
    const first = nameArray[0];
    nameArray.shift();
    const last = nameArray.join(' ');
    const [month, year] = expiration.split('/');
    const cc = await Iugu.CreditCard(number, month, year, first, last, cvv);

    Iugu.setAccountID(process.env.REACT_APP_ACCOUNT_ID);
    Iugu.setTestMode(process.env.REACT_APP_PRODUCTION !== 'TRUE');
    await Iugu.createPaymentToken(cc, async (response) => {
      if (response.errors) {
        if (response.errors.number) {
          setErrors('Número do cartão inválido');
        } else if (response.errors.frist_name) {
          setErrors('Nome do cartão inválido');
        } else if (response.errors.last_name) {
          setErrors('Sobrenome do cartão inválido');
        } else if (response.errors.expiration) {
          setErrors('Data de validade do cartão inválida');
        } else if (response.errors.verification_value) {
          setErrors('CVV do cartão inválido');
        } else {
          setErrors('Cartão inválido');
        }
      } else {
        await handleSubscription(response.id);
      }
    });
  }, [cvv, name, number, expiration]);

  return (
    <MainContainer>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="container">
        <div className="header">
          <h1>Planos</h1>
        </div>
        <div className="content">
          <div id="subtitle">
            <span>
              Planos {'>'} <b> MEI {plan.name}</b>
            </span>
            {advantages[0] ? (
              <p>
                Finalize sua assinatura do plano {plan.name} e usufrua de
                recursos como <strong> {advantages.join(', ')}</strong> e muito
                mais!
              </p>
            ) : (
              <p>
                Finalize sua assinatura do plano {plan.name} e usufrua dos
                recursos que ele oferece!
              </p>
            )}
          </div>
          <div id="wrapper">
            <span id="form-title">Pagamento</span>
            <div id="inputs-container">
              <div id="radioButton-container">
                <input
                  className="radio-button"
                  type="radio"
                  value="credit"
                  name="metodo"
                  checked={paymentType === 'credit'}
                  onClick={() => {
                    setPaymentType('credit');
                    setErrors('');
                  }}
                />
                <span>Cartão de crédito</span>
                <input
                  className="radio-button"
                  type="radio"
                  value="billet"
                  name="metodo"
                  checked={paymentType === 'billet'}
                  onClick={() => {
                    setPaymentType('billet');
                    setErrors('');
                  }}
                />
                <span>Boleto bancário</span>
              </div>

              {paymentType === 'credit' ? (
                <div className="inputsContent">
                  <fieldset className="name">
                    <p>Nome impresso no cartão</p>
                    <input
                      type="text"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </fieldset>

                  <fieldset className="number">
                    <p>Numero do cartão</p>
                    <input
                      type="text"
                      maxLength={16}
                      name="number"
                      value={numberMask(number)}
                      onChange={(e) => setNumber(e.target.value)}
                    />
                  </fieldset>
                  <fieldset className="validation">
                    <p>Validade</p>
                    <input
                      type="text"
                      name="validity"
                      maxLength="5"
                      value={validationDateMask(expiration)}
                      onChange={(e) => setExpiration(e.target.value)}
                    />
                  </fieldset>
                  <fieldset className="cvv">
                    <p>CVV</p>
                    <input
                      type="text"
                      maxLength="4"
                      name="verification_value"
                      value={numberMask(cvv)}
                      onChange={(e) => setCvv(e.target.value)}
                    />
                  </fieldset>
                </div>
              ) : (
                <div className="inputsContent">
                  <div className="warning">
                    <RiErrorWarningFill size={25} color={colors.mediumGray} />
                    <p>
                      Uma cópia do boleto será enviado por e-mail e você poderá
                      imprimi-lo na página seguinte.
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className="actions">
              <div className="error">
                {errors ? <p className="errors">{errors}</p> : null}
              </div>
              <Button
                backgroundColor={colors.orange}
                radius="8px"
                hover="#D26A24"
                active="#FF6600"
                width="200px"
                height="50px"
                text="Finalizar compra"
                onClick={() => {
                  // eslint-disable-next-line no-unused-expressions
                  paymentType === 'credit'
                    ? handleToken()
                    : handleSubscription();
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <ModalContent open={modalState} onClose={closeModal}>
        <div className="modalPayment">
          <div className="title">
            <h3>Operação realizada com sucesso</h3>
          </div>
          <div className="message">
            <p>
              {paymentType === 'credit'
                ? 'A compra do novo plano foi realizada com sucesso, você ja pode desfrutar das novas funcionalidades que a NUME oferece para o seu novo plano.'
                : 'Um boleto foi enviado para o seu email cadastrado na plataforma, após o boleto ser compensado você poderá desfrutar das novas funcionalidades que a NUME oferece para seu novo plano.'}
            </p>
          </div>
          <Button
            id="buttonSubmit"
            backgroundColor={colors.orange}
            radius="8px"
            hover="#D26A24"
            active="#FF6600"
            width="100px"
            height="50px"
            text="Entendi"
            onClick={closeModal}
          />
        </div>
      </ModalContent>
    </MainContainer>
  );
}

export default Pagamento;
