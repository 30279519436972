export default function ExpenditureList() {
  const expenditure = [
    { name: 'Salários' },
    { name: 'Encargos sociais' },
    { name: 'EPI' },
    { name: 'Outros gastos funcionais' },
    { name: 'Saúde do trabalhador/PPP' },
    { name: 'Vale transporte' },
    { name: 'Vale alimentação' },
    { name: 'Seguro de vida' },
    { name: 'Pró-labore (retirada dos sócios)' },
    { name: 'Encargos Sociais do Pró-Labore)' },
    { name: 'Energia elétrica' },
    { name: 'Água e esgoto' },
    { name: 'Telefone fixo' },
    { name: 'Telefone celular' },
    { name: 'Internet' },
    { name: 'Aluguel' },
    { name: 'Condomínio' },
    { name: 'Sistema de informática' },
    { name: 'Serviço de contabilidade' },
    { name: 'Combustíveis e lubrificantes' },
    { name: 'Manutenção de veículos' },
    { name: 'IPVA' },
    { name: 'Multas de trânsito' },
    { name: 'Seguro de veículos' },
    { name: 'Viagens e representações' },
    { name: 'Material de escritório/expediente' },
    { name: 'Higiene e limpeza' },
    { name: 'Copa e cozinha' },
    { name: 'Manutenção e reparos' },
    { name: 'Publicidade e propaganda' },
    { name: 'Patrocínios e doações' },
    { name: 'Alvará municipal' },
    { name: 'IPTU' },
    { name: 'Consultoria/Assessoria/Cursos' },
    { name: 'Taxas ambientais' },
    { name: 'Associação comercial/CDL' },
    { name: 'Revistas e jornais' },
    { name: 'Seguros de imobilizado e estoques' },
    { name: 'Bombeiros' },
    { name: 'Extintor de incêndio' },
    { name: 'Contribuição sindical patronal' },
    { name: 'Locação de máquina de cartão' },
    { name: 'Depreciação' },
    { name: 'Juros de capital de giro' },
    { name: 'Juros desconto de cheques' },
    { name: 'Juros cheque especial' },
    { name: 'Tarifas bancárias' },
    { name: 'IOF' },
    { name: 'Empréstimo' },
    { name: 'Financiamento' },
    { name: 'Outras despesas bancárias' },
  ];

  return expenditure;
}
