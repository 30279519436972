import * as yup from 'yup';

const schema = yup.object().shape({
  name: yup
    .string()
    .required('Preencha o seu nome')
    .test('has two names', 'Seu nome deve ser composto', (value) => {
      if (value) {
        const [frist, last] = value.split(' ');
        return last && frist;
      }
      return false;
    }),
  credit_card: yup.string().required('Preencha o numero do seu cartão'),
  due_date: yup
    .string()
    .min(5, 'Insira a data no formato correto mm/yy')
    .required('Preencha a data de validade do seu cartão'),
  cvv: yup.string().required('Preencha o cvv do seu cartão'),
});

export default schema;
