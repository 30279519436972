export const cpfMask = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const zipCodeMask = (value) => {
  return value.replace(/\D/g, '').replace(/(\d{5})(\d)/, '$1-$2');
};

export const removeMaskPhone = (value) => {
  return value.replace(/[^0-9]+/g, '');
};

export const phoneMask = (value) => {
  return value
    .replace(/\D/g, '')
    .substring(0, 11)
    .replace(/^(\d{2})(\d)/g, '($1) $2')
    .replace(/(\d)(\d{4})$/, '$1-$2');
};

export const numberMask = (value) => {
  return value.replace(/\D/g, '');
};

export const validationDateMask = (value) => {
  return value.replace(/\D/g, '').replace(/(\d{2})(\d)/, '$1/$2');
};

export const removeMask = (value) => {
  return value.replace(/\D/g, '');
};
