import React from 'react';
import { useSelector } from 'react-redux';
import { BiDownload } from 'react-icons/bi';
import Footer from '../../../components/Mobile/Footer';
import Header from '../../../components/Mobile/Header';
import { cpfMask } from '../../../utils/mask';
import {
  Container,
  Input,
  FormDiv,
  SectionTitle,
  Label,
  LineDiv,
  Button,
} from '../Profile/styles';

const DadosMEIMobile = () => {
  const user = useSelector((state) => state.auth.user);
  return (
    <Container>
      <Header thisPage="Dados Mei" />
      <FormDiv>
        <SectionTitle>Informação da conta</SectionTitle>
        <div>
          <Label>Nome Empresarial</Label>
          <Input value={user.mei.business_name} />
          <Label>Nome do empresário</Label>
          <Input value={user.mei.businessman_name} />
          <Label>Nome Fantasia</Label>
          <Input value={user.mei.fantasy_name} />
          <Label>CNPJ</Label>
          <Input value={user.mei.cnpj} />
          <Label>Situação cadastral vigente</Label>
          <Input value={user.mei.current_registration_situation} />
          <Label>Data do Cadastro Vigente</Label>
          <Input value={user.mei.current_registration_situation_start_date} />
        </div>
        <SectionTitle>Informações pessoais</SectionTitle>
        <div>
          <Label>Número da identidade</Label>
          <Input value={user.mei.rg} />
          <LineDiv>
            <div style={{ width: '40%' }}>
              <Label>Órgão Emissor</Label>
              <Input value={user.mei.emitting_organ} />
            </div>
            <div style={{ width: '40%' }}>
              <Label>UF Emissor</Label>
              <Input value={user.mei.emitting_ufe} />
            </div>
          </LineDiv>
          <Label>CPF</Label>
          <Input value={cpfMask(user.mei.cpf)} />
        </div>
        <SectionTitle>Endereço Comercial</SectionTitle>
        <div>
          <Label>CEP</Label>
          <Input value={user.mei.zip_code} />
          <Label>Rua</Label>
          <Input value={user.mei.public_place} />
          <LineDiv>
            <div style={{ width: '20%' }}>
              <Label>Número</Label>
              <Input value={user.mei.number} />
            </div>
            <div style={{ width: '75%' }}>
              <Label>Rua</Label>
              <Input value={user.mei.neighborhood} />
            </div>
          </LineDiv>
          <LineDiv>
            <div style={{ width: '20%' }}>
              <Label>UF</Label>
              <Input value={user.mei.uf} />
            </div>
            <div style={{ width: '75%' }}>
              <Label>Município</Label>
              <Input value={user.mei.county} />
            </div>
          </LineDiv>
        </div>
        <Button
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => {
            window.open(`${user.mei.certificate_url}`);
          }}
        >
          Baixar Certificado{' '}
          <BiDownload size={16} style={{ marginLeft: '10px' }} />
        </Button>
      </FormDiv>
      <Footer />
    </Container>
  );
};

export default DadosMEIMobile;
