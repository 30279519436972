/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { cpfMask } from '../../../utils/mask';
import { Button, CancelButton, FormDiv, Input, Label } from '../Profile/styles';
import { ChildCard } from './styles';

export const ChildrenForm = ({ childrens, openModal, deleteChild }) => {
  return (
    <>
      <Button
        style={{ margin: '15px 0' }}
        type="button"
        onClick={() => {
          openModal('create');
        }}
      >
        Adicionar criança
      </Button>
      {childrens.map((child, index) => (
        <ChildCard>
          <FormDiv>
            <Label>Nome</Label>
            <Input id="name" name="name" value={child.name} />
            <Label>CPF</Label>
            <Input
              id="cpf"
              name="cpf"
              value={child.cpf ? cpfMask(child.cpf) : ''}
            />
            <Label>Data de nascimento</Label>
            <Input
              type="date"
              id="birth_date"
              name="birth_date"
              value={
                child.birth_date
                  ? child.birth_date.split('T')[0]
                  : child.birth_date
              }
            />
            <Button
              style={{ margin: '15px 0' }}
              type="button"
              onClick={() => openModal('edit', child, index)}
            >
              Editar
            </Button>
            <CancelButton
              style={{ margin: '15px 0', width: '100%' }}
              type="button"
              onClick={() => deleteChild(child.id, index)}
            >
              Remover criança
            </CancelButton>
          </FormDiv>
        </ChildCard>
      ))}
    </>
  );
};
