import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../services/api';
import { Wrapper } from './styles';

import Button from '../Button';

const FormDuvidas = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [errors, setErrors] = useState(false);
  // const [validate, setValidate] = useState(false);
  const schema = yup.object().shape({
    name: yup.string().required('Por favor, preencha o campo Nome'),
    email: yup
      .string()
      .email('Formato do email inválido')
      .required('Por favor, preencha o campo e-mail'),
    message: yup.string().required('Por favor, preencha o campo Mensagem'),
  });

  function clearForm() {
    setName('');
    setEmail('');
    setMessage('');
  }

  async function handleValidate() {
    const data = { name, email, message };
    try {
      await schema.validate(data);
      setErrors(false);
      return true;
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        error.errors.map((err) => setErrors(err));
        // setValidate(false);
      }
      return false;
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const data = {
      name,
      email,
      message,
    };

    const validate = await handleValidate();

    if (validate) {
      await api
        .post('/admins/doubts', data)
        .then(() => {
          clearForm();
          toast('Sua dúvida foi enviada');
        })
        .catch((err) => console.log(err));
    }
  }

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  return (
    <Wrapper>
      <ToastContainer />
      <div className="content">
        <div className="formContainer">
          <h1>Tire todas suas dúvidas e simplifique</h1>
          <form onSubmit={handleSubmit}>
            <div className="inputContainer">
              <p>Nome</p>
              <input
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Seu nome"
              />
            </div>
            <div className="inputContainer">
              <p>Email</p>
              <input
                type="text"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Digite seu e-mail"
              />
            </div>
            <div className="inputContainer">
              <p>Mensagem</p>
              <textarea
                placeholder="Como podemos ajudar?"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                cols="30"
                rows="10"
              />
              {errors && <p className="errors">{errors}</p>}
            </div>
            <div className="action">
              <Button
                width={windowWidth <= 768 ? '100%' : '60%'}
                height="60px"
                type="submit"
                text="Enviar"
                onClick={handleSubmit}
              />
            </div>
          </form>
        </div>
      </div>
    </Wrapper>
  );
};

export default FormDuvidas;
