/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';

import { useFormik } from 'formik';
import Header from '../../../components/Mobile/Header';
import Footer from '../../../components/Mobile/Footer';
import Schema from '../../../utils/profileSchema';
import { colors } from '../../../constants/colors';
import { Modal } from '../Planos/styles';
import { Logout, UpdateUserRequest } from '../../../store/ducks/auth/auth';

import {
  numberMask,
  cpfMask,
  phoneMask,
  removeMask,
  zipCodeMask,
} from '../../../utils/mask';

import {
  Container,
  SectionTitle,
  FormDiv,
  Input,
  Label,
  Button,
  CancelButton,
  LineDiv,
  ErrorBox,
} from './styles';
import api, { apiCep } from '../../../services/api';
import { FormatUser } from '../../../utils/FormatUser';

function Profile() {
  const user = useSelector((state) => state.auth.user);
  const [handleRender, setHandleRender] = useState(true);
  const [success, setSuccess] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [errors, setErrors] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();
  const users = useSelector((state) => state.auth.user);

  const formik = useFormik({
    initialValues: {
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      birth_date: new Date(user.birth_date).toISOString().slice(0, 10),
      cpf: user.cpf,
      zip_code: user.zip_code,
      state: user.state,
      city: user.city,
      street_name: user.street_name,
      address_number: user.address_number,
      additional_info: user.additional_info,
      phone_number: user.phone_number,
    },
    validationSchema: Schema,
    validateOnBlur: true,

    // eslint-disable-next-line no-use-before-define
    onSubmit: handleEditUser,
  });

  useEffect(() => {
    if (formik.values.zip_code.length >= 8) {
      apiCep.get(`/${formik.values.zip_code}/json`).then((res) => {
        formik.values.street_name =
          res.data.logradouro !== ''
            ? res.data.logradouro
            : formik.values.street_name;
        formik.values.state = res.data.uf;
        formik.values.city = res.data.localidade;
        setHandleRender(!handleRender);
      });
    }
  }, [formik.values.zip_code]);

  const cancelPlan = () => {
    api
      .delete('/subscriptions')
      .then(() => {
        setSuccess(true);
        api.get('/users/me').then(async ({ data }) => {
          const userFormat = await FormatUser(data);
          dispatch(UpdateUserRequest(data));
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error.response);
      });
  };
  function closeModal() {
    setModalState(false);
    setTimeout(() => {
      setSuccess(false);
    }, 1000);
  }

  const DeleteUser = () => {
    api
      .delete('/users/delete')
      .then(() => {
        setSuccessDelete(true);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error.response);
      });
  };

  async function handleEditUser(data) {
    data.plan = user.plan;
    data.avatar_id === null ? delete data.avatar_id : data.avatar_id;
    data.phone_number = removeMask(data.phone_number);
    data.cpf = removeMask(data.cpf);
    setErrors(false);

    dispatch(UpdateUserRequest(data));
    toast.success('Dados atualizados com sucesso!');
    return true;
  }

  return (
    <Container>
      <ToastContainer />
      <Header thisPage="Configurações de conta" />
      <FormDiv>
        <SectionTitle>Informações da conta</SectionTitle>
        <div>
          <Label>Email</Label>
          <Input
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          {formik.touched.email && formik.errors.email ? (
            <ErrorBox>{formik.errors.email}</ErrorBox>
          ) : null}
        </div>
        <div>
          <Label>Plano atual</Label>
          <Input value={user.plan.name} disabled />
        </div>

        {user.id_iugu_subscription && (
          <CancelButton
            style={{ width: '100%' }}
            onClick={() => setModalState(true)}
          >
            Cancelar plano
          </CancelButton>
        )}

        <SectionTitle>Informações pessoais</SectionTitle>
        <div>
          <Label>Nome</Label>
          <Input
            id="first_name"
            name="first_name"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.first_name && formik.errors.first_name ? (
            <ErrorBox>{formik.errors.first_name}</ErrorBox>
          ) : null}
        </div>
        <div>
          <Label>Sobrenome</Label>
          <Input
            id="last_name"
            name="last_name"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.last_name && formik.errors.last_name ? (
            <ErrorBox>{formik.errors.last_name}</ErrorBox>
          ) : null}
        </div>
        <div>
          <Label>Data de nascimento</Label>
          <Input
            id="birth_date"
            name="birth_date"
            type="date"
            value={formik.values.birth_date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.birth_date && formik.errors.birth_date ? (
            <ErrorBox>{formik.errors.birth_date}</ErrorBox>
          ) : null}
        </div>
        <div style={{ marginBottom: 40 }}>
          <Label>CPF</Label>
          <Input
            id="cpf"
            name="cpf"
            value={cpfMask(formik.values.cpf)}
            maxLength={14}
            onChange={(e) => {
              e.target.value = numberMask(e.target.value);
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.cpf && formik.errors.cpf ? (
            <ErrorBox>{formik.errors.cpf}</ErrorBox>
          ) : null}
        </div>
        <SectionTitle>Informações de contato</SectionTitle>
        <div>
          <Label>CEP</Label>
          <Input
            id="zip_code"
            name="zip_code"
            value={zipCodeMask(formik.values.zip_code)}
            onChange={(e) => {
              e.target.value = removeMask(e.target.value);
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
          />
          {formik.touched.zip_code && formik.errors.zip_code ? (
            <ErrorBox>{formik.errors.zip_code}</ErrorBox>
          ) : null}
        </div>

        <LineDiv>
          <div style={{ width: '20%' }}>
            <Label>Estado</Label>
            <Input
              id="state"
              name="state"
              value={formik.values.state}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.state && formik.errors.state ? (
              <ErrorBox>{formik.errors.state}</ErrorBox>
            ) : null}
          </div>
          <div style={{ width: '75%' }}>
            <Label>Municipio</Label>
            <Input
              id="city"
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.city && formik.errors.city ? (
              <ErrorBox>{formik.errors.city}</ErrorBox>
            ) : null}
          </div>
        </LineDiv>

        <div>
          <Label>Rua</Label>
          <Input
            id="street_name"
            name="street_name"
            value={formik.values.street_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.street_name && formik.errors.street_name ? (
            <ErrorBox>{formik.errors.street_name}</ErrorBox>
          ) : null}
        </div>
        <LineDiv>
          <div style={{ width: '20%' }}>
            <Label>Número</Label>
            <Input
              id="address_number"
              name="address_number"
              value={formik.values.address_number}
              onChange={(e) => {
                e.target.value = numberMask(e.target.value);
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.address_number && formik.errors.address_number ? (
              <ErrorBox>{formik.errors.address_number}</ErrorBox>
            ) : null}
          </div>
          <div style={{ width: '75%' }}>
            <Label>Complemento</Label>
            <Input
              id="additional_info"
              name="additional_info"
              value={formik.values.additional_info}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.additional_info && formik.errors.additional_info ? (
              <ErrorBox>{formik.errors.additional_info}</ErrorBox>
            ) : null}
          </div>
        </LineDiv>
        <div>
          <Label>Telefone</Label>
          <Input
            id="phone_number"
            name="phone_number"
            maxLength={15}
            value={phoneMask(formik.values.phone_number)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.phone_number && formik.errors.phone_number ? (
            <ErrorBox>{formik.errors.phone_number}</ErrorBox>
          ) : null}
        </div>
        <LineDiv>
          <CancelButton
            onClick={() => {
              users.id_iugu_subscription
                ? toast(
                    'Você deve cancelar o seu plano atual para excluir a conta',
                  )
                : setModalDelete(true);
            }}
          >
            Excluir conta
          </CancelButton>
          <Button style={{ width: '47%' }} onClick={formik.handleSubmit}>
            Salvar alterações
          </Button>
        </LineDiv>
        {errors ? <p className="errors">{errors}</p> : null}
      </FormDiv>
      <Modal open={modalState} onClose={closeModal}>
        <div className="containerModal">
          <h1>Confirmação de cancelamento</h1>
          <p>
            {success === false
              ? `Estou ciente de que estou prestes a cancelar o plano ${
                  users.plan.name !== 'Free'
                    ? users.plan.name
                    : 'que foi adquirido via boleto e esta aguardando pagamento'
                }.`
              : 'Cancelamento feito com sucesso, agora você esta no plano Free.'}
          </p>

          {success === false ? (
            <>
              <CancelButton
                style={{ width: '100%', marginBottom: '15px' }}
                backgroundColor="transparent"
                color={colors.orange}
                border={colors.orange}
                radius="8px"
                hover="#D26A24"
                active="#FF6600"
                width="170px"
                height="42px"
                text="Cancelar"
                onClick={closeModal}
              >
                Cancelar
              </CancelButton>
              <Button
                style={{ marginBottom: '0px' }}
                backgroundColor={colors.orange}
                radius="8px"
                hover="#D26A24"
                active="#FF6600"
                width="170px"
                height="42px"
                text="Ok"
                onClick={cancelPlan}
              >
                Ok
              </Button>
            </>
          ) : (
            <Button
              style={{ marginBottom: '0px' }}
              backgroundColor={colors.orange}
              radius="8px"
              hover="#D26A24"
              active="#FF6600"
              width="170px"
              height="42px"
              text=""
              onClick={closeModal}
            >
              Ok
            </Button>
          )}
        </div>
      </Modal>
      <Modal open={modalDelete} onClose={() => setModalDelete(false)}>
        <div className="containerModal">
          <h1>
            {successDelete === false
              ? 'Você deseja excluir sua conta?'
              : 'Conta excluída'}
          </h1>
          <p>
            {successDelete === false
              ? ` Tem
              certeza que deseja nos deixar?`
              : 'Conta excluída com sucesso, esperamos que volte a trabalhar conosco!! '}
          </p>

          {successDelete === false ? (
            <>
              <CancelButton
                style={{ width: '100%', marginBottom: '15px' }}
                backgroundColor="transparent"
                color={colors.orange}
                border={colors.orange}
                radius="8px"
                hover="#D26A24"
                active="#FF6600"
                width="170px"
                height="42px"
                text="Cancelar"
                onClick={() => setModalDelete(false)}
              >
                Cancelar
              </CancelButton>
              <Button
                style={{ marginBottom: '0px' }}
                backgroundColor="transparent"
                border={colors.orange}
                radius="8px"
                hover="#D26A24"
                active="#FF6600"
                width="170px"
                height="42px"
                text="Sim"
                onClick={() => DeleteUser()}
              >
                Sim
              </Button>
            </>
          ) : (
            <Button
              style={{ marginBottom: '0px' }}
              backgroundColor={colors.orange}
              radius="8px"
              hover="#D26A24"
              active="#FF6600"
              width="170px"
              height="42px"
              text="Sim"
              onClick={() => {
                dispatch(Logout());
                history.push('/');
              }}
            >
              Sim
            </Button>
          )}
        </div>
      </Modal>
      <Footer />
    </Container>
  );
}

export default Profile;
