import { takeLatest, all, call, put, select } from 'redux-saga/effects';
import {
  Types,
  LoginAdmFailure,
  LoginAdmSuccess,
  LoginFailure,
  LoginSuccess,
  UpdateUserSuccess,
  UpdateUserFailure,
  UpdateEmployeeSuccess,
  UpdateEmployeeFailure,
} from './auth';
import api from '../../../services/api';

function* HandleAdmLogin({ payload }) {
  try {
    const { data } = yield call(api.post, '/admin-sessions', payload);
    api.defaults.headers.authorization = `Bearer ${data.token}`;
    yield sessionStorage.setItem('token', data.token);
    yield put(LoginAdmSuccess(data.admin, data.token));
  } catch (error) {
    yield put(LoginAdmFailure(error.response.data.error));
  }
}

function* HandleLogin({ payload }) {
  try {
    const { data } = yield call(api.post, '/sessions', payload);
    api.defaults.headers.authorization = `Bearer ${data.token}`;
    const { data: DataPlan } = yield call(
      api.get,
      `/plans/show/${data.user.plan.id}`,
      payload,
    );
    sessionStorage.setItem('token', data.token);
    try {
      const { data: dataEmployer } = yield call(api.get, '/users/employee', {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      });
      data.user.employee = dataEmployer;
      yield put(LoginSuccess(data.user, data.token, DataPlan));
    } catch (error) {
      yield put(LoginSuccess(data.user, data.token, DataPlan));
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error.response);
    yield put(LoginFailure(error.response.data.error));
  }
}

function* HandleUserUpdate({ payload }) {
  const user = yield select((state) => state.auth.user);
  const newUser = { ...user, ...payload };
  try {
    const { data: DataPlan } = yield call(
      api.get,
      `/plans/show/${payload.plan.id}`,
      payload,
    );

    yield call(api.patch, '/users/update', payload);

    yield put(UpdateUserSuccess(newUser, DataPlan));
  } catch (error) {
    yield put(UpdateUserFailure(error.response.message));
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

function* HandleUpdateEmployee({ payload }) {
  const user = yield select((state) => state.auth.user);
  const newUser = { ...user, employee: payload };
  try {
    yield call(api.patch, '/users/employee/update', payload);

    yield put(UpdateEmployeeSuccess(newUser));
  } catch (error) {
    yield put(UpdateEmployeeFailure(error.response.message));
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export default all([
  takeLatest(Types.LOGIN_ADM_REQUEST, HandleAdmLogin),
  takeLatest(Types.LOGIN_REQUEST, HandleLogin),
  takeLatest(Types.UPDATE_USER_REQUEST, HandleUserUpdate),
  takeLatest(Types.UPDATE_EMPLOYEE_REQUEST, HandleUpdateEmployee),
]);
