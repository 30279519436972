/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { RiCloseCircleLine } from 'react-icons/ri';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import api from '../../services/api';
import { Container, Modal } from './styles';
import { colors } from '../../constants/colors';
import exclamation from '../../assets/svg/exclamationMark.svg';
import closeIcon from '../../assets/svg/close-circle.svg';
import Button from '../../components/Button';
import ExpenditureList from '../../utils/expenditure';
import FormatDate from '../../utils/formatDate';
import Loading from '../../components/Loading';

function Relatorios() {
  const expenditure = ExpenditureList();
  const user = useSelector((state) => state.auth.user);
  const [modalState, setModalState] = useState(false);
  const [deleteModalState, setDeleteModalState] = useState(false);
  const subs = user.subscription_aquisition;
  const dateNow = new Date().toISOString().slice(0, 10);
  const [data, setData] = useState(false);
  const [reload, setReload] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataYear, setDataYear] = useState(false);
  const history = useHistory();
  const [period, setPeriod] = useState('lastfifteendays');
  const [periodYear, setPeriodYear] = useState('');
  const [receitas, setReceitas] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupExpiredPlan, setShowPopupExpiredPlan] = useState(false);
  const [billingMonths, setBillingMonths] = useState([]);
  const [chartData, setChartData] = useState([
    {
      name: 'carregando',
      receita: 300,
      despesas: 100,
    },
  ]);
  const months = [
    'null',
    'Janeiro',
    'Fevereiro',
    'Marco',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  const initialValue = {
    name: '',
    value: 0,
    date: dateNow,
    user_id: user.id,
    installments: 1,
    payment_method_id: 1,
    is_paid: 0,
  };

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: yup.object().shape({
      name: yup
        .string()
        .max(100, 'A descrição deve ter no máximo 100 caracteres')
        .required('Descrição é um campo obrigatório'),
      value: yup
        .number()
        .min(1, 'O valor deve ser de no minimo R$ 1.00')
        .required('Valor é um campo obrigatório'),
      date: yup
        .string()
        .test('dataLimit', `Data maxima ${FormatDate(dateNow)}`, (value) => {
          if (value > dateNow) {
            return false;
          }
          return true;
        })
        .required('Data é um campo obrigatório'),
    }),
    onSubmit: (values) => {
      if (modalState.id) {
        EditTransactions();
      } else {
        AddTransaction();
      }
    },
  });

  function openModal() {
    formik.setValues(initialValue);
    setModalState(true);
  }

  function handleOpenEditModal(item) {
    formik.setErrors('');
    formik.values.name = item.name;
    formik.values.date = item.date.slice(0, 10);
    formik.values.value = receitas === false ? item.value * -1 : item.value;
    setModalState(item);
  }

  function closeModal() {
    setModalState(false);
    formik.setErrors(false);
  }

  useEffect(() => {
    handleExpiredMonth(subs);
  }, [subs]);

  const handleExpiredMonth = (subs) => {
    const show = sessionStorage.getItem('showPopUpExpired');
    const monthAcquisition = parseInt(
      new Date(subs).toISOString().slice(5, 7),
      10,
    );
    const todayMonth = parseInt(new Date().toISOString().slice(5, 7), 10);
    if (
      (todayMonth - monthAcquisition === -1 ||
        todayMonth - monthAcquisition === 11) &&
      show === 'true' &&
      user.plan.name !== 'Free'
    ) {
      setShowPopupExpiredPlan(true);
    }
    return true;
  };

  useEffect(async () => {
    setData(false);
    if (periodYear === '') {
      const response = await api.get(`/transactions/show?${period}=1`);
      setData(response.data);
      setDataYear(false);
    } else {
      setPeriod('');
      const response = await api.get(`/transactions/show?${period}=1`);
      setData(response.data);
      const responseYear = await api.get(
        `/transactions/show?year=${periodYear.toString()}`,
      );
      setDataYear(responseYear.data);
    }
  }, [period, periodYear, reload]);

  useEffect(() => {
    if (dataYear === false) {
      const list = [];
      const listN = [];
      if (data.length > 0) {
        data.map((item) => {
          if (item.value > 0) {
            list.push(item.value);
          } else {
            listN.push(item.value);
          }
        });
        const value =
          list.length > 0 ? list.reduce((total, val) => total + val) : 0;
        const valueN =
          listN.length > 0 ? listN.reduce((total, val) => total + val) : 0;
        setChartData([
          {
            name: 'Orçamento',
            receita: value || 0,
            despesas: valueN ? valueN * -1 : 0,
          },
        ]);
      } else {
        setChartData([
          {
            name: 'Orçamento',
            receita: 0,
            despesas: 0,
          },
        ]);
      }
    } else {
      setChartData([
        {
          name: `Orçamento ${periodYear}`,
          receita: dataYear.Revenues,
          despesas: dataYear.Expenses * -1,
        },
      ]);
    }
  }, [data, dataYear]);

  useEffect(async () => {
    const fullData = new Date();
    const day = fullData.getDate();
    const year = fullData.getFullYear();
    const show = sessionStorage.getItem('showPopUp');
    const noTransaction = await api.get(
      `/transactions/show?notransactions=${year}`,
    );
    day >= 20 ? handleNoTransactions(noTransaction.data, show) : '';
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  function handleClick(e) {
    const target = e.target.className;
    if (!target || target.baseVal !== 'dropdownButton') {
      setDropDown(false);
    }
  }

  const handleClose = () => {
    sessionStorage.setItem('showPopUp', false);
    setShowPopup(false);
  };

  const handleCloseExpiredPlan = () => {
    setShowPopupExpiredPlan(false);
    sessionStorage.setItem('showPopUpExpired', false);
  };

  const handleNoTransactions = (noTransactions, show) => {
    const noTransactionMonths = [];
    if (show === 'true') {
      noTransactions.map((noTransactionMonth) => {
        if (noTransactionMonth === null) {
          setShowPopup(false);
        } else {
          if (months[noTransactionMonth])
            noTransactionMonths.push(months[noTransactionMonth]);
          setShowPopup(true);
        }
      });
      if (noTransactionMonths === undefined || noTransactionMonths === null) {
        setShowPopup(false);
      }
      return setBillingMonths(noTransactionMonths);
    }

    return false;
  };

  const handleEpiredPlan = () => {
    setShowPopupExpiredPlan(true);
  };

  function DropDownState(itemId) {
    if (dropDown === itemId) {
      setDropDown(false);
    } else {
      setDropDown(itemId);
    }
  }

  async function AddTransaction() {
    setIsLoading(true);
    if (receitas === false) {
      formik.values.value *= -1;
    }

    api
      .post('/transactions', formik.values)
      .then(() => {
        closeModal();
        setIsLoading(false);
        setReload(!reload);
        toast.success(
          `${receitas ? 'Receita' : 'Despesa'} cadastrada com sucesso!`,
        );
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        toast.error(`Erro ao cadastrar ${receitas ? 'receita' : 'despesa'}`);
      });
  }

  async function DeleteTransaction() {
    setIsLoading(true);
    setDeleteModalState(false);

    api
      .delete(`/transactions/${deleteModalState.id}`)
      .then(() => {
        toast.success(
          `${receitas ? 'Receita' : 'Despesa'} deletada com sucesso`,
        );
        setIsLoading(false);
        setReload(!reload);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error);
      });
  }

  async function EditTransactions() {
    setIsLoading(true);
    const data = {
      name: formik.values.name,
      date: formik.values.date.slice(0, 10),
      value:
        receitas === false ? (formik.values.value *= -1) : formik.values.value,
    };

    api
      .patch(`/transactions/${modalState.id}`, data)
      .then(() => {
        setIsLoading(false);
        toast.success(
          `${receitas ? 'Receita' : 'Despesa'} editada com sucesso`,
        );
        closeModal();
        setReload(!reload);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error);
      });
  }

  return (
    <Container>
      <Loading open={isLoading} />
      <ToastContainer />
      <div className="header">
        <h1>Relatório mensal financeiro</h1>
        <div className="actions">
          <div className="yearContainer">
            <span>Ano:</span>
            <input
              name="ano"
              id="ano"
              type="number"
              step="1"
              value={periodYear}
              onChange={(e) => setPeriodYear(e.target.value)}
              style={{ borderRadius: '10px', border: '1px solid #000' }}
            />
          </div>
          <select
            onChange={(e) => {
              setPeriod(e.target.value);
              setPeriodYear('');
            }}
            value={period}
            name="period"
            style={{ borderRadius: '10px' }}
          >
            <option value="lastfifteendays">Periodo: Últimos 15 dias</option>
            <option value="lastthirtydays">Periodo: Últimos 30 dias</option>
            <option value="lastsixtydays">Periodo: Últimos 60 dias</option>
            <option value="lastninetydays">Periodo: Últimos 90 dias</option>
            <option value="">Periodo: Todos os registros</option>
          </select>
        </div>
      </div>
      <div id="chart">
        <BarChart responsive width={800} height={300} data={chartData}>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip
            formatter={(value) =>
              new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(value)
            }
          />
          <Bar type="monotone" dataKey="receita" fill={colors.pink} />
          <Bar type="monotone" dataKey="despesas" fill={colors.orangeActive} />
          <Legend wrapperStyle={{ bottom: -10 }} iconType="circle" />
        </BarChart>
      </div>
      <div id="list">
        <nav>
          <div id="tabs">
            <span
              className={receitas && 'selected'}
              onClick={() => setReceitas(true)}
            >
              Receitas
            </span>
            <span
              className={!receitas && 'selected'}
              onClick={() => setReceitas(false)}
            >
              Despesas
            </span>
          </div>

          <Button
            color="#FFF"
            width="150px"
            height="30px"
            radius="50px"
            fontSize="1.2em"
            text={receitas ? 'Nova Receita' : 'Nova Despesa'}
            backgroundColor={colors.orange}
            active="#FF6600"
            hover="#D26A24"
            onClick={openModal}
          />
        </nav>
        <ul className="transactionsList">
          {data &&
            data
              .filter((o) => (receitas ? o.value >= 0 : o.value <= 0))
              .sort((a, b) => {
                if (a.date > b.date) {
                  return -1;
                }
                if (a.date < b.date) {
                  return 1;
                }
                return 0;
              })
              .map((item, index) => (
                <li className="transaction" key={index}>
                  <div>
                    <span>
                      {item.date.slice(0, 10).split('-').reverse().join('/')}
                    </span>
                    <span>{item.name}</span>
                  </div>
                  <div>
                    <span className="value">
                      {receitas
                        ? Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(item.value)
                        : Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(-1 * item.value)}
                    </span>
                    <div>
                      <BiDotsVerticalRounded
                        className="dropdownButton"
                        style={{ cursor: 'pointer' }}
                        onClick={() => DropDownState(item.id)}
                        size={21}
                      />
                      <div className="optionsContainer">
                        <ul
                          className={`options ${
                            dropDown === item.id ? 'dropOpen' : 'dropClose'
                          }`}
                        >
                          <li
                            onClick={() => {
                              handleOpenEditModal(item);
                            }}
                          >
                            Editar
                          </li>
                          <li onClick={() => setDeleteModalState(item)}>
                            Deletar
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
        </ul>
      </div>
      {showPopup ? (
        <div id="popup-effect">
          <div id="popup">
            <img
              id="close-icon"
              src={closeIcon}
              alt="close-icon"
              onClick={handleClose}
            />
            <h1 id="popup-title">Estamos chegando no fim do mês!</h1>
            <p>
              Não se esqueça de informar os dados relacionados ao faturamento
              mensal! Abaixo se encontram os meses que não possuem nenhum
              registro no sistema:
            </p>
            <ul>
              {billingMonths.map((month, index) => (
                <li key={index}>
                  <img
                    id="exclamation"
                    src={exclamation}
                    alt="exclamation-mark"
                  />
                  <span id="month">{month}</span>
                </li>
              ))}
            </ul>
            <div id="despesas-button">
              <Button
                text="Adicionar Receitas e Despesas"
                width="100%"
                radius="8px"
                onClick={() => {
                  handleClose();
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        ''
      )}

      {showPopupExpiredPlan ? (
        <div id="popup-effect">
          <div className="popup-expired-plan" id="popup">
            <img
              id="close-icon"
              src={closeIcon}
              alt="close-icon"
              onClick={handleCloseExpiredPlan}
            />
            <h1 id="popup-title">O seu plano está expirando!</h1>
            <p>
              Sem ele, você deixa de ter acesso aos recursos do plano{' '}
              {user.plan.name} da Nume. Considere renová-lo para continuar
              aproveitando todas as facilidades de nossa contabilidade online.
            </p>
            <div id="despesas-button">
              <Button
                text="OK"
                width="100%"
                radius="8px"
                onClick={handleCloseExpiredPlan}
              />
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      <Modal open={modalState} onClose={() => closeModal()}>
        <div className="transactionContainer">
          <nav>
            <h3>{receitas ? 'Nova receita' : 'Nova despesa'}</h3>
            <a onClick={() => closeModal()}>
              <RiCloseCircleLine size={25} color={colors.mediumGray} />
            </a>
          </nav>
          <div className="inputContainer">
            {receitas ? (
              <fieldset className="inputLarge">
                <p>Descrição</p>
                <input
                  type="text"
                  placeholder="Descrição"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="errors">{formik.errors.name}</div>
                ) : null}
              </fieldset>
            ) : (
              <fieldset className="inputLarge">
                <p>Descrição</p>
                <select
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                >
                  <option value="">Selecione despesa</option>
                  {expenditure.map((expense) => (
                    <option value={expense.name}>{expense.name}</option>
                  ))}
                </select>
                {formik.touched.name && formik.errors.name ? (
                  <div className="errors">{formik.errors.name}</div>
                ) : null}
              </fieldset>
            )}
            <fieldset>
              <p>Data</p>
              <input
                type="date"
                placeholder="Descrição"
                name="date"
                max="9999-12-31"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.date.split('T')[0]}
              />
              {formik.touched.date && formik.errors.date ? (
                <div className="errors">{formik.errors.date}</div>
              ) : null}
            </fieldset>
            <fieldset>
              <p>Valor</p>
              <input
                type="number"
                placeholder="Valor"
                name="value"
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.values.value = parseFloat(formik.values.value);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.value}
              />
              {formik.touched.value && formik.errors.value ? (
                <div className="errors">{formik.errors.value}</div>
              ) : null}
            </fieldset>
          </div>
          <Button
            color="#FFF"
            width="100%"
            height="40px"
            radius="8px"
            fontSize="1.2em"
            text="Confirmar"
            backgroundColor={colors.orange}
            active="#FF6600"
            hover="#D26A24"
            onClick={() => formik.handleSubmit()}
            disabled
          />
        </div>
      </Modal>
      <Modal open={deleteModalState} onclose={() => setDeleteModalState(false)}>
        <div className="popConfirmContainer">
          <h3>Tem certeza que deseja excluir este registro?</h3>

          <p>Essa ação não poderá ser desfeita.</p>
          <div className="actions">
            <Button
              color={colors.orange}
              width="125px"
              height="35px"
              radius="8px"
              fontSize="1.2em"
              text="Cancelar"
              backgroundColor="transparent"
              border={colors.orange}
              active="#FF6600"
              hover="#D26A24"
              onClick={() => setDeleteModalState(false)}
            />
            <Button
              color="#FFF"
              width="125px"
              height="35px"
              marginLeft="5px"
              radius="8px"
              fontSize="1.2em"
              text="Deletar"
              backgroundColor={colors.orange}
              active="#FF6600"
              hover="#D26A24"
              onClick={DeleteTransaction}
            />
          </div>
        </div>
      </Modal>
    </Container>
  );
}

export default Relatorios;
