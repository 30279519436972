/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import { IoIosArrowDown } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Logout } from '../../store/ducks/auth/auth';
import { Container, ButtonSideBar, AvatarMenu, MainContainer } from './styles';
import logoHeader from '../../assets/svg/logoHeaderMob.svg';
import userIcon from '../../assets/svg/userIcon.svg';
import { colors } from '../../constants/colors';

function SideBar({ page }) {
  const admin = useSelector((state) => state.auth.adm);

  const [showAvatarMenu, setShowAvatarMenu] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleChangePassword = () => {
    history.push('/dashboard/alterarSenha');
    setShowAvatarMenu(false);
  };

  const handleProfile = () => {
    // fazer pagina
    history.push('/dashboard/profile');
    setShowAvatarMenu(false);
  };

  const handleLogout = () => {
    dispatch(Logout());
    history.push('/gestao/login');
  };
  return (
    <Container id="container">
      <div id="line" />
      <div id="wrapper">
        <img id="logoHeader" src={logoHeader} alt="logo da nume" />
        <MainContainer>
          <AvatarMenu
            id="avatar-menu"
            onClick={() => setShowAvatarMenu(!showAvatarMenu)}
          >
            <div id="profile-pic">
              <img src={userIcon} alt="avatar-profile-pic" />
            </div>
            <div id="userInfoContainer">
              <span id="nome" className="infos">
                {admin.name}
              </span>
              <span id="tipoMEI" className="infos">
                Gestor
              </span>
            </div>
            <IoIosArrowDown size="24" color={colors.orange} id="arrow" />
          </AvatarMenu>
          {showAvatarMenu ? (
            <div id="options">
              <div id="photo-name">
                <img src={userIcon} alt="avatar-profile-pic" />
                <span id="user-name">{admin.name}</span>
              </div>
              <div id="list">
                <div id="options-button" />
                {/* <span className="text-options" onClick={handleProfile}>
                  Configurações de conta
                </span>
                <span className="text-options" onClick={handleChangePassword}>
                  Alterar Senha
                </span> */}
                <span className="text-options" onClick={handleLogout}>
                  Logout
                </span>
              </div>
            </div>
          ) : (
            ''
          )}
        </MainContainer>

        <div id="wrapper-buttons">
          <ButtonSideBar
            active={page === 'SearchClients'}
            onClick={() => history.push('/gestao/dashboard')}
          >
            Pesquisar clientes
          </ButtonSideBar>
          <ButtonSideBar
            active={page === 'ViewInadinplences'}
            onClick={() => history.push('/gestao/client/overdue')}
          >
            Visualizar inadimplências
          </ButtonSideBar>
          {/* <ButtonSideBar
            active={page === 'Plans'}
            onClick={() => history.push('/gestao/plans')}
          >
            Planos
          </ButtonSideBar> */}
          <ButtonSideBar
            active={page === 'RegisterAdm'}
            onClick={() => history.push('/gestao/novo-usuario-sertec')}
          >
            Cadastrar usuarios SERTEC
          </ButtonSideBar>
          {/* <ButtonSideBar active={page === 'Reports'}>
            Relatórios a serem definidos
          </ButtonSideBar> */}
        </div>
      </div>
    </Container>
  );
}

export default SideBar;
