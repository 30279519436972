/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { AiFillCheckCircle } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { ContainerMobile, Modal, Plan } from './styles';
import Header from '../../../components/Mobile/Header';
import Footer from '../../../components/Mobile/Footer';
import { UpdateUserRequest } from '../../../store/ducks/auth/auth';
import api from '../../../services/api';
import { colors } from '../../../constants/colors';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import { FormatUser } from '../../../utils/FormatUser';

const PlanosMobile = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const user = useSelector((state) => state.auth.user);
  const myPlan = useSelector((state) => state.auth.plan);
  const [plans, setPlans] = useState([]);
  const [advantages, setAdvantages] = useState([]);
  const [myAdvantages, setMyAdvantages] = useState('');
  const [planChange, setPlanChange] = useState({});
  const [open, setOpen] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    api
      .get('/plans')
      .then((response) => {
        setPlans(response.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const tempArray = [];
    myPlan.advantages.map((advantage) => {
      tempArray.push(advantage.advantage);
      return true;
    });
    setMyAdvantages(tempArray.join(', '));
  }, [myPlan]);

  useEffect(() => {
    api
      .get('/plans/advantages')
      .then((response) => {
        setAdvantages(response.data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  function closeModal() {
    setModalState(false);
    setSuccess(false);
  }

  function openModal(plan) {
    setModalState(true);
    setPlanChange(plan);
  }

  function verifyAdvantage(advantageId, plan) {
    const existAdvantage = plan.advantages.find(
      (advantage) => advantage.id === advantageId,
    );
    if (existAdvantage !== undefined) {
      return true;
    }
    return false;
  }

  const changePlan = async () => {
    setIsLoading(true);
    api
      .patch('/subscriptions/change-plan', { plan_id: planChange.id })
      .then(() => {
        setSuccess(true);
        api.get('/users/me').then(async ({ data }) => {
          const userFormat = await FormatUser(data);
          dispatch(UpdateUserRequest(userFormat));
        });
        setIsLoading(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        closeModal();
        toast.error('Erro ao mudar o plano');
        console.log(error.response);
        setIsLoading(false);
      });
  };

  return (
    <ContainerMobile>
      <Loading open={isLoading} />
      <ToastContainer />
      <div>
        <Header thisPage="Planos" />
        <div className="content">
          <header>
            <h3>
              Atualmente o seu plano é o <strong>MEI {user.plan.name}</strong>
            </h3>
            <p>
              Para que você possa conhecer melhor a plataforma, disponibilizamos
              algumas funções para auxiliar nessa jornada!
            </p>
            <p>
              Você tem à sua disposição{' '}
              {myAdvantages
                ? `${myAdvantages} e muito mais`
                : 'varias vantagens'}
            </p>
          </header>
          <div className="plansContainer">
            <p>Escolha um plano que atenda às suas necessidades!</p>
            {plans.map((plan, index) => (
              <Plan
                active={open === index}
                color={
                  plan.name === 'Free'
                    ? colors.blue
                    : plan.name === 'Premium'
                    ? colors.pink
                    : colors.orange
                }
              >
                <div
                  className="header"
                  onClick={() => setOpen(open === index ? false : index)}
                >
                  <h1>MEI {plan.name}</h1>
                  <p>
                    {plan.value_cents === 0
                      ? 'Grátis'
                      : Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(plan.value_cents / 100)}
                  </p>
                  <RiArrowDropDownLine
                    className={open === index ? 'arrow' : 'back'}
                    size={30}
                  />
                </div>
                <div className={`content ${open === index ? 'open' : 'close'}`}>
                  <div className="description">
                    <p>{plan.description}</p>
                  </div>
                  <ul>
                    {advantages.map((advantage) => (
                      <li>
                        <AiFillCheckCircle
                          className="ico"
                          color={
                            verifyAdvantage(advantage.id, plan)
                              ? colors.green
                              : colors.darkOrange
                          }
                        />
                        <span>{advantage.advantage}</span>
                      </li>
                    ))}
                  </ul>
                  {plan.id === 1 ? (
                    <Button
                      color="#FFF"
                      width="100%"
                      height="42px"
                      radius="8px"
                      marginTop="20px"
                      fontSize="14px"
                      text={
                        user.plan_id === 1
                          ? 'Você já possui esse plano'
                          : 'Selecionar Plano'
                      }
                      backgroundColor={colors.darkGray}
                      active={colors.darkGray}
                      hover={colors.darkGray}
                    />
                  ) : (
                    <Button
                      color="#FFF"
                      width="100%"
                      height="42px"
                      radius="8px"
                      fontSize="14px"
                      marginTop="20px"
                      text={
                        user.plan_id === plan.id
                          ? 'Você já possui esse plano'
                          : 'Selecionar Plano'
                      }
                      backgroundColor={
                        user.plan_id === plan.id ||
                        user.next_plan_id === plan.id
                          ? colors.darkGray
                          : colors.orange
                      }
                      active={
                        user.plan_id === plan.id ||
                        user.next_plan_id === plan.id
                          ? colors.darkGray
                          : '#FF6600'
                      }
                      hover={
                        user.plan_id === plan.id ||
                        user.next_plan_id === plan.id
                          ? colors.darkGray
                          : '#D26A24'
                      }
                      onClick={() => {
                        // eslint-disable-next-line no-unused-expressions
                        user.plan_id === plan.id ||
                        user.next_plan_id === plan.id
                          ? null
                          : user.id_iugu_subscription
                          ? openModal(plan)
                          : push({
                              pathname: '/dashboard/pagamento',
                              state: { plan },
                            });
                      }}
                    />
                  )}
                </div>
              </Plan>
            ))}
          </div>
        </div>
      </div>
      <Footer />
      <Modal open={modalState}>
        <div className="containerModal">
          <h1>Confirmação de alteração</h1>
          <p>
            {success === false
              ? `Estou ciente de que estou prestes a trocar o plano 
              ${user.plan.name} pelo plano  MEI ${planChange.name}. Com isso estou ciente
              que o valor do meu plano mudará também.`
              : user.last_payment_method === 'bank_slip'
              ? 'Mudança feita com sucesso, agora você já deve ter recebido a fatura em seu email e poderá desfrutar das novas funcionalidades que a NUME oferece para o seu novo plano.'
              : 'Mudança feita com sucesso, o valor da sua parcela já foi alterado e poderá desfrutar das novas funcionalidades que a NUME oferece para o seu novo plano.'}
          </p>

          {!success ? (
            <>
              <Button
                backgroundColor={colors.orange}
                marginBottom="10px"
                radius="8px"
                hover="#D26A24"
                active="#FF6600"
                width="100%"
                height="42px"
                text="Ok"
                onClick={changePlan}
              />

              <Button
                backgroundColor="transparent"
                color={colors.orange}
                border={colors.orange}
                radius="8px"
                hover="#D26A24"
                active="#FF6600"
                width="100%"
                height="42px"
                text="cancelar"
                onClick={closeModal}
              />
            </>
          ) : (
            <Button
              id="buttonSubmit"
              backgroundColor={colors.orange}
              radius="8px"
              hover="#D26A24"
              active="#FF6600"
              width="100%"
              height="42px"
              text="Ok"
              onClick={closeModal}
            />
          )}
        </div>
      </Modal>
    </ContainerMobile>
  );
};

export default PlanosMobile;
