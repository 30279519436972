import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import HeaderMobileLogin from '../../../components/Mobile/HeaderLogin';
import Loading from '../../../components/Loading';
import logoNume from '../../../assets/svg/logoHeaderDesktop.svg';
import Logo from '../../../assets/svg/logoNUME.svg';
import { MobileContainer, InputField, ButtonWhite } from './styles';
import api from '../../../services/api';
import { colors } from '../../../constants/colors';

const RecuperarSenhaMobile = () => {
  const history = useHistory();
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState('');
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email('Formato do email inválido')
        .required('Este campo é obrigatório'),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      api
        .post('/reset-user-password', values)
        .then(() => {
          setIsLoading(false);
          setSuccess(true);
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response.data) {
            setErrors(error.response.data.error);
          }
          return false;
        });
      return true;
    },
  });

  return (
    <MobileContainer>
      <Loading open={isLoading} />
      {success === false ? (
        <>
          <HeaderMobileLogin />
          <div className="container">
            <main>
              <div className="message">
                <strong>Esqueceu sua senha?</strong>
                <p>Não se preocupe, basta nos informar seu email.</p>
              </div>
              <div className="inputContainer">
                <InputField>
                  <p>Email</p>
                  <input
                    type="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {errors || (formik.touched.email && formik.errors.email) ? (
                    <div className="errors">
                      {formik.errors.email || errors}
                    </div>
                  ) : null}
                </InputField>
              </div>
              <ButtonWhite onClick={formik.handleSubmit}>
                <span>Enviar</span>
              </ButtonWhite>
            </main>
          </div>
        </>
      ) : (
        <div className="successContainer">
          <img src={logoNume} alt="logo-nume" />

          <div>
            <img src={Logo} alt="logo" />
            <strong>Tudo certo!</strong>
            <p>
              Foram enviadas instruções para o seu e-mail, para recuperar sua
              senha. Verifique sua caixa de entrada e seus spams, e siga as
              instruções.
            </p>
            <ButtonWhite onClick={() => history.push('/login')}>
              <span>Voltar ao login</span>
            </ButtonWhite>
          </div>
        </div>
      )}
    </MobileContainer>
  );
};

export default RecuperarSenhaMobile;
