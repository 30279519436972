import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  .container {
    width: 100%;
    height: 100%;
    #payment-form{
      display: none;
    }
    .header {
      width: 100%;
      height: 100px;
      display: flex;
      align-items: center;
      box-shadow: 0px 4px 12px rgba(17, 14, 27, 0.13);
      padding: 24px;

      h1 {
        font-size: 32px;
        color: ${colors.darkOrange};
      }
    }
    .content {
      width: 100%;
      height: calc(90% - 100px);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 24px;
      color: ${colors.darkGray};

      #subtitle {
        width: 100%;
        height: 50%;
        padding-top: 3em;
        span {
          font-size: 20px;
        }
        p {
          padding-top: 2em;
          width: 65%;
          font-size: 14px;
          color: ${colors.gray};
          opacity: 0.7;
        }
      }
      #wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        span {
          font-weight: 600;
        }
        #inputs-container {
          width: 80%;
          height: 200px;
          padding-top: 1em;
          margin-top: 1em;
          box-shadow: 0px 18px 36px rgba(17, 14, 27, 0.1),
            0px 5.42647px 10.8529px rgba(17, 14, 27, 0.065102),
            0px 2.25388px 4.50776px rgba(17, 14, 27, 0.0499628),
            0px 0.815184px 1.63037px rgba(17, 14, 27, 0.0348159);
          border-radius: 16px;
          /* background-color: yellow; */
          #radioButton-container {
            display: flex;
            width: 50%;
            justify-content: flex-start;
            align-items: center;
            height: 15%;
            flex-direction: row;
            padding-left: 1em;

            .radio-button {
              width: 20px;
              height: 20px;
            }
            span {
              padding-left: 0.5em;
              padding-right: 1.5em;
              font-size: 14px;
            }
          }

          .inputsContent {
            width: 100%;
            display: grid;
            /* align-items: center; */
            /* flex-wrap: wrap; */
            /* justify-content: space-evenly; */
            grid-template-areas: ' name  number ' 'validation cvv';
            padding: 0 1em;

            fieldset {
              width: 100%;
              border: none;
              margin-top: 10px;
              position: relative;

              p {
                font-size: 14px;
                color: ${colors.mediumGray};
              }

              input {
                border: none;
                border-bottom: 1px solid ${colors.mediumGray};
                color: ${colors.mediumGray};
                width: 100%;
                outline: none;
                margin-top: 8px;
                font-size: 14px;

                :focus {
                  border-bottom: 1px solid ${colors.orange};
                }
              }
            }
            .name{
              grid-area: name ;
              width: 90%;
            }
            .number{
              grid-area: number ;
              width: 90%;
            }
            .validation{
              grid-area: validation ;
              width: 35%;
            }
            .cvv{
              grid-area: cvv ;
              width: 35%;
            }
            .warning {
              margin-top: 25px;
              display: flex;
              width: 100%;
              align-items: center;

              p {
                margin-left: 3px;
                color: ${colors.mediumGray};
                padding: 0;
                width: 100%;
                font-size: 14px;
              }
            }
          }
        }
        .actions {
          width: 80%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;

          .error {
      .errors {
        color: red;
      }
        }
      }
    }
  }
`;
