export const Types = {
  LOGIN_REQUEST: 'auth/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'auth/LOGIN_SUCCESS',
  LOGIN_FAILURE: 'auth/LOGIN_FAILURE',
  UPDATE_USER_REQUEST: 'auth/UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'auth/UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'auth/UPDATE_USER_FAILURE',
  UPDATE_EMPLOYEE_REQUEST: 'auth/UPDATE_EMPLOYEE_REQUEST',
  UPDATE_EMPLOYEE_SUCCESS: 'auth/UPDATE_EMPLOYEE_SUCCESS',
  UPDATE_EMPLOYEE_FAILURE: 'auth/UPDATE_EMPLOYEE_FAILURE',
  LOGIN_ADM_REQUEST: 'auth/LOGIN_ADM_REQUEST',
  LOGIN_ADM_SUCCESS: 'auth/LOGIN_ADM_SUCCESS',
  LOGIN_ADM_FAILURE: 'auth/LOGIN_ADM_FAILURE',
  LOGOUT: 'auth/LOGOUT',
  UPDATE_USER_ON_REDUCER: 'auth/UPDATE_USER_ON_REDUCER',
};

const initialState = {
  user: {},
  plan: {},
  adm: {},
  error: '',
  token: '',
  isLogged: false,
  isLoggedAdm: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        isLogged: true,
        isLoggedAdm: false,
        adm: {},
        error: '',
        plan: action.payload.plan,
      };

    case Types.LOGIN_FAILURE:
      return {
        ...state,
        isLogged: false,
        isLoggedAdm: false,
        token: '',
        user: {},
        adm: {},
        error: action.payload,
        plan: {},
      };

    case Types.LOGIN_ADM_SUCCESS:
      return {
        ...state,
        adm: action.payload.user,
        user: {},
        token: action.payload.token,
        isLogged: false,
        isLoggedAdm: true,
        error: '',
        plan: {},
      };

    case Types.LOGIN_ADM_FAILURE:
      return {
        ...state,
        isLogged: false,
        isLoggedAdm: false,
        token: '',
        user: {},
        adm: {},
        error: action.payload,
        plan: {},
      };

    case Types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        plan: action.payload.DataPlan,
        error: '',
      };

    case Types.UPDATE_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case Types.UPDATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        user: action.payload,
        error: '',
      };

    case Types.UPDATE_EMPLOYEE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case Types.LOGOUT:
      return initialState;

    case Types.UPDATE_USER_ON_REDUCER:
      return { ...state, user: { ...state.user, ...action.payload } };

    default:
      return state;
  }
}

export function LoginRequest(email, password) {
  return {
    type: Types.LOGIN_REQUEST,
    payload: { email, password },
  };
}

export function LoginSuccess(user, token, plan) {
  return {
    type: Types.LOGIN_SUCCESS,
    payload: { user, token, plan },
  };
}

export function LoginFailure(error) {
  return {
    type: Types.LOGIN_FAILURE,
    payload: error,
  };
}

export function LoginAdmRequest(email, password) {
  return {
    type: Types.LOGIN_ADM_REQUEST,
    payload: { email, password },
  };
}

export function LoginAdmSuccess(user, token) {
  return {
    type: Types.LOGIN_ADM_SUCCESS,
    payload: { user, token },
  };
}

export function LoginAdmFailure(error) {
  return {
    type: Types.LOGIN_ADM_FAILURE,
    payload: error,
  };
}

export function UpdateUserRequest(user) {
  return {
    type: Types.UPDATE_USER_REQUEST,
    payload: user,
  };
}

export function UpdateUserSuccess(user, DataPlan) {
  return {
    type: Types.UPDATE_USER_SUCCESS,
    payload: { user, DataPlan },
  };
}

export function UpdateUserFailure(error) {
  return {
    type: Types.UPDATE_USER_FAILURE,
    payload: error,
  };
}

export function UpdateEmployeeRequest(user) {
  return {
    type: Types.UPDATE_EMPLOYEE_REQUEST,
    payload: user,
  };
}

export function UpdateEmployeeSuccess(user) {
  return {
    type: Types.UPDATE_EMPLOYEE_SUCCESS,
    payload: user,
  };
}

export function UpdateEmployeeFailure(error) {
  return {
    type: Types.UPDATE_EMPLOYEE_FAILURE,
    payload: error,
  };
}

export function Logout() {
  return {
    type: Types.LOGOUT,
  };
}

export function UpdateUserOnReducer(user) {
  return {
    type: Types.UPDATE_USER_ON_REDUCER,
    payload: user,
  };
}
