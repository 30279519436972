import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  background: transparent;
  width: 300px;
  height: 150px;
  content: '';
  position: relative;
  img {
    position: absolute;
    object-fit: contain;
    transform: translateY(-50%);
  }
  #rs {
    width: 13%;
    top: 45%;
    left: 8%;
  }
  #preco {
    width: 55%;
    top: 50%;
    left: 25%;
  }
  #mes {
    width: 18%;
    top: 58%;
    right: 0%;
  }
`;
