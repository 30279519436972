import styled from 'styled-components';
import { ButtonContainer } from '../../components/Button/styles';
import { colors } from '../../constants/colors';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  .container {
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    overflow-y: auto;
    section {
      display: flex;
      flex-direction: column;
      h1 {
        font-size: 24px;
        line-height: 36px;
        color: ${colors.darkOrange};
      }
      .inputContainer {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;
        fieldset {
          width: 30%;
          border: none;
          margin-top: 27px;
          p {
            font-size: 14px;
            color: ${colors.mediumGray};
            input :focus {
              p {
                color: red;
              }
            }
          }
          input {
            border: none;
            border-bottom: 1px solid ${colors.mediumGray};
            color: ${colors.mediumGray};
            width: 100%;
            outline: none;
            margin-top: 8px;
            font-size: 14px;
            background-color: transparent;
            :focus {
              border-bottom: 1px solid ${colors.orange};
            }
          }
        }
      }
    }
    .download {
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 15px;
    }
  }

  .header {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    box-shadow: 0px 4px 12px rgba(17, 14, 27, 0.13);

    h1 {
      font-size: 32px;
      line-height: 36px;
      color: ${colors.darkOrange};
    }

    p {
      color: red;
      font-size: 13px;
    }
  }
`;

export const Button = styled(ButtonContainer)`
  background-color: transparent;
  border: 2px solid ${colors.pink};
  width: 200px;
  height: 50px;
  span {
    color: ${colors.pink};
  }
  &:hover {
    background-color: ${colors.pink};
    span {
      color: #fff;
    }
  }
  &:active {
    background-color: #fff;
    span {
      color: ${colors.pink};
    }
  }
`;
