import React from 'react';
import { Helmet } from 'react-helmet';
import MetaTags from 'react-meta-tags';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import DuvidasMEI from '../../components/DuvidasMEI';
import SectionOne from './SectionOne';
import SectionTwo from './SectionTwo';
import SectionThree from '../Planos/SectionFour';

function FAQ() {
  return (
    <>
      <Helmet>
        <title>Nume | FAQ</title>
      </Helmet>
      <Header />
      <SectionOne />
      <SectionTwo />
      <DuvidasMEI />
      <SectionThree />
      <Footer />
    </>
  );
}

export default FAQ;
