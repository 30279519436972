/* eslint-disable react/prop-types */
import React from 'react';

import { Container } from './styles';

// import logoSquare from '../../../assets/svg/logoNUME.svg';

function SideBar({ children }) {
  return (
    <Container>
      <div id="line" />
      <div id="wrapper">
        {/*  <div id="square">
          <img id="logoSquare" src={logoSquare} alt="logo da nume" />
          <span>texto</span>
        </div> */}
        {children}
        <ul className="userTerms">
          <li id="termos">
            <a
              href="https://numebucket.s3.amazonaws.com/Termo_uso_11112020.pdf"
              target="__blank"
              rel="noreferer"
            >
              Termos de uso
            </a>
          </li>
          <li id="termos">
            <a
              href="https://numebucket.s3.amazonaws.com/Declara%C3%A7%C3%A3o+de+privacidade-NUME+(1).pdf"
              target="__blank"
              rel="noreferer"
            >
              Políticas de privacidade
            </a>
          </li>
        </ul>
      </div>
    </Container>
  );
}

export default SideBar;
