import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import { Container } from './styles';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import DuvidasMEI from '../../components/DuvidasMEI';
import SectionOne from './SectionOne';
import SectionTwo from './SectionTwo';
import SectionThree from './SectionThree';
import SectionFour from './SectionFour';

function Planos() {
  const location = useLocation();
  const planosRef = useRef(null);

  useEffect(() => {
    if (location.state?.id === 'planos') {
      planosRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location.state]);

  return (
    <Container>
      <Helmet>
        <title>Nume | Ja tenho MEI</title>
      </Helmet>
      <Header />
      <SectionOne />
      <SectionTwo />
      <SectionThree refProp={planosRef} />
      <DuvidasMEI />
      <SectionFour className="Four" />
      <Footer />
    </Container>
  );
}

export default Planos;
